import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './FileDragAndDrop.css';

interface IFileDragAndDrop {
    onUpload: Function
    children: any
    count: number
    formats: string[]
}

export default function FileDragAndDrop(props: IFileDragAndDrop) {

    const drop = useRef(null);
    const drag = useRef(null);
    const [dragging, setDragging] = useState(false);

    const [status, setStatus] = useState<string>("waiting");

    useEffect(() => {
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('drop', handleDrop);
        drop.current.addEventListener('dragenter', handleDragEnter);
        drop.current.addEventListener('dragleave', handleDragLeave);

        return () => {
            drop.current.removeEventListener('dragover', handleDragOver);
            drop.current.removeEventListener('drop', handleDrop);
            drop.current.removeEventListener('dragenter', handleDragEnter);
            drop.current.removeEventListener('dragleave', handleDragLeave);
        };
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target !== drag.current) {
            setDragging(true);
            setStatus('dragging');
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target === drag.current) {
            setDragging(false);
            setStatus('waiting');
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false);

        // this is required to convert FileList object to array
        const files = [...e.dataTransfer.files];

        if (props.count && props.count < files.length) {
            showMessage('error', 2000);
            return;
        }

        if (props.formats && files.some((file) => !props.formats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase())))) {
            showMessage('error', 2000);
            return;
        }

        if (files && files.length) {
            showMessage('success', 1000);
            props.onUpload(files);
        }
    };

    const showMessage = (type, timeout) => {
        setStatus(type);

        setTimeout(() => setStatus("waiting"), timeout);
    };

    return (
        <div ref={drop} className={'FilesDragAndDrop ' + status}>
            <div ref={drag}>

                {props.children}
            </div>
        </div>
    );
}

FileDragAndDrop.propTypes = {
    onUpload: PropTypes.func.isRequired,
};