import { BaseModel } from "../interfaces/IBaseModel";

export class wramps extends BaseModel {

    "filename": string;
    "filetype": string;
    "fileblob": Blob;
    "watershedGroupName": string;
    //"email": string

    constructor() {
        super('wramps');
    }
}  