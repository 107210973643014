import React, { Component } from "react";
import IAxisRightProps from '../interfaces/IAxisRightProps';
import * as d3 from 'd3';

export default class AxisRightMillionFormat extends Component<IAxisRightProps> {
    ref: React.RefObject<SVGGElement>;

    constructor(props: IAxisRightProps) {
      super(props);
      this.ref = React.createRef();
    }
  
    componentDidMount() {
      if (this.ref.current) {
        d3.select(this.ref.current)
        // .call(d3.axisRight(this.props.scale).tickFormat(x => { return  x !== 0 ? (String(x).substring(0,2) + " M" ) : "0 M" } ));
        // .call(d3.axisRight(this.props.scale).tickFormat(x => { return  x !== 0 ? x !== 5000000 ? (String(x).substring(0,2) + " M" ) : "5 M" : "0 M" } ));
        // .call(d3.axisRight(this.props.scale).tickFormat(x => { return  x !== 0 ? x < 10000000 ? (String(x).substring(0,2) + " M" ) : (String(x).substring(0,1) + " M" ) : "0 M" } ));
          // .call(d3.axisRight(this.props.scale).tickFormat(x => { console.log(x); return `${(x / 1000000)} M`}));
          .call(d3.axisRight(this.props.scale).tickFormat(d3.format(".2s")));

      }
    }
  
    componentDidUpdate() {
      if (this.ref.current) {
        d3.select(this.ref.current)
          .transition()
          // .call(d3.axisRight(this.props.scale).tickFormat(x => { return  x !== 0 ? x < 10000000 ? (String(x).substring(0,2) + " M" ) : (String(x).substring(0,1) + " M" ) : "0 M" } ));
          // .call(d3.axisRight(this.props.scale).tickFormat(x => { return (String(x).replace(/0/g, '')) + " M" } ));
          // .call(d3.axisRight(this.props.scale).tickFormat(x => {  console.log(x); return `${(x / 1000000)} M`}));
          .call(d3.axisRight(this.props.scale).tickFormat(d3.format(".2s")));

      }
    }
  
    render() {
      // className={this.props.class != null ? this.props.class : null}
      return <g color={this.props.color} fontSize={12} ref={this.ref} transform={`translate(${this.props.position}, 0)`} />;
      // style={{color: this.props.color}} 
    }
}