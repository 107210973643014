import React, { Component } from "react";
import * as d3 from 'd3';
import IAxisBottomProps from '../interfaces/IAxisBottomProps';
import ILabel from "../interfaces/ILabel";

export default class LabelLeft extends Component<ILabel> {
    ref: React.RefObject<SVGGElement>;

    constructor(props: ILabel) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        if (this.ref.current) {
            d3.select(this.ref.current).append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - this.props.margin.left + 5)
                .attr("x", 0 - (this.props.height / 2))
                .attr("dy", "1em")
                .attr("text-anchor", "middle")
                .attr("font-size", this.props.fontSize)
                .attr("font-family", "sans-serif")
                .attr("font-weight", "bold")
                .attr("letter-spacing", "0.1em")
                .text(this.props.text);
        }
    }

    componentDidUpdate() {
        if (this.ref.current) {
            d3.select(this.ref.current).selectAll("text").remove();

            d3.select(this.ref.current).append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - this.props.margin.left + 5)
                .attr("x", 0 - (this.props.height / 2))
                .attr("dy", "1em")
                .attr("text-anchor", "middle")
                .attr("font-size", this.props.fontSize)
                .attr("font-family", "sans-serif")
                .attr("font-weight", "bold")
                .attr("letter-spacing", "0.1em")
                .text(this.props.text);
        }
    }

    render() {
        return <g ref={this.ref} />;
    }
}