import React, { Component } from "react";
import './networkSidePanel.css';
import { identifier } from "@babel/types";
import { INetworkViewer } from "../../../interfaces/INetworkViewer";
import { networkviewer } from "../../../models/networkAP";
import { AxiosResponse } from "axios";
import IDataXY from "../../Optimization/D3/chart/interfaces/IDataXY";
import { networktimeseriesitem, networktimeseries } from "../../../models/networktimeseries";
import { networkdownload } from "../../../models/networkdownload";
import { Label, Dropdown, Button, Table, TableHeader, TableRow, TableBody } from "semantic-ui-react";
import logo from '../../../images/WMMS_logo_full.png';
import ResponsiveChart from "../../Optimization/D3/ResponsiveChart";
import TimeseriesLineChart from "../../Optimization/D3/TimeseriesLineChart";
import { DropdownOption } from "../../DOMObjects/IDropdownProps"
import DropDownH20 from "../../DOMObjects/Dropdown"
import PrecipTimeseriesChart from "../../Optimization/D3/PrecipTimeseriesChart";
import Config from "../../../config/Config";
import DropdownH2O from "../../DOMObjects/Dropdown";
import { PrecipTimeSeriesData } from "../../Optimization/D3/chart/PrecipTimeseriesSVG";
import DataXY from "../../Optimization/D3/chart/interfaces/IDataXY";
import HrefLink from "../../../core/hrefLink/hrefLink";


export class NetworkSidePanelState {
    viewToShow: number = 1;
    firstDropdownSelection: string = "";
    secondDropdownSelection: string = "";
    thirdDropdownSelection: string = "";
    thirdDropDownOptions: [];
    thirdDropdownIndex: number = 0;
    timeseriesData: Array<IDataXY>;
    preciptimeseriesData: Array<PrecipTimeSeriesData>;
    outData: networktimeseries;
    preData: networktimeseries;
    selAP: string = "";
    timeseriesStats: TimeseriesStats = new TimeseriesStats();
    startDate: number;
    endDate: number;
    zoomed: boolean = false;
    zoomingStartDate: number;
    zoomingEndDate: number;
    numFadeOut: boolean = false;
    dataUnits: string = "";
    downloadFileSpinner: boolean = false;
    statRound: number = 2;
}

export class TimeseriesStats {
    min: number;
    max: number;
    average: number;
    perc5: number;
    perc10: number;
    perc25: number;
    perc50: number;
    perc75: number;
    perc90: number;
    perc95: number;
    stdev: number;
    variationCoeff: number; 
}

export class TimeseriesData {
    x: number;
    y: number;
}

export default class NetworkSidePanel extends Component<INetworkViewer> {

    state = new NetworkSidePanelState();

    constituentMapping: any[] = [
        { index: 0, constituent: "Flow Rate", units: "cfs" }, 
        { index: 1, constituent: "Total Suspended Solids", units: "mg/l" }, 
        { index: 2, constituent: "Total Nitrogen", units: "mg/l" }, 
        { index: 3, constituent: "Total Phosphorous", units: "mg/l" }, 
        { index: 4, constituent: "Total Cadmium", units: "ug/l" }, 
        { index: 5, constituent: "Total Copper", units: "ug/l" }, 
        { index: 6, constituent: "Total Lead", units: "ug/l" }, 
        { index: 7, constituent: "Total Zinc", units: "ug/l" }      
    ]

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {
        this.buildTimeseriesData();
    }

    componentDidUpdate() {
        // Selecting a new / different assessment point from the map
        if (this.props.apData != null && this.props.apData.assessmentPointId != null && this.props.apData.assessmentPointId != this.state.selAP) {
            this.setState({ selAP: this.props.apData.assessmentPointId, loading: true }, () => {
                this.setDropDowns();
            })
        }
    }

    handleDateChange = (downClick: number, upClick: number) => {
        console.log('handle clicks sidepanel', downClick, upClick);
        if (downClick != null && upClick != null && downClick != upClick) {
            if (downClick < upClick) {
                this.setState({
                    startDate: downClick,
                    endDate: upClick,
                    zoomed: true, 
                    zoomingStartDate: null,
                    zoomingEndDate: null
                }, () => { this.buildTimeseriesData(); })
            } else {
                this.setState({
                    startDate: upClick,
                    endDate: downClick,
                    zoomed: true,
                    zoomingStartDate: null,
                    zoomingEndDate: null
                }, () => { this.buildTimeseriesData(); })
            }
        }
    }

    zoomingHandler = (zoomStart: number, zoomEnd: number) => {
        if (zoomStart != null && zoomEnd != null) {
            this.setState({ zoomingStartDate: zoomStart, zoomingEndDate: zoomEnd });
        } else {
            this.setState({ zoomingStartDate: null, zoomingEndDate: null });
        }
    }

    resetZoom = () => {
        if (this.state.outData != null) {
            let startDate = Date.parse(this.state.outData.data[0].date.toString());
            let endDate = Date.parse(this.state.outData.data[this.state.outData.data.length - 1].date.toString());
            this.setState({
                startDate: startDate,
                endDate: endDate,
                zoomed: false,
                zoomingStartDate: null,
                zoomingEndDate: null
            }, () => { this.buildTimeseriesData(); })
        }
    }

    buildTimeseriesData = () => {
        if (this.state.outData != null) {
            var coordinates = this.state.outData.data.map((i: networktimeseriesitem) => ({ x: Date.parse(i.date.toString()), y: i.values[this.state.thirdDropdownIndex] })).filter((item: TimeseriesData) => (item.x >= this.state.startDate && item.x <= this.state.endDate));
            if (coordinates.length > 16600) { // create subset if dataset is too large
                //console.log('too large', coordinates.length);
                let group: number = Math.round(coordinates.length / 10000); // target about 10000 points when done...
                let coordinatesGroup: Array<DataXY> = new Array<DataXY>();
                let groupCnt: number = 0;
                let maxItem: DataXY = { x: -1, y: -1 };
                coordinatesGroup.push(coordinates[0]);
                for (var i = 0; i < coordinates.length; i++) {
                    if (maxItem.y < coordinates[i].y) {
                        maxItem.x = coordinates[i].x;
                        maxItem.y = coordinates[i].y;
                    }

                    if (groupCnt >= group) {
                        groupCnt = 0;
                        if (maxItem.x > -1) {
                            coordinatesGroup.push(maxItem);
                        }
                        maxItem = { x: -1, y: -1 };
                    }

                    groupCnt++;
                }
                coordinatesGroup.push(coordinates[coordinates.length - 1]);
                coordinates = coordinatesGroup;
            }
            //console.log('coordinates', coordinates.length);
            coordinates = this.padTimeseriesData(coordinates);
            this.props.updateLoadingFunction(false, "", this.props.networkContext);
            this.setState({ timeseriesData: coordinates }, () => { this.buildTimeseriesStats();});
        } else {
            this.props.updateLoadingFunction(false, "", this.props.networkContext);
            this.setState({ timeseriesData: [{ x: 0, y: 0 }]});
        }

        if (this.state.preData != null) {
            let coordinates: Array<PrecipTimeSeriesData> = this.state.preData.data.map((i: networktimeseriesitem) => (new PrecipTimeSeriesData(new Date(i.date.toString()), i.values[0]))).filter((item: PrecipTimeSeriesData) => (item.id >= this.state.startDate && item.id <= this.state.endDate));
            //if (coordinates.length > 10000) { console.log('too large pre', coordinates.length);}
            //.filter((item: PrecipTimeSeriesData) => (item.id >= this.state.startDate && item.id <= this.state.endDate))
            let msg: string = "No Precipitation Data Found";
            if (coordinates.length > 0) { msg = ""; }
            coordinates = this.padPrecipTimeseriesData(coordinates);
            this.props.updateLoadingFunction(null, msg, this.props.networkContext);
            this.setState({ preciptimeseriesData: coordinates });
        } else {
            this.props.updateLoadingFunction(null, "No Precipitation Data Found", this.props.networkContext);
            this.setState({ preciptimeseriesData: [{ x: 0, y: 0 }] });
        }
    }

    padTimeseriesData = (coordinates: Array<TimeseriesData>) => {
        if (coordinates.length > 0) {
            if (coordinates[0].x > this.state.startDate) { coordinates.splice(0, 0, { x: this.state.startDate, y: 0 }); }
            if (coordinates[coordinates.length - 1].x < this.state.endDate) { coordinates.push({ x: this.state.endDate, y: 0 }); }
        } else {
            coordinates.push({ x: this.state.startDate, y: 0 });
            coordinates.push({ x: this.state.endDate, y: 0 });
        }
        return coordinates;
    }

    padPrecipTimeseriesData = (coordinates: Array<PrecipTimeSeriesData>) => {
        if (coordinates.length > 0) {
            if (coordinates[0].id > this.state.startDate) { coordinates.splice(0, 0, new PrecipTimeSeriesData(new Date(this.state.startDate), 0 )); }
            if (coordinates[coordinates.length - 1].id < this.state.endDate) { coordinates.push(new PrecipTimeSeriesData(new Date(this.state.endDate), 0)); }
        } else {
            coordinates.push(new PrecipTimeSeriesData(new Date(this.state.startDate), 0));
            coordinates.push(new PrecipTimeSeriesData(new Date(this.state.endDate), 0));
        }
        return coordinates;
    }

    buildTimeseriesStats = () => {
        let stats: TimeseriesStats = new TimeseriesStats();
        if (this.state.outData != null) {
            let dataArr: number[] = new Array<number>();
            this.state.timeseriesData.map((item) => { dataArr.push(item.y); });
            dataArr.sort((a: number, b: number) => { return a - b; });
            stats.min = dataArr[0];
            stats.max = dataArr[dataArr.length - 1];
            stats.average = dataArr.reduce((a, b) => a + b, 0) / dataArr.length;

            stats.perc5 = this.quartile(dataArr, 0.05);
            stats.perc10 = this.quartile(dataArr, 0.10);
            stats.perc25 = this.quartile(dataArr, 0.25);
            stats.perc50 = this.quartile(dataArr, 0.5);
            stats.perc75 = this.quartile(dataArr, 0.75);
            stats.perc90 = this.quartile(dataArr, 0.90);
            stats.perc95 = this.quartile(dataArr, 0.95);

            //st.dev
            const n = dataArr.length;
            const mean = dataArr.reduce((a, b) => a + b) / n;
            stats.stdev = Math.sqrt(dataArr.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);

            stats.variationCoeff = stats.stdev / stats.average;
        } 

        let rounding: number = 2;
        if (stats.max == null) { rounding = 2; }
        else if (stats.max > 100) { rounding = 1; }
        else if (stats.max <= 100 && stats.max > 10) { rounding = 2; }
        else if (stats.max <= 10.0) { rounding = 3; }
        
        this.setState({ numFadeOut: true }, () => {
            setTimeout(() => {
                //run or call logic here with timeout to animate table numebers 
                this.setState({ numFadeOut: false, timeseriesStats: stats, statRound: rounding });
              }, 500);
        })

    }

    quartile = (data, q) => {
        var pos = ((data.length) - 1) * q;
        var base = Math.floor(pos);
        var rest = pos - base;
        if ((data[base + 1] !== undefined)) {
            return data[base] + rest * (data[base + 1] - data[base]);
        } else {
            return data[base];
        }
    }

    setDropDowns = () => {
        // figure out initial selections
        
        let distinctSimulations: string[] = this.props.apData.data.map(item => item.simulation).filter((value, index, self) => self.indexOf(value) === index);
        let selSimulation: string = distinctSimulations[0];;
        if (distinctSimulations.indexOf("Long Term") > -1) {
            selSimulation = "Long Term";
        } 

        let eventOptions = this.props.apData.data.filter((item) => item.simulation == selSimulation).map(item => item.event);
        let selEvent: string = eventOptions[0];
        if (eventOptions.indexOf("Daily") > -1) {
            selEvent = "Daily";
        }

        this.setState({ firstDropdownSelection: selSimulation, secondDropdownSelection: selEvent }, () => { this.getAssessmentPointData(selSimulation, selEvent);})

    }

    updateDropdownOne = (classContext: any) => {
        if (classContext.props.dropdownItems.length > 0) {
            if (classContext.state.value !== this.state.firstDropdownSelection) {
                classContext.setState({value: this.state.firstDropdownSelection, loading: false})
            };
        };
    }

    updateDropdownTwo = (classContext: any) => {
        if (classContext.props.dropdownItems.length > 0) {
            if (classContext.state.value !== this.state.secondDropdownSelection) {
                classContext.setState({value: this.state.secondDropdownSelection, loading: false})
            };
        };
    };

    updateDropdownThree = (classContext: any) => {
        if (classContext.props.dropdownItems.length > 0) {
            if (classContext.state.value !== this.state.thirdDropdownSelection) {
                classContext.setState({value: this.state.thirdDropdownSelection, loading: false})
            };
        };



        if (classContext.props.dropdownItems.length > 0) {
            this.constituentMapping.forEach((m) => {
                if (this.state.thirdDropdownSelection == m.constituent) {
                    if (this.state.dataUnits !== m.units ) {
                        this.setState({dataUnits: m.units})
                    };
                };
            });
        };
    };

    downloadFile = () => {
        let dataPath: string = this.props.apData.data.filter(item => item.simulation == this.state.firstDropdownSelection && item.event == this.state.secondDropdownSelection)[0].outFile;
        if (dataPath != null && dataPath.length > 0) {
            this.setState({ downloadFileSpinner: true });
            let filename: string = dataPath.replace(/^.*[\\\/]/, '');
            networkdownload.axiosGetAll({ path: dataPath }).then((r: AxiosResponse) => {
                const blob = new Blob([r.data], { type: 'text/plain' });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var link = document.createElement('a');
                    var href = window.URL.createObjectURL(blob);
                    link.setAttribute('href', href);
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    setTimeout(() => {
                        window.URL.revokeObjectURL(href);
                        document.body.removeChild(link);
                    }, 0)
                    this.setState({ downloadFileSpinner: false });
                }
            }).catch((r: AxiosResponse) => {
                console.log('error download', r);
                this.setState({ downloadFileSpinner: false });
            })
        }
        
    }

    getAssessmentPointData = (simulation: string, event: string) => {
        let dataPath: string = this.props.apData.data.filter(item => item.simulation == simulation && item.event == event)[0].outFile;
        let prePath: string = this.props.apData.data.filter(item => item.simulation == simulation && item.event == event)[0].preFile;
        // get first file based on initial selection

        // fetch out data
        networktimeseries.axiosGetAll({ path: dataPath }).then((r: AxiosResponse) => {
            //console.log('out data res', r.data);
            let outData: networktimeseries = r.data;
            let thirdOptions = [];
            //console.log('get data', this.constituentMapping);
            for (let i: number = 0; i < outData.data[0].values.length; i++) {
                //console.log('in loop', i, outData.data[0].values.length, this.constituentMapping[i])
                if (i < this.constituentMapping.length) {
                    thirdOptions.push(this.constituentMapping[i].constituent);
                }
            }
            let startDate = Date.parse(outData.data[0].date.toString());
            let endDate = Date.parse(outData.data[outData.data.length -1].date.toString());
            this.setState({ outData: outData, thirdDropdownSelection: thirdOptions[0], thirdDropDownOptions: thirdOptions, thirdDropdownIndex: 0, startDate: startDate, endDate: endDate }, () => {
                if (prePath == null || prePath.length == 0) {
                    this.setState({ preData: new networktimeseries(), preciptimeseriesData: new Array<PrecipTimeSeriesData>() }, () => {
                        this.buildTimeseriesData();
                    })
                } else {
                    // fetch pre data
                    networktimeseries.axiosGetAll({ path: prePath }).then((r: AxiosResponse) => {
                        //console.log('out data res', r.data);
                        let preData: networktimeseries = r.data;
                        this.setState({ preData: preData }, () => {
                            this.buildTimeseriesData();
                        });
                    }).catch((r: AxiosResponse) => {
                        console.log('error getting data', r);
                        this.props.updateLoadingFunction(false, "Error retrieving data", this.props.networkContext);

                    });
                }
            });
        }).catch((r: AxiosResponse) => {
            console.log('error getting data', r);
            this.props.updateLoadingFunction(false, "Error retrieving data", this.props.networkContext);
        });
    }



    dropdownFuctionOne = (event: any, data: any, index: any) => {


        if (this.props.apData != null && this.props.apData.data != null) {
            let selEvent = this.props.apData.data.filter((item) => item.simulation == data.value)[0].event;
            this.props.updateLoadingFunction(true, "Loading...", this.props.networkContext);
            this.setState({ firstDropdownSelection: data.value, secondDropdownSelection: selEvent }, () => {
                this.getAssessmentPointData(data.value, selEvent);
            });
        };

    }


    getFirstDropdownOptions = (): DropdownOption[] => {

        if (this.props.apData != null && this.props.apData.data != null) {
            return  this.props.apData.data.map(item => item.simulation).filter((value, index, self) => self.indexOf(value) === index).map((option, index) => {
                let dropOption = new DropdownOption();
                dropOption.optionText = option;
                dropOption.optionValue = option;
                return dropOption;
            });
        } else {
            return [];
        };

    };


    dropdownFunctionTwo = (event: any, data: any, index: any) => {
        this.props.updateLoadingFunction(true, "Loading...", this.props.networkContext);
        this.setState({ secondDropdownSelection: data.value}, () => {
            this.getAssessmentPointData(this.state.firstDropdownSelection, data.value);
        });
    }

    getSecondDropdownOptions = (): DropdownOption[] => {
        

        if (this.props.apData != null && this.props.apData.data != null ) {

            return this.props.apData.data.filter((item) => item.simulation == this.state.firstDropdownSelection).map((option, index) => {
                let dropOption = new DropdownOption();
                dropOption.optionText = option.event;
                dropOption.optionValue = option.event;
                return dropOption;
            });

        } else {
            return [];
        };
        

    };

    dropdownFunctionThree = (event: any, data: any, index: any) => {
        // add unit selection here
        console.log('third drop-down', data);
        let key = 0;

        this.state.thirdDropDownOptions.forEach((o, thisIndex) => {
            if (o == data.value) {
                key = thisIndex;
            }
        });

        this.setState({ thirdDropdownIndex: key, thirdDropdownSelection: data.value }, () => {
            this.buildTimeseriesData();
        })
    };

    getThirdDropdownOptions = () => {
        
        if (this.state.outData != null) {

            return this.state.thirdDropDownOptions.map((option, index) => {
                let dropOption = new DropdownOption();
                dropOption.optionText = option;
                dropOption.optionValue = option;
                return dropOption;
            });

        } else {
            return [];
        };

    };

    render() {

        let graphWidth = (window.innerWidth * .65) - 65;

        let classContext: NetworkSidePanel = this;
        // these are props that will be passed to the d3 chart
        let chartprops = {
            height: 400,
            width: graphWidth,    // cheating for now. should be dynamic from div and screensize...
            fill: "none",
            color: "#2185d0",
            linetype: "line",
            data: classContext.state.timeseriesData, 
            clickHandler: this.handleDateChange,
            zoomHandler: this.zoomingHandler,
            zoomStart: this.state.zoomingStartDate,
            zoomEnd: this.state.zoomingEndDate,
            units: this.state.dataUnits, 
            loading: this.props.loading
        }

        let precipchartprops = {
            height: 120,
            width: graphWidth,    // cheating for now. should be dynamic from div and screensize...
            fill: "#4BC9EF",
            color: "#4BC9EF",
            linetype: "area",
            data: classContext.state.preciptimeseriesData,
            clickHandler: this.handleDateChange,
            zoomHandler: this.zoomingHandler,
            zoomStart: this.state.zoomingStartDate,
            zoomEnd: this.state.zoomingEndDate,
            noData: this.state.preData != null && this.state.preData.data.length > 0 ? false : true,
            precipMsg: this.props.precipMsg,
            loading: this.props.loading
        }

        //bmpData[bmp.name] = [{ x: reduction, y: bmp.value }];


        // Create params for dropdowns
        let dropDownOne = {
            dropdownTitle: "Time Series Type",
            dropdownItems: this.getFirstDropdownOptions(),
            onSelect: this.dropdownFuctionOne,
            isMultiselect: false,
            onUpdate: this.updateDropdownOne
        }

        let dropDownTwo = {
            dropdownTitle: "Time Step",
            dropdownItems:this.getSecondDropdownOptions(),
            onSelect: this.dropdownFunctionTwo,
            isMultiselect: false,
            onUpdate: this.updateDropdownTwo

        }

        let dropDownThree = {
            dropdownTitle: "Constituent",
            dropdownItems: this.getThirdDropdownOptions(),
            onSelect: this.dropdownFunctionThree,
            isMultiselect: false,
            onUpdate: this.updateDropdownThree

        }

        return (
            <div className="network-side-panel-container">
                <div className="network-side-panel-inner-container">
                    {
                        this.props.view == 1 &&
                            <div className="network-welcome-div">
                                <div className="wmms-blue-header-div wmms-blue-header">
                                    <img className="mainLogo" src={logo} alt="wmms2Logo" />
                                </div>
                                <div className="side-panel-subtext">
                                    <p >
                                    Welcome to the Watershed Management Modeling System, Los Angeles County’s most advanced platform for visioning future strategies to improve water quality, water supply and flood control.  The Los Angeles County Flood Control District developed WMMS to allow for simulation of all the major watersheds within Los Angeles County including hydrologic and pollutant generation processes, transport routines, and forecasts of benefits that could be achieved by stormwater capture infrastructure and source control programs.  WMMS is built upon two open source, USEPA-developed models – LSPC and SUSTAIN; learn more about WMMS on the <HrefLink endpointUrl={Config.appUrl + 'about'} linkText='About' wmmsColor='sustain' /> page. 
                                    </p>
                                    <p>
                                    The interactive map to the right shows the major elements of the LSPC model within WMMS 2.0 and allows for visualization of WMMS 2.0 outputs. The WMMS 2.0 configuration and calibration process incorporates:
                                    </p>
                                    <ul>
                                        <li className="side-panel-list-padding">3,117 square miles of model domain</li>
                                        <li className="side-panel-list-padding">2,500+ miles of explicitly modeled stream network</li>
                                        <li className="side-panel-list-padding">2,655 subwatersheds</li>
                                        <li className="side-panel-list-padding">4,066 ‘jurisheds’</li>
                                        <li className="side-panel-list-padding">662 precipitation nodes for weather boundary conditions</li>
                                        <li className="side-panel-list-padding">11 water reclamation plants</li>
                                        <li className="side-panel-list-padding">26 dams</li>
                                        <li className="side-panel-list-padding">26 spreading grounds</li>
                                        <li className="side-panel-list-padding">109 debris basins</li>
                                        <li className="side-panel-list-padding">23 stream diversions</li>
                                        <li className="side-panel-list-padding">34 hydrologic calibration stations, and</li>
                                        <li className="side-panel-list-padding">83 water quality calibration stations</li>
                                    </ul>
                                <p>To visualize the WMMS 2.0 simulation outputs, the time series for 40 'assessment points' across LA County have been extracted. Click on the assessment points (red flags) to view and download WMMS 2.0 outputs for a variety of hydrology and water quality simulations!</p>
                                </div>
                            </div>
                    }
                    {
                        this.props.view == 3 &&
                        <div className="network-side-panel-container">
                            <div className="blue-hr-top">
                                {/* here for styles */}
                            </div>


                            <div className="grey-header-container effect7">
                                <div className="network-sidepanel-header">
                                    <div className="back-icon-div icon-div">
                                        <i aria-hidden="true" className="arrow left icon back-icon" onClick={((e: React.MouseEvent) => {this.props.renderSidePanelView(1, this.props.networkContext); this.props.renderMainView(1, this.props.networkContext);  this.props.networkContext.childEsriMapRef.current.ZoomTo();})}></i>
                                    </div>
                                    <div className="side-panel-details-header wmms-blue-header">
                                        <div>
                                            <i aria-hidden="true" className="code branch icon"></i> Simulation Output Viewer
                                        </div>
                                    </div>
                                    <div className="icon-div">
                                        
                                    </div>
                                </div>

                                <div className="network-dropdown-inner-container">
                                    <div className="network-width-div dropdown-title network-dropdown-header">10-year Calibration Timeseries</div>
                                    {/*<div className="network-width-div">
                                        <DropdownH2O {...dropDownOne}/> 
                                    </div> */}
                                    <div className="network-width-div">
                                        <DropdownH2O {...dropDownTwo}/>
                                    </div>    
                                    <div className="network-width-div">
                                        <DropdownH2O {...dropDownThree}/>
                                    </div>       
                                    
                                    <div className="network-btn-div network-width-div">
                                        <div className="network-download-btn-div">
                                            <Button className="ui wmms-dark-blue-btn button network-download-btn" loading={this.state.downloadFileSpinner}  data-toggle="tooltip" title="Download Timeseries" onClick={() => { this.downloadFile(); }}><i aria-hidden="true" className="download icon network-download-icon"></i></Button>
                                        </div>

                                        {
                                            this.state.zoomed == true && 
                                            <div>
                                                <button className="ui button wmms-light-blue-btn network-reset-btn" title="Reset Zoom" onClick={() => { this.resetZoom(); }}>Reset</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="blue-hr-bottom">
                                {/* here for styles */}
                            </div>
                            <div className="network-graph-note"><i>Drag on graph to zoom. Click the button on right to download the time series file.</i></div>
                            <div>
                                <PrecipTimeseriesChart {...precipchartprops} />
                            </div>
                            <div>
                                <TimeseriesLineChart {...chartprops} />
                            </div>
                            <div className="network-table-div">
                                <Table celled selectable striped size="small" className="table-shadow" style={{tableLayout: 'fixed'}}>
                                    <TableHeader>
                                        <TableRow>
                                            <Table.HeaderCell className='blue-table-header'>Statistic</Table.HeaderCell>
                                            <Table.HeaderCell className='blue-table-header'>Value</Table.HeaderCell>
                                            <Table.HeaderCell className='blue-table-header'>Statistic</Table.HeaderCell>
                                            <Table.HeaderCell className='blue-table-header'>Value</Table.HeaderCell>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        <TableRow>
                                            <Table.Cell>Average</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.average != null ? this.state.timeseriesStats.average.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                            <Table.Cell>Median</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.perc50 != null ? this.state.timeseriesStats.perc50.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                        </TableRow>
                                        <TableRow>
                                            <Table.Cell>Minimum</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.min != null ? this.state.timeseriesStats.min.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                            <Table.Cell>75th Percentile</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.perc75 != null ? this.state.timeseriesStats.perc75.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                        </TableRow>
                                        <TableRow>
                                            <Table.Cell>5th Percentile</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.perc5 != null ? this.state.timeseriesStats.perc5.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                            <Table.Cell>90th Percentile</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.perc90 != null ? this.state.timeseriesStats.perc90.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                        </TableRow>
                                        <TableRow>
                                            <Table.Cell>10th Percentile</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.perc10 != null ? this.state.timeseriesStats.perc10.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                            <Table.Cell>95th Percentile</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.perc95 != null ? this.state.timeseriesStats.perc95.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                        </TableRow>
                                        <TableRow>
                                            <Table.Cell>25th Percentile</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.perc25 != null ? this.state.timeseriesStats.perc25.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                            <Table.Cell>Maximum</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.max != null ? this.state.timeseriesStats.max.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                        </TableRow>
                                        <TableRow>
                                            <Table.Cell>Standard Deviation</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.stdev != null ? this.state.timeseriesStats.stdev.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + this.state.dataUnits : "N/A"}</span></Table.Cell>
                                            <Table.Cell>Coefficient of Variation</Table.Cell>
                                            <Table.Cell><span className={this.state.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.state.timeseriesStats.variationCoeff != null ? this.state.timeseriesStats.variationCoeff.toFixed(this.state.statRound).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "N/A"}</span></Table.Cell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}


