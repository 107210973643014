
export let Config = {
    "map_connection_url": "http://gis.wramps.org/cgi-bin/qgis_mapserv.fcgi?MAP=/home/ubuntu/wmms/dev1.2/wmms_1_9.qgs",

    // For localhost
    // "api_endpoint": "https://localhost:44381/api/",
    // "client_endpoint": "http://localhost:3000/",
    // "appUrl": 'http://localhost:3000/',
    // "routeUrl": '/'

     //For Production 
    "api_endpoint": "https://portal.safecleanwaterla.org/wmms-util-API/api/",
    "client_endpoint": "/wmms/",
    "appUrl": 'https://portal.safecleanwaterla.org/wmms/',
    "routeUrl": '/wmms/'


    // For Deployed QA version
    //"api_endpoint": "https://portal.safecleanwaterla.org/wmms-util-API_QA/api/",
    //"client_endpoint": "/wmms-qa/",
    //"appUrl": 'https://portal.safecleanwaterla.org/wmms-qa/',
    //"routeUrl": '/wmms-qa/'
}

export default Config;