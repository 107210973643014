import React, { Component } from "react";
import './about.css';
import { INavHistory } from "../../interfaces/INavHistory";
import Config from "../../config/Config";
import wmmsMap from "../../images/wmms_map.png";
import wmmsCurve from "../../images/wmms_curve.png";
import { Grid, GridRow, GridColumn, Icon } from "semantic-ui-react";
import DownloadLink from "../../core/downloadLink/downloadLink";
import HrefLink from "../../core/hrefLink/hrefLink";

export default class About extends Component<INavHistory> {

    state = {

    }

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {

    }


    render() {

        let classContext: About = this;


        return (
            <div className="optimization-container">
                <div className="optimization-inner-container">
                    <div className="downloads-top" id="downloadTop">
                        <div className="wmms-blue-header-div wmms-blue-header">
                            <div>
                                About
                            </div>
                        </div>
                        <Grid verticalAlign='middle' centered padded>
                            <GridRow className='smallGutter'>
                                <GridColumn width={16}>
                                    <p className="aboutText">Since its release by the Los Angeles County Flood Control District (LACFCD) and Los Angeles County Department of Public Works (LACDPW 2010a, 2010b, 2011), the Watershed Management Modeling System (WMMS) has played an important role in supporting watershed planning (LARWQCB 2018), best management practice (BMP) conceptual design and implementation, and climate change analyses for the region (USBR and LACDPW 2016). At the time of its release, WMMS was a state-of-the-art planning tool based on the best available data and models. Recent advancements in computational efficiency, monitoring, and high-resolution data and lessons learned through best management practice (BMP) planning and implementation have provided an opportunity to enhance and update WMMS. To incorporate these advances and develop an accessible, user-focused web platform, LACFCD and LACDPW conducted an update to WMMS (WMMS 2.0) (LACFCD and LACDPW 2020a and 2020b).</p>
                                </GridColumn>
                            </GridRow>
                            <GridRow className='smallGutter'>
                                <GridColumn width={4}>
                                    <div className="aboutGraphic">
                                        <div>
                                            <img className="aboutScaleImg" src={wmmsMap} alt="wmms network map" />
                                        </div>
                                    </div>
                                </GridColumn>
                                <GridColumn width={12}>
                                    <p className="aboutText"><b>Loading Simulation Program in C++ (LSPC):</b> The LSPC component of WMMS 2.0 is an open-source, process-based watershed modeling system developed by the U.S. Environmental Protection Agency (EPA) for simulating watershed hydrology, sediment erosion and transport, and water quality processes from both upland contributing areas and receiving streams (Shen <i>et al.</i> 2005). LSPC serves as the baseline watershed model within WMMS 2.0, and includes improved representation of land characteristics, meteorological boundary conditions, and pollutant sources and transport. The LSPC model was calibrated and validated based on extensive hydrologic and water quality monitoring datasets available throughout Los Angeles County watersheds. </p>
                                </GridColumn>
                            </GridRow>
                            <GridRow className='smallGutter'>
                                <GridColumn width={12}>
                                    <p className="aboutText"><b>System for Urban Stormwater Treatment and Analysis Integration (SUSTAIN):</b> The SUSTAIN component of WMMS 2.0 is an open-source, decision support system developed by the EPA that provides process-based simulation and cost-optimization of BMPs (USEPA 2009). WMMS 2.0 provides updates to SUSTAIN including improvements to the model code and the representation, parameterization, and cost estimation of BMPs. </p>
                                </GridColumn>
                                <GridColumn width={4}>
                                    <div className="aboutGraphic">
                                        <div>
                                            <img className="aboutScaleImgLg" src={wmmsCurve} alt="wmms optimization curve" />
                                        </div>
                                    </div>
                                </GridColumn>
                            </GridRow>
                            <GridRow className='smallGutter'>
                                <GridColumn width={16}>
                                    <p className="aboutText">To enable maximum usability and transparency for LSPC and SUSTAIN applications in the County, the WMMS 2.0 web-based user platform was developed. This platform includes a <HrefLink endpointUrl={Config.appUrl + 'downloads'} linkText='file repository' wmmsColor='sustain' /> where the LSPC and SUSTAIN executables and input data can be downloaded, an <HrefLink endpointUrl={Config.appUrl + 'home'} linkText='interactive map and timeseries viewer' wmmsColor='sustain' /> to illustrate LSPC model inputs and output, and a <HrefLink endpointUrl={Config.appUrl + 'utilities'} linkText='Utility suite' wmmsColor='sustain' /> with support documentation to facilitate BMP cost optimizations that can inform watershed planning efforts. In concert, the updated LSPC baseline model, SUSTAIN model, and the WMMS 2.0 web platform provide a substantively more powerful and accessible modeling framework, further empowering successful stormwater management for LA County watersheds.</p>
                                </GridColumn>
                            </GridRow>
                        </Grid>
                        <div className="wmms-blue-header-div wmms-blue-header">
                            <div>
                                References
                            </div>
                        </div>
                        <p className="side-panel-subtext">
                            <p>LACDPW (Los Angeles County Department of Public Works). 2010a. <i>Los Angeles County Watershed Model Configuration and Calibration—Part I: Hydrology.</i> Los Angeles County Department of Public Works, Watershed Management Division.
                            </p>
                            <p>LACDPW (Los Angeles County Department of Public Works). 2010b. <i>Los Angeles County Watershed Model Configuration and Calibration—Part II: Water Quality.</i> Los Angeles County Department of Public Works, Watershed Management Division. 
                            </p>
                            <p>LACDPW (Los Angeles County Department of Public Works). 2011a. <i>Phase III Report: Development of the Framework for Watershed-Scale Optimization Modeling.</i> Los Angeles County Department of Public Works, Watershed Management Division.
                            </p>
                            <p>LACFCD (Los Angeles County Flood Control District) and LACDPW (Los Angeles County Department of Public Works). 2020a. <i>Watershed Management Modeling System Version 2.0 Phase I Report: Baseline Hydrology and Water Quality Model.</i> Los Angeles County Department of Public Works, Stormwater Quality Division. <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={49} linkText="download" filename="PhaseI_PlaceHolder.pdf" mimetype="application/pdf" bold={false} wmmsColor='sustain' />
                            </p>
                            <p>LACFCD (Los Angeles County Flood Control District) and LACDPW (Los Angeles County Department of Public Works). 2020b. <i>Watershed Management Modeling System Version 2.0 Phase II Report: BMP Model and Optimization Framework.</i> Los Angeles County Department of Public Works, Stormwater Quality Division. <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={50} linkText="download" filename="PhaseII_PlaceHolder.pdf" mimetype="application/pdf" bold={false} wmmsColor='sustain' />
                            </p>
                            <p>LARWQCB (Los Angeles Regional Water Quality Control Board). 2018. Watershed Management Programs. Accessed January 2020 at <HrefLink endpointUrl="https://www.waterboards.ca.gov/losangeles/water_issues/programs/stormwater/municipal/watershed_management/" newTab={true} linkText='link. ' wmmsColor='sustain' icon='external' />
                            </p>
                            <p>Shen, J., A. Parker, J. Riverson. 2005. A New Approach for a Windows-based Watershed Modeling System Based on a Database-supporting Architecture. <i>Environmental Modelling & Software</i>, 20(9) pp 1127-1138. <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={55} linkText="download" filename="new-approach-windows-based-model-shen.pdf" mimetype="application/pdf" bold={false} wmmsColor='sustain' />
                            </p>
                            <p>USBR (U.S. Department of Interior, Bureau of Reclamation) and LACDPW (Los Angeles County Department of Public Works). 2016. <i>Los Angeles Basin Study.</i> Prepared by the U.S. Department of Interior, Bureau of Reclamation, Lower Colorado Region, Southern California Area Office, Los Angeles County Department of Public Works, and Los Angeles County Flood Control District.
                            </p>
                            <p>USEPA (United States Environmental Protection Agency). 2009. <i>SUSTAIN - A Framework for Placement of Best Management Practices in Urban Watersheds to Protect Water Quality.</i> Office of Research and Development. National Risk Management Research Laboratory, Water Supply and Water Resources Division. 
                            </p>
                        </p>
                        <p className="side-panel-subtext">
                            Please contact <HrefLink endpointUrl="mailto:wmms@pw.lacounty.gov" linkText='wmms@pw.lacounty.gov' wmmsColor='sustain' /> if there are questions related to the application of WMMS 2.0 or access to the associated models or utilities.
                        </p>
                    </div>
                </div>
            </div>
        );

    }
}