import React, { Component } from "react";
import IPrecipTimseriesSVGProps from "./interfaces/IPrecipTimeseriesSVGProps";
import * as d3 from 'd3';
import AxisLeftTicks from "./chartPieces/AxisLeftTicks";
import Callout from "./chartPieces/Callout";
import PathsInverse from "./chartPieces/PathsInverse";
import AreaStartEnd from "./chartPieces/AreaStartEnd";
import IData from "./interfaces/IData";
import IDataXY from "./interfaces/IDataXY";
import LabelLeft from "./chartPieces/LabelLeft";
import LabelBottom from "./chartPieces/LabelBottom";
import { Segment } from "semantic-ui-react";

export class PrecipTimeseriesSVGState {
    mouseDate: Date = null;
    mouseValue: number = null;
    calloutX: number = null;
    calloutY: number = null;
    label: string = "";
    mouseDownDate: Date = null;
    mouseUpDate: Date = null;
    listenersApplied: boolean = false;
}


export class PrecipTimeSeriesData implements IData {
    id: number;
    date: string;
    value: number;

    constructor(date: Date, value: number) {
        this.date = date == null? "": date.toLocaleDateString();
        this.id = date == null? null: date.getTime();
        this.value = value;
    }
}

export default class PrecipTimeseriesSVG extends Component<IPrecipTimseriesSVGProps> {
    state = new PrecipTimeseriesSVGState();
    xScale: any;
    yScale: any;
    myBisect: any;

    mouseEventOffset: number = 70;

    bisect = (mx: any): IData => {
        //console.log('bisect in', mx);
        let date = this.xScale.invert(mx);
        //console.log('bisect date', date);
        let index = this.myBisect(this.props.data, date, 1);
        //console.log('bisect index', index);
        let a = this.props.data[index - 1];
        let b = this.props.data[index];
        if (a == null && b != null) { return b; }
        if (a != null && b == null) { return a; }
        if (a == null && b == null) { return  new PrecipTimeSeriesData(null, null ); }
        return date - a.id > b.id - date ? b : a;

    }

    componentDidUpdate = () => {
        const classContext = this;

        if (!this.props.loading) {
            if (!this.state.listenersApplied) {

                this.applyD3Listeners();
                this.setState({listenersApplied: true});
            };
        };



    }

    applyD3Listeners = () => {
        const classContext = this;

        d3.select('#precipG').append('rect')
        .attr('width', this.props.svgWidth)
        .attr('height', this.props.svgHeight)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .on("mousemove", function (x, y, z) {
            let mouseCoords = d3.mouse(z[0]);
            let res: IData = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
            if (res != null && res != undefined && res.value != null) {
                let xcoord = classContext.xScale(res.id);
                let ycoord = classContext.yScale(res.value);
                //console.log('mousedata', res);
                let label = res.value.toFixed(2) + " in." + "\n" + new Date(res.id).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })
                //classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
                if (classContext.state.mouseDownDate != null) {
                    //console.log('dragging', res.x);
                    classContext.setState({ mouseDate: res.id, mouseValue: res.value, calloutX: xcoord, calloutY: ycoord, mouseUpDate: res.id, label: label });
                    classContext.props.zoomHandler(classContext.state.mouseDownDate, res.id);
                } else {
                    classContext.setState({ mouseDate: res.id, mouseValue: res.value, calloutX: xcoord, calloutY: ycoord, label: label });
                }
    
            }

        })
        .on("touchmove", function (x, y, z) {
            let mouseCoords = d3.touch(z[0]);
            let res: IData = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
            if (res != null && res != undefined && res.value != null) {
                let xcoord = classContext.xScale(res.id);
                let ycoord = classContext.yScale(res.value);
                //console.log('mousedata', res);
                let label = res.value.toFixed(2) + " in." + "\n" + new Date(res.id).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })
                //classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
                if (classContext.state.mouseDownDate != null) {
                    //console.log('dragging', res.x);
                    classContext.setState({ mouseDate: res.id, mouseValue: res.value, calloutX: xcoord, calloutY: ycoord, mouseUpDate: res.id, label: label });
                    classContext.props.zoomHandler(classContext.state.mouseDownDate, res.id);
                } else {
                    classContext.setState({ mouseDate: res.id, mouseValue: res.value, calloutX: xcoord, calloutY: ycoord, label: label });
                }
    
            }

        })
        .on("mousedown", function (x, y, z) {
            let mouseCoords = d3.mouse(z[0]);
            let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
            //console.log('downdate', res.x);
            classContext.setState({ mouseDownDate: res.id, mouseUpDate: res.id });
        })
        .on("mouseup", function (x ,y, z) {
            let mouseCoords = d3.mouse(z[0]);
            let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
            let startDate = classContext.state.mouseDownDate;
            classContext.setState({ mouseUpDate: null, mouseDownDate: null }, () => {
                if (startDate != new Date(res.id)) { // only zoom if not focused on a single date
                    classContext.props.clickHandler(startDate, res.id);
                }
            });

        });

        d3.select("#precipSVG").on("mouseout", function () {
            classContext.setState({ mouseDate: null, mouseValue: null, calloutX: 0, calloutY: 0 });
        })
        .on("touchend", function () {
            classContext.setState({ mouseDate: null, mouseValue: null, calloutX: 0, calloutY: 0 });
        });

    }


    // componentDidMount = () => {

    //     const classContext = this;

    //     d3.select("#precipG")
    //         .on("mouseover", function (x, y, z) {
    //             let mouseCoords = d3.mouse(z[0]);
    //             let res: IData = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
    //             if (res != null && res != undefined && res.value != null) {
    //                 let xcoord = classContext.xScale(res.id);
    //                 let ycoord = classContext.yScale(res.value);
    //                 //console.log('mousedata', res);
    //                 let label = res.value.toFixed(2) + " in." + "\n" + new Date(res.id).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })
    //                 //classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
    //                 if (classContext.state.mouseDownDate != null) {
    //                     //console.log('dragging', res.x);
    //                     classContext.setState({ mouseDate: res.id, mouseValue: res.value, calloutX: xcoord, calloutY: ycoord, mouseUpDate: res.id, label: label });
    //                     classContext.props.zoomHandler(classContext.state.mouseDownDate, res.id);
    //                 } else {
    //                     classContext.setState({ mouseDate: res.id, mouseValue: res.value, calloutX: xcoord, calloutY: ycoord, label: label });
    //                 }
        
    //             }

    //         })
    //         .on("touchmove", function (x, y, z) {
    //             let mouseCoords = d3.touch(z[0]);
    //             let res: IData = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
    //             if (res != null && res != undefined && res.value != null) {
    //                 let xcoord = classContext.xScale(res.id);
    //                 let ycoord = classContext.yScale(res.value);
    //                 //console.log('mousedata', res);
    //                 let label = res.value.toFixed(2) + " in." + "\n" + new Date(res.id).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })
    //                 //classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
    //                 if (classContext.state.mouseDownDate != null) {
    //                     //console.log('dragging', res.x);
    //                     classContext.setState({ mouseDate: res.id, mouseValue: res.value, calloutX: xcoord, calloutY: ycoord, mouseUpDate: res.id, label: label });
    //                     classContext.props.zoomHandler(classContext.state.mouseDownDate, res.id);
    //                 } else {
    //                     classContext.setState({ mouseDate: res.id, mouseValue: res.value, calloutX: xcoord, calloutY: ycoord, label: label });
    //                 }
        
    //             }

    //         })
    //         .on("mousedown", function (x, y, z) {
    //             let mouseCoords = d3.mouse(z[0]);
    //             let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
    //             //console.log('downdate', res.x);
    //             classContext.setState({ mouseDownDate: res.id, mouseUpDate: res.id });
    //         })
    //         .on("mouseup", function (x ,y, z) {
    //             let mouseCoords = d3.mouse(z[0]);
    //             let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
    //             let startDate = classContext.state.mouseDownDate;
    //             classContext.setState({ mouseUpDate: null, mouseDownDate: null }, () => {
    //                 if (startDate != new Date(res.id)) { // only zoom if not focused on a single date
    //                     classContext.props.clickHandler(startDate, res.id);
    //                 }
    //             });

    //         });

    //         d3.select("#precipSVG").on("mouseout", function () {
    //             classContext.setState({ mouseDate: null, mouseValue: null, calloutX: 0, calloutY: 0 });
    //         })
    //         .on("touchend", function () {
    //             classContext.setState({ mouseDate: null, mouseValue: null, calloutX: 0, calloutY: 0 });
    //         });

    // }

    mouseEvent = (event: any) => {
        //console.log('mouseevent', event);
        let res: IData = this.bisect(event.clientX - this.mouseEventOffset); // hard-coded margein left for now plus something - offset of sorts?
        if (res != null && res != undefined && res.value != null) {
            let xcoord = this.xScale(res.id);
            let ycoord = this.yScale(res.value);
            //console.log('mousedata', res);
            let label = res.value.toFixed(2) + " in." + "\n" + new Date(res.id).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })
            //this.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
            if (this.state.mouseDownDate != null) {
                //console.log('dragging', res.x);
                this.setState({ mouseDate: res.id, mouseValue: res.value, calloutX: xcoord, calloutY: ycoord, mouseUpDate: res.id, label: label });
                this.props.zoomHandler(this.state.mouseDownDate, res.id);
            } else {
                this.setState({ mouseDate: res.id, mouseValue: res.value, calloutX: xcoord, calloutY: ycoord, label: label });
            }

        }
    }

    mouseLeave = () => {
        this.setState({ mouseDate: null, mouseValue: null, calloutX: 0, calloutY: 0 });
    }

    mouseClickStart = (event: any) => {
        //console.log('start drag', event);
        let res = this.bisect(event.clientX - this.mouseEventOffset); // hard-coded margein left for now plus something - offset of sorts?
        //console.log('downdate', res.x);
        this.setState({ mouseDownDate: res.id, mouseUpDate: res.id });

    }

    mouseClickEnd = (event: any) => {
        //console.log('end drag', event);
        let res = this.bisect(event.clientX - this.mouseEventOffset); // hard-coded margein left for now plus something - offset of sorts?
        let startDate = this.state.mouseDownDate;
        this.setState({ mouseUpDate: null, mouseDownDate: null }, () => {
            if (startDate != new Date(res.id)) { // only zoom if not focused on a single date
                this.props.clickHandler(startDate, res.id);
            }
        });
    }

    render() {
        const { svgHeight, svgWidth, data } = this.props;

        const margin = { top: 10, right: 20, bottom: 45, left: 70 };
        const width = svgWidth - margin.left - margin.right;
        const height = svgHeight - margin.top - margin.bottom;

        // 'range' defines the space on the svg that the element can use (in pixels)
        // 'domain' defines the values that will show on the axes
        // with range and domain defined, d3 will scale appropriately and create ticks with values on the axes
        // if needed, can additionally define how we want ticks formatted (eg 10M instead of 10,000,000)


        this.xScale = d3.scaleTime()
            .domain(d3.extent(this.props.data, d => d.id))
            .range([0, width]);

        this.myBisect = d3.bisector(d => d.id).left;

        this.yScale = d3.scaleLinear().range([0, height]);

        // reductionData & crcData are undefined on first render...
        if (data != undefined) {
            this.yScale.domain([0, d3.max(data, d => d.value) || 0]);
        }

        const axisLeftProps = {
            scale: this.yScale,
            ticks: 3,
            class: "net-viewer-axis"
        };

        const pathsInverseProps = {
            data: data,
            yScale: this.yScale,
            xScale: this.xScale,
            height: 0,
            color: this.props.color,
            strokeWidth: "2.0"
        }

        const calloutProps = {
            xvalue: new Date(this.state.mouseDate),
            yvalue: this.state.mouseValue,
            xCoord: this.state.calloutX,
            yCoord: this.state.calloutY,
            label: this.state.label
        }

        const areaStartEndProps = {
            data: [{ x: this.state.mouseDownDate != null && this.state.mouseUpDate != null ? this.state.mouseDownDate : this.props.zoomStart, y: 50 }, { x: this.state.mouseUpDate != null && this.state.mouseDownDate != null ? this.state.mouseUpDate : this.props.zoomEnd, y: 50 }],
            yScale: this.yScale,
            xScale: this.xScale,
            height: height,
            fill: "#AADBFF",
            color: "#AADBFF",
            key: "test"
        }

        const labelLeftProps = {
            text: "inches",
            width: width,
            height: height,
            margin: margin,
            fontSize: "15"
        }

        const labelBottomProps = {
            text: this.props.noData == true? this.props.precipMsg: "",
            width: width,
            height: height / 2,
            margin: margin,
            fontSize: "15"
        }


        // NOTE: order of chart pieces is important. first rendered will be in back, with each additional piece overlayed
        return (
            <Segment >
                <svg id="precipSVG" style={{pointerEvents: "all"}} height={svgHeight} width={svgWidth} 
                    // onMouseDown={((event) => { this.mouseClickStart(event) })}
                    // onMouseUp={((event) => { this.mouseClickEnd(event) })}
                    // onTouchMove={((event) => { this.mouseEvent(event) })} 
                    // onMouseMove={((event) => { this.mouseEvent(event) })} 
                    // onTouchEnd={(() => { this.mouseLeave() })} 
                    // onMouseLeave={(() => { this.mouseLeave() })} 
                    >
                    <g id="precipG" transform={`translate(${margin.left},${margin.top})`}>
                        <AreaStartEnd {...areaStartEndProps} />
                        <LabelBottom {...labelBottomProps} />
                        <PathsInverse {...pathsInverseProps} />
                        <AxisLeftTicks {...axisLeftProps} />
                        <LabelLeft {...labelLeftProps} />
                        <Callout {...calloutProps} />
                    </g>
                </svg>
            </Segment>
        );
    }
}