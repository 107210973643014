import React, { Component } from "react";
import './climatechangeutil.css';
import { INavHistory } from "../../../interfaces/INavHistory";
import { Grid, Icon, Message, Button, Label, Input, Divider } from "semantic-ui-react";
import Config from "../../../config/Config";
import { IUtilityCard } from "../../../interfaces/IUtilityCard";
import { AxiosResponse } from "axios";
import { processviewmodel } from "../../../models/processviewmodel";
import { setInterval } from "timers";
import { climatechange } from "../../../models/climatechange"
import HrefLink from "../../../core/hrefLink/hrefLink";
import DownloadLink from "../../../core/downloadLink/downloadLink";


export class ClimateChangeUtilState {
    pageObj: climatechange = new climatechange();
    filenameXls: string = "No selection";
    fileXls: File = null;
    filenameInp: string = "No selection";
    fileInp: File = null;
    filenameCsv: string = "No selection";
    fileCsv: File = null;
    filenameCConfig: string = "No selection";
    fileCConfig: File = null;
    filenameCcf: string = "No selection";
    fileCcf: File = null;
    basePath: string = "";
    numBatch: number = 1;
    processing: boolean = false;
    //email: string = "";
    processStatus: string = "";
    processMessage: string = "";
}

export default class ClimateChangeUtil extends Component<IUtilityCard> {

    state = new ClimateChangeUtilState;

    uploadFile() {
        // error list
        let list = new Array<string>();
        if (this.state.fileXls == null) {
            list.push("No Excel file selected");
            this.handleErrors(list);
        // } else if (this.state.fileInp == null) {
        //     list.push("No Input template file selected");
        //     this.handleErrors(list);
        // } else if (this.state.fileCsv == null) {
        //     list.push("No WstNum file selected");
        //     this.handleErrors(list);
        } else if (this.state.fileCcf == null) {
            list.push("No Ccf file selected");
            this.handleErrors(list);   
        } else if (this.state.fileCConfig== null) {
            list.push("No Climate Config file selected");
            this.handleErrors(list);                        
        // } if (this.state.basePath == null || this.state.basePath.length < 1) {
        //     list.push("No base path specified");
        //     this.handleErrors(list);
        } else {
            let classContext: ClimateChangeUtil = this;
            classContext.setState({ processing: true });
            this.handleErrors(new Array<string>());
            let form = new FormData();

            form.append("data", this.state.fileXls);
            // form.append("data", this.state.fileInp);
            // form.append("data", this.state.fileCsv);
            form.append("data", this.state.fileCcf);
            form.append("data", this.state.fileCConfig);
            // form.append("basepath", this.state.basePath.toString());
            // form.append("numbatch", this.state.numBatch.toString());

            climatechange.axiosPostUpload(form).then((r: AxiosResponse) => {
                classContext.setStatusMessage(r.data.status, r.data.message); 
                if (r.data.status.toLowerCase() == "submitted") {
                    let data: processviewmodel = r.data;
                    if (data.queueId > 0) {
                        let id = data.queueId;
                        let intvl = setInterval(() => {
                            processviewmodel.axiosGet(id).then((r: AxiosResponse) => {
                                let data: processviewmodel = r.data;
                                classContext.setStatusMessage(data.status, data.message);
                                if (data.status != undefined && data.status.toLowerCase() == "completed") {
                                    classContext.props.stopInterval();
                                    if (data.message != null) {
                                        list.push(data.message);
                                        classContext.handleErrors(list);
                                    }
                                    classContext.getResult(data.resultId);
                                } else if (data.status != undefined && data.status.toLowerCase() == "error") {
                                    classContext.props.stopInterval();
                                    classContext.setState({ processing: false });
                                    list.push("Error during processing");
                                    list.push(data.message);
                                    classContext.handleErrors(list);
                                }
                                else {
                                    //console.log('keep checking');
                                }
                            });
                        }, 1000);  // TODO change interval?
                        classContext.props.beginInterval(intvl);
                    }
                } else if (r.data.status.toLowerCase() == "error") {
                    classContext.setState({ processing: false });
                    list.push("Error during processing");
                    list.push(r.data.message);
                    classContext.handleErrors(list);
                } else {
                    classContext.setState({ processing: false });
                    list.push("Unknown error");
                    classContext.handleErrors(list);
                }

            }).catch(function (error) {
                classContext.setState({ processing: false });
                list.push(error.message);
                classContext.handleErrors(list);
            })
        }
    }

    componentDidMount() {
    }

    getResult(id: number) {
        let classContext: ClimateChangeUtil = this;
        classContext.setState({ processing: false });
        if (id > 0) {
            window.location.href = Config.api_endpoint + 'ClimateChange/' + id;
        }
    }

    setStatusMessage(status: string, message: string) {
        let classContext: ClimateChangeUtil = this;
        if (status != undefined && (status.toLowerCase() == "submitted" || status.toLowerCase() == "queued" || status.toLowerCase() == "processing")) {
            classContext.setState({ processStatus: status, processMessage: message })
        } else {
            classContext.setState({ processStatus: "", processMessage: "" })
        }
    }

    handleErrors = (list) => {
        let err = document.getElementById('errorPlace');
        err.classList.remove('hidden');

        let msgs = document.getElementById('errorMessages');
        while (msgs.firstChild) {
            msgs.removeChild(msgs.firstChild);
        }
        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].indexOf(';') > 0) {
                    var smallArr = list[i].split(';');
                    for (var j = 0; j < smallArr.length; j++) {
                        if (smallArr[j].length > 1) {
                            let dv = document.createElement('li');
                            dv.append(smallArr[j])
                            msgs.appendChild(dv);
                        }
                    }
                }
                else {
                    let dv = document.createElement('li');
                    dv.append(list[i])
                    msgs.appendChild(dv);
                }
            }
        } else {
            err.classList.add('hidden');
        }
    }

    handleItemUpdate(evt, lblName, stateName, stateObj) {
        let lbl = document.getElementById(lblName);
        if (evt.target.files.length > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
            this.setState({ [stateName]: evt.target.files[0].name, [stateObj]: evt.target.files[0] });
            this.handleErrors(new Array<string>());
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
            this.setState({ [stateName]: 'None Selected', file: null });
        }
    }

    handleUpdate(evt) {
        let lbl = document.getElementById('uploadlabel');
        if (evt.target.value.length > 2 && evt.target.value.indexOf(" ") == -1) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
        }
        this.setState({ basePath: evt.target.value });
    }

    handleUpdateBatch(evt) {
        let lbl = document.getElementById('batchlabel');
        if (evt.target.value > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
        }
        this.setState({ numBatch: evt.target.value });
    }

    render() {
        let classContext: ClimateChangeUtil = this;
        return (
            <div className='scroll-fix backWhite'>
                <div>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <h2><Icon className={'page-icon wmms-' + this.props.wmmsColor} name={this.props.cardIcon} circular inverted />{this.props.cardTitle}</h2>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <p>
                                    Changing climate is forecasted to significantly impact rainfall patterns around the world, including throughout Los Angeles County. To support water resources planning, general circulation models (GCMs) have been widely used to understand and predict future climate variability (Barsugli et al. 2009; Taylor et al. 2012; IPCC 2013). This Utility leverages 10 GCMs from 2 Representative Concentration Pathways (RCPs) for a total of 20 future climate projections. GCMs were developed from the Intergovernmental Panel on Climate Change (IPCC) Coupled Model Inter-comparison Project Phase 5 (CMIP5) (CCTAG 2015). To evaluate expected future conditions specific to the Los Angeles region the selected GCMs were spatially and temporally downscaled. For a detailed accounting of the GCMs employed, the process and assumptions, and the underlying climatological data, reference the <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={66} linkText="WMMS 2.0 Climate Impact Analysis Report" filename="Climate_Change_Impact_Analysis.pdf" mimetype="application/pdf" bold={true} wmmsColor={classContext.props.wmmsColor} />.
                                </p>
                            </Grid.Column>
                            <Grid.Column>
                                <p>
                                    Each of the 20 modeled GCMs were spatially downscaled to the WMMS 2.0 subbasin scale and temporally downscaled to an hourly continuous timeseries (both precipitation and evapotranspiration). This produced an ensemble of future climate projections which can be directly simulated in WMMS 2.0. Specifically, the BMP implementation strategies developed through the Optimization Framework can be evaluated for their climate resiliency. 
                                </p>
                                <p>
                                    Through the process described in the <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={65} linkText="Climate Resiliency Utility User Document" filename="Climate_Resiliency_Utility_User_Document.pdf" mimetype="application/pdf" bold={true} wmmsColor={classContext.props.wmmsColor} /> a series of files are first uploaded to this Utility to create a set of SUSTAIN input files. Then, input files are downloaded and executed on a user’s desktop with any combination of the 20 GCM timeseries provided below. Lastly, to interact with and interpret model results, SUSTAIN output is uploaded to the <HrefLink endpointUrl={Config.appUrl + 'climate'} linkText='WMMS 2.0 Climate Resiliency Viewer' wmmsColor='sustain' />. To provide additional context for the format of data uploaded to this Utility, a sample dataset is provided below.
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    <div id="errorPlace" className="hidden">
                                        <Message color='red' onDismiss={() => classContext.handleErrors(new Array<string>())}>
                                            <Message.Header>Processing Messages:</Message.Header>
                                            <Message.List id='errorMessages'>
                                            </Message.List>
                                        </Message>
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.filenameXls}</b></div>
                                        <label className="upload-basic-btn" htmlFor='bmpupload'><Label className="upload-basic-btn" color='red' basic id='uploadlabelxls'>Click here to select a .xlsx file.</Label></label>
                                        <input id='bmpupload' type="file" accept=".xlsx" onChange={(evt) => classContext.handleItemUpdate(evt, 'uploadlabelxls', 'filenameXls', 'fileXls')} className="hidden" />
                                    </div>
                                    {/* <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.filenameInp}</b></div>
                                        <label className="upload-basic-btn" htmlFor='bmptemplate'><Label className="upload-basic-btn" color='red' basic id='uploadlabelinp'>Click here to select a .inp template file.</Label></label>
                                        <input id='bmptemplate' type="file" accept=".inp" onChange={(evt) => classContext.handleItemUpdate(evt, 'uploadlabelinp', 'filenameInp', 'fileInp')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.filenameCsv}</b></div>
                                        <label className="upload-basic-btn" htmlFor='bmpwstnum'><Label className="upload-basic-btn" color='red' basic id='uploadlabelcsv'>Click here to select a .csv wstnum file.</Label></label>
                                        <input id='bmpwstnum' type="file" accept=".csv" onChange={(evt) => classContext.handleItemUpdate(evt, 'uploadlabelcsv', 'filenameCsv', 'fileCsv')} className="hidden" />
                                    </div> */}
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.filenameCConfig}</b></div>
                                        <label className="upload-basic-btn" htmlFor='climateconfig'><Label className="upload-basic-btn" color='red' basic id='uploadlabelcconfig'>Click here to select a .csv climate config file.</Label></label>
                                        <input id='climateconfig' type="file" accept=".csv" onChange={(evt) => classContext.handleItemUpdate(evt, 'uploadlabelcconfig', 'filenameCConfig', 'fileCConfig')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.filenameCcf}</b></div>
                                        <label className="upload-basic-btn" htmlFor='bmpccf'><Label className="upload-basic-btn" color='red' basic id='uploadlabelccf'>Click here to select a .ccf file.</Label></label>
                                        <input id='bmpccf' type="file" accept=".ccf" onChange={(evt) => classContext.handleItemUpdate(evt, 'uploadlabelccf', 'filenameCcf', 'fileCcf')} className="hidden" />
                                    </div>
                                    {/* <div className='top-padding'>
                                        <Label className="upload-basic-btn-nh" color='red' basic id='uploadlabel'>Base Path</Label>
                                        <Input id='basePath' type="text" value={this.state.basePath} placeholder="C:\Paradigm" onChange={(evt) => classContext.handleUpdate(evt)} className="" />
                                    </div>
                                    <div className='top-padding'>
                                        <Label className="upload-basic-btn-nh" color='green' basic id='batchlabel'>Number of Batches</Label>
                                        <Input id='numBatch' type="number" value={this.state.numBatch} placeholder="1" onChange={(evt) => classContext.handleUpdateBatch(evt)} className="" />
                                    </div> */}
                                </div>
                                <br />
                                <div className="semantic-green-disabled-btn">
                                    <Button size='medium' loading={this.state.processing} disabled={this.state.processing} className={"wmms-" + this.props.wmmsColor + "-btn"} onClick={() => this.uploadFile()} >Process</Button>
                                </div>  
                                <b>{this.state.processStatus}</b> {this.state.processMessage}
                                <div className='WeatherStatus'>
                                </div>
                            </Grid.Column>
                            <Grid.Column className="">
                                <div>
                                    <p><b>Additional datasets include:</b></p>
                                    <li><b>Continuous hourly timeseries:</b> Two RCPs are available for this analysis to capture the range of potential future climatic conditions from the 10 modeled GCMs. 
                                        <ul><li>The first is RCP 4.5, commonly referred to as the ‘stabilization’ scenario, where radiative forcing rises until mid-century, and then stabilizes at 4.5 W/m<sup>2</sup> by 2099 (<DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={62} linkText=".pre" filename="RCP45_pre.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> and <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={63} linkText=".air" filename="RCP45_air.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> files).</li>
                                            <li>The second is RCP 8.5, commonly referred to as the ‘business-as-usual’ scenario, where carbon emissions continue to climb at historical rates of increase with radiative forcing of about 8.5 W/m<sup>2</sup> by 2099 (IIASA 2009) (<DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={68} linkText=".pre" filename="RCP85_pre.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> and <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={69} linkText=".air" filename="RCP85_air.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> files).</li>
                                            </ul></li>
                                    <li><b>Future 85<sup>th</sup> percentile 24-hr storm timeseries:</b> As described in Los Angeles County Public Works Hydrology Manual (LACDPW 2004), the 85<sup>th</sup> percentile 24-hour storm is the depth of precipitation determined from a spatially distributed statistical analysis using rainfall stations within the region. Using this approach and the continuous hourly timeseries above, a future 85<sup>th</sup> percentile storm was developed from <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={64} linkText="RCP 4.5 and RCP 8.5" filename="FutureMeteorological85th_Storm.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> for the year 2099. </li>
                                    <li><b>Sample upload dataset:</b> <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={67} linkText="Sample data" filename="ClimateUtil_SampleData.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> includes a completed spreadsheet from the BMP Opportunities Utility (*.xlsx), a climate configuration file (*.csv), and an AP data file (*.ccf) from the Optimization Viewer.</li>
                                </div>

                                <Divider />
                                <div>
                                <div><small><b>REFERENCES:</b> 
                                    <p>Barsugli J., C. Anderson, J. Smith, and J. Vogel. 2009. <i>Options for Improving Climate Modeling to Assist Water Utility Planning for Climate Change.</i> Water Utility Climate Alliance. San Francisco, CA. pp 146.</p>
                                    <p>CCTAG (Climate Change Technical Advisory Group). 2015. <i>Perspectives and Guidance for Climate Change Analysis.</i> California Department of Water Resources.</p>
                                    <p>IIASA (International Institute for Applied Systems Analysis). 2009. Representative Concentration Pathways (RCP) Database, version 2.0. Data accessed March 2019 at <HrefLink endpointUrl="https://tntcat.iiasa.ac.at/RcpDb/dsd?Action=htmlpage&page=welcome" newTab={true} linkText='link. ' wmmsColor='sustain' icon='external' /></p>
                                    <p>IPCC (Intergovernmental Panel on Climate Change Climate Change). 2013. <i>The Physical Science Basis. Contribution of Working Group I to the Fifth Assessment Report of the Intergovernmental Panel on Climate Change.</i> Cambridge University Press, Cambridge, United Kingdom and New York, NY, USA, pp 1535.</p>
                                    <p>LACDPW (Los Angeles County Department of Public Works). 2004. <i>Analysis of 85th Percentile 24-hour Rainfall Depth Analysis Within the County of Los Angeles.</i> Los Angeles County Department of Public Works, Water Resources Division, Alhambra, CA. </p>
                                    <p>Taylor K., R. Stouffer, and G. Meehl. 2012. An Overview of CMIP5 and the Experiment Design. <i>Bulletin of the American Meteorological Society</i>, 93, 485-498.</p>
                                </small></div>


                                </div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>

            </div>
        );
    }
}