import React, { Component } from "react";
import IAxisLeftProps from '../interfaces/IAxisLeftProps';
import * as d3 from 'd3';

export default class AxisLeftTicks extends Component<IAxisLeftProps> {
    ref: React.RefObject<SVGGElement>;

    constructor(props: IAxisLeftProps) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        if (this.ref.current) {
            d3.select(this.ref.current)
                .call(d3.axisLeft(this.props.scale).ticks(this.props.ticks));
        }
    }

    componentDidUpdate() {
        if (this.ref.current) {
            d3.select(this.ref.current)
                .transition()
                .call(d3.axisLeft(this.props.scale).ticks(this.props.ticks));
        }
    }

    render() {
        return <g className={this.props.class != null ? this.props.class : null} ref={this.ref} />;
    }
}