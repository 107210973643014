import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { ButtonGroup } from "../../../core/buttonGroup/ButtonGroup";
import './detailItem.css'
import {formatNumber } from "../../../core/HelperFx";


export const DetailItem = ({data, toggleVisible, visibleArray, close, YAxisSel, hideui}) => {

    const [itemIdx, setItemIdx] = useState<number>(0);
    const [itemList, setItemList] = useState<Array<number>>([...Array(data.length).keys()] )

    return (
        <div className='detail-item'>
            <div className={'' + (hideui == false? 'detail-item-background': '')}></div>
            <div className='detail-item-content'>
                <hr className="utilitiesHr" />
                <div className='detail-item-header'>
                    {data.length > 1 && 
                        <ButtonGroup labelText='Item:' groupOptions={itemList} addOr={false} activeColor={'blue'} currentSelect={itemIdx} setSelection={setItemIdx}/>}
                    <div className='detail-item-title header-width'>Details: {data[itemIdx].fullModelName}</div>
                    {
                        visibleArray.indexOf(data[itemIdx].fullModelName) > -1 && hideui == false && 
                        <div className='detail-item-cursor header-width' onClick={() => toggleVisible({id: 'x ' + data[itemIdx].fullModelName})} title='Enable'><Icon name='eye slash' color='grey' size='large' /></div>
                    }
                    {
                        visibleArray.indexOf(data[itemIdx].fullModelName) < 0 && hideui == false && 
                        <div className='detail-item-cursor header-width' onClick={() => toggleVisible({id: data[itemIdx].fullModelName})} title='Disable'><Icon name='eye' color='black' size='large' /></div>
                    }
                    { hideui == false && <div className='detail-item-cursor' onClick={() => close()} title='Close Details'><Icon name='chevron up' color='red' size='large' /></div>}
                </div>

                <div className='detail-item-body'>
                    <div>
                        <div><b>Model:</b> {data[itemIdx].model}</div>
                        <div><b>Event Label:</b> {data[itemIdx].eventLabel}</div>
                        <div><b>Event:</b> {data[itemIdx].event}</div>
                        <div><b>RCP:</b> {data[itemIdx].rcp}</div>
                    </div>
                    <div>
                        <div><b>Runoff:</b> <span className={'' + YAxisSel == 'Runoff'? 'detail-item-highlight': ''} >{data[itemIdx]["Runoff"]}</span> ac-ft</div>
                        <div><b>Capture:</b> <span className={'' + YAxisSel == 'Capture'? 'detail-item-highlight': ''} >{data[itemIdx]["Capture"]}</span> ac-ft</div>
                        <div><b>Remaining Flow:</b> <span className={'' + YAxisSel == 'Remaining Flow'? 'detail-item-highlight': ''} >{data[itemIdx]["Remaining Flow"]}</span> ac-ft</div>
                        <div><b>% Reduction:</b> <span className={'' + YAxisSel == '% Reduction'? 'detail-item-highlight': ''} >{data[itemIdx]["% Reduction"]}</span> %</div>
                    </div>
                    <div>
                        <div><b>Cost:</b> ${formatNumber(data[itemIdx]["scenarioCost"],  2)}</div>
                        <div><b>Capacity:</b> {data[itemIdx]["scenarioCapacity"]} ac-ft</div>
                        <div><b>Footprint:</b> {data[itemIdx]["scenarioFootprint"]} sq-ft</div>
                    </div>
                </div>
            </div>
        </div>
    )
}