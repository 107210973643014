import React, { Component } from "react";
import "./scenarioViewer.css";
import { INavHistory } from "../../../interfaces/INavHistory";
import { Dropdown, Button, FormGroup, Label, Input, Grid, Segment, Loader } from "semantic-ui-react";
import { IOptimizationViewer } from "../../../interfaces/IOptimizationViewer";
import { AxiosResponse } from "axios";
import { optimizationsummary } from "../../../models/optimizationsummary";
import ResponsiveChart from "../D3/ResponsiveChart";
import OptimizationTable from "./optimizationTable";
import DropdownH2O from "../../DOMObjects/Dropdown";
import { DropdownOption } from "../../DOMObjects/IDropdownProps"
import { optimizationimplementationplan } from "../../../models/optimizationimplementationplan";
import { sustainToLSPCbatch } from "../../../models/sustainToLSPCbatch";
import { optimizationsummaryitem } from "../../../models/optimizationsummaryitem";
import ReactDOM from "react-dom";
import html2canvas from 'html2canvas';
import OptimizationSVG from "../D3/chart/OptimizationSVG";
import { TooltipModal } from "../../toolTipModal/toolTipModal";
import * as d3 from 'd3';

import Canvg from 'canvg';
import { alertPropsModel } from "../../../models/alertPropsModel";
import AlertComponent from "../../AlertComponent/alertComponent";
import { optimizationdeliveryratio } from "../../../models/optimizationdeliveryratio";
import { optimizationassessmentpointdata } from "../../../models/optimizationassessmentpointdata";

export class ScenarioViewerState {
    scenario: number;
    assessmentpoint: number;
    factor: string = "Flow";
    factorNumber: number = 0; 
    scenIndexToLookAt: number = 0;
    apIndexToLookAt: number = 0;
    facIndexToLookAt: number = 0;
    reductionCoordinateData: Array<any>;
    bmpCoordinateData: Array<any>;
    maxCapacity: number = 0;
    currentCRC: any;
    bmpGroups: any;
    tableData: any;
    tableBmps: any;
    tableFactor: string;
    tableTitle: string;
    tablePercent: number;
    tableMaxPct: number;
    tableTitleIsCustom: boolean = false;
    defaultCirclePct: number = 0;
    isUpdating: boolean = false;
    updateMsg: string = "Loading...";
    currentCiclePct: number = 0;
    scenOptions: any = [];
    apsOptions: any = [];
    facOptions: any = [];
    key: number;
    toggleFetchData: boolean = false;
    chartColors: any = {
        "Infiltration Well": "#F59841",
        "LID, Bioretention": "#62AEE4", 
        "LID, Other": "#009933", 
        "Regional Treatment": "#C288F8",
        "Region Infiltration": "#4DD6D3", // TODO remove once updated to 'regional' in all places
        "Regional Infiltration": "#4DD6D3",
        "Regional TBD":"#F9E43E",
        "Existing LID": "#D5D5D5",
        "Existing Regional": "#CCC2CC",
        "MJR BMP": "#FF7777"
    };
    isGeneratingPlan: boolean = false;
    isGeneratingBatch: boolean = false;
    isGeneratingRatio: boolean = false;
    isGeneratingAPData: boolean = false;
    alertIsOpen: boolean = false; 
    alertConfguration: alertPropsModel = new alertPropsModel();
    numFadeOut: boolean = false;
}

export default class ScenarioViewer extends Component<IOptimizationViewer> {

    state = new ScenarioViewerState();
    child: React.RefObject<OptimizationSVG> = React.createRef<OptimizationSVG>();

    // 0.6875 because widescreen break-down is 11 and 5 thus 11/16 = 0.6875. Other screens (less than 1920) go full width.
    curveGraphWidth: number = document.body.clientWidth >= 1920 ? (document.body.clientWidth * 0.6875) - 100 : document.body.clientWidth - 100;

    componentDidMount() {

        let classContext: ScenarioViewer = this;

        // on load, use first scenario to populate graph and set initial state
        let defaultScenario = classContext.props.scenariodata.scenarios[0];
        let sortedBmpGroups = classContext.props.scenariodata.bmpGroups;
        // Set factor name early - lots of dependencies
        classContext.setState({ factor: defaultScenario.factorName }, () => {
            // find point at middle reduction value to place initial dot and populate initial table
            let reductionValues = classContext.props.scenariodata.defaultCRC.map(x => x.responses.find(y => y.name == defaultScenario.factorName).value);
            let lastCRC = classContext.props.scenariodata.defaultCRC[classContext.props.scenariodata.defaultCRC.length - 1];
            let maxPct = lastCRC.responses.find(x => x.name == defaultScenario.factorName).value;
            let middle = maxPct / 2;
            var closest = reductionValues.reduce(function (prev, curr) {
                return (Math.abs(curr - middle) < Math.abs(prev - middle) ? curr : prev);
            });

            // only want to show groups in table that are represented on chart
            let tableBmpGroups = lastCRC.bmPs.filter(x => x.value != 0).map(x => x.name);

            let defaultTableData = classContext.props.scenariodata.defaultCRC.find(x => x.responses.find(y => y.name == defaultScenario.factorName).value == closest);

            let tableTitle = `Scenario ${defaultScenario.scenario} (${defaultScenario.factorName} - ${defaultScenario.mjrEffect}) -- Assessment Point ${defaultScenario.assessmentPoint} (${defaultScenario.city})`;
            let tablePercent = defaultTableData.responses.find(x => x.name == defaultScenario.factorName).value.toFixed(2);

            // dynamically adding colors not already in the color pallet - default is set of primary colors:
            let currentColors = this.extendColors(tableBmpGroups);
            //console.log('did mount', this.props);
            classContext.setState({
                scenario: defaultScenario.scenario,
                assessmentpoint: defaultScenario.assessmentPoint,
                currentCRC: classContext.props.scenariodata.defaultCRC,
                bmpGroups: sortedBmpGroups,
                defaultCirclePct: closest,
                currentCiclePct: closest,
                key: this.props.scenariodata.key,
                tableFactor: defaultScenario.factorName,
                tableData: defaultTableData,
                tableBmps: tableBmpGroups,
                tableTitle: tableTitle,
                tablePercent: tablePercent,
                tableMaxPct: maxPct,
                chartColors: currentColors
            }, () => {
                classContext.generateSenarioDropdownOptions();
                classContext.generateAPsDropdownOptions();
                classContext.generateFactorDropdownOptions();
                classContext.getBatchData();
                //classContext.getPlanData();
            });

            classContext.handleReductionLineData(classContext.props.scenariodata.defaultCRC);
            classContext.handleCapacityAreasData(classContext.props.scenariodata.defaultCRC);
        })
    }

    componentDidUpdate = () => {
        this.props.onStateUpdate(this.state);
    }

    onTableTitleUpdate = (title, pct) => {
        if (this.state.tableTitle != title) {
            this.setState({ 
                tableTitle: title,
                tableTitleIsCustom: true
             });
        }
    }

    onTablePercentUpdate = (pct) => {
        if (this.state.tablePercent != pct) {
             // update circle and table data to closest matching percent
            let reductionValues = this.state.currentCRC.map(x => x.responses.find(y => y.name == this.state.tableFactor).value);
            var closest = reductionValues.filter(x => x >= pct).reduce(function(prev, curr) {
                return (Math.abs(curr - pct) < Math.abs(prev - pct) ? curr : prev);
            });
            // use closest to reposition circle and re-populate data table
            let matchingData = this.state.currentCRC.find(x => x.responses.find(y => y.name == this.state.tableFactor).value == closest);
            this.setState({
                tablePercent: closest.toFixed(2),
                defaultCirclePct: closest,
                tableData: matchingData,
                currentCiclePct: closest
            })
        }
    }

    updateViewer() {
        let classContext: ScenarioViewer = this;
        if (classContext.state.toggleFetchData == true) {
            classContext.setState({ isUpdating: true, toggleFetchData: false });

            // use selected ap and scenario to find matching scenario summary, then fetch appropriate crc data
            let matchingScenario = classContext.props.scenariodata.scenarios.filter(x => x.scenario == classContext.state.scenario && x.assessmentPoint == classContext.state.assessmentpoint);

            if (matchingScenario.length > 0) {
                let data = new optimizationsummary();
                data.guid = classContext.props.scenariodata.guid;
                data.scenarios = [matchingScenario];
                data.isSample = classContext.props.scenariodata.isSample;

                optimizationsummary.axiosGetAll(data).then((r: AxiosResponse) => {
                    if (r.data != null) {
                        let data: optimizationsummary = r.data;
                        if (data.errors != null && data.errors.length > 0) {
                            //console.log('errors in opt data');
                            let alertProps: alertPropsModel = {
                                header: "There was an error loading the data",
                                body: '',
                                color: 'warning',
                                size: 'small-alert',
                                className: '',
                                context: this,
                                closeBtn: true,
                                setTimeOut: true,
                                timeOutTime: 2500
                            };
                            this.setState({ alertConfguration: alertProps, isUpdating: false }, () => {
                                this.setState({ alertIsOpen: true });
                            });
                        } else {
                            data.defaultcrc = r.data.defaultCRC;
                            data.bmpgroups = r.data.bmpGroups;
                            // find point at middle reduction value to place initial dot and populate initial table
                            let reductionValues = data.defaultcrc.map(x => x.responses.find(y => y.name == classContext.state.factor).value);
                            let maxPct = data.defaultcrc[data.defaultcrc.length - 1].responses.find(x => x.name == classContext.state.factor).value;
                            let middle = maxPct / 2;

                            // find closest matching % value in data (next up if no exact match)
                            var closest = reductionValues.reduce(function (prev, curr) {
                                return (Math.abs(curr - middle) < Math.abs(prev - middle) ? curr : prev);
                            });

                            // only want to show groups in table that are represented on chart
                            let tableBmpGroups = r.data.defaultCRC[r.data.defaultCRC.length - 1].bmPs.filter(x => x.value != 0).map(x => x.name);

                            let defaultTableData = data.defaultcrc.find(x => x.responses.find(y => y.name == classContext.state.factor).value == closest);

                            let tableTitle;
                            if (!classContext.state.tableTitleIsCustom) {
                                tableTitle = `Scenario ${classContext.state.scenario} (${matchingScenario[0].factorName} - ${matchingScenario[0].mjrEffect}) -- Assessment Point ${classContext.state.assessmentpoint} (${matchingScenario[0].city})`;
                            } else {
                                tableTitle = classContext.state.tableTitle
                            };

                            // dynamically adding colors not already in the color pallet - default is set of primary colors:
                            let currentColors = this.extendColors(tableBmpGroups);

                            this.setState({ numFadeOut: true }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        numFadeOut: false,
                                        chartColors: currentColors,
                                        currentCRC: data.defaultcrc,
                                        bmpGroups: data.bmpgroups,
                                        defaultCirclePct: closest,
                                        currentCiclePct: closest,
                                        tableFactor: classContext.state.factor,
                                        tableData: defaultTableData,
                                        tableBmps: tableBmpGroups,
                                        tableTitle: tableTitle,
                                        tablePercent: closest.toFixed(2),
                                        tableMaxPct: maxPct,
                                        isUpdating: false

                                    }, () => {
                                        // pass data to graph component
                                        classContext.handleReductionLineData(data.defaultcrc);
                                        classContext.handleCapacityAreasData(data.defaultcrc);
                                        this.child.current.forceResetCircle();
                                    });

                                }, 500);
                            })
                        }
                    }
                })
            } else {
                // alert("Please choose a valid combination");

                let alertProps: alertPropsModel = {
                    header: "Please choose a valid combination",
                    body: '',
                    color: 'warning',
                    size: 'small-alert',
                    className: '',
                    context: this,
                    closeBtn: true,
                    setTimeOut: true,
                    timeOutTime: 2500
                };
                classContext.setState({ alertConfguration: alertProps }, () => {
                    classContext.setState({ alertIsOpen: true, isUpdating: false });
                });
            }
        } else {
            // If only eval factor has changed we already have the data
            // 
            //console.log('else');
            classContext.setState({ isUpdating: true });
            let reductionValues = classContext.state.currentCRC.map(x => x.responses.find(y => y.name == classContext.state.factor).value);
            let maxPct = classContext.state.currentCRC[classContext.state.currentCRC.length - 1].responses.find(x => x.name == classContext.state.factor).value;

            // get index of current reduction selection and then get new reduction value from index (index = ML)
            let prevReductionValues = classContext.state.currentCRC.map(x => x.responses.find(y => y.name == classContext.state.tableFactor).value);
            let currentSolutionNumber = prevReductionValues.indexOf(classContext.state.currentCiclePct);

            // find closest matching % value in data (next up if no exact match)
            var closest = reductionValues[currentSolutionNumber];

            // only want to show groups in table that are represented on chart
            let tableBmpGroups = classContext.state.currentCRC[classContext.state.currentCRC.length - 1].bmPs.filter(x => x.value != 0).map(x => x.name);

            let defaultTableData = classContext.state.currentCRC.find(x => x.responses.find(y => y.name == classContext.state.factor).value == closest);

            // dynamically adding colors not already in the color pallet - default is set of primary colors:
            let currentColors = this.extendColors(tableBmpGroups);

            this.setState({ numFadeOut: true }, () => {
                setTimeout(() => {
                    this.setState({
                        numFadeOut: false,
                        chartColors: currentColors,
                        defaultCirclePct: closest,
                        currentCiclePct: closest,
                        tableFactor: classContext.state.factor,
                        tableData: defaultTableData,
                        tableBmps: tableBmpGroups,
                        tablePercent: closest.toFixed(2),
                        tableMaxPct: maxPct,
                        isUpdating: false

                    }, () => {
                        // pass data to graph component
                        classContext.handleReductionLineData(classContext.state.currentCRC);
                        classContext.handleCapacityAreasData(classContext.state.currentCRC);
                        //this.child.current.forceResetCircle();
                    });

                }, 500);
            })
        }
    }

    extendColors(tableBmpGroups: any): any {
        let currentColors = Object.assign({}, this.state.chartColors);
        let dynamicColors = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000', '#f58231']
        for (let c = 0; c < tableBmpGroups.length; c++) {
            if (currentColors[tableBmpGroups[c]] === undefined) {
                currentColors[tableBmpGroups[c]] = dynamicColors[c];
            }
        }
        return currentColors;
    }

    handleReductionLineData(crc) {
        let classContext: ScenarioViewer = this;

        // turn this data into array of x,y coordinate pair objects (where x = percent reduction, y = total cost)
        var coordinates = crc.map(data => ({ 
            x: data.responses.filter(r => r.name == classContext.state.factor)[0].value, 
            y: data.cost  
        }));
        classContext.setState({ reductionCoordinateData: coordinates });
    };

    handleCapacityAreasData(crc) {
        let classContext: ScenarioViewer = this;
        let bmpData = {};
        let maxCapacity = 0;
        let totalCap = 0;
        // for each "stack" of bmps, create array of coordinates and add it to bmpData
        crc.forEach(datum => {
            let reduction = datum.responses.filter(r => r.name == classContext.state.factor)[0].value; 
            
            // scale left axis based on total capacity of all bmps for a selection
            let capacity = datum.totalCapacity;
            maxCapacity = Math.max(maxCapacity, capacity);
            totalCap += capacity;

            datum.bmPs.forEach(bmp => {
                if(bmpData[bmp.name] == undefined) {
                    bmpData[bmp.name] = [{ x: reduction, y: bmp.value }];
                } else {
                    bmpData[bmp.name].push({ x: reduction, y: bmp.value });
                };
            });  
        });

        // "stack" bmps by adding y values of previous points
        // in order to "stack", need each y coordinate to incorporate previous y values at the same point 
        // add previous y value each time so by the end, the last bmpgroup accounts for all capacity
        // use reverse order array of bmp group names to iterate over
        let reverseBmpArray;
        if (classContext.state.bmpGroups == undefined) {
            reverseBmpArray = classContext.props.scenariodata.bmpGroups.map(x => x.groupName).reverse();
        } else {
            reverseBmpArray = classContext.state.bmpGroups.map(x => x.groupName).reverse();
        };
        for (let i = 1; i < reverseBmpArray.length; i++) {
            let bmp = reverseBmpArray[i];
            let prevbmp = reverseBmpArray[i - 1];
            
            for (let j = 0; j < bmpData[bmp].length; j++) {
                let currentY = bmpData[bmp][j].y;
                let prevY = bmpData[prevbmp][j].y;
                let newY = currentY + prevY;
                bmpData[bmp][j].y = newY;
            };
        };

        classContext.setState({ 
            bmpCoordinateData: bmpData, 
            maxCapacity: maxCapacity 
        }, () => {
            classContext.getBatchData();
            //classContext.getPlanData();
        });
    }

    handlePctReductionSelection = (pctRed, cost) => {
        // get first matching crc item, because depending on data, there may be repeats that have the same values
        let matchingCRCitem = this.state.currentCRC.filter(x => x.cost == cost && x.responses.filter(y => y.name == this.state.tableFactor)[0].value == pctRed)[0];

        let tablePct = matchingCRCitem.responses.find(x => x.name == this.state.tableFactor).value.toFixed(2); 

        this.setState({ numFadeOut: true }, () => {
            setTimeout(() => {
                this.setState({
                    numFadeOut: false,
                    tableData: matchingCRCitem, 
                    currentCiclePct: pctRed,
                    tablePercent: tablePct  
                });

            }, 500);
        });
    }

    updateDropdownScenario = (classContext: any) => {
        if (classContext.props.dropdownItems.length > 0) {
            if (classContext.state.value !== this.state.scenario) {
                classContext.setState({ value: this.state.scenario, loading: false, toggleFetchData: true});
            };
        };
    };

    dropdownScenario = (event: any, data: any, index: any) => {
        let classContext: ScenarioViewer = this;
        let key = 0;
        this.state.scenOptions.forEach((o, thisIndex) => {
            if (o == data.value) {
                key = thisIndex;
            }
        });

        this.setState({ scenIndexToLookAt: key, scenario: data.value, toggleFetchData: true }, () => {
            // call this to update APs dropdown
            classContext.generateAPsDropdownOptions();
        });
    };

    updateDropdownAPs = (classContext: any) => {
        if (classContext.props.dropdownItems.length > 0) {
            if (classContext.state.value !== this.state.assessmentpoint) {
                classContext.setState({ value: this.state.assessmentpoint, loading: false, toggleFetchData: true})
            };
        };
    };

    dropdownAPs = (event: any, data: any, index: any) => {
        let key = 0;
        this.state.apsOptions.forEach((o, thisIndex) => {
            if (o == data.value) {
                key = thisIndex;
            };
        });

        this.setState({ apIndexToLookAt: key, assessmentpoint: data.value, toggleFetchData: true }, () => {
        })
    };

    updateDropdownFactor = (classContext: any) => {
        if (classContext.props.dropdownItems.length > 0) {
            if (classContext.state.value !== this.state.factorNumber) {
                classContext.setState({value: this.state.factorNumber, loading: false})
            };
        };
    };

    dropdownFactor = (event: any, data: any, index: any) => {
        let key = 0;
        let factor;
        let factorNummber;
        this.state.facOptions.forEach((o, thisIndex) => {
            if (o.optionValue == data.value) {
                key = thisIndex;
                factor = data.options[data.value].text;
                factorNummber = thisIndex;
            };  
        });

        this.setState({ facIndexToLookAt: key, factor: factor, factorNumber: factorNummber });
    };

    generateSenarioDropdownOptions = () => {
        let classContext: ScenarioViewer = this;

        let scenOptions;
        let scens = [];
        classContext.props.scenariodata.scenarios.forEach(x => scens.push({ key: x.scenario, text: `${x.scenario} - ${x.factorName} - ${x.mjrEffect}`, value: x.scenario }));
        const unique = Array.from(new Set(scens.map(x => x.key))).map(key => {
            let dropOption = new DropdownOption();
            dropOption.optionText = scens.find(x => x.key === key).text;
            dropOption.optionValue = scens.find(x => x.key === key).value;

            return dropOption;
        });
        scenOptions = unique;

        this.setState({ scenOptions: scenOptions});
    }

    generateAPsDropdownOptions = () => {
        let classContext: ScenarioViewer = this;
        let APs = [];
        let filtered = classContext.props.scenariodata.scenarios.filter(x => x.scenario == classContext.state.scenario);
        let sortedAPs = filtered.sort((a: any, b: any) => {
            if ( a.assessmentPoint < b.assessmentPoint ){
                return -1;
              }
              if ( a.assessmentPoint > b.assessmentPoint ){
                return 1;
              }
              return 0;
        });
        let matchApId: boolean = false; // used to verify the current AP ID selection is valid in the new result set
        if (sortedAPs.length > 0) {
            sortedAPs.forEach((x) => {

                let dropOption = new DropdownOption();
                dropOption.optionText = `${x.assessmentPoint} - ${x.city}`;
                dropOption.optionValue = x.assessmentPoint;

                APs.push(dropOption);
                if (classContext.state.assessmentpoint == x.assessmentPoint) {
                    matchApId = true;
                }
            });
        };
        classContext.setState({ apsOptions: APs })
        if (matchApId === false) {
            classContext.setState({ assessmentpoint: sortedAPs[0].assessmentPoint });
        }
    }

    generateFactorDropdownOptions = () => {
        let factors = ["Flow","SOSED", "PLS_TN", "PLS_TP", "PLS_TCd", "PLS_TCu", "PLS_TPb", "PLS_TZn"];
        let formattedFactors: any = [];
        factors.forEach((fact, index) => {
            let dropOption = new DropdownOption();
            dropOption.optionText = fact;
            dropOption.optionValue = index;
            formattedFactors.push(dropOption);
        });

        this.setState({ facOptions: formattedFactors }, () => {
            let data: any = {};
            data.options = []
            for (var i = 0; i < factors.length; i++) {
                var item: any = {};
                item.key = i;
                item.value = i;
                item.text = factors[i];
                data.options.push(item);
                if (factors[i] == this.state.tableFactor) {
                    data.value = i;
                }
            }
            this.dropdownFactor(null, data, null);
        });
    }

    findChildElement = (elem: any, childClassName: string): any => {
        for (var i = 0; i < elem.children.length; i++) {
            if (elem.children[i].classList.contains(childClassName)) {
                return elem.children[i];
            } else {
                let res = this.findChildElement(elem.children[i], childClassName);
                if (res != null) { return res;}
            }
        }
        return null;
    }

    updateColor = (color: string, bmpName: string) => {
        //console.log('update color', color, bmpName, this);
        let currentColors = Object.assign({}, this.state.chartColors);
        if (currentColors[bmpName] !== undefined) {
            currentColors[bmpName] = color;
            this.setState({ chartColors: currentColors });
        }
    }

    generateAPData = () => {
        //alert('generate ap');
        let classContext: ScenarioViewer = this;
        classContext.setState({ isGeneratingAPData: true });
        let matchingScenario: optimizationsummaryitem[] = classContext.props.scenariodata.scenarios.filter(x => x.scenario == classContext.state.scenario && x.assessmentPoint == classContext.state.assessmentpoint);
        if (matchingScenario != null && matchingScenario.length > 0) {
            matchingScenario[0].factorname = classContext.state.factor;

            
            // let data = new optimizationassessmentpointdata();
            // data.guid = classContext.props.scenariodata.guid;
            // data.scenarios = matchingScenario;
            // data.reduction = classContext.state.currentCiclePct;

            let formData: FormData = new FormData();
            formData.append("guid", classContext.props.scenariodata.guid);
            formData.append("scenarios", JSON.stringify(matchingScenario));
            formData.append("reduction", classContext.state.currentCiclePct.toString());

            let filename: string = "apData.ccf";
            //filename = classContext.generateAPFileName(filename, "APData_", ".ccf");

            optimizationassessmentpointdata.axiosPostImage(formData)
                .then((r: AxiosResponse) => {
                    classContext.setState({ isGeneratingAPData: false });
                    const blob = new Blob([r.data], { type: 'text/plain' });
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else {
                        const blob = new Blob([r.data], { type: "text/plain" });
                        var link = document.createElement('a');
                        var href = window.URL.createObjectURL(blob);
                        link.setAttribute('href', href);
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        setTimeout(() => {
                            window.URL.revokeObjectURL(href);
                            document.body.removeChild(link);
                        }, 0)
                    }
                })
                .catch((r: AxiosResponse) => {
                    //console.log("error getting implementation plan ", r.data, classContext)
                    classContext.setState({ isGeneratingAPData: false });
                    let alertProps: alertPropsModel = {
                        header: "There was an error generating the assessment point data.",
                        body: '',
                        color: 'warning',
                        size: 'small-alert',
                        className: '',
                        context: classContext,
                        closeBtn: true,
                        setTimeOut: true,
                        timeOutTime: 2500
                    };

                    //console.log('after props', alertProps);
                    classContext.setState({ alertConfguration: alertProps }, () => {
                        classContext.setState({ alertIsOpen: true });
                    });
                })
        }
    }

    generateDeliveryRatios = () => {
        //alert('generating ratio');
        let classContext: ScenarioViewer = this;
        classContext.setState({ isGeneratingRatio: true });
        let matchingScenario: optimizationsummaryitem[] = classContext.props.scenariodata.scenarios.filter(x => x.scenario == classContext.state.scenario && x.assessmentPoint == classContext.state.assessmentpoint);
        if (matchingScenario != null && matchingScenario.length > 0) {
            matchingScenario[0].factorname = classContext.state.factor;

            let formData: FormData = new FormData();
            formData.append("guid", classContext.props.scenariodata.guid);
            formData.append("scenarios", JSON.stringify(matchingScenario));
            formData.append("reduction", classContext.state.currentCiclePct.toString());

            let data = new optimizationdeliveryratio();
            //data.guid = classContext.props.scenariodata.guid;
            //data.scenarios = JSON.stringify(matchingScenario);
            //data.reduction = classContext.state.currentCiclePct;

            optimizationdeliveryratio.axiosPostImage(formData)
                .then((r: AxiosResponse) => {
                    classContext.setState({ isGeneratingRatio: false });
                    let filename: string = "DeliveryRatio.mrc";
                    const blob = new Blob([r.data], { type: 'text/plain' });
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else {
                        const blob = new Blob([r.data], { type: "text/plain" });
                        var link = document.createElement('a');
                        var href = window.URL.createObjectURL(blob);
                        link.setAttribute('href', href);
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        setTimeout(() => {
                            window.URL.revokeObjectURL(href);
                            document.body.removeChild(link);
                        }, 0)
                    }
                })
                .catch((r: AxiosResponse) => {
                    //console.log("error getting implementation plan ", r.data, classContext)
                    classContext.setState({ isGeneratingRatio: false });
                    let alertProps: alertPropsModel = {
                        header: "There was an error generating the delivery ratio.",
                        body: '',
                        color: 'warning',
                        size: 'small-alert',
                        className: '',
                        context: classContext,
                        closeBtn: true,
                        setTimeOut: true,
                        timeOutTime: 2500
                    };

                    //console.log('after props', alertProps);
                    classContext.setState({ alertConfguration: alertProps }, () => {
                        classContext.setState({ alertIsOpen: true });
                    });
                })
        }
    }


    generateImplementationPlan = () => {
        let classContext: ScenarioViewer = this;
        classContext.setState({ isGeneratingPlan: true });
        
        let elem = ReactDOM.findDOMNode(classContext);
        //console.log('elem', elem);
        let svgElem = classContext.findChildElement(elem, 'optSVG');


        if (svgElem == null) {
            //  alert('element not found'); 
            let alertProps: alertPropsModel = {
                header: "Element not found",
                body: '',
                color: 'warning',
                size: 'small-alert',
                className: '',
                context: classContext,
                closeBtn: true,
                setTimeOut: true,
                timeOutTime: 2500
            };
            classContext.setState({ alertConfguration: alertProps }, () => {
                classContext.setState({ alertIsOpen: true });
            });


            
        }
        else {
            svgElem.scrollTo(0,0);
            window.scrollTo(0,0);

            
            let newCanvas = document.createElement("canvas");
            let ctx  = newCanvas.getContext("2d");
            // d3.select(svgElem).style("background-color", "white");
            

            Canvg.from(ctx, svgElem.outerHTML, {ignoreClear: true}).then((canvg: Canvg) => {
                //get the current ImageData for the canvas.
                // canvg.render();

                canvg.start();

                canvg.ready().then((x => {

                    let data = ctx.getImageData(0, 0, classContext.curveGraphWidth, 500);
                    
                    //store the current globalCompositeOperation
                    let compositeOperation = ctx.globalCompositeOperation;
        
                    //set to draw behind current content
                    ctx.globalCompositeOperation = "destination-over";
        
                    //set background color
                    ctx.fillStyle = "white";
        
                    //draw background / rect on entire canvas
                    ctx.fillRect(0, 0, classContext.curveGraphWidth,500);
    
                    let imgData = newCanvas.toDataURL('image/jpeg');    
                    
                    //clear the canvas
                    ctx.clearRect(0, 0, classContext.curveGraphWidth,500);
    
                    //restore it with original / cached ImageData
                    ctx.putImageData(data, 0,0);		
    
                    //reset the globalCompositeOperation to what it was
                    ctx.globalCompositeOperation = compositeOperation;
    
                    let matchingScenario: optimizationsummaryitem[] = classContext.props.scenariodata.scenarios.filter(x => x.scenario == classContext.state.scenario && x.assessmentPoint == classContext.state.assessmentpoint);
                    matchingScenario[0].factorname = classContext.state.factor;
                    if (matchingScenario != null && matchingScenario.length > 0) {

                        let formData: FormData = new FormData();

                        formData.append("base64image", imgData);
                        formData.append("guid", classContext.props.scenariodata.guid);
                        formData.append("scenarios", JSON.stringify(matchingScenario));
                        formData.append("reduction", classContext.state.currentCiclePct.toString());

                        optimizationimplementationplan.axiosPostImage(formData)
                            .then((r: AxiosResponse) => {
                                classContext.setState({ isGeneratingPlan: false });
                                let filename: string = "ImplementationPlan.xlsx";
                                const blob = new Blob([r.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                    window.navigator.msSaveOrOpenBlob(blob, filename);
                                } else {
                                    const blob = new Blob([r.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                                    var link = document.createElement('a');
                                    var href = window.URL.createObjectURL(blob);
                                    link.setAttribute('href', href);
                                    link.setAttribute('download', filename);
                                    document.body.appendChild(link);
                                    link.click();
                                    setTimeout(() => {
                                        canvg.stop();
                                        window.URL.revokeObjectURL(href);
                                        document.body.removeChild(link);
                                    }, 0)
                                }
                            })
                            .catch((r: AxiosResponse) => {
                                //console.log("error getting implementation plan ", r.data)
                                classContext.setState({ isGeneratingPlan: false });
                                let alertProps: alertPropsModel = {
                                    header: "There was an error generating the implementation plan",
                                    body: '',
                                    color: 'warning',
                                    size: 'small-alert',
                                    className: '',
                                    context: classContext,
                                    closeBtn: true,
                                    setTimeOut: true,
                                    timeOutTime: 2500
                                };
                                classContext.setState({ alertConfguration: alertProps }, () => {
                                    classContext.setState({ alertIsOpen: true });
                                });
                            })
                    }

                }));


            })
            .catch((error) => {
                console.log("error creating image.");
                classContext.setState({ isGeneratingPlan: false });
            });
        }
    }
    
    getBatchData = () => {

        let classContext: ScenarioViewer = this;
        let matchingScenario: optimizationsummaryitem[] = classContext.props.scenariodata.scenarios.filter(x => x.scenario == classContext.state.scenario && x.assessmentPoint == classContext.state.assessmentpoint);
        if (matchingScenario != null && matchingScenario.length > 0) {
            matchingScenario[0].factorname = classContext.state.factor;

            let data = new sustainToLSPCbatch();
            data.guid = classContext.props.scenariodata.guid;
            data.scenarios = matchingScenario;
            data.reduction = classContext.state.currentCiclePct;

            classContext.props.setBatchData(data, classContext.props.indexValue);
        }
    };


    //getPlanData = () => {
    //    let classContext: ScenarioViewer = this;

    
    //};

    getSustainToLSPCBath = () => {
        //console.log('generating batch: ', this.state.currentCiclePct);
        let classContext: ScenarioViewer = this;
        classContext.setState({ isGeneratingBatch: true }, () => {

            // use selected ap and scenario to find matching scenario summary, then fetch appropriate crc data
            let matchingScenario: optimizationsummaryitem[] = classContext.props.scenariodata.scenarios.filter(x => x.scenario == classContext.state.scenario && x.assessmentPoint == classContext.state.assessmentpoint);
            if (matchingScenario != null && matchingScenario.length > 0) {
                matchingScenario[0].factorname = classContext.state.factor;

                //let data = new sustainToLSPCbatch();
                //data.guid = classContext.props.scenariodata.guid;
                //data.scenarios = JSON.stringify(matchingScenario);
                //data.reduction = classContext.state.currentCiclePct;

                let formData: FormData = new FormData();
                formData.append("guid", classContext.props.scenariodata.guid);
                formData.append("scenarios", JSON.stringify(matchingScenario));
                formData.append("reduction", classContext.state.currentCiclePct.toString());

                sustainToLSPCbatch.axiosPostImage(formData)
                    .then((r: AxiosResponse) => {
                        classContext.setState({ isGeneratingBatch: false }, () => {
                            let filename: string = "Sustain2LSPC.zip";
                            const bloboBaggins = new Blob([r.data], { type: "application/zip" });

                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(bloboBaggins, filename);
                            } else {
                                const bloboBaggins = new Blob([r.data], { type: 'application/zip' });
                                var link = document.createElement('a');
                                var href = window.URL.createObjectURL(bloboBaggins);
                                link.setAttribute('href', href);
                                link.setAttribute('download', filename);
                                document.body.appendChild(link);
                                link.click();
                                setTimeout(() => {
                                    window.URL.revokeObjectURL(href);
                                    document.body.removeChild(link);
                                }, 0)
                            };
                        });
                    })
                    .catch((r: any) => {
                        let alertProps: alertPropsModel = {
                            header: "Error generating the requested data.",
                            body: '',
                            color: 'warning',
                            size: 'small-alert',
                            className: '',
                            context: this,
                            closeBtn: true,
                            setTimeOut: true,
                            timeOutTime: 3000
                        };
                        this.setState({ alertConfguration: alertProps }, () => {
                            this.setState({ alertIsOpen: true });
                        });


                        console.log("error getting batch file for ", this.state.currentCiclePct);
                        classContext.setState({ isGeneratingBatch: false });
                    });
            }
        });
    }

    render() {
        let classContext: ScenarioViewer = this;

        let chartprops = {
            svgHeight: 500,
            svgWidth: classContext.curveGraphWidth,
            crcData: classContext.state.bmpCoordinateData,
            reductionData: classContext.state.reductionCoordinateData,
            maxCapacity: classContext.state.maxCapacity,
            clickHandler: classContext.handlePctReductionSelection,
            bmpGroupInfo: classContext.state.bmpGroups,
            colors: classContext.state.chartColors,
            defaultCirclePct: classContext.state.defaultCirclePct ,
            currentCirclePct: classContext.state.currentCiclePct,
            loading: classContext.state.isUpdating,
            textSize: 12,
            isSample: classContext.props.scenariodata.isSample
        };

        let tableprops = {
            data: classContext.state.tableData,
            bmpGroups: classContext.state.tableBmps,
            factor: classContext.state.tableFactor,
            title: classContext.state.tableTitle,
            percent: classContext.state.tablePercent,
            maxPct: classContext.state.tableMaxPct,
            colors: classContext.state.chartColors,
            onTitleUpdate: classContext.onTableTitleUpdate,
            onPercentUpdate: classContext.onTablePercentUpdate,
            generatePlan: classContext.generateImplementationPlan,
            isGeneratingPlan: classContext.state.isGeneratingPlan,
            generateBatch: classContext.getSustainToLSPCBath,
            isGeneratingBatch: classContext.state.isGeneratingBatch,
            generateRatio: classContext.generateDeliveryRatios,
            generateAPData: classContext.generateAPData,
            isGeneratingAPData: classContext.state.isGeneratingAPData,
            isGeneratingRatio: classContext.state.isGeneratingRatio,
            tooltipFactory: classContext.props.tooltipFactory,
            tooltipsReady: classContext.props.tooltipsReady,
            numFadeOut: classContext.state.numFadeOut,
            updateColorFunction: classContext.updateColor,
            mrcAvailable: classContext.props.scenariodata.mrcAvailable,
            isSample: classContext.props.scenariodata.isSample
        };

        // Create params for dropdowns
        let dropDownOne = {
            dropdownTitle: "Optimization Target",
            dropdownItems: classContext.state.scenOptions,
            onSelect: classContext.dropdownScenario,
            isMultiselect: false,
            onUpdate: classContext.updateDropdownScenario
        };

        let dropDownTwo = {
            dropdownTitle: "Assessment Point",
            dropdownItems: classContext.state.apsOptions,
            onSelect: classContext.dropdownAPs,
            isMultiselect: false,
            onUpdate: classContext.updateDropdownAPs
        };

        let dropDownThree = {
            dropdownTitle: "Evaluation Factor",
            dropdownItems: classContext.state.facOptions,
            onSelect: classContext.dropdownFactor,
            isMultiselect: false,
            onUpdate: classContext.updateDropdownFactor

        };

        return (
            <div className="scenario">
                {
                    this.state.alertIsOpen &&
                    <AlertComponent
                        // ref={this.childAlert} 
                        header={this.state.alertConfguration.header}
                        body={this.state.alertConfguration.body}
                        color={this.state.alertConfguration.color}
                        size={this.state.alertConfguration.size}
                        className={this.state.alertConfguration.className}
                        context={this.state.alertConfguration.context}
                        closeBtn={this.state.alertConfguration.context}
                        setTimeOut={this.state.alertConfguration.setTimeOut}
                        timeOutTime={this.state.alertConfguration.timeOutTime}
                    />
                }
                <Grid centered>
                    <Grid.Column stretched={true} mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={11}>
                        <Grid.Row className="centered-row">
                            <div className="viewer-menu">
                                <div className="btn-w-tooltip-div">
                                    <DropdownH2O {...dropDownOne}/>
                                    <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1-point5 pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer3.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer3.body : 'Tooltip data is still loading please try again in a moment'} /> 
                                </div>
                                <div className="btn-w-tooltip-div">
                                    <DropdownH2O {...dropDownTwo}/>
                                    <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1-point5 pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer4.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer4.body : 'Tooltip data is still loading please try again in a moment'} /> 
                                </div>
                                <div className="btn-w-tooltip-div">
                                    <DropdownH2O {...dropDownThree}/>
                                    <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1-point5 pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer5.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer5.body : 'Tooltip data is still loading please try again in a moment'} /> 

                                </div>
                                <div className="update-btn-div">
                                    <Button 
                                        loading={this.state.isUpdating} 
                                        className="menu-item update-btn wmms-light-blue-btn" 
                                        onClick={() => this.updateViewer()} 
                                    >Update</Button>
                                </div>
                            </div>
                        </Grid.Row>
                        <Grid.Row className="centered-row">
                            <Segment>
                                <Loader className="opt-loader" content="Loading ..." size="big" active={this.state.isUpdating}/>
                                {/* <ResponsiveChart {...chartprops} /> */}
                                <OptimizationSVG ref={this.child}{...chartprops}/> 
                            </Segment>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column  stretched={true} mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={5}>
                        <Grid.Row className="centered-row">
                            <OptimizationTable {...tableprops} />
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}