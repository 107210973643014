import { AxiosError, AxiosResponse } from "axios";
import React, { Component } from "react";
import "./ClimateViewer.css";
import FileDragAndDrop from "../../core/fileDragAndDrop/FileDragAndDrop";
import { INavHistory } from "../../interfaces/INavHistory";
import { alertPropsModel } from "../../models/alertPropsModel";
import { climateUpload } from "../../models/ClimateChange/climateUpload";
import { ClimateSummary } from "../../models/ClimateChange/climateSummary";
import { ClimateCsv } from "../../models/ClimateChange/climateCsv";
import { Button, Checkbox, Container, Dropdown, Icon, Label, List, Loader, Transition } from "semantic-ui-react";
import { MyResponsiveBar } from "./NivoBarChart/myResponsiveBar";
import { ButtonGroup } from "../../core/buttonGroup/ButtonGroup";
import { MyResponsiveLine } from "./NivoLineChart/myResponsiveLine";
import { MyResponsiveBullet } from "./NivoBullet/myResponsiveBullet";
import { DetailItem } from "./DetailItem/detailItem";
import { PerformanceSummary } from "./PerformanceSummary/performanceSummary";
import {formatNumber } from "../../core/HelperFx";
import { DataTable } from "./DataTable/dataTable";
import { DivergingBar } from "./NivoDivergingBar/DivergingBar";
import YesNoModal from "../../core/modals/yesNoModal/yesNoModal";
import { Watermark } from "../../core/watermark/watermark";
import { TooltipModal } from "../toolTipModal/toolTipModal";
import Config from "../../config/Config";
import DownloadLink from "../../core/downloadLink/downloadLink";
import logo from '../../images/wmms-climate.png';
import { ClimateItem } from "../../models/ClimateChange/climateItem";


export class ClimateViewerState {
    summary: ClimateSummary = new ClimateSummary();
    origData: ClimateSummary = new ClimateSummary();
    zipfilename: string = "No selection";
    zipfile: File = null;
    isLoadingData: boolean = false;
    isGeneratingCsv: boolean = false;
    alertConfguration: alertPropsModel = new alertPropsModel();
    alertIsOpen: boolean = false;
    barsBy: string = "All";
    selScenarioId: number = 1;
    selScenarioVal: number = 479;
    evalIdx: number = 0;
    evalName: number = 0;
    evalNames = [];
    lineXName = 'Capacity';
    lineXNames = ['Capacity', 'Footprint', 'Cost', 'Reduction'];
    lineXUnit = 'ac-ft';
    lineYName = 'Capture';
    lineYNames = ['Runoff', 'Capture', 'Remaining Flow', '% Reduction']
    lineYUnit = 'ac-ft';
    //solGraphNumberOptions = ["5", "10", "20", "All"]
    selGraphNumberSolutions = "All";
    statsBarOptions = ["Median", "Mean"]
    selStatsBarOption = "Median";
    lineData = []
    performanceGraphData = []
    filteredGraphs = []
    costGroups: any[] = ["group A"];
    modelGroups: any[] = ["group A"];
    eventGroups: string[] = ["something"];
    scenarioGroups: any[] = ["something"];
    scenSelOptions = [];
    singleNames: string = "";
    bulletData = [
        {"id":"2","ranges":[162,678],"markers":[295], "weight": 1},
        {"id":"5","ranges":[162,678],"markers":[234], "weight": 1},
        {"id":"Historic","ranges":[162,163], "weight": 1}
    ]
    bulletMin = 1500;
    bulletMax = 2500;
    bulletAvgs = [];
    hideui: boolean = false;
    showDetails: boolean = false;
    detailItem: any = [];
    divergingData: any = [];
    divergingLegend: string = "both";
    divKeys: string[] = [];
    divKeysMean: string[] = [];
    divColorsMean: string[] = [];
    divColors: string[] = [];
    divYMinMean: number = -100;
    divYMaxMean: number = 100;
    divYMin: number = -100;
    divYMax: number = 100;
}

export default class ClimateViewer extends Component<INavHistory> {

    state =  new ClimateViewerState();
    componentDidMount() {
        this.addSample();
    }

    fileDragDropUpload = (files: Array<any>) => {
        let classContext: ClimateViewer = this;

        if (files != undefined && files.length == 1) {
            classContext.setState({ ['zipfilename']: files[0].name, ['zipfile']: files[0] }, () => {
                classContext.addScenarioClick();
            });
        }
    }

    getState = () => {
        let stateCopy = Object.assign({}, this.state)
        return stateCopy;
    }

    // recursively removes rect with rgba(255,255,255,1) from base element 
    clearChildrenRecursive = (elem: any): any => {
        if(elem.tagName == 'rect'){
            if(elem.attributes['fill'] !== undefined){
                if(elem.attributes['fill'].nodeValue === "rgba(255, 255, 255, 1)"){
                    return {"is":true, "any": true};
                }
            }
        }
        let track = {"is":false, "any": false}
        for(var i = 0; i < elem.children.length; i++){
            let res = this.clearChildrenRecursive(elem.children[i]);
            if(res.is == true){
                track.any = true;
                elem.removeChild(elem.children[i]);
            } else if(res.any == true){
                track.any = true;
            }

        }
        return track;
    }

    // Get's called when we come back from the API or change number of solutions. Highest level entry point
    dataPrep = () =>{
        let classContext: ClimateViewer = this;
        let dataCopy: ClimateSummary = Object.assign({}, classContext.state.origData);

        let subsetNum = 10000;
        if(classContext.state.selGraphNumberSolutions.toLowerCase() != "all"){
            subsetNum = Number.parseInt(classContext.state.selGraphNumberSolutions);
        }

        let newScenariosIds = Array.from(new Set(dataCopy.items.map(item => item.scenarioId))).sort(function(a: number, b: number){return a - b;});
        if(newScenariosIds.length > subsetNum){
            let selScenarioIds: number[] = new Array<number>();
            
            selScenarioIds.push(newScenariosIds.shift());
            newScenariosIds.push(newScenariosIds[newScenariosIds.length -1]);
            newScenariosIds.pop();
            
            if(selScenarioIds.indexOf(dataCopy.selectedScenarioId)< 0){
                selScenarioIds.push(dataCopy.selectedScenarioId);
                newScenariosIds.splice(newScenariosIds.indexOf(dataCopy.selectedScenarioId), 1);
            }
            
            let itemsNeededRemain = subsetNum - selScenarioIds.length;
            let averageNum = newScenariosIds.length / itemsNeededRemain;
            let numAgg = 0.0;

            while(numAgg < newScenariosIds.length && selScenarioIds.length <= subsetNum){
                numAgg += averageNum;
                selScenarioIds.push(newScenariosIds[Math.round(numAgg) -1]);                
            }

            dataCopy.items = dataCopy.items.filter((item) => selScenarioIds.indexOf(item.scenarioId) >= 0);
        }

        classContext.setState({summary: dataCopy}, () => {
            if(classContext.state.summary!= null && classContext.state.summary.items != null && classContext.state.summary.items.length > 0){
                let newCosts = Array.from(new Set(classContext.state.summary.items.map(item => item.cost))).sort();
                let newEvents = Array.from(new Set(classContext.state.summary.items.map(item => item.eventLabel))).sort();
                let newScenarios = Array.from(new Set(classContext.state.summary.items.map(item => item.scenarioId))).sort(function(a: number, b: number){return a - b;});

                let newModels = Array.from(new Set(classContext.state.summary.items.map(item => item.model))).sort();
                let newEvalNames = [];

                for(var i = 0; i < classContext.state.summary.items[0].responses.length; i++){
                    newEvalNames.push(classContext.state.summary.items[0].responses[i].constituent);
                }

                let newScenOpt = [];
                for(var i = 0; i < newScenarios.length; i++){
                    let scenCap = classContext.state.summary.items.filter((item) => item.scenarioId == newScenarios[i])[0];
                    newScenOpt.push({
                        key: i,
                        value: newScenarios[i],
                        text: scenCap.scenarioCapacity.toString() + ' ac-ft - ' + formatNumber(scenCap.scenarioFootprint, 0).toString() + ' sq-ft (' + newScenarios[i].toString() + ')'
                    })
                }
                let scenId: any = 0;
                if(classContext.state.summary.selectedScenarioId > 0){
                    scenId = classContext.state.summary.selectedScenarioId;
                } else {
                    if(newScenarios.length > 0){ scenId = newScenarios[newScenarios.length - 1]; }
                }

                classContext.setState({ costGroups: newCosts, eventGroups: newEvents, evalNames: newEvalNames, evalName: newEvalNames[0], scenSelOptions: newScenOpt, scenarioGroups: newScenarios, modelGroups: newModels, selScenarioId: scenId }, () => this.dataUpdate());
            }
        })
    }

    // Get's called anytime the user changes viz options (x or y axis, scenario selection, etc...)
    dataUpdate = () =>{
        let classContext: ClimateViewer = this;
        let newData: any = new Array();
        if(classContext.state.summary!= null && classContext.state.summary.items != null && classContext.state.summary.items.length > 0){
            for(var i = 0; i < classContext.state.summary.items.length; i++){
                classContext.state.summary.items[i].id = i;
                classContext.state.summary.items[i]['Remaining Flow'] = classContext.state.summary.items[i].responses[classContext.state.evalIdx].initEval;
                classContext.state.summary.items[i]['Capture'] = Math.round((classContext.state.summary.items[i].responses[classContext.state.evalIdx].postDev - classContext.state.summary.items[i].responses[classContext.state.evalIdx].initEval) * 100) / 100 ;
                classContext.state.summary.items[i]['Runoff'] = classContext.state.summary.items[i].responses[classContext.state.evalIdx].postDev;
                classContext.state.summary.items[i]['% Reduction'] = classContext.state.summary.items[i].responses[classContext.state.evalIdx].percentReduction;
                classContext.state.summary.items[i]['Tier2Reduction'] = classContext.state.summary.items[i].responses[classContext.state.evalIdx].tier2Reduction;

                // creating full model name
                if(classContext.state.summary.items[i].modelDescription && classContext.state.summary.items[i].modelDescription.length > 1){
                    classContext.state.summary.items[i].fullModelName = classContext.state.summary.items[i].modelDescription;
                }
                else if(classContext.state.summary.items[i].event){
                    classContext.state.summary.items[i].fullModelName = classContext.state.summary.items[i].model + ' - ' + classContext.state.summary.items[i].event
                } else {
                    classContext.state.summary.items[i].fullModelName = classContext.state.summary.items[i].model;
                }
            }

            let newFullModelNames = Array.from(new Set(classContext.state.summary.items.map(item => item.fullModelName))).sort();
            
            let yName = 'scenarioId';
            if(classContext.state.lineXName == 'Cost') {yName = 'scenarioCost';}
            if(classContext.state.lineXName == 'Reduction') {yName = 'Tier2Reduction';}
            if(classContext.state.lineXName == 'Capacity') {yName = 'scenarioCapacity';}
            if(classContext.state.lineXName == 'Footprint') {yName = 'scenarioFootprint';}

            let selScenVal = classContext.state.summary.items.filter((selitm) => selitm.scenarioId == classContext.state.selScenarioId)[0][yName];
            
            // build line graph
            for(var i = 0; i < newFullModelNames.length; i++){
                let lineItem: any = {};
                let filter: boolean = false;
                if(classContext.state.filteredGraphs.indexOf(newFullModelNames[i]) >= 0){
                    filter = true;
                    lineItem.id = 'x ' + newFullModelNames[i];
                    
                } else {
                    lineItem.id = newFullModelNames[i];
                }
                lineItem.data = new Array();
                let modelItems = classContext.state.summary.items.filter((item) => item.fullModelName == newFullModelNames[i]);
                // sort it
                modelItems = modelItems.sort(function(a: any, b: any) {return a[yName] - b[yName]})

                if(!filter){
                    for(var j = 0; j < modelItems.length; j++){
                        lineItem.data.push({x: modelItems[j][yName], y: modelItems[j][classContext.state.lineYName]});
                    }
                }
                newData.push(lineItem);
            }

            // build bullet/box-whisker data & performanceBars
            // always 'event based' -- eventGroups
            let newBoxData = [];
            let boxMin = 0;
            let boxMax = 0;
            let newAvgs = [];
            let newSingleNames = "";
            let newPerformanceBarData = [];

            let scenarioItems = classContext.state.summary.items.filter((item) => item.scenarioId == classContext.state.selScenarioId && classContext.state.filteredGraphs.indexOf(item.fullModelName) < 0)
            if(scenarioItems && scenarioItems.length > 0){
                boxMin = scenarioItems.reduce((min, p) => p[classContext.state.lineYName] < min? p[classContext.state.lineYName] : min, scenarioItems[0][classContext.state.lineYName]);
                boxMax = scenarioItems.reduce((max, p) => p[classContext.state.lineYName] > max? p[classContext.state.lineYName] : max, scenarioItems[0][classContext.state.lineYName]);
                // calculate min/max as 10% beyond min/max data points without going negative
                let pct10 = (boxMax - boxMin) * 0.15;
                boxMin = Math.round((boxMin - pct10) * 1000)/1000;
                boxMax = Math.round((boxMax + pct10) * 1000)/1000;
                let historicalLines = [];
                
                // consider the Y-Axis selection for 'which' datapoints to use. -- lineYName
                classContext.state.eventGroups.map((x) =>{
                    if(x !== null){
                        let items = scenarioItems.filter((item) => item.eventLabel == x && item.scenarioId == classContext.state.selScenarioId && classContext.state.filteredGraphs.indexOf(item.fullModelName) < 0)
                        if(items.length == 1){
                            let eventName = classContext.getItmName(items[0]);
                            newBoxData.push(classContext.buildBoxSingle(items[0], eventName, boxMin, boxMax));
                            historicalLines.push(items[0][classContext.state.lineYName])
                            newSingleNames += eventName + " "

                            newPerformanceBarData.push(classContext.buildPerformanceBar(items, eventName));
                        } else if(items.length > 0) {
                            let eventName = classContext.getItmName(items[0]);
                            let newItem = classContext.buildBoxStats(items, eventName);
                            newBoxData.push(newItem);
                            newAvgs.push(newItem.measures[0])

                            newPerformanceBarData.push(classContext.buildPerformanceBar(items, eventName + ' (' + classContext.state.selStatsBarOption+')'));
                        }
                    } else {
                        let items = scenarioItems.filter((item) => item.eventLabel == x && item.scenarioId == classContext.state.selScenarioId && classContext.state.filteredGraphs.indexOf(item.fullModelName) < 0)
                        items.map((y) =>{
                            let eventName = classContext.getItmName(y);
                            newBoxData.push(classContext.buildBoxSingle(y, eventName, boxMin, boxMax))
                            historicalLines.push(y[classContext.state.lineYName])
                            newSingleNames += eventName + " "

                            newPerformanceBarData.push(classContext.buildPerformanceBar(items, eventName));
                        })
                    }
                })

                newData = newData.reverse(); // need to reverse for legend and graph to line up 
                
                // ingest the historical marker lines into the box plots
                for(var i = 0; i < newBoxData.length; i++){
                    if(newBoxData[i].weight == 2){
                        historicalLines.map((x) =>{newBoxData[i].measures.push(x);});
                    }
                }

                newBoxData = newBoxData.sort(function(a, b) {
                    if(a.weight != b.weight) {return a.weight - b.weight;}
                    else { 
                        let aDate: any = new Date(a.startDate);
                        let bDate: any = new Date(b.startDate)
                        return aDate - bDate;}
                })
                newPerformanceBarData = newPerformanceBarData.sort(function(a, b) {
                    if(a.weight != b.weight) {return a.weight - b.weight;}
                    else { 
                        let aDate: any = new Date(a.startDate);
                        let bDate: any = new Date(b.startDate)
                        return aDate - bDate;}
                })

                // adjust first (assumed historic); set capture zero and remaining = runoff
                newPerformanceBarData[0].Capture = 0;
                newPerformanceBarData[0]['Remaining Flow'] = newPerformanceBarData[0].Runoff;

                // if all weight one / aka nothing larger than weight one, then set min to zero...
                if(newBoxData.filter((item) => item.weight > 1).length == 0){
                    // reset box data items from previous boxMin to zero...
                    for(var i = 0; i < newBoxData.length; i++){
                        for(var j = 0; j < newBoxData[i].ranges.length; j++){
                            if(newBoxData[i].ranges[j] == boxMin){newBoxData[i].ranges[j] = 0.001;}
                        }
                    }
                    boxMin = 0;
                }
            }

            classContext.setState({ bulletMin: boxMin, bulletMax: boxMax, bulletAvgs: newAvgs, lineData: newData, bulletData: newBoxData, 
                    selScenarioVal: selScenVal, singleNames: newSingleNames, performanceGraphData: newPerformanceBarData
                 });
        }
    }

    getItmName = (itm: ClimateItem): string => {
        let name = itm.fullModelName;
        if(itm.eventLabel !== null && itm.eventLabel.length > 0){name = itm.eventLabel;}

        return name;
    }

    findItmByNumber = (num: number, event: string) => {
        let classContext: ClimateViewer = this;
        //console.log('find item by number', num, event)
        let searchResult = classContext.state.summary.items.filter((item) => item.eventLabel == event && item.scenarioId == classContext.state.selScenarioId && item[classContext.state.lineYName] == num);
        classContext.setState({showDetails: true, detailItem: searchResult});
    }

    findItmByFullModelName = (num: number, fullName: string) => {
        let classContext: ClimateViewer = this;
        let searchResult = classContext.state.summary.items.filter((item) => item.fullModelName == fullName && item[classContext.state.lineYName] == num);
        classContext.setState({showDetails: true, detailItem: searchResult});
    }

    buildPerformanceBar = (items, name) => {
        let classContext: ClimateViewer = this;
        let runoff = 0;
        let capture = 0;
        let captureAvg = -999;
        let remainingFlow = 0;
        let weight = 0;
        if(items.length > 1){
            let itemValsRunoffSort = Array.from(items.map(item => item["Runoff"])).sort(function(a: number, b: number){return a - b;});
            let itemValsCaptureSort = Array.from(items.map(item => item["Capture"])).sort(function(a: number, b: number){return a - b;});
            let itemValsRemainFlowSort = Array.from(items.map(item => item["Remaining Flow"])).sort(function(a: number, b: number){return a - b;});
            if(classContext.state.selStatsBarOption == "Median"){
                runoff = classContext.quantile(itemValsRunoffSort, 0.5)
                capture = classContext.quantile(itemValsCaptureSort, 0.5)
                remainingFlow = classContext.quantile(itemValsRemainFlowSort, 0.5)
            } else {
                runoff = classContext.average(itemValsRunoffSort)
                capture = classContext.average(itemValsCaptureSort);
                remainingFlow = classContext.average(itemValsRemainFlowSort)
            }
            weight = 2;
        } else {
            runoff = items[0]["Runoff"]
            capture = items[0]["Capture"]
            remainingFlow = items[0]["Remaining Flow"]
            weight = 1;
        }

        let newBarItem ={
            "id": name, 
            "Runoff": runoff,
            "Capture": capture,
            "Remaining Flow": remainingFlow,
            "weight": weight,
            "startDate": items[0].modelStartDate,
            "title": classContext.buildBoxTitle(name)
        }

        return newBarItem;
    }

    buildBoxStats = (items, name) => {
        let classContext: ClimateViewer = this;
        let itemValsSort = Array.from(items.map(item => item[classContext.state.lineYName])).sort(function(a: number, b: number){return a - b;});
        let itemMin = itemValsSort[0];
        let itemMax = itemValsSort[itemValsSort.length -1];
        let newBoxItem = {
            "id": name, 
            "ranges": [classContext.quantile(itemValsSort, 0.25), classContext.quantile(itemValsSort, 0.5), classContext.quantile(itemValsSort, 0.75)],
            "markers": itemValsSort,
            "measures": [classContext.average(itemValsSort)],
            "weight": 2,
            "startDate": items[0].modelStartDate,
            "title": classContext.buildBoxTitle(name)
        };
        return newBoxItem;
    }

    buildBoxSingle = (item, name, boxMin, boxMax) => {
        let classContext: ClimateViewer = this;
        let newBoxItem2 = {
            "id": name,
            "ranges": [boxMin,boxMin,boxMin,boxMin,item[classContext.state.lineYName]],
            "markers":[],
            "measures":[], 
            "weight": 1,
            "startDate": item.modelStartDate,
            "title": classContext.buildBoxTitle(name)
        }
        return newBoxItem2;
    }

    buildBoxTitle = (name) =>{
        return (<text 
            style={{
                fill: '#000',
                fontWeight: 700,
                fontSize: '16px',
                textAnchor: 'middle'
            }}
        >
            {name}
        </text>)
    }

    quantile = (arr, q) => {
        arr = arr.sort(function(a: number, b: number){return a - b;})
        const pos = (arr.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (arr[base + 1] !== undefined) {
            return Math.round((arr[base] + rest * (arr[base + 1] - arr[base]))*1000)/1000;
        } else {
            return Math.round((arr[base])*1000)/1000;
        }
    };

    average = (arr) => {
        let sum: number = arr.reduce((a: number, b: number): number => {return a + b;});
        return Math.round((sum / arr.length)*1000)/1000;
    }

    lineLegendClick = (data) => {
        let classContext: ClimateViewer = this;
        let id: string = data.id.toString();
        let filterArr: Array<string> = classContext.state.filteredGraphs;
        if(id.startsWith("x ")){
            let unfilteredId = id.substr(2);
            filterArr.splice(filterArr.indexOf(unfilteredId), 1);
        } else {
            filterArr.push(id);
        }
        classContext.setState({filteredGraphs: filterArr}, () =>{
            classContext.dataUpdate();
        })
    }

    bulletMarkerClick = (data, evt) => {
        let classContext: ClimateViewer = this;
        let num = data.value;
        let event = evt.target.parentElement.parentElement.children[evt.target.parentElement.parentElement.children.length-1].children[0].innerHTML;
        classContext.findItmByNumber(num, event);
    }

    addSample() {
        let classContext: ClimateViewer = this;
        if (!classContext.state.isLoadingData) {
            classContext.setState({
                isLoadingScenario: true
            });

            let form = new FormData();
            form.append("sample", "true");

            if (!classContext.state.isLoadingData) {
                classContext.setState({isLoadingData: true, barsBy: "All", lineXName: "Capacity", lineYName: "Capture", selGraphNumberSolutions: "All", 
                    filteredGraphs: [], showDetails: false, detailItem: [], lineXUnit: 'ac-ft', lineYUnit: 'ac-ft', summary: new ClimateSummary(),
                    origData: new ClimateSummary(), performanceGraphData: []});

                climateUpload.axiosPostUpload(form)
                    .then((r: AxiosResponse) =>{
                        //console.log('ok reponse', r);
                        classContext.setState({origData: r.data, isLoadingData: false}, () => {classContext.dataPrep()});
                        //alert('yay data');
                    }).catch((r: AxiosError) => {
                        console.log('upload error', r);
                        classContext.setState({isLoadingData: false}, () => {});
                    })
            }
        }
    }



    addScenarioClick() {
        let classContext: ClimateViewer = this;
        if (this.state.zipfile == null) {
            let alertProps: alertPropsModel = {
                header: 'Please select a file',
                body: '',
                color: 'warning',
                size: 'small-alert',
                className: '',
                context: classContext,
                closeBtn: true,
                setTimeOut: true,
                timeOutTime: 2500
            };
            classContext.setState({ alertConfguration: alertProps }, () => {
                classContext.setState({ alertIsOpen: true });
            });

        } else {
            if (!classContext.state.isLoadingData) {
                classContext.setState({isLoadingData: true, barsBy: "All", lineXName: "Capacity", lineYName: "Capture", selGraphNumberSolutions: "All", 
                    filteredGraphs: [], showDetails: false, detailItem: [], lineXUnit: 'ac-ft', lineYUnit: 'ac-ft', summary: new ClimateSummary(),
                    origData: new ClimateSummary(), performanceGraphData: []});

                let form = new FormData();
                form.append("data", classContext.state.zipfile);

                climateUpload.axiosPostUpload(form)
                    .then((r: AxiosResponse) =>{
                        //console.log('ok reponse', r);
                        classContext.setState({origData: r.data, isLoadingData: false}, () => {classContext.dataPrep()});
                        //alert('yay data');
                    }).catch((r: AxiosError) => {
                        console.log('upload error', r);
                        classContext.setState({isLoadingData: false}, () => {});
                    })
            }
        }
    }

    handleIdxChange = (newName) =>{
        let classContext: ClimateViewer = this;
        let newIdx: number = 0;
        for(var i = 0; i < classContext.state.summary.items[0].responses.length; i++){
            if(newName === classContext.state.summary.items[0].responses[i].constituent){
                newIdx = i;
                break;
            }
        }
        classContext.setState({evalIdx: newIdx, evalName: newName}, () =>{classContext.dataUpdate()});
    }

    handleSolNumberChange = (newNumber) =>{
        let classContext: ClimateViewer = this;
        classContext.setState({selGraphNumberSolutions: newNumber, showDetails: false, detailItem: []}, () =>{classContext.dataPrep()}); 
    }

    handleAllAlertClick =(val: boolean) =>{
        let classContext: ClimateViewer = this;
        if(val === true){
            classContext.handleSolNumberChange('All');
        }
    }

    handleStatsBarChange = (newName) =>{
        let classContext: ClimateViewer = this;
        classContext.setState({selStatsBarOption: newName}, () =>{classContext.dataUpdate()});
    }

    handleLineXIdxChange = (newName) =>{
        let classContext: ClimateViewer = this;
        let newUnitName = 'ac-ft';
        if(newName === "Footprint"){newUnitName = "sq-ft";}
        if(newName === "Cost"){newUnitName = "$";}
        if(newName === "Reduction"){newUnitName = "%";}
        classContext.setState({lineXName: newName, lineXUnit: newUnitName}, () =>{classContext.dataUpdate()});
    }
    
    handleLineYIdxChange = (newName) =>{
        let classContext: ClimateViewer = this;
        let newUnitName = 'ac-ft';
        if(newName === "% Reduction") {newUnitName = "%"};
        classContext.setState({lineYName: newName, lineYUnit: newUnitName}, () =>{classContext.dataUpdate()});
    }

    handleScenChange = (e, {value}) =>{
        let classContext: ClimateViewer = this;
        classContext.setState({selScenarioId: value}, () =>{classContext.dataUpdate()});
    }

    handleBarsButtonClick = (sel: string) =>{
        let classContext: ClimateViewer = this;
        classContext.setState({barsBy: sel});
    }

    handleItemUpdate(evt, typestr) {
        let lbl = document.getElementById(typestr + 'uploadlabel');
        if (evt.target.files.length > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
            this.setState({ [typestr + 'filename']: evt.target.files[0].name, [typestr + 'file']: evt.target.files[0] });
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
            this.setState({ [typestr + 'filename']: 'None Selected', file: null });
        }
    }

    generateCsv = () => {
        let classContext: ClimateViewer = this;
        let navigation: any = window.navigator;
        classContext.setState({ isGeneratingCsv: true });
        if (classContext.state.summary != null && classContext.state.summary.guid.length > 0) {

            let formData: FormData = new FormData();
            formData.append("guid", classContext.state.summary.guid);

            let filename: string = "ClimateData.csv";

            ClimateCsv.axiosPostImage(formData)
                .then((r: AxiosResponse) => {
                    classContext.setState({ isGeneratingCsv: false });
                    const blob = new Blob([r.data], { type: 'text/csv' });
                    if (navigation?.msSaveOrOpenBlob) {
                        navigation.msSaveOrOpenBlob(blob, filename);
                    } else {
                        const blob = new Blob([r.data], { type: "text/csv" });
                        var link = document.createElement('a');
                        var href = window.URL.createObjectURL(blob);
                        link.setAttribute('href', href);
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        setTimeout(() => {
                            window.URL.revokeObjectURL(href);
                            document.body.removeChild(link);
                        }, 0)
                    }
                })
                .catch((r: AxiosResponse) => {
                    classContext.setState({ isGeneratingCsv: false });
                    let alertProps: alertPropsModel = {
                        header: "There was an error generating the csv.",
                        body: '',
                        color: 'warning',
                        size: 'small-alert',
                        className: '',
                        context: classContext,
                        closeBtn: true,
                        setTimeOut: true,
                        timeOutTime: 2500
                    };

                    classContext.setState({ alertConfguration: alertProps }, () => {
                        classContext.setState({ alertIsOpen: true });
                    });
                })
        }
    }

    toggleDetails = () =>{
        let classContext: ClimateViewer = this;
        classContext.setState({showDetails: !classContext.state.showDetails})
    }

    
    toggleUI = () => {
        //console.log(!this.state.mjrbmp);
        this.setState({ hideui: !this.state.hideui });
    }

    render() {
        let classContext: ClimateViewer = this;

        return (
            <div className="climate-container">
                <FileDragAndDrop onUpload={classContext.fileDragDropUpload} count={1} formats={[".zip"]} >

                <div className="climate-inner-container">
                    {/* <div className='overlay'></div> */}
                    <div>
                        <div className='climate-top'>
                            <div className="opt-header-div wmms-blue-header">
                                <img className="mainLogo" src={logo} alt="wmms2Logo" />
                            </div>
                            <div className='climate-header-surround'>
                                <div className=''>
                                    <div className=''>
                                        <div>
                                            <p className='side-panel-subtext'>
                                                WELCOME to the Climate Resiliency Viewer. This viewer renders watershed-scale model output from the Climate Resiliency Utility. The plots below illustrate the range of runoff and BMP performance from as many as 20 general circulation models (GCMs) through the year 2099. For additional context on the data, process, and assumptions used to develop these outputs reference the <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={65} linkText="Climate Resiliency Utility User Document" filename="Climate_Resiliency_Utility_User_Document.pdf" mimetype="application/pdf" wmmsColor="sustain" />.
                                            </p>
                                            <p className='side-panel-subtext'>
                                                To illustrate the functionality of the viewer, sample data has been pre-loaded below. For context, this data was developed from BMPs characterized in the BMP Opportunities Utility Tutorial.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="white">                                    
                            {((classContext.state.summary == null || classContext.state.summary.guid == null) || classContext.state.isLoadingData === true) && 
                            <div>
                                {(classContext.state.summary == null || classContext.state.summary.guid == null) &&
                                    <div className='drop-data-msg'>
                                        <div><p className='side-panel-subtext'>Drag &amp; drop a climate utility data.zip to visualize and explore results</p></div>
                                    </div>
                                }
                                {classContext.state.isLoadingData == true &&
                                    <Loader active={true} inline={true} size={'large'}>Loading data ...</Loader>
                                }
                            </div>
                            }
                        </div>

                    {classContext.state.isLoadingData == false && classContext.state.summary != null && classContext.state.summary.guid != null && classContext.state.summary.guid.length > 0 &&
                        <div >
                            <div className="secondary-row" >
                                <div className="">

                                    <div className="blue-hr-top-sticky">
                                        {/* here for styles */}
                                    </div>
                                    <div className="grey-header-container-sticky effect7-sticky">
                                        <div className="optimization-main-menu">    
                                            <div className="inner-optimization-main-menu">
                                                <div className="upload-scenarios">
                                                    <div className='upload-scenarios-label'>
                                                        <p>Select a scenario to upload:</p>
                                                    </div>
                                                    <div className=''>
                                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.zipfilename}</b></div>
                                                        <label className="upload-basic-btn" htmlFor='zipupload'><Label className="upload-basic-btn" color='red' basic id='zipuploadlabel'>Click here to select a .zip file.</Label></label>
                                                        <input id='zipupload' type="file" accept=".zip" onChange={(evt) => classContext.handleItemUpdate(evt, 'zip')} className="hidden" />
                                                    </div>
                                                    <div className='btn-w-tooltip-div dark-blue-disabled-btn'>
                                                        <Button animated='fade'
                                                            loading={this.state.isLoadingData} 
                                                            disabled={this.state.isLoadingData}
                                                            className='scenario-btn ui wmms-dark-blue-btn button' 
                                                            onClick={() => this.addScenarioClick()}>
                                                            <Button.Content visible>Add to Viewer</Button.Content>
                                                            <Button.Content hidden style={{display: "inline"}} ><Icon name='eye' /></Button.Content>
                                                        </Button>
                                                    </div>
                                                    <div className='climate-ui-toggle'>
                                                        <div className="climate-flex">
                                                            <div className="ui fitted toggle checkbox ">
                                                                <Checkbox onClick={classContext.toggleUI} checked={this.state.hideui} className="climatetoggle" toggle />
                                                            </div>
                                                            <div className="toggleText">
                                                                {this.state.hideui == true && <span>Hide UI</span>}
                                                                {this.state.hideui == false && <span>Show UI</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blue-hr-top-sticky">
                                            {/* here for styles */}
                                        </div>                                        
                                        <div>
                                            <div className='graph-label-top'>Historical &amp; Forecasted {classContext.state.lineYName} ({classContext.state.lineYUnit})  <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'large'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.HistoricalForecasted.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.HistoricalForecasted.body : 'Tooltip data is still loading please try again in a moment'} /></div>
                                            <div className='graph-sub-label'>Four types of model output can be displayed in the Viewer: Runoff, Capture, Remaining Flow, and Percent Reduction</div>
                                        </div>
                                        {this.state.hideui == false && <div className='climate-header-surround'>
                                            <div className=''>
                                                <div className='climate-header'>
    
                                                    {/* <ButtonGroup labelText='Number of Solutions:' groupOptions={classContext.state.solGraphNumberOptions} currentSelect={classContext.state.selGraphNumberSolutions} setSelection={classContext.handleSolNumberChange} activeColor='blue' /> */}
                                                    <div className='group-spacer'>
                                                        <ButtonGroup labelText='Y Axis:' groupOptions={classContext.state.lineYNames} currentSelect={classContext.state.lineYName} setSelection={classContext.handleLineYIdxChange} activeColor='blue' /> 
                                                        <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.YAxis.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.YAxis.body : 'Tooltip data is still loading please try again in a moment'} />
                                                    </div>
                                                    <div className='group-spacer'>
                                                        <span><b>BMP Solution:</b> </span>
                                                        <Dropdown
                                                            onChange={classContext.handleScenChange}
                                                            options={classContext.state.scenSelOptions}
                                                            placeholder='Choose a BMP Solution'
                                                            selection
                                                            value={classContext.state.selScenarioId}
                                                        />
                                                        <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.CurrentBmp.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.CurrentBmp.body : 'Tooltip data is still loading please try again in a moment'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="blue-hr-bottom-sticky">
                                        {/* here for styles */}
                                    </div>
                                </div>
                            </div>
                            <div className="space-padding graph-item">
                                <Watermark watermark_text={classContext.state.origData.isSample === true?'SAMPLE DATA':''}>
                                <div className="line-margin " id='bullets' >
                                    <div className='flex-bullet'>
                                        <div className="container-details" style={{minHeight: 350, height: 350}}>
                                            <MyResponsiveBullet  data={classContext.state.bulletData} min={classContext.state.bulletMin} max={classContext.state.bulletMax} avgs={classContext.state.bulletAvgs} markerClick={classContext.bulletMarkerClick} />
                                        </div>
                                        <div className='rotate'>{classContext.state.lineYUnit}</div>
                                    </div>
                                    <div className='bullet-legend'>
                                        {classContext.state.bulletData.filter((item) => item.weight == 1).length !== 0 && <div className='bullet-legend-single'></div>}
                                        {classContext.state.bulletData.filter((item) => item.weight == 1).length !== 0 && <div className='bullet-legend-item'>{classContext.state.singleNames}</div>}
                                        {classContext.state.bulletData.filter((item) => item.weight > 1).length !== 0 && <div className='bullet-legend-q2'></div>}
                                        {classContext.state.bulletData.filter((item) => item.weight > 1).length !== 0 && <div className='bullet-legend-item'>Q2</div>}
                                        {classContext.state.bulletData.filter((item) => item.weight > 1).length !== 0 && <div className='bullet-legend-q3'></div>}
                                        {classContext.state.bulletData.filter((item) => item.weight > 1).length !== 0 && <div className='bullet-legend-item'>Q3</div>}
                                        {classContext.state.bulletData.filter((item) => item.weight > 1).length !== 0 && <div className='bullet-legend-x'></div>}
                                        {classContext.state.bulletData.filter((item) => item.weight > 1).length !== 0 && <div className='bullet-legend-item'>Mean</div>}
                                        {classContext.state.bulletData.filter((item) => item.weight > 1).length !== 0 && <div className='bullet-legend-pt-container'><div className='bullet-legend-pt'></div></div>}
                                        {classContext.state.bulletData.filter((item) => item.weight > 1).length !== 0 && <div className='bullet-legend-item'>Data Point</div>}
                                    </div>
                                </div>
                                <div>
                                    <Transition.Group as={List} animation='fade down' duration={500}>
                                        {classContext.state.showDetails && 
                                            <Container className='container-details'>
                                                <DetailItem 
                                                    data={classContext.state.detailItem} 
                                                    toggleVisible={classContext.lineLegendClick}
                                                    visibleArray={classContext.state.filteredGraphs}
                                                    close={classContext.toggleDetails}
                                                    YAxisSel={classContext.state.lineYName}
                                                    hideui={classContext.state.hideui}
                                                    {...classContext.props}
                                                />
                                            </Container>
                                        }
                                    </Transition.Group>
                                </div>   
                                </Watermark>                             
                            </div>
                            <div className="space-padding graph-item">
                                <div className="blue-hr-top-sticky">
                                    {/* here for styles */}
                                </div>
                                <div className="grey-header-container-sticky effect7-sticky">
                                    <div className='graph-label'>Climate Change Mitigation Benefits for Runoff &amp; Capture <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'large'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.MitigationCharts.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.MitigationCharts.body : 'Tooltip data is still loading please try again in a moment'} /></div>
                                    <div className='graph-sub-label'>Plots below summarize mean and median historical and forecasted output (left), volume deficit or excess for future scenarios (right), and a plain language summary (bottom)</div>

                                    {classContext.state.hideui == false && classContext.state.bulletData.filter((item) => item.weight > 1).length !== 0 && <div className='group-spacer'><ButtonGroup labelText='Statistic:' groupOptions={classContext.state.statsBarOptions} currentSelect={classContext.state.selStatsBarOption} setSelection={classContext.handleStatsBarChange} activeColor='blue' /></div>}

                                </div>
                                <div className="blue-hr-bottom-sticky">
                                    {/* here for styles */}
                                </div>
                            </div>
                            <div className="space-padding graph-item">
                                <Watermark watermark_text={classContext.state.origData.isSample === true?'SAMPLE DATA':''}>
                                    <div className=" " >
                                        <div className='performance-wrapper'>
                                            <div className='flex-grow pr-2' style={{minHeight: 350, height: 350, minWidth: 450, width: '35%'}}>                                            
                                                <MyResponsiveBar 
                                                    indexBy="id" 
                                                    data={classContext.state.performanceGraphData}
                                                    myKeys={['Capture', 'Remaining Flow']}
                                                    // myColors={["#F59841", "#006099"]}
                                                    myColors={["#13A0C2", "#1ACE7D"]}
                                                    groupMode={'stacked'}
                                                    tickRotation={0} //30
                                                    itemHeight={50}
                                                    padding={-20} // -100
                                                    markerVal={classContext.state.performanceGraphData && classContext.state.performanceGraphData.length > 0?classContext.state.performanceGraphData[0].Runoff:null}
                                                    markerTxt={classContext.state.performanceGraphData && classContext.state.performanceGraphData.length > 0?classContext.state.performanceGraphData[0].id:""}
                                                />
                                            </div>
                                            <div className='flex-grow' style={{minHeight: 350, height: 350, minWidth: 550, width: '55%'}}>                                    
                                                <DivergingBar 
                                                    indexBy="id" 
                                                    data={classContext.state.performanceGraphData}
                                                    groupMode={'grouped'}
                                                    legendNames={['Excess Runoff','Runoff Deficit']}
                                                    capturePropName={'CaptureAvg'}
                                                />
                                            </div>
                                        
                                        </div>
                                    </div>
                                </Watermark>
                                <div className='performance-padding'>
                                        <PerformanceSummary barData={classContext.state.performanceGraphData} hideui={classContext.state.hideui} tooltipFactory={classContext.props.tooltipFactory} tooltipsReady={classContext.props.tooltipsReady} />
                                </div>
                            </div>
                            <div className="space-padding graph-item">
                                <div className="blue-hr-top-sticky">
                                    {/* here for styles */}
                                </div>
                                <div className="grey-header-container-sticky effect7-sticky">
                                    <div className='graph-label-top'>All Solutions Plot <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'large'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.Lines.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.Lines.body : 'Tooltip data is still loading please try again in a moment'} /></div>
                                    <div className='graph-sub-label'>Line plot illustrates the range in performance for all solutions uploaded to the Viewer</div>

                                    {classContext.state.hideui == false && <div className='climate-header'>
                                        <div className=''><ButtonGroup labelText='Y Axis:' groupOptions={classContext.state.lineYNames} currentSelect={classContext.state.lineYName} setSelection={classContext.handleLineYIdxChange} activeColor='blue' /> </div>
                                        <div className='group-spacer'><ButtonGroup labelText='X Axis:' groupOptions={classContext.state.lineXNames} currentSelect={classContext.state.lineXName} setSelection={classContext.handleLineXIdxChange} activeColor='blue' /></div>
                                        {/* <div><TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.AxisSelection.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.AxisSelection.body : 'Tooltip data is still loading please try again in a moment'} /></div> */}
                                    </div>}
                                </div>
                                <div className="blue-hr-bottom-sticky">
                                    {/* here for styles */}
                                </div>
                            </div>
                            <div className="space-padding graph-item" >
                                <Watermark watermark_text={classContext.state.origData.isSample === true?'SAMPLE DATA':''}>                                   
                                    <div className="line-margin" style={{minHeight: 450, height: 450}}>
                                        <div className='line-view-all'>
                                        {
                                            classContext.state.filteredGraphs.length >0 && classContext.state.hideui == false && 
                                            <div className='view-all-cursor' onClick={() => classContext.setState({filteredGraphs: []}, () => classContext.dataUpdate())} title='View all'><Icon name='eye' color='black' size='large' /></div>

                                        }
                                        {/* <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.AllSolutionsLines.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.AllSolutionsLines.body : 'Tooltip data is still loading please try again in a moment'} /> */}
                                        </div>
                                        <MyResponsiveLine 
                                            data={classContext.state.lineData} 
                                            xAxisName={classContext.state.lineXName} 
                                            yAxisName={classContext.state.lineYName} 
                                            selScenXVal={classContext.state.selScenarioVal} 
                                            lineLegendClick={classContext.lineLegendClick}
                                            seriesClick={classContext.findItmByFullModelName}
                                            xAxisUnit={classContext.state.lineXUnit}
                                            yAxisUnit={classContext.state.lineYUnit}
                                        /> 
                                    </div>
                                </Watermark>
                            </div>           
                            <div className="space-padding graph-item">
                                <div className="blue-hr-top-sticky">
                                    {/* here for styles */}
                                </div>
                                <div className="grey-header-container-sticky effect7-sticky">
                                    <div className='graph-label-top'>All Model Output <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'large'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.Bars.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.Bars.body : 'Tooltip data is still loading please try again in a moment'} /></div>
                                    <div className='graph-sub-label'>Stacked bar plots for all modeled output uploaded to the Viewer. Organized by Event, GCM, or both</div>

                                    {classContext.state.hideui == false && <div className='climate-header'>

                                            <div><ButtonGroup labelText='Bars by:' groupOptions={new Array("All", "Event", "Model")} addOr={false} activeColor={'blue'} currentSelect={classContext.state.barsBy} setSelection={classContext.handleBarsButtonClick}/></div>
                                            <div className='btn-w-tooltip-div dark-blue-disabled-btn group-spacer'>
                                                        <Button animated='fade'
                                                            loading={this.state.isGeneratingCsv} 
                                                            disabled={classContext.state.isGeneratingCsv || classContext.state.origData.isSample}
                                                            className='ui wmms-dark-blue-btn button' 
                                                            onClick={() => this.generateCsv()}>
                                                            <Button.Content visible>Generate csv</Button.Content>
                                                            <Button.Content hidden style={{display: "inline"}} ><Icon name='download' /></Button.Content>
                                                        </Button>
                                                        {/* <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.GenerateCsv.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.GenerateCsv.body : 'Tooltip data is still loading please try again in a moment'} /> */}
                                                    </div>                                          
                                    </div>}
                                </div>
                                <div className="blue-hr-bottom-sticky">
                                    {/* here for styles */}
                                </div>
                            </div>                                             
                            {classContext.state.barsBy == "All" && 
                                <div className="space-padding graph-item" >
                                    <Watermark watermark_text={classContext.state.origData.isSample === true?'SAMPLE DATA':''}>
                                        <div className='graph-label-less-pad'>All</div>
                                        <div className="line-margin" style={{minHeight: 350, height: 350}}>
                                            <MyResponsiveBar 
                                                indexBy="fullModelName" 
                                                data={classContext.state.summary.items.filter((item) => item.scenarioId == classContext.state.selScenarioId && classContext.state.filteredGraphs.indexOf(item.fullModelName) < 0)}
                                                myKeys={['Capture', 'Remaining Flow']}
                                                // myColors={["#F59841", "#006099"]}
                                                myColors={["#13A0C2", "#1ACE7D"]}
                                                groupMode={'stacked'}
                                                tickRotation={classContext.state.summary.items.filter((item) => item.scenarioId == classContext.state.selScenarioId && classContext.state.filteredGraphs.indexOf(item.fullModelName) < 0).length > 12? -30: 0}
                                                itemHeight={50}
                                                padding={-100}
                                                markerVal={null}
                                                markerTxt={''}
                                            />
                                        </div>
                                    </Watermark>
                                </div>
                            }
                            {classContext.state.barsBy == "Event" && classContext.state.eventGroups.map((x) =>{
                                return(
                                    <div className="space-padding graph-item">
                                        <Watermark watermark_text={classContext.state.origData.isSample === true?'SAMPLE DATA':''}>
                                            <div className='graph-label-less-pad'>Event: {x != null? x : "N/A"}</div>
                                            <div className="line-margin" style={{minHeight: 350, height: 350}}>
                                            <MyResponsiveBar 
                                                indexBy="model" 
                                                data={classContext.state.summary.items.filter((item) => item.eventLabel == x && item.scenarioId == classContext.state.selScenarioId && classContext.state.filteredGraphs.indexOf(item.fullModelName) < 0)} 
                                                myKeys={['Capture', 'Remaining Flow']}
                                                // myColors={["#F59841", "#006099"]}
                                                myColors={["#13A0C2", "#1ACE7D"]}
                                                groupMode={'stacked'}
                                                tickRotation={0}
                                                itemHeight={50}
                                                padding={-20}
                                                markerVal={null}
                                                markerTxt={''}
                                            />
                                            </div>
                                        </Watermark>
                                    </div>
                                )
                            })
                            }
                            {classContext.state.barsBy == "Model" && classContext.state.modelGroups.map((x) =>{
                                return(
                                    <div className="space-padding graph-item">
                                        <Watermark watermark_text={classContext.state.origData.isSample === true?'SAMPLE DATA':''}>
                                            <div className='graph-label-less-pad'>Model: {x != null? x : "N/A"}</div>
                                            <div className="line-margin" style={{minHeight: 350, height: 350}}>
                                            <MyResponsiveBar 
                                                indexBy='event' 
                                                data={classContext.state.summary.items.filter((item) => item.model == x && item.scenarioId == classContext.state.selScenarioId && classContext.state.filteredGraphs.indexOf(item.fullModelName) < 0)} 
                                                myKeys={['Capture', 'Remaining Flow']}
                                                // myColors={["#F59841", "#006099"]}
                                                myColors={["#13A0C2", "#1ACE7D"]}
                                                groupMode={'stacked'}
                                                tickRotation={0}
                                                itemHeight={50}
                                                padding={-20}
                                                markerVal={null}
                                                markerTxt={''}
                                            />
                                            </div>
                                        </Watermark>
                                    </div>
                                )
                            })
                            }

                            <div className="space-padding graph-item">
                                <div className="blue-hr-top-sticky">
                                    {/* here for styles */}
                                </div>
                                <div className="grey-header-container-sticky effect7-sticky">
                                    <div className='graph-label-top pt-1-point5'>All Model Output</div>
                                    <div className='graph-sub-label-no-pad'>Tabular format for all modeled output uploaded to the Viewer</div>
                                </div>
                                <div className="blue-hr-bottom-sticky">
                                    {/* here for styles */}
                                </div>
                            </div>

                            <div className="space-padding graph-item">
                                <Watermark watermark_text={classContext.state.origData.isSample === true?'SAMPLE DATA':''}>
                                    <div className="line-margin">
                                    <DataTable 
                                        tableData={classContext.state.summary.items.filter((selitm) => selitm.scenarioId == classContext.state.selScenarioId && classContext.state.filteredGraphs.indexOf(selitm.fullModelName) < 0)} 
                                        columnArr={['model', 'event', 'rcp', 'Runoff', 'Capture', 'Remaining Flow', '% Reduction']}
                                        headerNames={['Model', 'Event', 'RCP', 'Runoff', 'Capture', 'Remaining Flow', '% Reduction']}
                                        valuePostpend={['', '', '', 'ac-ft', 'ac-ft', 'ac-ft', '%']}
                                        columnSortType={['text', 'text', 'text', 'number', 'number', 'number', 'number']}
                                        numberRoundLength={[-1, -1, -1, 3, 3, 3, 2]}
                                        headerBackgroundColor="#006099"
                                        headerTextColor="#FFFFFF"
                                        sortable={true}
                                        striped={true}
                                        celled={true}
                                        fixed={true}
                                    />
                                    </div>
                                </Watermark>
                            </div>
                            <div className='pb-2'></div>
                        </div>
                    }

                    </div>
                </div>
                

                </FileDragAndDrop>
            </div>
        );
    }
}