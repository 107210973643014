import { BaseModel } from "../interfaces/IBaseModel";

export class processviewmodel extends BaseModel {

    "id": number;
    "queueId": number;
    "resultId": number;
    "status": string;
    "type": string;
    "message": string;


    constructor() {
        super('process');
    }
}  
