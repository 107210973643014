import React, { Component } from "react";
import IPathProps from "../interfaces/IPathProps";
import * as d3 from 'd3';

export default class Path extends Component<IPathProps> {
  ref: React.RefObject<SVGPathElement>;

  constructor(props: IPathProps) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidUpdate() {
    const { data, yScale, xScale, height } = this.props;

      if (data !== undefined) {
          var line = d3.line(data)
              .x(function (d) { return xScale(d.x); })
              .y(function (d) { return yScale(d.y); });

          d3.select(this.ref.current)
              .attr("class", "path")
              .attr("d", line(data))
              .transition();
      }
  }

  render() {
    const { fill, color, strokeWidth } = this.props;

    return (
    <g className="path-wrapper">
      <path data-testid="path" fill={fill} stroke={color} strokeWidth={strokeWidth} strokeLinejoin="round" strokeLinecap="round" ref={this.ref} />
    </g>);
  }
}