import React, { Component } from "react";
import './utilitiesPW.css';
import { Card, Icon, Button, Confirm, Divider } from "semantic-ui-react";
import { IUtilityPage } from "../../interfaces/IUtilityPage";
import { IUtilityCard } from "../../interfaces/IUtilityCard";
import { clearInterval } from "timers";
import Logo from "../../images/WMMS_Utility_Diagram_ForPW.png";
import { Route } from 'react-router-dom';
import Config from "../../config/Config";


export class UtilitiesStatePW {
    processing: boolean = false;
    selection: string = "";
    utilComponentIsOpen: boolean = false;
    showCards: boolean = false;
}

export default class UtilitiesPW extends Component<IUtilityPage> {

    state = new UtilitiesStatePW;

    constructor(props) {
        super(props);
    };

    // track intervals here so we can check on 'back' click;
    interval: any;

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {



        // this.handleFirstRender();

    }

    handleFirstRender = () => {

        let desiredUrl: string = window.location.href;

        if (Config.client_endpoint + "utilities" !== desiredUrl) {
            let desiredRoute = desiredUrl.split(Config.client_endpoint + "utilities/")[1];
            this.props.history.push("/utilities/" + desiredRoute);
            this.setState({ selection: desiredRoute })
        }

    }

    handleItemClick = (name) => {
        this.setState({
            showCards: !this.state.showCards,
            utilComponentIsOpen: !this.state.utilComponentIsOpen
        });

        setTimeout(() => {
            this.setState({ selection: name }, () => {
                // this.setUtilityRoute(name);


            })
        }, 500);
    }

    beginInterval = (intvl: any) => {
        let classContext: UtilitiesPW = this;
        classContext.interval = intvl;
        classContext.setState({ processing: true })
    }

    stopInterval = () => {
        let classContext: UtilitiesPW = this;
        if (classContext.interval != null) {
            clearInterval(classContext.interval);
            classContext.interval = null;
            classContext.setState({ processing: false })
        }
    }

    setUtilityRoute = (name) => {
        this.props.history.push("/utilities/" + name);
    }

    getUtilityRouteJSX = () => {

        let classContext = this;

        return classContext.props.utilities.map((card: IUtilityCard, index: number) => {
            return <Route path={"/utilities/" + card.internalName} render={(props) => {
                return < card.component
                    key={card.internalName}
                    cardTitle={card.cardTitle}
                    cardDescription={card.cardDescription}
                    component={card.component}
                    cardIcon={card.cardIcon}
                    visible={card.visible}
                    active={card.active}
                    wmmsColor={card.wmmsColor}
                    internalName={card.internalName}
                    cardSubtitle={card.cardSubtitle}
                    helpDoc={card.helpDoc}
                    templateDoc={card.templateDoc}
                    beginInterval={this.beginInterval}
                    stopInterval={this.stopInterval}
                    utilComponentIsOpen={this.state.utilComponentIsOpen}
                    {...props}
                />
            }} key={index} />

        });

    }




    render() {

        let classContext: UtilitiesPW = this;

        return (
            <div className="util-container" style={{ height: window.innerHeight - 25 + "px" }}>
                <div className="util-inner-container" >
                    {/*<div className="wmms-blue-header-div wmms-blue-header"><Icon name='cogs' /> Utilities</div>*/}
                    {classContext.state.selection.length == 0 &&
                        <div className={this.state.showCards == false ? 'ui centered cards cards-container cardsSlideUp scroll-fix' : 'ui centered cards cards-container cardsSlideDown scroll-fix'}>
                            <div className="downloads-graphic">
                                <div>
                                    <img className="scaleImg" src={Logo} alt="wmms utility logo" />
                                </div>
                            </div>
                            <div className="until-card-container">
                                <div className="util-left">
                                    <div className="wmms-lspc util-subheader">
                                        LSPC
                                    </div>
                                    <div className="ui centered cards cards-container">
                                        {classContext.props.utilities.map((card: IUtilityCard) => {
                                            if (card.internalName == 'ftableutil' || card.internalName == 'weatherutil') {

                                                return (card.visible == false ? "" : card.active ? <Card key={card.internalName} onClick={() => classContext.handleItemClick(card.internalName)} className={'ulit-card wmms-' + card.wmmsColor}>
                                                    <Card.Content>
                                                        <Icon name={card.cardIcon} circular inverted className={'float-right wmms-' + card.wmmsColor} />
                                                        <Card.Header>{card.cardTitle}</Card.Header>
                                                        {/*<Card.Meta>
                                                            <span className=''>{card.cardSubtitle}</span>
                                                        </Card.Meta>*/}
                                                        <Card.Description>
                                                            {card.cardDescription}
                                                        </Card.Description>
                                                    </Card.Content>
                                                </Card> : <Card color='grey' className="ulit-card" key={card.internalName}>
                                                        <Card.Content>
                                                            <Icon name={card.cardIcon} color='grey' circular inverted className="float-right" />
                                                            <Card.Header>{card.cardTitle}</Card.Header>
                                                            {/*<Card.Meta>
                                                                <span className=''>{card.cardSubtitle}</span>
                                                            </Card.Meta>*/}
                                                            <Card.Description>
                                                                {card.cardDescription}
                                                            </Card.Description>
                                                        </Card.Content>
                                                    </Card>)
                                            }
                                        })}

                                    </div>
                                </div>

                                <div className="vertical-devider"></div>

                                <div className="util-right">
                                    <div className="wmms-input util-subheader">
                                        SUSTAIN
                                    </div>
                                    <div className="ui centered cards cards-container">
                                        {classContext.props.utilities.map((card: IUtilityCard) => {
                                            if (card.internalName != 'ftableutil' && card.internalName != 'weatherutil') {

                                                return (card.visible == false ? "" : card.active ? <Card key={card.internalName} onClick={() => classContext.handleItemClick(card.internalName)} className={'ulit-card wmms-' + card.wmmsColor}>
                                                    <Card.Content>
                                                        <Icon name={card.cardIcon} circular inverted className={'float-right wmms-' + card.wmmsColor} />
                                                        <Card.Header>{card.cardTitle}</Card.Header>
                                                        {/*<Card.Meta>
                                                            <span className=''>{card.cardSubtitle}</span>
                                                        </Card.Meta>*/}
                                                        <Card.Description>
                                                            {card.cardDescription}
                                                        </Card.Description>
                                                    </Card.Content>
                                                </Card> : <Card color='grey' className="ulit-card" key={card.internalName}>
                                                        <Card.Content>
                                                            <Icon name={card.cardIcon} color='grey' circular inverted className="float-right" />
                                                            <Card.Header>{card.cardTitle}</Card.Header>
                                                            {/*<Card.Meta>
                                                                <span className=''>{card.cardSubtitle}</span>
                                                            </Card.Meta>*/}
                                                            <Card.Description>
                                                                {card.cardDescription}
                                                            </Card.Description>
                                                        </Card.Content>
                                                    </Card>)
                                            }
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {classContext.state.selection.length > 0 &&
                        <div className={this.state.utilComponentIsOpen == true ? 'slideDown' : 'slideUp'}>

                            <div className="Utilities-btn-div">
                                <Button
                                    className="wmms-semantic-gray-btn"
                                    size={'medium'}
                                    disabled={this.state.processing}
                                    onClick={() => {
                                        classContext.handleItemClick("");
                                        classContext.stopInterval();
                                    }} >
                                    <Icon name={"arrow up"} color='black' />Back to Main Utility Page</Button>
                            </div>
                            {/* <React.Fragment>
                                {this.getUtilityRouteJSX()}
                            </React.Fragment> */}

                            {classContext.props.utilities.map((card: IUtilityCard) => {
                                if (card.internalName == classContext.state.selection) {
                                    return (
                                        < card.component
                                            key={card.internalName}
                                            cardTitle={card.cardTitle}
                                            cardDescription={card.cardDescription}
                                            component={card.component}
                                            cardIcon={card.cardIcon}
                                            visible={card.visible}
                                            active={card.active}
                                            wmmsColor={card.wmmsColor}
                                            internalName={card.internalName}
                                            cardSubtitle={card.cardSubtitle}
                                            helpDoc={card.helpDoc}
                                            templateDoc={card.templateDoc}
                                            beginInterval={this.beginInterval}
                                            stopInterval={this.stopInterval}
                                            utilComponentIsOpen={this.state.utilComponentIsOpen}
                                        />
                                    )
                                }
                            })
                            }

                        </div>
                    }
                </div>
            </div>
        );

    }
}