import React, { Component } from "react";
import "./comparisonTable.css";
import { INavHistory } from "../../../interfaces/INavHistory";
import { Dropdown, Button, FormGroup, Label, Input, Grid, Table, Tab, Icon } from "semantic-ui-react";
import ITableProps from "../../../interfaces/ITableProps";
import { TooltipModal } from "../../toolTipModal/toolTipModal";
import { sustainToLSPCbatch } from "../../../models/sustainToLSPCbatch";
import { AxiosResponse } from "axios";
import { optimizationimplementationplan } from "../../../models/optimizationimplementationplan";

export default class ComparisonTable extends Component<ITableProps> {

    state = {
        loadingPlan: false,
        loadingBatch: false
    }


    constructor(props: ITableProps){
        super(props);
    } 

    createHeader() {
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell className="headerCell blue-table-header comp-table-header">
                        Optimization Metric
                    </Table.HeaderCell>
                    <Table.HeaderCell className="headerCell blue-table-header comp-table-header">
                        {this.props.data[0].tableTitle}
                    </Table.HeaderCell>
                    <Table.HeaderCell className="headerCell blue-table-header comp-table-header">
                        {this.props.data[1].tableTitle}
                    </Table.HeaderCell>
                    {
                        this.props.data.length == 3 ?
                        <Table.HeaderCell className="headerCell blue-table-header comp-table-header">
                            {this.props.data[2].tableTitle}
                        </Table.HeaderCell>
                        : null
                    }
                </Table.Row>
            </Table.Header>
        );
    }

    createBmpRows() {
        let bmpNames = this.props.data[0].tableData.bmPs.map(x => x.name);
        return bmpNames.map(bmp => {
            return (
            <Table.Row>
                <Table.Cell>
                    {bmp}
                </Table.Cell>
                <Table.Cell>
                        {(this.props.data[0].tableData.bmPs.find(x => x.name == bmp).value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ac-ft
                </Table.Cell>
                <Table.Cell>
                        {(this.props.data[1].tableData.bmPs.find(x => x.name == bmp).value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ac-ft
                </Table.Cell>
                {
                    this.props.data.length == 3 ?
                    <Table.Cell>
                                {(this.props.data[2].tableData.bmPs.find(x => x.name == bmp).value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ac-ft
                    </Table.Cell>
                    : null
                }
            </Table.Row>
            )
        });
    }

    getPlan = (data) => {

        this.setState({loadingPlan: true}, () => {
            
                    optimizationimplementationplan.axiosPostImage(data)
                    .then((r: AxiosResponse) => {
                        // classContext.setState({ isGeneratingPlan: false });
                        let filename: string = "ImplementationPlan.xlsx";
                        const blob = new Blob([r.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob, filename);
                        } else {
                            const blob = new Blob([r.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                            var link = document.createElement('a');
                            var href = window.URL.createObjectURL(blob);
                            link.setAttribute('href', href);
                            link.setAttribute('download', filename);
                            document.body.appendChild(link);
                            link.click();
                            setTimeout(() => {
                                window.URL.revokeObjectURL(href);
                                document.body.removeChild(link);
                            }, 0)
                        }
                        this.setState({loadingPlan: false});
                    })
                    .catch((r: AxiosResponse) => {
                        console.log("error getting implementation plan ", r.data)
                        // classContext.setState({ isGeneratingPlan: false });
                    });

        })



    }

    getBatch = (data) => {

        this.setState({loadingBatch: true}, () => {

            
                sustainToLSPCbatch.axiosGetBlob(data)
                    .then((r: AxiosResponse) => {
        
                        let filename: string = "Sustain2LSPC.zip";
                        const bloboBaggins = new Blob([r.data], {type: "application/zip"});
        
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(bloboBaggins, filename);
                        } else {
                            const bloboBaggins = new Blob([r.data], { type: 'application/zip' });
                            var link = document.createElement('a');
                            var href = window.URL.createObjectURL(bloboBaggins);
                            link.setAttribute('href', href);
                            link.setAttribute('download', filename);
                            document.body.appendChild(link);
                            link.click();
                            setTimeout(() => {
                                window.URL.revokeObjectURL(href);
                                document.body.removeChild(link);
                            }, 0)
                        };

                        this.setState({loadingBatch: false});
                    })
                    .catch((r: AxiosResponse) => {
                        alert("Could not find match for scenario");
                        console.log("error getting batch file in compare");
                    });

        });
    }

    createBody() {
        return (
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        Reduction
                    </Table.Cell>
                    <Table.Cell>
                        {this.props.data[0].tableFactor}: {(this.props.data[0].tableData.responses.find(x => x.name == this.props.data[0].tableFactor).value).toFixed(2)}%
                    </Table.Cell>
                    <Table.Cell>
                     {this.props.data[1].tableFactor}: {(this.props.data[1].tableData.responses.find(x => x.name == this.props.data[1].tableFactor).value).toFixed(2)}% 
                    </Table.Cell>
                    {
                        this.props.data.length == 3 &&
                        <Table.Cell>
                            {this.props.data[2].tableFactor}: {(this.props.data[2].tableData.responses.find(x => x.name == this.props.data[2].tableFactor).value).toFixed(2)}% 
                        </Table.Cell>
                    }
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Total Cost
                    </Table.Cell>
                    <Table.Cell>
                        ${(this.props.data[0].tableData.cost / 1000000).toFixed(0)} M
                    </Table.Cell>
                    <Table.Cell>
                        ${(this.props.data[1].tableData.cost / 1000000).toFixed(0)} M
                    </Table.Cell>
                    {
                        this.props.data.length == 3 &&
                        <Table.Cell>
                            ${(this.props.data[2].tableData.cost / 1000000).toFixed(0)} M
                        </Table.Cell>
                    }
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Total Capacity
                    </Table.Cell>
                    <Table.Cell>
                        {(this.props.data[0].tableData.bmPs.reduce((a, b) => ({ value: a.value + b.value })).value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ac-ft
                    </Table.Cell>
                    <Table.Cell>
                        {(this.props.data[1].tableData.bmPs.reduce((a, b) => ({ value: a.value + b.value })).value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ac-ft
                    </Table.Cell>
                    {
                        this.props.data.length == 3 &&
                        <Table.Cell>
                            {(this.props.data[2].tableData.bmPs.reduce((a, b) => ({ value: a.value + b.value })).value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ac-ft
                        </Table.Cell>
                    }
                </Table.Row>
                {this.createBmpRows()}
            </Table.Body>
        );
    }
    
    render() {
        let classContext: ComparisonTable = this;
            return (
                <div>
                    <div className="comp-header-wrap">
                        <div className="comp-header wmms-blue-header">
                            <Icon name="table"/> CRZ Comparison Table
                        </div>
                        <div className="comp-table-desc">
                            <b>Slice Comparison:</b>  the following table compares the BMP costs and capacities for the selected slices.
                        </div>
                    </div>
                    <div className="comp-table-div">
                        <Table id={this.props.data.length == 2 ? "comp-table-2" : "comp-table-3"} celled striped size="small" className="table-shadow comp-table" >
                            {this.createHeader()}
                            {this.createBody()}
                        </Table>
                    </div>
                </div>
            );
    }
}