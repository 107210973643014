import { BaseModel } from "../interfaces/IBaseModel";

export class climatechange extends BaseModel {

    "filenameXls": string;
    "filetypeXls": string;
    "fileblobXls": Blob;
    "filenameInp": string;
    "filetypeInp": string;
    "fileblobInp": Blob;
    "filenameCsv": string;
    "filetypeCsv": string;
    "fileblobCsv": Blob;
    "basePath": string

    constructor() {
        super('climatechange');
    }
}  