import { BaseModel } from "../interfaces/IBaseModel";

export class mjrsolutions extends BaseModel {

    "zipfilename": string;
    "zipfiletype": string;
    "zipfileblob": Blob;
    "wmmsfilename": string;
    "wmmsfiletype": string;
    "wmmsfileblob": Blob;


    constructor() {
        super('mjrbmpsolutions');
    }
}  