import React from "react";
import "./ButtonGroup.css";
import { Button, Label, SemanticCOLORS } from "semantic-ui-react";

interface IButtonGroupProps {
    groupOptions: any[];
    addOr?: boolean;
    currentSelect: any;
    setSelection: Function;
    activeColor: SemanticCOLORS;
    labelText?: string;
}

export const ButtonGroup: React.FC<IButtonGroupProps> = ({ ...props }) => {

    return (
        <Button.Group>
            {props.groupOptions.map((item, idx) => {
                return(
                    <React.Fragment>
                        {props.addOr === true && idx > 0 && 
                        <Button.Or />
                        }
                        {props.labelText !== undefined && props.labelText.length > 0 && idx === 0 &&
                            <Button content={item} active={props.currentSelect === item} onClick={() => props.setSelection(item)} color={props.currentSelect === item ? props.activeColor : null} labelPosition='left' label={{as: 'a', basic: true, content:props.labelText, pointing:false}} />
                        }
                        {(props.labelText === undefined || idx > 0) &&
                            <Button active={props.currentSelect === item} onClick={() => props.setSelection(item)} color={props.currentSelect === item ? props.activeColor : null}>{item}</Button>
                        })
                    </React.Fragment>
            )})}

        </Button.Group>
    );
}
