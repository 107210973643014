import { BaseModel } from "../interfaces/IBaseModel";

export class networktimeseries extends BaseModel {

    "path": string;
    "data": Array<networktimeseriesitem>;

    constructor() {
        super('networkviewer/data');

        this.data = new Array<networktimeseriesitem>();
    }
}

export class networktimeseriesitem {
    "date": Date;
    "values": Array<number>;
}