import React, { Component } from "react";
import ITimseriesSVGProps from "./interfaces/ITimeseriesSVGProps";
import * as d3 from 'd3';
import AxisLeft from "./chartPieces/AxisLeft";
import AxisBottomTimeFormat from "./chartPieces/AxisBottomTimeFormat";
import Path from "./chartPieces/Path";
import AreaStartEnd from "./chartPieces/AreaStartEnd";
import LabelBottom from "./chartPieces/LabelBottom";
import LabelLeft from "./chartPieces/LabelLeft";
import Callout from "./chartPieces/Callout";
import IDataXY from "./interfaces/IDataXY";
import { Loader, Segment } from "semantic-ui-react";

export class TimeseriesSVGState {
    mouseDate: Date = null;
    mouseValue: number = null;
    calloutX: number = null;
    calloutY: number = null;
    label: string = "";
    mouseDownDate: Date = null;
    mouseUpDate: Date = null;
    listenersApplied: boolean = false;
}


export default class TimeseriesSVG extends Component<ITimseriesSVGProps> {
    state = new TimeseriesSVGState();
    xScale: any;
    yScale: any;
    myBisect: any;

    mouseEventOffset: number = 70;

    bisect = (mx: any): IDataXY => {
        //console.log('bisect in', mx);
        let date = this.xScale.invert(mx);
        //console.log('bisect date', date);
        let index = this.myBisect(this.props.data, date, 1);
        //console.log('bisect index', index);
        let a = this.props.data[index - 1];
        let b = this.props.data[index];
        if (a == null && b != null) { return b; }
        if (a != null && b == null) { return a; }
        if (a == null && b == null) { return { x: null, y: null };}
        return date - a.x > b.x - date ? b : a;
       
    }

    componentDidUpdate = () => {
        if (!this.props.loading) {
            if (!this.state.listenersApplied) {
                this.applyD3Listeners();
                this.setState({listenersApplied: true});
            };
        };
    };


    applyD3Listeners = () => {
        const classContext: TimeseriesSVG = this;
        d3.select("#timeG").append('rect')
            .attr('width', this.props.svgWidth)
            .attr('height', this.props.svgHeight)
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .on("mousemove", function (x, y, z) {
                let mouseCoords = d3.mouse(z[0]);
                let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
                let xcoord = classContext.xScale(res.x);
                let ycoord = classContext.yScale(res.y);
                let label = res.y.toFixed(4) + " " + classContext.props.units + "\n" + new Date(res.x).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"})
                //classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
                if (classContext.state.mouseDownDate != null) {
                    //console.log('dragging', res.x);
                    classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, mouseUpDate: res.x, label: label });
                    classContext.props.zoomHandler(classContext.state.mouseDownDate, res.x);
                } else {
                    classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
                }

            })
            .on("touchmove", function (x, y, z) {
                let mouseCoords = d3.touch(z[0]);
                let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
                let xcoord = classContext.xScale(res.x);
                let ycoord = classContext.yScale(res.y);
                let label = res.y.toFixed(4) + " " + classContext.props.units + "\n" + new Date(res.x).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"})
                //classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
                if (classContext.state.mouseDownDate != null) {
                    //console.log('dragging', res.x);
                    classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, mouseUpDate: res.x, label: label });
                    classContext.props.zoomHandler(classContext.state.mouseDownDate, res.x);
                } else {
                    classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
                }

            })
            .on("mouseup", function (x,y,z) {
                let mouseCoords = d3.mouse(z[0]);

                let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
                let startDate = classContext.state.mouseDownDate;
                classContext.setState({ mouseUpDate: null, mouseDownDate: null }, () => {
                    if (startDate != new Date(res.x)) { // only zoom if not focused on a single date
                        classContext.props.clickHandler(startDate, res.x);
                    }
                });

            })
            .on("mousedown", function (x, y, z) {
                let mouseCoords = d3.mouse(z[0]);
                let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
                //console.log('downdate', res.x);
                classContext.setState({ mouseDownDate: res.x, mouseUpDate: res.x });
            });
            
            d3.select("#timeSVG")
            .on("mouseout", function () {
                classContext.setState({ mouseDate: null, mouseValue: null, calloutX: 0, calloutY: 0 });
            })
            .on("touchend", function () {
                classContext.setState({ mouseDate: null, mouseValue: null, calloutX: 0, calloutY: 0 });
            });

            // d3.select("#timeG")
        //     .on("mouseover", function (x, y, z) {
        //         let mouseCoords = d3.mouse(z[0]);
        //         let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
        //         let xcoord = classContext.xScale(res.x);
        //         let ycoord = classContext.yScale(res.y);
        //         let label = res.y.toFixed(4) + " " + classContext.props.units + "\n" + new Date(res.x).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"})
        //         //classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
        //         if (classContext.state.mouseDownDate != null) {
        //             //console.log('dragging', res.x);
        //             classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, mouseUpDate: res.x, label: label });
        //             classContext.props.zoomHandler(classContext.state.mouseDownDate, res.x);
        //         } else {
        //             classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
        //         }

        //     })
        //     .on("touchmove", function (x, y, z) {
        //         let mouseCoords = d3.touch(z[0]);
        //         let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
        //         let xcoord = classContext.xScale(res.x);
        //         let ycoord = classContext.yScale(res.y);
        //         let label = res.y.toFixed(4) + " " + classContext.props.units + "\n" + new Date(res.x).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"})
        //         //classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
        //         if (classContext.state.mouseDownDate != null) {
        //             //console.log('dragging', res.x);
        //             classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, mouseUpDate: res.x, label: label });
        //             classContext.props.zoomHandler(classContext.state.mouseDownDate, res.x);
        //         } else {
        //             classContext.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
        //         }

        //     })
        //     .on("mouseup", function (x,y,z) {
        //         let mouseCoords = d3.mouse(z[0]);

        //         let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
        //         let startDate = classContext.state.mouseDownDate;
        //         classContext.setState({ mouseUpDate: null, mouseDownDate: null }, () => {
        //             if (startDate != new Date(res.x)) { // only zoom if not focused on a single date
        //                 classContext.props.clickHandler(startDate, res.x);
        //             }
        //         });

        //     })
        //     .on("mousedown", function (x, y, z) {
        //         let mouseCoords = d3.mouse(z[0]);
        //         let res = classContext.bisect(mouseCoords[0]); // hard-coded margein left for now plus something - offset of sorts?
        //         //console.log('downdate', res.x);
        //         classContext.setState({ mouseDownDate: res.x, mouseUpDate: res.x });
        //     });






    };

    mouseEvent = (event: any) => {
        //console.log('mouseevent', event);
        let res = this.bisect(event.clientX - this.mouseEventOffset); // hard-coded margein left for now plus something - offset of sorts?
        let xcoord = this.xScale(res.x);
        let ycoord = this.yScale(res.y);
        let label = res.y.toFixed(4) + " " + this.props.units + "\n" + new Date(res.x).toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"})
        //this.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
        if (this.state.mouseDownDate != null) {
            //console.log('dragging', res.x);
            this.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, mouseUpDate: res.x, label: label });
            this.props.zoomHandler(this.state.mouseDownDate, res.x);
        } else {
            this.setState({ mouseDate: res.x, mouseValue: res.y, calloutX: xcoord, calloutY: ycoord, label: label });
        }
    }

    mouseLeave = () => {
        this.setState({ mouseDate: null, mouseValue: null, calloutX: 0, calloutY: 0 });
    }

    mouseClickStart = (event: any) => {
        //console.log('start drag', event);
        let res = this.bisect(event.clientX - this.mouseEventOffset); // hard-coded margein left for now plus something - offset of sorts?
        //console.log('downdate', res.x);
        this.setState({ mouseDownDate: res.x, mouseUpDate: res.x });

    }

    mouseClickEnd = (event: any) => {
        //console.log('end drag', event);
        let res = this.bisect(event.clientX - this.mouseEventOffset); // hard-coded margein left for now plus something - offset of sorts?
        let startDate = this.state.mouseDownDate;
        this.setState({ mouseUpDate: null, mouseDownDate: null }, () => {
            if (startDate != new Date(res.x)) { // only zoom if not focused on a single date
                this.props.clickHandler(startDate, res.x);
            }
        });
    }
       
    render() {
        const { svgHeight, svgWidth, data } = this.props;

        const margin = { top: 5, right: 20, bottom: 50, left: 70 };
        const width = svgWidth - margin.left - margin.right;
        const height = svgHeight - margin.top - margin.bottom;

        // 'range' defines the space on the svg that the element can use (in pixels)
        // 'domain' defines the values that will show on the axes
        // with range and domain defined, d3 will scale appropriately and create ticks with values on the axes
        // if needed, can additionally define how we want ticks formatted (eg 10M instead of 10,000,000)


        this.xScale = d3.scaleTime()
            .domain(d3.extent(this.props.data, d => d.x))
            .range([0, width]);

        this.myBisect= d3.bisector(d => d.x).left;

        this.yScale = d3.scaleLinear().range([height, 0]);

        // reductionData & crcData are undefined on first render...
        if (data != undefined) {
            this.yScale.domain([0, d3.max(data, d => d.y) || 0]);
        }

        const axisBottomProps = {
            height: height,
            scale: this.xScale,
            class: "net-viewer-axis"
        };

        const axisLeftProps = {
            scale: this.yScale,
            position: height,
            class: "net-viewer-axis"
        };


        const pathProps = {
            data: data,
            yScale: this.yScale,
            xScale: this.xScale,
            height: height,
            fill: this.props.fill,
            color: this.props.color,
            strokeWidth: "1.0"
        };

        const areaStartEndProps = {
            data: [{ x: this.state.mouseDownDate != null && this.state.mouseUpDate != null? this.state.mouseDownDate: this.props.zoomStart, y: 50 }, {x: this.state.mouseUpDate != null && this.state.mouseDownDate != null? this.state.mouseUpDate: this.props.zoomEnd, y: 50}],
            yScale: this.yScale,
            xScale: this.xScale,
            height: height,
            fill: "#AADBFF",
            color: "#AADBFF",
            key: "test"
        }

        const calloutProps = {
            xvalue: new Date(this.state.mouseDate),
            yvalue: this.state.mouseValue,
            xCoord: this.state.calloutX,
            yCoord: this.state.calloutY,
            label: this.state.label
        }

        const labelBottomProps = {
            text: "Simulation Date/Time",
            width: width,
            height: height,
            margin: margin,
            fontSize: "15"
        }

        const labelLeftProps = {
            text: this.props.units,
            width: width,
            height: height,
            margin: margin,
            fontSize: "15"
        }

        // NOTE: order of chart pieces is important. first rendered will be in back, with each additional piece overlayed
        return (

            <div>
                <Segment >
                    <Loader content={"Loading ..."} size="big" active={this.props.loading} />
                    <svg id="timeSVG" height={svgHeight} width={svgWidth}
                        // onMouseDown={((event) => { this.mouseClickStart(event) })}
                        // onMouseUp={((event) => { this.mouseClickEnd(event) })}
                        // onTouchMove={((event) => { this.mouseEvent(event) })}
                        // onMouseMove={((event) => { this.mouseEvent(event) })}
                        // onTouchEnd={((event) => { this.mouseLeave() })}
                        // onMouseLeave={((event) => { this.mouseLeave() })}
                        >
                        <g id="timeG" style={{pointerEvents: "all"}} height={this.props.svgHeight} width={this.props.svgWidth} transform={`translate(${margin.left},${margin.top})`}>
                            
                            <AreaStartEnd {...areaStartEndProps} />
                            <Path {...pathProps} />
                            <AxisBottomTimeFormat {...axisBottomProps} />
                            <LabelBottom {...labelBottomProps} />
                            <LabelLeft {...labelLeftProps} />
                            <AxisLeft {...axisLeftProps} />
                            <Callout {...calloutProps} />
                        </g>
                    </svg>
                </Segment>          
            </div> 
        
        );
    }
}