import React, { Component } from "react";
import './toolTipModal.css';
import Config from '../../config/Config';
import { Icon, Modal, Button } from 'semantic-ui-react';
// import { Dropdown, Button, FormGroup, Label, Input, Grid, Table, Icon } from "semantic-ui-react";

export class TooltipModal extends React.Component<{ icon: any, iconClassName: any, className: string, modalSize: any, header: any, bodyText: any, alertColors?: any, isOpen?: boolean, textInstead?: string}> {
    constructor(props) {
        super(props);

    }
    
    state = {
        modal: this.props.isOpen
    }
    
    handleOpen = () => this.setState({ modal: true })

    handleClose = () => this.setState({ modal: false })

    getHeaderText = () => {
        let htmlDoc = {__html: this.props.header};
        return htmlDoc;
    }

    getBodyText = () => {
        let htmlDoc = { __html: this.props.bodyText.replace("DeployURLSwapTooltip", Config.client_endpoint)};
        return htmlDoc;
    }

    render() {
        return (
            <span>
                <Modal size={this.props.modalSize} centered={false} open={this.state.modal} onClose={this.handleClose} className={this.props.className} trigger={<Icon className={this.props.iconClassName} name={this.props.icon} onClick={this.handleOpen} title="More Info"/>}>
    
                        <Modal.Header>
                            <div dangerouslySetInnerHTML={this.getHeaderText()} />
                        </Modal.Header>
        
                        <Modal.Content scrolling className="body-padding-fix">
                            <div dangerouslySetInnerHTML={this.getBodyText()}/>
                        </Modal.Content >
                        
                    <Modal.Actions>
                        <Button onClick={this.handleClose} className='wmms-dark-blue-btn'>Close</Button>
                    </Modal.Actions>
                </Modal>
            </span>
        );
    }
}
