// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import React, { Component, useEffect, useState } from "react";
import { ResponsiveBar } from '@nivo/bar'
import {animated, to} from '@react-spring/web'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const MyResponsiveBar = ({ data, indexBy, myKeys, myColors, groupMode, tickRotation, itemHeight, padding, markerVal, markerTxt /* see data tab */ }) => {

    const [extraNameSpace, setExtraNameSpace] = useState<boolean>(false);
    useEffect(() =>{
        // console.log('data', data);
        if(data && data.length > 0 && data[0].id){
            let needSpace = false;
            if(data.filter((item) => item.id.length >= 20).length > 0){
                needSpace = true;
            }

            setExtraNameSpace(needSpace);
        }
     }, [data])

    const CustomLeftTick = (val: any, animatedProps: any) => {
        return (
            <animated.g opacity={animatedProps.opacity} transform={animatedProps.transform}>
                <animated.line x1="0" x2="5" y1="0" y2="0" stroke="rgb(119, 119, 119)" stroke-width="1px"></animated.line>    
                <animated.text dx={-2}
                    textAnchor="end"
                    dominantBaseline="middle"
                    style={{
                        fill: '#333',
                        fontSize: 12,
                        fontWeight: 700
                    }}
                >
                    {val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ac-ft
                </animated.text>
            </animated.g>
        )
    }

    const CustomBottomTick = (val: any, animatedProps: any) => {
        return (
            <animated.g opacity={animatedProps.opacity} transform={animatedProps.transform}>
                <animated.line x1="0" x2="" y1="0" y2="5" stroke="rgb(119, 119, 119)" stroke-width="1px"></animated.line>   
                {/* need to programmatically split if val is longer than XXX chars & rickRotation == 0 */} 
                {(val === null || val.length < 20 || tickRotation !== 0) && 
                    <animated.text dy={20}
                        style={{
                            fill: '#000',
                            fontWeight: 700,
                            fontSize: '14px',
                            textAnchor: tickRotation != 0? 'end' : 'middle',
                            transform: `rotate(${tickRotation}deg)`
                        }}>
                        {val}
                    </animated.text>
                }
                {(val && val.length >= 20 && tickRotation === 0) && 
                    val.split(' ').map((v, i) => {return (
                    <animated.text dy={20 + (i * 20)}
                        style={{
                            fill: '#000',
                            fontWeight: 700,
                            fontSize: '14px',
                            textAnchor: tickRotation != 0? 'end' : 'middle',
                            transform: `rotate(${tickRotation}deg)`
                        }}>
                        {v}
                    </animated.text>
                )})}

            </animated.g>
        )
    }

    return(<ResponsiveBar
        data={data}
        animate={true}
        motionConfig={'wobbly'}
        keys={myKeys}
        indexBy={indexBy}
        margin={{ top: 30, right: 30, bottom: tickRotation !== 0? 180: extraNameSpace === true? 100: 60, left: 95 }} // 100
        padding={0.4}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={myColors}
        groupMode={groupMode}
        enableLabel={false}
        valueFormat={v => `${v} ac-ft`}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisBottom={{ tickRotation: tickRotation, renderTick: ({ value, animatedProps }) => CustomBottomTick(value, animatedProps)}}
        axisLeft={{format: v => `${v} ac-ft`, renderTick: ({ value, animatedProps }) => CustomLeftTick(value, animatedProps)}}
        markers={markerVal != null? [
            {
                axis: 'y',
                value: markerVal,
                lineStyle: {stroke:'#13A0C2', strokeWidth:3, strokeDasharray:"6,2"},
                legend: markerTxt,
                legendOrientation: 'vertical',
                legendPosition: 'bottom-right'
            },
        ]: []}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: tickRotation !== 0? 80: extraNameSpace === true? 90 : 50, // 90
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: itemHeight,
                itemDirection: 'left-to-right',
                itemOpacity: 1.0,
                symbolSize: 30,
                padding: padding
            }
        ]}
        theme={{
            fontSize: 14
        }}
        //motionStiffness={90}
        //motionDamping={15}
    />)
}