import React, { Component } from "react";
import './colorPickerModal.css';
import Config from '../../config/Config';
import { Icon, Modal, Button } from 'semantic-ui-react';
import { SketchPicker } from 'react-color';
// import { Dropdown, Button, FormGroup, Label, Input, Grid, Table, Icon } from "semantic-ui-react";

export class ColorPickerModalState {
    modal: boolean = false;
    selColor: string = "#aaa";
}

export class ColorPickerModal extends React.Component<{ className: string, modalSize: any, isOpen?: boolean, startColor: string, bmpName: string, callback: Function }> {
    constructor(props) {
        super(props);

    }

    state: ColorPickerModalState = new ColorPickerModalState();

    componentDidMount = () => {
        this.setState({ modal: this.props.isOpen, selColor: this.props.startColor })
    }

    handleOpen = () => this.setState({ modal: true })

    handleClose = () => {
        this.setState({ modal: false });
        this.props.callback(this.state.selColor, this.props.bmpName);
    }

    colorPicked = (color: any, event: any) => {
        console.log('picked', color);
        this.setState({ selColor: color.hex })
    }

    colorCancel = (event: any) => {
        console.log('cancel');
    }


    render() {
        return (
            <span className="colorModal">
                <Modal size={this.props.modalSize} centered={false} open={this.state.modal} onClose={this.handleClose} className={this.props.className} trigger={<span className="colorModal" onClick={this.handleOpen} title="Select Color" />}>

                    <Modal.Header>
                        <div>Select a color for {this.props.bmpName}:</div>
                    </Modal.Header>

                    <Modal.Content className="body-padding-fix">
                        <SketchPicker width={300} color={this.state.selColor} onChange={this.colorPicked} onChangeComplete={this.colorPicked} disableAlpha={true} presetColors={[]} />
                    </Modal.Content >

                    <Modal.Actions>
                        <Button onClick={this.handleClose} className='wmms-dark-blue-btn'>Done</Button>
                    </Modal.Actions>
                </Modal>
            </span>
        );
    }
}
