import { BaseModel } from "../interfaces/IBaseModel";
import { optimizationsummaryitem } from "./optimizationsummaryitem";
import { optimizationcrc } from "./optimizationcrc";

export class optimizationsummary extends BaseModel {

    "guid": string;
    "scenarios": Array<optimizationsummaryitem>;
    "defaultcrc": Array<optimizationcrc>;
    "bmpgroups": any;
    "mrcavailable": boolean;
    "isSample": boolean;
    "errors": Array<string>;
    "visible"?: boolean;
    "childState"?: any;
    "key"?: number;

    constructor() {
        super('optimizationviewer');
    }
}  