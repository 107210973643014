import { IUtilityCard } from "./interfaces/IUtilityCard";
import FTableUtil from "./components/Utilities/FTable/ftableutil";
import WeatherUtil from "./components/Utilities/Weather/weatherutil";
import { ftable } from "./models/ftable";
import BmpOpportunityUtil from "./components/Utilities/BmpOpportunity/bmpopportunityutil";
import WrampsUtil from "./components/Utilities/Wramps/wrampsutil";
import InclusiveBestSolutionsUtil from "./components/Utilities/InclusiveBestSolutions/inclusivebestsolutionsutil";
import Tier2Util from "./components/Utilities/Tier2/tier2util";
import MjrBMPSolutions from "./components/Utilities/MjrBMPSolutions/mjrbmpsolutions";
import ClimateChangeUtil from "./components/Utilities/ClimateChange/climatechangeutil";

export default class UtilityMenu {
    static getMenu(): Array<IUtilityCard> {

        // MainTab data structure array
        var utils: Array<IUtilityCard> = new Array<IUtilityCard>();

        // User Projects Info

        utils.push({
            cardTitle: "F-table Utility",
            cardDescription: "This utility converts model reach and/or BMP geometric characteristics into a stage-storage-discharge rating curve for use in LSPC.",
            component: FTableUtil, cardIcon: "area chart", visible: true, active: true, wmmsColor: "lspc", internalName: "ftableutil", cardSubtitle: "Stage/Storage Utility", helpDoc: "", templateDoc: "", utilComponentIsOpen: null, beginInterval: null, stopInterval: null
            /* how to do this correctly? */
        });
        utils.push({
            cardTitle: "Weather Utility",
            cardDescription: "This utility integrates three meteorological data sources to create hourly precipitation and evapotranspiration timeseries for use in LSPC.",
            component: WeatherUtil, cardIcon: "cloud", visible: true, active: true, wmmsColor: "lspc", internalName: "weatherutil", cardSubtitle: "Weather Utility", helpDoc: "", templateDoc: "", utilComponentIsOpen: null,  beginInterval: null, stopInterval: null
        });
        utils.push({
            cardTitle: "WRAMPS Utility",
            cardDescription: "For WRAMPS users. This utility parameterizes the contents of a bulk export from the WRAMPS database into a spreadsheet that can then be uploaded to the BMP Opportunities Utility.",
            component: WrampsUtil, cardIcon: "signal", visible: true, active: true, wmmsColor: "input", internalName: "wrampsutil", cardSubtitle: "WRAMPS Utility", helpDoc: "", templateDoc: "", utilComponentIsOpen: null, beginInterval: null, stopInterval: null
        });
        utils.push({
            cardTitle: "BMP Opportunities Utility",
            cardDescription: "This utility translates BMP specifications (e.g., footprint, drainage area, ponding height) into SUSTAIN input files. These input files are used to initiate the first tier of the Optimization Framework.",
            component: BmpOpportunityUtil, cardIcon: "braille", visible: true, active: true, wmmsColor: "input", internalName: "bmpopportunityutil", cardSubtitle: "BMP Opportunities Utility", helpDoc: "", templateDoc: "", utilComponentIsOpen: null,  beginInterval: null, stopInterval: null
        });
        utils.push({
            cardTitle: "Inclusive Best Solutions Utility",
            cardDescription: "This utility organizes output files from the first tier of the optimization.  Utility output is a set inclusive set of best solutions that is evaluated in the second tier of the Optimization Framework.",
            component: InclusiveBestSolutionsUtil, cardIcon: "sync alternate", visible: true, active: true, wmmsColor: "sustain", internalName: "inclusivebestsolutionsutil", cardSubtitle: "Inclusive Best Solutions Utility", helpDoc: "", templateDoc: "", utilComponentIsOpen: null, beginInterval: null, stopInterval: null
        });
        utils.push({
            cardTitle: "MJR BMP Utility",
            cardDescription: "If multi-jurished regional (MJR) BMPs are present, this utility can be used to evaluate their performance in conjunction with other upstream regional and distributed BMP projects.",
            component: MjrBMPSolutions, cardIcon: "sun", visible: true, active: true, wmmsColor: "sustain", internalName: "mjrsolutil", cardSubtitle: "MJR BMP Solutions Utility", helpDoc: "", templateDoc: "", utilComponentIsOpen: null, beginInterval: null, stopInterval: null
        });
        utils.push({
            cardTitle: "Two-tiered Utility",
            cardDescription: "This Utility accepts results from the second tier of optimization (plus the MJR BMP simulation if applicable). Utility output is a performance summary file that is uploaded to the Optimization Viewer.",
            component: Tier2Util, cardIcon: "sort amount up", visible: true, active: true, wmmsColor: "sustain", internalName: "tier2util", cardSubtitle: "Tier 2 Utility", helpDoc: "", templateDoc: "", utilComponentIsOpen: null, beginInterval: null, stopInterval: null
        });
        utils.push({																															 
            cardTitle: "Climate Resiliency Utility",
            cardDescription: "This Utility processes the results from the Optimization Viewer to evaluate various future climate scenarios. Output is uploaded to the Climate Resiliency Viewer.",
            component: ClimateChangeUtil, cardIcon: "leaf", visible: true, active: true,  wmmsColor: "sustain", internalName: "climatechangeutil", cardSubtitle: "Climate Resiliency Input File Utility", helpDoc: "", templateDoc: "", utilComponentIsOpen: null, beginInterval: null, stopInterval: null
        });

        return utils
    }
}