import React, { Component } from "react";
import IAreaProps from "../interfaces/IAreaProps";
import * as d3 from 'd3';

export class AreaStartEndState {

}

export default class AreaStartEnd extends Component<IAreaProps> {

    state = new AreaStartEndState();

    ref: React.RefObject<SVGPathElement>;

    constructor(props: IAreaProps) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        // const { data, yScale, xScale } = this.props;
        // console.log(data);

        // data is undefined at this point, so can just leave this empty and use componentDidUpdate instead
    }

    addCircle() {
    }

    componentDidUpdate() {
        const { data, yScale, xScale, height } = this.props;

        var line = d3.area(data)
            .x(function (d) { return xScale(d.x); })
            .y0(0)
            .y1(height);

        d3.select(this.ref.current)
            .attr("class", "path")
            .attr("d", line(data))
            .on("mousemove", this.addCircle())
            // .append("circle").attr("r", 5).attr("fill", "blue").attr("cx", 100)
            .transition();

    }

    render() {
        const { fill, color } = this.props;
        const attributes = {
            /* TODO is this needed at all? from san mateo example*/
        };

        /*
               .attr("stroke-width", 1.5)
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
         */

        return <path data-testid="path" fill={fill} stroke={color} opacity={0.5} strokeLinejoin="round" strokeLinecap="round" {...attributes} ref={this.ref} />;
    }
}