import React, { Component } from "react";
import './downloadSubComponent.css';
import { Grid, Button, Table, Icon, Dimmer, Loader } from "semantic-ui-react";
import { INavHistory } from "../../../interfaces/INavHistory";
import UploadModal from "../../DOMObjects/UploadModal/UploadModal";
import IUploadModal from "../../DOMObjects/UploadModal/IUploadModal";
import { BaseModel } from "../../../interfaces/IBaseModel";
import ModelDownloads from "../../../models/ModelDownload";
import { AxiosResponse } from "axios";
import { TooltipModal } from "../../toolTipModal/toolTipModal";
import { IDownloadSubComponentProps } from "../../../interfaces/IDownloadSubComponentProps";

export class DownloadSubComponentState {

    modelData: ModelDownloads[] = [];
    loading: boolean = true;
    downloadProgress: number[] = new Array<number>();
}

export default class DownloadSubComponent extends Component<IDownloadSubComponentProps> {

    state = new DownloadSubComponentState();

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {
        let classContext: DownloadSubComponent = this;
        classContext.setState({ loading: true });
        classContext.getModelData(classContext.props.downloadType, "modelData");
    }

    getModelData(datafilter: string, stateProp: string) {
        let classContext: DownloadSubComponent = this;
        ModelDownloads.axiosGetAll({ "DownloadType": datafilter, "Archived": "false" })
            .then((r: AxiosResponse) => {
                let modelData: ModelDownloads[] = r.data;
                let compState = Object.assign({}, classContext.state);
                compState[stateProp] = modelData.sort((a: ModelDownloads, b: ModelDownloads) => { return a.displayWeight - b.displayWeight });
                compState.loading = false;
                classContext.setState(compState);
            })
            .catch((r: AxiosResponse) => {
                console.log("error fetching data: " + datafilter);
            });
    }

    makeJSX = (data: ModelDownloads[]) => {
        return data.map((d) => {
            return (<Table.Row>
                <Table.Cell width='4' verticalAlign='middle' textAlign='center' >
                    {d.displayName}
                </Table.Cell>
                <Table.Cell width='2' verticalAlign='middle' textAlign='center'>
                    <Button className="wmms-dark-blue-btn" size='mini' onClick={() => { this.downloadModelFile(d.id, d.mimeType, d.fileName) }} loading={this.state.downloadProgress.includes(d.id)}>Download</Button>
                </Table.Cell>
                <Table.Cell width='4' verticalAlign='middle' textAlign='left' >
                    <p dangerouslySetInnerHTML={{ __html: d.description }}></p>
                    {
                        d.tooltipName != null && d.tooltipName.length > 0 &&
                        <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-small pr-2 pl-point5'} className={""} modalSize={'small'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData[d.tooltipName].header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData[d.tooltipName].body : 'Tooltip data is still loading please try again in a moment'} />
                    }
                </Table.Cell>
            </Table.Row>
            );
        });
    };

    modelFormatter = (file: File): BaseModel => {

        let modelDownload = new ModelDownloads();

        modelDownload.archived = false;
        modelDownload.fileName = file.name;
        modelDownload.mimeType = file.type;
        modelDownload.downloadType = 'timeseries';

        return modelDownload;
    };

    lspcModelFormatter = (file: File): BaseModel => {

        let modelDownload = new ModelDownloads();

        modelDownload.archived = false;
        modelDownload.fileName = file.name;
        modelDownload.mimeType = file.type;
        modelDownload.downloadType = 'lspc';

        return modelDownload;
    };

    onUploadModalClose = () => {
        ModelDownloads.axiosGetAll({ "DownloadType": "timeseries" })
            .then((r: AxiosResponse) => {
                this.setState({ timeSeriesData: r.data });
            })
            .catch((r: AxiosResponse) => {
                console.log("error fetching data");
            });

    };

    lspcOnUploadModalClose = () => {
        ModelDownloads.axiosGetAll({ "DownloadType": "lspc" })
            .then((r: AxiosResponse) => {
                this.setState({ lspcData: r.data });
            })
            .catch((r: AxiosResponse) => {
                console.log("error fetching data");
            });
    };

    downloadModelFile = (modelDownloadId: number, mimeType: string, filename: string) => {
        let classContext: DownloadSubComponent = this;
        let compState = Object.assign({}, classContext.state);
        compState["downloadProgress"].push(modelDownloadId)
        compState["downloadProgress"] = compState["downloadProgress"];
        classContext.setState(compState, () => {
            ModelDownloads.axiosGetBlobById(modelDownloadId)
                .then((r: AxiosResponse) => {
                    const bloboBaggins = new Blob([r.data], { type: mimeType });
                    classContext.removeFromDownloadProgress(modelDownloadId);
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(bloboBaggins, filename);
                    } else {
                        const bloboBaggins = new Blob([r.data], { type: mimeType });
                        var link = document.createElement('a');
                        var href = window.URL.createObjectURL(bloboBaggins);
                        link.setAttribute('href', href);
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        setTimeout(() => {
                            window.URL.revokeObjectURL(href);
                            document.body.removeChild(link);
                        }, 0)
                    };
                })
                .catch((r: AxiosResponse) => {
                    classContext.removeFromDownloadProgress(modelDownloadId);
                    console.log("error downloading file")
                });
        });
    };

    removeFromDownloadProgress = (id: number) => {
        let classContext: DownloadSubComponent = this;
        let compState = Object.assign({}, classContext.state);
        compState["downloadProgress"] = compState["downloadProgress"].filter(i => i !== id);
        classContext.setState(compState);
    }

    render() {

        let classContext: DownloadSubComponent = this;

        return (


            <Grid.Row>
                <Table className={'section-' + (classContext.props.sectionName)} celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3'> {classContext.props.componentTitle}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            classContext.state.modelData.length > 0 && classContext.state.loading == false && 
                            classContext.makeJSX(classContext.state.modelData)
                        }
                        {classContext.state.modelData.length == 0 && classContext.state.loading == false && 
                            <Table.Row>
                                <Table.Cell colSpan='3'> None available
                                </Table.Cell>
                            </Table.Row>
                        }
                        {
                            classContext.state.loading == true && 
                            <Table.Row>
                                <Table.Cell colSpan='3'>
                                    <Loader active />
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Grid.Row>
 

        );
    }
}