import React from "react";
import "./watermark.css";


interface IWatermarkProps {
    watermark_text: string
    children: any
}

export const Watermark: React.FC<IWatermarkProps> = ({ ...props }) => {

    return (
        <div className="watermark_container">
            <div className="watermark_wrapper">
                <div className="watermark_inner_wrapper">
                    <div className="watermark_markings">
                        {props.watermark_text}
                    </div>
                </div>
            </div>
            {props.children}
        </div>
    );
}
