import { BaseModel } from "../interfaces/IBaseModel";

export class inclusivebestsolutions extends BaseModel {

    "zipfilename": string;
    "zipfiletype": string;
    "zipfileblob": Blob;
    "wmmsfilename": string;
    "wmmsfiletype": string;
    "wmmsfileblob": Blob;
    "email": string;
    "reduction": number;

    constructor() {
        super('inclusivebestsolutions');
    }
}  