import { BaseModel } from "../interfaces/IBaseModel";
import { optimizationsummaryitem } from "./optimizationsummaryitem";
import { optimizationcrc } from "./optimizationcrc";

export class optimizationassessmentpointdata extends BaseModel {

    "guid": string;
    "scenarios": Array<optimizationsummaryitem>;
    //"defaultcrc": Array<optimizationcrc>;
    //"bmpgroups": any;
    "reduction": number;
    "errors": Array<string>;

    constructor() {
        super('optimizationviewer/getapdata');
    }
}  