import React, { Component } from "react";
import { INavHistory } from "../../interfaces/INavHistory";
import { Icon } from "semantic-ui-react";
import HrefLink from "../../core/hrefLink/hrefLink";

export default class Links extends Component<INavHistory> {

    state = {

    }

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {

    }


    render() {

        let classContext: Links = this;


        return (
            <div className="optimization-container">
                <div className="optimization-inner-container">
                    <div className="downloads-top" id="downloadTop">
                        <div className="wmms-blue-header-div wmms-blue-header">
                            <div>
                                Links
                            </div>
                        </div>
                        <p className="side-panel-subtext">
                            <ul>
                                <li className="side-panel-list-padding">Los Angeles County Public Works: <HrefLink endpointUrl="https://dpw.lacounty.gov/" linkText='link ' icon='external' newTab={true} wmmsColor='sustain' /></li>
                                <li className="side-panel-list-padding">USEPA SUSTAIN web page: <HrefLink endpointUrl="https://www.epa.gov/water-research/system-urban-stormwater-treatment-and-analysis-integration-sustain" linkText='link ' icon='external' newTab={true} wmmsColor='sustain' /></li>
                                <li className="side-panel-list-padding">USEPA LSPC web page: <HrefLink endpointUrl="https://cfpub.epa.gov/si/si_public_record_Report.cfm?Lab=NERL&dirEntryId=75860&CFID=22884508&CFTOKEN=98267566" linkText='link ' icon='external' newTab={true} wmmsColor='sustain' /></li>
                                <li className="side-panel-list-padding">Los Angeles Region Watershed Management Programs: <HrefLink endpointUrl="https://www.waterboards.ca.gov/losangeles/water_issues/programs/stormwater/municipal/watershed_management/" linkText='link ' icon='external' newTab={true} wmmsColor='sustain' /></li>
                                <li className="side-panel-list-padding">Developing Reasonable Assurance: A Guide to Performing Model-Based Analysis to Support Municipal Stormwater Program Planning. <HrefLink endpointUrl="https://www3.epa.gov/region9/water/npdes/pdf/stormwater/meeting-2016-09/dev-reasonable-assur-guide-model-base-analys-munic-stormw-prog-plan-2017-02.pdf" linkText='link ' icon='external' newTab={true} wmmsColor='sustain' /></li>
                                <li className="side-panel-list-padding">California Stormwater Quality Association: <HrefLink endpointUrl="https://www.casqa.org/" linkText='link ' icon='external' newTab={true} wmmsColor='sustain' /></li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        );

    }
}