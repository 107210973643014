import { BaseMapOptions } from "./BaseMapOptions";
import { LayerInfo } from "./LayerInfo";
import { IMapProps } from "./IMapProps";

// Class for easily instaniating typesafe props objects for EsriMap.
export class MapConfig implements IMapProps {
    
    basemapType: BaseMapOptions;    
    startingCoords: number[];
    startingZoom: number;
    mapServiceURL: string;
    layerInfos: LayerInfo[];
    classContext: any;
    legendOpenByDefault: boolean;
    angle: number
    optionalLegendCallBack: () => any;

    constructor(basemapType: BaseMapOptions, startingCoords: number[], startingZoom: number, mapServiceURL: string, layerInfos: LayerInfo[], classContext: any, angle: number, legendOpenByDefault: boolean, optionalCallback?: any) {
        this.basemapType = basemapType;
        this.startingCoords = startingCoords;
        this.startingZoom = startingZoom;
        this.mapServiceURL = mapServiceURL;
        this.layerInfos = layerInfos;
        this.classContext = classContext;
        this.angle = angle;
        this.legendOpenByDefault = legendOpenByDefault;
        this.optionalLegendCallBack = optionalCallback;
    }

}