import React, { Component } from "react";
import "./alertComponent.css";
import { IAlertProps } from "../../interfaces/IAlertProps";
import { Button, Icon } from "semantic-ui-react";



// How TO USE 

// 1. The component you want to render this from will need these two item in it's state.
// alertIsOpen: boolean = false; 
// alertConfguration: alertPropsModel = new alertPropsModel(); 

// 2. In the spot you want to trigger the alert from you will need this. You can configure it as needed(i.e. color, size, weather or not it should be a time out alter, how long before it times out, if it should be a click to close alert, or combo of both options.

// let alertProps: alertPropsModel = {
//     header: "Entered value must not exceed max % from data",
//     body: '',
//     color: 'warning',
//     size: 'small-alert',  OPT(small-alert, med-alert, large-alert)
//     className: '',
//     context: this,
//     closeBtn: true,
//     setTimeOut: true,
//     timeOutTime: 3000 e.g. (this has a 3 second timeout)
// };
// this.setState({ alertConfguration: alertProps }, () => {
//     this.setState({ alertIsOpen: true });
// });


export class AlertComponentState {

}

export default class AlertComponent extends Component<IAlertProps> {
    state = new AlertComponentState();

    componentDidMount() {

        if (this.props.setTimeOut == true) {
            this.startTimeOut()
        }
    }

    closeAlert = () => {
        this.props.context.setState({ alertIsOpen: false });
    }

    startTimeOut = () => {
        setTimeout(() => {
         
            this.props.context.setState({ alertIsOpen: false });
        }, this.props.timeOutTime)
    }

    render() {
        let classContext: AlertComponent = this;
        return (
            <div className="alert-backdrop">
                <div className={"ui " + this.props.color + " message " + this.props.size}>
                    {
                        this.props.closeBtn &&
                        <div className="close-alert-btn-div">

                            <div>
                                <Button className="alert-close" onClick={() => this.closeAlert()}><Icon name="x" /></Button>
                            </div>
                        </div>
                    }
                    <div className='alert-content-div'>
                        <div className="alter-header-div">
                            <div className="header">{this.props.header}</div>

                        </div>
                        <p>{this.props.body}</p>
                    </div>
                </div>
            </div>
        ) 
    } 
}