import { INavHistory } from "../../interfaces/INavHistory";

export interface IDropdownH2O extends INavHistory {
    dropdownItems: DropdownOption[];
    dropdownTitle: string;
    isMultiselect: boolean;
    onSelect: (event: any, data: any, index?: any) => any;
    onUpdate?: (classContext: any) => any;
}

export class DropdownOption {
    optionText: string;
    optionValue: any;
}