import React, { Component } from 'react';
import { loadModules, setDefaultOptions } from 'esri-loader';
import './EsriMap.css';
import { IMapProps } from './mapTypes/IMapProps';
import { LayerInfo, LayerType } from './mapTypes/LayerInfo';
import { EsriMapLegend } from './EsriMapLegend';
import { FeatureInfoFinder } from './helpers/FeatureInfoFinder';
import { GetFeatureInfo } from './helpers/GetFeatureInfo';
import Axios, { AxiosResponse } from 'axios';
import Config from '../../config/Config';
import { FeatureInfoResult } from './helpers/FeatureInfoResult';
import { MapLegendElementTemplate } from './mapTypes/MapLegendElementTemplate';


class EsriMapState {
    map: any = null;
}

export class EsriMap extends Component<IMapProps> {
    
    legendState: any[] = [];

    mapRef: any = null;
    view: any = null;
    featureInfoFinder: FeatureInfoFinder;

    // used to hold dynamically selected layers for hover interaction
    hoverLayers: string[] = [];


    // classes needed in memory
    wmsClass: any = null;
    geoJSONClass: any = null;
    mercatorUtils: any = null;
    spatialRef: any = null;

    // holds obj ID of popup feature
    popupFeature: any = null;

    state = new EsriMapState();

    layerCount = 0;

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

    componentDidMount() {
        setDefaultOptions({ version: '4.13' }); // starting 4.14 the geojson click event fails - the attributes only include objectId, not sure why or how to resolve rb 1-12-22
        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules(['esri/Map', 'esri/views/MapView', 'esri/layers/WMSLayer', 'esri/geometry/support/webMercatorUtils', 'esri/geometry/Point', 'esri/geometry/SpatialReference', 'esri/layers/GeoJSONLayer', 'esri/core/watchUtils'], { css: true })
            .then(([ArcGISMap, MapView, WMSLayer, webMercatorUtils, Point, SpatialReference, geoJSONLayer, watchUtils]) => {

                this.wmsClass = WMSLayer;
                this.geoJSONClass = geoJSONLayer;
                this.mercatorUtils = webMercatorUtils;
                this.spatialRef = SpatialReference;

                

                let map = new ArcGISMap({
                    basemap: this.props.basemapType
                });

                let statecopy = Object.assign({}, this.state);
                statecopy["map"] = map;

                this.getHoverLayers(this.props.layerInfos);

                this.setState(statecopy, () => {

                    this.view = new MapView({
                        container: this.mapRef.current,
                        map: this.state.map,
                        center: this.props.startingCoords,
                        zoom: this.props.startingZoom,
                        spatialReference: new SpatialReference({"wkid": 3857})
                    });


                    this.createMapLayers(this.props.layerInfos);    
                    this.featureInfoFinder = new FeatureInfoFinder(this.props.mapServiceURL, webMercatorUtils, Point, this.view);

                    // this handles over events
                    this.view.on("pointer-move", (event) => {
                        let sp = {
                            x: event.x,
                            y: event.y
                        };

                        this.view.hitTest(sp).then((r) => {
                            let apWasFound = false;
                            r.results.forEach(rr => {
                                this.hoverLayers.forEach(h => {
                                    if (rr.graphic.layer.id == h) {
                                        //console.log(this.legendState);
                                        this.legendState.forEach((l) => {
                                            //console.log("l", l.key)
                                            //console.log("h", h)
                                            if (l.key == h) {
                                                if (l.value) {
                                                    this.handleHoverHit(rr);
                                                    apWasFound = true;
                                                };
                                            };
                                        });
                                    };
                                });
                            });  
                            // if (apWasFound == false) {
                            //     // console.log('things');
                            //     this.popupFeature = "";
                            //     this.view.popup.close();
                            // }                          
                        });
                    });
                    

                    // this handles click events.
                    this.view.on("click",(event) => {
                        this.view.hitTest(event).then((r) => {
                            r.results.forEach((rr) => {
                                this.hoverLayers.forEach((h) => {
                                    if (h == rr.graphic.layer.id) {
                                        this.legendState.forEach((l) => {
                                            if (l.key == h) {
                                                if (l.value) {
                                                    this.props.classContext.eventListenerFunction(event, r, this.props.classContext);
                                                };
                                            };
                                        });
                                    };
                                });
                            });
                        });

                        // TODO: build axios request to hit GIS server into featureInfoFinderClass.
                        let url: string = this.featureInfoFinder.buildQueryString(this.props.layerInfos, event.mapPoint.latitude, event.mapPoint.longitude);
                        
                        // url is returned null if no WMS layers are queryable. 
                        if (url) {
                            GetFeatureInfo.axiosGet({url: url})
                                .then((r: AxiosResponse) => {
                                if (r.data != null) {
                                    //console.log("results", r.data);
                                    this.props.classContext.eventListenerFunction(event, r.data, this.props.classContext);
                                };

                                }).catch((r: AxiosResponse) => {
                                    console.log("error retreiving feature info");
                                });
                        };

                        // need to check geojson layers for click popups.

                    });

                    this.view.popup = {
                        dockEnabled: false, dockOptions: {buttonEnabled: false} };
                    
                    // TODO: find better solution that uses esri watch functions
                    this.updateMap();
                });
            });


        }

    componentWillUnmount() {
        if (this.view) {
            // destroy the map view
            this.view.container = null;
        }
    }

    updateMap = function () {
        let mapContext = this;
        setTimeout(function() {
            let newExtent = mapContext.view.extent;
            if (newExtent !== null) {
                newExtent.xmin -= .0001;
                mapContext.view.extent = newExtent;
            } else {
                mapContext.updateMap();
            }
        }, 2000)
    };


    // get legend state so we can sync hover and click event with it
    getLegendLayersState = (state) => {

        let legendStateArray = [];
        for (let key in state) {
            legendStateArray.push({
                key: key,
                value: state[key]
            });
        };

        this.legendState = legendStateArray;

        if (this.view) {
            this.view.popup.close();
        };

        if (this.state.map) {
            this.state.map.layers.items.forEach((layer) => {
                this.legendState.forEach((ls) => {
                    if (ls.key == layer.id) {
                        if (ls.value == true) {
                            layer.popupEnabled = true;
                        } else {
                            layer.popupEnabled = false;

                        }
                    };
                });
            });
        };
    };


    ZoomTo(evt?: any) {
        
        if (evt) {
            var geo = this.view.center;
            geo.x = evt.mapPoint.x;
            geo.y = evt.mapPoint.y;
            let zm = this.view.zoom;
            this.view.goTo({
                target: geo,
                zoom: zm
            });
        } else {
            var geo = this.view.center;
            geo.x = -13157963.81;
            geo.y = 4055688.86;
            let zm = 10;
            this.view.goTo({
                target: geo,
                zoom: zm
            });
        };

    }


    // pushes the name property of the LayerInfo into an array if it is geojson and queryable.
    getHoverLayers = (layerInfos: LayerInfo[]) => {
        layerInfos.forEach(i => {
            if (i.layerType == 'geojson' && i.queryFeatures) {
                this.hoverLayers.push(i.shortName);
            }
        });
    };

    // this takes the result object from the view.hitTest function
    handleHoverHit = (hitResult: any) => {


        if (this.popupFeature !== hitResult.graphic.attributes.OBJECTID) {
            
            this.view.popup.close();
            this.popupFeature = hitResult.graphic.attributes.OBJECTID;
            this.view.popup.open({
                location: hitResult.graphic.geomtry,
                features: [hitResult.graphic]
            });
        } 

    }

    // add more layer types to the beggining array as needed.
    // function name must match pattern of existing function names
    // add + layer type in all caps ex: WMS + Layers

    createMapLayers = (layerInfos: LayerInfo[]) => {
        let context: EsriMap = this;
        ['wms', 'geojson'].map((t: LayerType) => { return { type: t, layers: [] } }).map((t) => {
            layerInfos.forEach((l: LayerInfo) => {
                if (l.layerType == t.type) {
                    t.layers.push(l);
                };
            });
            return t;
        }).forEach((t) => {
            let functionName = "context.add" + t.type.toUpperCase() + "Layers(t.layers)";
            eval(functionName);
        });

    };

    // order of arguements matter dynamically buidling new layer options
    addWMSLayers = (layerInfos: LayerInfo[]) => {
        layerInfos.forEach((info: LayerInfo) => {
            let wmsLayer = new this.wmsClass({
                url: this.props.mapServiceURL,
                title: info.shortName,
                id: info.shortName,
                name: info.shortName,
                sublayers:[{name: info.name}],
                imageFormat: "PNG",
                version: "1.3.0",
                opacity: info.visible ? 1 : 0,
                spatialReferences: [3857, 4326]
            })
            this.state.map.add(wmsLayer);
        });
    };

    addGEOJSONLayers = (layerInfos: LayerInfo[]) => {
        console.log('add geojson');
        const template = {
            title: "<p style='word-break: keep-all'>{Name}</p>",
            //content: "{Name} Assessment Point",
            //dockOptions: {buttonEnabled: false},
            overwriteActions: true
        };

        const renderer = {
            type: "simple",
            //symbol: {
            //  type: "simple-marker",
            //  color: "rgb(227, 49, 81)",
            //  size: "12px",
            //  outline: {
            //    color: "white"
            //  }
            //}
            symbol: {
                type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
                url: Config.appUrl + "apSymbol_red.png",
                width: "24px",
                height: "24px",
                angle: this.props.angle
            }
        };

        layerInfos.forEach((info: LayerInfo) => {

            let geoLayer = new this.geoJSONClass({
                url: Config.appUrl + 'geojson/' + info.name + ".json",
                popupTemplate: template,
                renderer: renderer,
                id: info.shortName,
                title: info.shortName,
                name: info.shortName,
                opacity: info.visible ? 1 : 0,
            });

            //for (var i = 0; i < this.state.map)
            console.log('map',this.state.map);

            this.state.map.add(geoLayer);

        });

    }


    render() {

        const mapLegendProps = {
            baseMap: this.state.map,
            baseMapType: this.props.basemapType,
            mapLayers: this.props.layerInfos,
            getState: this.getLegendLayersState,
            legendOpenByDefault: this.props.legendOpenByDefault,
            optionalLegendCallBack: this.props.optionalLegendCallBack
        };

        return (
            <div style={{height: "100%", width: '100%', position: 'relative'}}>
                <div className="webmap" ref={this.mapRef} />
                <EsriMapLegend {...mapLegendProps}/>
            </div>
        );
    }
}
