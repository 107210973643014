import React, { Component } from "react";
import './mobile.css';
import { INavHistory } from "../../interfaces/INavHistory";
import logo from '../../images/WMMS_logo_full.png';


export default class Mobile extends Component<INavHistory> {

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {}

    render() {
      
        let classContext: Mobile = this;

        return (
            <div className="network-container">
                <div className="network-inner-container">
                    <div className="network-welcome-div">
                        <div className="wmms-blue-header-div wmms-blue-header">
                            <img className="mainLogo" src={logo} alt="wmms2Logo" />
                        </div>
                        <div className="side-panel-subtext mobile-center">
                            <p >
                                Thank you for visiting the Watershed Management Modeling System. Please visit this site on a desktop as it is not optimized for mobile viewing.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



// original layout 
{/* <div className="network-container">
<div className="network-inner-container">
    <div className={this.state.mainViewToShow == 2 ? "view-two-container" : "view-one-container"}>
        <div className={this.state.mainViewToShow == 2 ? 'contract-up-animation' : "view-two-top-div expand-up-animation"}>
            <div className={this.state.mainViewToShow == 2 ? ' contract-right-animation side-panel-div-big' : "side-panel-div expand-right-animation"}>
                <NetworkSidePanel view={this.state.sidePlanelView} renderMainView={this.renderMainView} renderSidePanelView={this.renderSidePanelView} networkContext={this} updateAssessmentPoint={this.updateAssessmentPointData} />
            </div>


            <div className="right-side-map-div" id="nw-map-div">
                <EsriMap {...esriMapConfig}/>
            </div>

        </div>
        {
            this.state.showBottomPanel == 2 &&
            <div className="view-two-bottom-div">
                <NetworkBottomPanel view={this.state.sidePlanelView} renderMainView={this.renderMainView} renderSidePanelView={this.renderSidePanelView} networkContext={this} updateAssessmentPoint={this.updateAssessmentPointData} />
            </div>
        }
    </div>
</div>
</div> */}