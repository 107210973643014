import React, { Component } from "react";
import './downloadSustain.css';
import { Grid, Button, Table, Icon } from "semantic-ui-react";
import { INavHistory } from "../../../interfaces/INavHistory";
import UploadModal from "../../DOMObjects/UploadModal/UploadModal";
import IUploadModal from "../../DOMObjects/UploadModal/IUploadModal";
import { AxiosPromise, AxiosResponse } from "axios";
import ModelDownloads from "../../../models/ModelDownload";
import { BaseModel } from "../../../interfaces/IBaseModel";
import { TooltipModal } from "../../toolTipModal/toolTipModal";
import DownloadSubComponent from "../DownloadSubComponent/downloadSubComponent";

export class DownloadSustainState {
    // TODO this is a placeholder for real data that will be obtained from the API
    // data: any = [
    //     {Name: "adobada"},
    //     {Name: "lengua"},
    //     {Name: "carne asada"}
    // ];

}

export default class DownloadSustain extends Component<INavHistory> {

    state = new DownloadSustainState();

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {

    };

    disclaimerAgree() {
        let classContext: DownloadSustain = this;
        classContext.setState({ displayDisclaimer: false });
    };
    
    // TODO can be used to dynamically create rows of file names each with its own download button if needed
    // createRows() {
    //     let classContext: DownloadSustain = this;        
    //     let rows = classContext.state.data.map(datum => {
    //         return (
    //             <Grid.Row className="sustain-data-row">
    //                 {datum.Name} 🌮
    //                 <Button>Download</Button>
    //             </Grid.Row>
    //         );
    //     });
    //     return rows;
    // }

    modelFormatter = (file: File): BaseModel => {

        let modelDownload = new ModelDownloads();

        modelDownload.archived = false;
        modelDownload.fileName = file.name;
        modelDownload.mimeType = file.type;
        modelDownload.downloadType = 'sustain';


        return modelDownload;
    };

    onUploadModalClose = () => {
        ModelDownloads.axiosGetAll({"DownloadType": "sustain"})
            .then((r: AxiosResponse) => {
                this.setState({sustainData: r.data});
            })
            .catch((r: AxiosResponse) => {
                console.log("error fetching data")
            });
    };


    render() {
        let classContext: DownloadSustain = this;

        const modalProps: IUploadModal = {
            triggerText: "Upload",
            headerText: "Upload Sustain Data and Executables",
            dropZoneHeight: 250,
            modelFormatter: this.modelFormatter,
            validationFields: ['description', 'displayName'],
            onModalClose: this.onUploadModalClose
        };

        return (
            <div id="sustain-tab" className="cardsSlideUp">
                <div className="sustain-inner-container">
                    <Grid >
                        <Grid.Row >
                            <div className="ui floating message">
                                <h3 className="ui header">
                                    <i className="exclamation triangle icon"></i>
                                    Disclaimer
                                </h3>
                                <p>
                                    Entries in the SUSTAIN panel of the Repository contain two files to support the use of the SUSTAIN (System for Urban Stormwater Treatment and Analysis INtegration) model developed for the Los Angeles region. For a full description on how these files were created and how they should be used, reference the appropriate user documentation.
                                </p>
                            </div>
                        </Grid.Row>
                        <DownloadSubComponent componentTitle="SUSTAIN Downloads" downloadType="sustain" tooltipFactory={classContext.props.tooltipFactory} tooltipsReady={classContext.props.tooltipsReady} sectionName='sustain' />

                    </Grid>
                </div>
            </div>
        );
    }
}