import { BaseModel } from "../../interfaces/IBaseModel";

export class ClimateCsv extends BaseModel {

    "guid": string;


    constructor() {
        super('climatechangeviewer/getCsv');
    }
}  