import React, { Component } from "react";
import './bmpopportunityutil.css';
import { INavHistory } from "../../../interfaces/INavHistory";
import { Grid, Icon, Message, Button, Label } from "semantic-ui-react";
import Config from "../../../config/Config";
import { IUtilityCard } from "../../../interfaces/IUtilityCard";
import { bmpopportunity } from "../../../models/bmpopportunity";
import { AxiosResponse } from "axios";
import { processviewmodel } from "../../../models/processviewmodel";
import { setInterval } from "timers";
import DownloadLink from "../../../core/downloadLink/downloadLink";

export class BmpOpportunityUtilState {
    pageObj: bmpopportunity = new bmpopportunity();
    filename: string = "No selection";
    file: File = null;
    processing: boolean = false;
    //email: string = "";
    processStatus: string = "";
    processMessage: string = "";
}

export default class BmpOpportunityUtil extends Component<IUtilityCard> {

    state = new BmpOpportunityUtilState;
    interval: NodeJS.Timeout;

    uploadFile() {
        // error list
        let list= new Array<string>();
        if (this.state.file == null) {
            list.push("No file selected");
            this.handleErrors(list);
        } else {
            let classContext: BmpOpportunityUtil = this;
            classContext.setState({ processing: true });
            this.handleErrors(new Array<string>());
            let form = new FormData();

            let bmpopp = new bmpopportunity();
            bmpopp.fileblob = this.state.file;
            bmpopp.filename = this.state.filename;
            bmpopp.filetype = "xlsx";
            form.append("attachmentData", JSON.stringify(bmpopp));

            form.append("data", this.state.file);

            //if (this.state.email.length > 0) {
            //    if (classContext.validateEmail(classContext.state.email)) {
            //        form.append("email", classContext.state.email);
            //    } else {
            //        list.push("The email address provided is invalid");
            //        classContext.handleErrors(list);
            //        classContext.setState({ processing: false });
            //        return;
            //    }
            //}

            bmpopportunity.axiosPostUpload(form).then((r: AxiosResponse) => {
                classContext.setStatusMessage(r.data.status, r.data.message); 
                if (r.data.status.toLowerCase() == "submitted") {
                    let data: processviewmodel = r.data;
                    if (data.queueId > 0) {
                        let id = data.queueId;
                        let intvl = setInterval(() => {
                            processviewmodel.axiosGet(id).then((r: AxiosResponse) => {
                                let data: processviewmodel = r.data;
                                classContext.setStatusMessage(data.status, data.message);
                                if (data.status != undefined && data.status.toLowerCase() == "completed") {
                                    classContext.props.stopInterval();
                                    if (data.message != null) {
                                        list.push(data.message);
                                        classContext.handleErrors(list);
                                    }
                                    classContext.getResult(data.resultId);
                                } else if (data.status != undefined && data.status.toLowerCase() == "error") {
                                    classContext.props.stopInterval();
                                    classContext.setState({ processing: false });
                                    list.push("Error during processing");
                                    list.push(data.message);
                                    classContext.handleErrors(list);
                                }
                                else {
                                }
                            });
                        }, 1000);  
                        classContext.props.beginInterval(intvl);
                    }
                } else if (r.data.status.toLowerCase() == "error") {
                    classContext.setState({ processing: false });
                    list.push("Error during processing");
                    list.push(r.data.message);
                    classContext.handleErrors(list);
                } else {
                    classContext.setState({ processing: false });
                    list.push("Unknown error");
                    classContext.handleErrors(list);
                }

            }).catch(function (error) {
                classContext.setState({ processing: false });
                list.push(error.message);
                classContext.handleErrors(list);
            })
        }
    }

    componentDidMount() {
    }

    getResult(id: number) {
        let classContext: BmpOpportunityUtil = this;
        classContext.setState({ processing: false });
        if (id > 0) {
            window.location.href = Config.api_endpoint + 'BmpOpportunity/' + id;
        }
    }

    setStatusMessage(status: string, message: string) {
        let classContext: BmpOpportunityUtil = this;
        if (status != undefined && (status.toLowerCase() == "submitted" || status.toLowerCase() == "queued" || status.toLowerCase() == "processing")) {
            classContext.setState({ processStatus: status, processMessage: message })
        } else {
            classContext.setState({ processStatus: "", processMessage: "" })
        }
    }

    handleErrors = (list) => {
        let err = document.getElementById('errorPlace');
        err.classList.remove('hidden');

        let msgs = document.getElementById('errorMessages');
        while (msgs.firstChild) {
            msgs.removeChild(msgs.firstChild);
        }
        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].indexOf(';') > 0) {
                    var smallArr = list[i].split(';');
                    for (var j = 0; j < smallArr.length; j++) {
                        if (smallArr[j].length > 1) {
                            let dv = document.createElement('li');
                            dv.append(smallArr[j])
                            msgs.appendChild(dv);
                        }
                    }
                }
                else {
                    let dv = document.createElement('li');
                    dv.append(list[i])
                    msgs.appendChild(dv);
                }
            }
        } else {
            err.classList.add('hidden');
        }
    }


    handleItemUpdate(evt) {
        let lbl = document.getElementById('uploadlabel');
        if (evt.target.files.length > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
            this.setState({ ['filename']: evt.target.files[0].name, ['file']: evt.target.files[0] });
            this.handleErrors(new Array<string>());
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
            this.setState({ filename: 'None Selected', file: null });
        }
    }

    // TODO will we set status messages for this util?
    //setStatusMessage(status: string, message: string) {
    //    let classContext: BmpOpportunityUtil = this;
    //    if (status != undefined && (status.toLowerCase() == "submitted" || status.toLowerCase() == "processing")) {
    //        classContext.setState({ processStatus: status, processMessage: message })
    //    } else {
    //        classContext.setState({ processStatus: "", processMessage: "" })
    //    }
    //}

    // TODO will we use email delivery?
    //handleTextUpdate(evt) {
    //    this.setState({ email: evt.target.value });
    //}



    render() {
        let classContext: BmpOpportunityUtil = this;
        return (
            <div className='scroll-fix'>
                <div>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <h2><Icon className={'page-icon wmms-' + this.props.wmmsColor} name={this.props.cardIcon} circular inverted />{this.props.cardTitle}</h2>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    The BMP Opportunities Utility translates BMP specifications (e.g. maximum footprint, drainage area, soil depth) from a separate user-completed screening/feasibility analysis into model-ready SUSTAIN input files. These files are the driving component in the Optimization Framework for quantifying BMP performance (e.g., load reduction). 
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                    Through the processes described in the <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={30} linkText="BMP Opportunities User Document" filename="BMPOpportunitiesUtilityUserDocument.pdf" mimetype="application/pdf" bold={true} wmmsColor={classContext.props.wmmsColor} />, BMP specifications are entered by a user in the <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={31} linkText="standardized spreadsheet format" filename="BMPOpportunities_Template.xlsx" mimetype="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" bold={true} wmmsColor={classContext.props.wmmsColor} />. The Utility then converts those details into model input files with the corresponding parameters. Input files are formatted for simulation at the subwatershed/jurisdiction (“jurished”) scale in the SUSTAIN model. Additionally, a <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={56} linkText="tutorial document and sample dataset" filename="User_Package.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> are provided for further context on how data is prepared and processed for upload to this Utility.
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    <div id="errorPlace" className="hidden">
                                        <Message color='red' onDismiss={() => classContext.handleErrors(new Array<string>())}>
                                            <Message.Header>Processing Messages:</Message.Header>
                                            <Message.List id='errorMessages'>
                                            </Message.List>
                                        </Message>
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.filename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='bmpupload'><Label className="upload-basic-btn" color='red' basic id='uploadlabel'>Click here to select a .xlsx file.</Label></label>
                                        <input id='bmpupload' type="file" accept=".xlsx" onChange={(evt) => classContext.handleItemUpdate(evt)} className="hidden" />
                                    </div>
                                </div>
                                <br />
                                <div className="semantic-green-disabled-btn">
                                    <Button size='medium' loading={this.state.processing} disabled={this.state.processing} className={"wmms-" + this.props.wmmsColor + "-btn"} onClick={() => this.uploadFile()} >Process</Button>
                                </div>
                                <b>{this.state.processStatus}</b> {this.state.processMessage}
                                <div className='WeatherStatus'>
                                </div>
                            </Grid.Column>
                            <Grid.Column className="">
                                <div>
                                    <p><b>Additional supporting documentation includes:</b></p>
                                    <li><b>HRU GIS data: </b><DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={48} linkText="Mapped HRU raster" filename="WMMS2_MappedHRUs.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> and <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={33} linkText="Complete HRU raster" filename="WMMS2_CompleteHRUs.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> for characterization of BMP drainage areas. See the Utility user documentation for distinctions between these two datasets.</li>
                                    <li><b>Jurished GIS data: </b><DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={34} linkText="Jurished layer" filename="Jurisheds_2020.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> representing the jurisdictional boundaries across the WMMS model domain.</li>
                                    <li><b>Tabular data: </b><DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={32} linkText="Overspray and Irrigation percentages by subwatershed" filename="bmpopportunity_HRU_Irrigation_Overspray.xlsx" mimetype="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" bold={true} wmmsColor={classContext.props.wmmsColor} />. These values were parameterized in the LSPC model calibration and validation process and used to develop the Complete HRU raster from the Mapped HRU raster. See the Utility user documentation for additional details.</li>
                                    <br/>
                                    <p><small><b>DISCLAIMER:</b> The Complete HRU raster contains mapped HRU values (e.g., ResHigh) that were reassigned to unmapped groups (e.g., Overspray) using a spatial statistical distribution method. Therefore, the cell-by-cell reassignment of the unmapped HRUs should not be interpreted as actual locations of unmapped activities. For example, a cell that was reassigned to Overspray does not express the precise location where Overspray is occurring in a subwatershed. Instead, the reassignments should be considered at the subwatershed scale and as diffuse reassignments.</small></p>
                                </div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>

            </div>
        );
    }
}