import React, { Component } from 'react';
import './EsriMap.css';
import { MapLegendElementTemplate } from './mapTypes/MapLegendElementTemplate';
import { IMapLegendProps } from './mapTypes/IMapLegendProps';
import { List, Checkbox, Grid, Icon, Header, Divider } from 'semantic-ui-react';
import { LayerInfo } from './mapTypes/LayerInfo';

export class EsriMapLegendState {
    basemapswap: boolean = true;
    legendExpanded: boolean = false;
    mapLayers: MapLegendElementTemplate [] = [];
    // legendHeight: string;

};

export class EsriMapLegend extends Component<IMapLegendProps> {

    state = new EsriMapLegendState();
    
    componentDidMount() {
        this.setLegendState(this.initializeMapLegend);
        if (this.props.optionalLegendCallBack) {
            this.props.optionalLegendCallBack(this);
        };

        // let legendHeight: any = window.innerHeight - 100;
        // this.setState({ legendHeight: legendHeight});

        // console.log(legendHeight, 'legendHeight');
    }

    componentDidUpdate() {
        this.props.getState(this.state);
    }

    // used as call back to create legend elements after setting legend state
    initializeMapLegend = () => {
        this.state.mapLayers = this.createElementTemplateArray();
        this.updateLayerVisibility();
    }

    // create an array of lightweight types to build legend from. 
    createElementTemplateArray = (): MapLegendElementTemplate[] => {

        let templateArray =  this.props.mapLayers.map((l: LayerInfo) => {
            let newTemplate = new MapLegendElementTemplate();

            newTemplate.displayName = l.legendDisplayName;
            newTemplate.layer = l.shortName;

            // tie this in later by adding color as a property to LayerInfos and requiring a color argument per layer when creating map.
            // write code to dyanmically create color rule for toggle based of this color.  could be done inside get legend items function.
            newTemplate.color = "";

            return newTemplate;
        });

        templateArray.push({layer: "basemapswap", displayName: "Streets / Satellite", color: ""});

        return templateArray;
    }

    // loops over map layers to set legend layers and their starting visibility to state
    setLegendState = (callback: () => void) => {

        let stateCopy = Object.assign({}, this.state);

        this.props.mapLayers.forEach((l: LayerInfo) => {
            stateCopy[l.shortName] = l.visible;
        });

        stateCopy["basemapswap"] = false;
        stateCopy["legendExpanded"] = this.props.legendOpenByDefault;

        this.setState(stateCopy, () => {
            callback();
        });

    }

    handleLayerToggle = (evt, data) => {

        let newState: EsriMapLegendState = Object.assign({}, this.state);

        if (this.state[data.id] == true) {
            newState[data.id] = false;
        } else if (this.state[data.id] == false) {
            newState[data.id] = true;
        };

        this.setState(newState, () => {
            this.updateLayerVisibility();
        });
    };

    handleLegendSizeToggle = () => {
        if (this.state.legendExpanded) {
            this.setState({legendExpanded: false});
        } else if (!this.state.legendExpanded) {
            this.setState({legendExpanded: true});
        };
    };

    collapseLegend = () => {
        if (this.state.legendExpanded) {
            this.setState({legendExpanded: false});
        };
    };

    updateLayerVisibility = () => {
        if (this.props.baseMap !== undefined && this.props.baseMap !== null) {
            this.state.mapLayers.forEach((l: MapLegendElementTemplate) => {
                let layer = this.props.baseMap.findLayerById(l.layer);
                if (layer !== null && layer !== undefined) {
                    if (this.state[l.layer] == true) {
                        this.fadeLayerIn(layer);
                    } else if (this.state[l.layer] == false) {
                        this.fadeLayerOut(layer)
                    };
                } else {
                    if (this.state[l.layer] == true) {
                        if (l.layer == 'basemapswap') {
                            this.toggleBasemapType(true);
                        };
                    } else if (this.state[l.layer] == false) {
                        if (l.layer == 'basemapswap') {
                            this.toggleBasemapType(false);
                        };
                    };
                };
            });
        }
    };

    fadeLayerIn = (layer) => {
        let legendContext = this;
        setTimeout(function() {
            if (layer.opacity < 1) {
                layer.opacity = layer.opacity + .081;
                legendContext.fadeLayerIn(layer)
            };
        }, 10);
    
    }

    fadeLayerOut = (layer) => {
        let legendContext = this;
        setTimeout(function() {
            if (layer.opacity > 0) {
                layer.opacity = layer.opacity - .081;
                legendContext.fadeLayerOut(layer)
            };
        }, 10);
    }

    toggleBasemapType = (satellite: boolean) => {

        if (satellite) {
            this.props.baseMap.basemap = "satellite";
        } else if (!satellite) {
            this.props.baseMap.basemap = this.props.baseMapType;
        }

    };

    getMapLegendItems = (): JSX.Element[] => {
        return this.state.mapLayers.map((layer: MapLegendElementTemplate, index: number) => {
            //console.log(layer.layer);
            return (
                <List.Item  key={index}>
                    <List.Content>
                        <div style={{whiteSpace: 'nowrap'}} className='legend-item'>
                            <Checkbox onClick={this.handleLayerToggle} checked={this.state[layer.layer]} id={layer.layer} className={layer.layer} toggle />
                            <div style={{marginLeft: 7, whiteSpace: 'nowrap', paddingLeft: '.3em'}}>
                                {`${layer.displayName}`}
                            </div>
                        </div>
                    </List.Content>
                </List.Item>)
        });
    };

    render() {
        let height = 320;
        let width = 200;

        if (!this.state.legendExpanded) {
            height = 47;
            width = 47;
        }

        let overFlow: any = this.state.legendExpanded ? 'auto' : 'hidden';
        let legendHeight: any = window.innerHeight - 100;
        return(
                <Grid centered={!this.state.legendExpanded ? true : false} onClick={!this.state.legendExpanded ? this.handleLegendSizeToggle : null} className={!this.state.legendExpanded ? "mini-legend-hover" : ""} style={
                    {
                        // height: height, 
                        // width: width,
                        zIndex: 9995, 
                        position: "absolute", 
                        right: 25, 
                        top: 27,
                        backgroundColor: "white",
                        padding: 20,
                        borderRadius: 4,
                        border: '1px solid rgb(190, 193, 198, .8)', 
                        maxHeight: legendHeight + "px", 
                        overflowY: overFlow, 
                        overflowX: "hidden"
                    }}>

                    {
                        !this.state.legendExpanded &&
                        <div className="mini-legend-icon-wrapper">
                            <Grid.Row style={{paddingTop: ".1em"}}>
                                    <Icon.Group size="big">
                                        <Icon name='map' style={{marginRight: ".20rem", color: 'rgb(3, 86, 137)'}}/>
                                        <Icon corner="top left" style={{color: 'rgb(75, 201, 239)'}} name='map marker alternate'/>
                                    </Icon.Group>
                            </Grid.Row>
                        </div>

                        // <Grid.Column verticalAlign="middle" textAlign="center">
                        // </Grid.Column>
                    }
                    {
                        this.state.legendExpanded &&
                        <div className="full-legend-wrapper" style={{paddingRight: "0px"}}>
                            <Grid.Row style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <div>
                                    <Icon className="expanded-legend-minimize-placeholder" />
                                </div>
                                <div style={{alignItems: "center", display: "flex"}}>
                                    <div style={{ marginLeft: "-5px", textAlign: "center", marginRight: "7px"}}>
                                        <Icon.Group size="big">
                                            <Icon name='map' style={{color: 'rgb(3, 86, 137)'}}/>
                                            <Icon corner="top left" style={{color: 'rgb(75, 201, 239)'}} name='map marker alternate'/>
                                        </Icon.Group>
                                    </div>
                                    <div>
                                        <Header textAlign="center" as='h4'>
                                            Map Legend
                                        </Header>
                                    </div>
                                </div>
                                <div>
                                    <Icon className="expanded-legend-minimize" style={{padding: ".3rem 0", fontSize: "1.1em"}} onClick={this.handleLegendSizeToggle} name="close" />
                                </div>
                            </Grid.Row>
                            <Divider className="legend-hr"/>
                            <Grid.Row textAlign="left">
                                <div className="pb-point5">
                                    <List divided verticalalign='middle'>
                                        {
                                            this.getMapLegendItems()
                                        }
                                    </List>
                                </div>
                            </Grid.Row>
                        </div>
                    }

                </Grid>);
    }
  
};