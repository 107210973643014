import React, { Component } from "react";
import ILineProps from "../interfaces/ILineProps";
import * as d3 from 'd3';

export default class Line extends Component<ILineProps> {
  ref: React.RefObject<SVGPathElement>;

  constructor(props: ILineProps) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidUpdate() {
    const { defaultx, x, y, height } = this.props;
    let lineData;
    if (x == null){
        lineData = [
            [defaultx, y],
            [defaultx, height]
        ];
    } else {
        lineData = [
            [x, y],
            [x, height]
        ];
    }

    var line = d3.line(lineData);

    d3.select(this.ref.current)
      .attr("class", "line")
      .attr("d", line(lineData))
      .transition();
  }

  render() {
    const { color, strokeWidth } = this.props;

    return (
    <g className="line-wrapper">
      <path data-testid="line" stroke={color} strokeWidth={strokeWidth} ref={this.ref} />
    </g>);
  }
}