import React, { Component } from "react";
import IAxisBottomProps from '../interfaces/IAxisBottomProps';
import * as d3 from 'd3';

export default class AxisBottom extends Component<IAxisBottomProps> {
    ref: React.RefObject<SVGGElement>;

    constructor(props: IAxisBottomProps) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        if (this.ref.current) {
            d3.select(this.ref.current)
                .call(d3.axisBottom(this.props.scale).ticks(6, d3.timeFormat("%m/%d/%Y %H:%M")));
        }
    }

    componentDidUpdate() {
        if (this.ref.current) {
            d3.select(this.ref.current)
                .transition()
                .call(d3.axisBottom(this.props.scale).ticks(6, d3.timeFormat("%m/%d/%Y %H:%M")));
        }
    }

    render() {
        return <g className={this.props.class != null ? this.props.class : null} transform={`translate(0, ${this.props.height})`} ref={this.ref} />;
    }
}