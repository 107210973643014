import React, { Component } from "react";
import './networkBottomPanel.css';
import { identifier } from "@babel/types";
import { INetworkViewerBottomPanel } from "../../../interfaces/INetworkViewerBottomPanel";
import { networkviewer } from "../../../models/networkAP";
import { AxiosResponse } from "axios";
import { Table, TableHeader, TableRow, TableBody } from "semantic-ui-react";


export class NetworkBottomPanelState {


}

export default class NetworkBottomPanel extends Component<INetworkViewerBottomPanel> {

    state = new NetworkBottomPanelState();

    componentDidMount() {

    }



    render() {

        let classContext: NetworkBottomPanel = this;

        // window.screen.availHeight
        return (
            <div className="view-two-bottom-inner-div" style={{height: (((window.innerHeight / 13) / 2)) + "em"}}>
                <div className="assessment-date-table-div">
                    <Table celled selectable striped size="small" className="table-shadow"  style={{tableLayout: 'fixed'}}>
                        <TableHeader>
                            <TableRow>
                                <Table.HeaderCell className='blue-table-header'>Assessment Point Features</Table.HeaderCell>
                                <Table.HeaderCell className='blue-table-header'></Table.HeaderCell>
                                {/* <Table.HeaderCell className='blue-table-header'>Method</Table.HeaderCell>
                                <Table.HeaderCell className='blue-table-header'>Value</Table.HeaderCell> */}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <Table.Cell>Point Name</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.Watershed != null && this.props.apInfo.Watershed.length > 0 ? this.props.apInfo.Watershed : "N/A"}</span></Table.Cell>
                            </TableRow>
                            <TableRow>
                                <Table.Cell>Type (terminal outlet or trib outlet)</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.Type != null && this.props.apInfo.Type.length > 0 ? this.props.apInfo.Type : "N/A"}</span></Table.Cell>
                            </TableRow>
                            <TableRow>
                                <Table.Cell>Subwatershed Outlet ID</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.SWS_ID != null && this.props.apInfo.SWS_ID.length > 0? this.props.apInfo.SWS_ID: "N/A"}</span></Table.Cell>
                            </TableRow>
                            <TableRow>
                                <Table.Cell>Precipitation Node ID <i>(used in graph on left)</i></Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.PreFile_ID != null && this.props.apInfo.PreFile_ID.length > 0 ? this.props.apInfo.PreFile_ID : "N/A"}</span></Table.Cell>
                            </TableRow>
                            <TableRow>
                                <Table.Cell># of Upstream Subwatersheds</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.SWS_Cnt != null && this.props.apInfo.SWS_Cnt.length > 0 ? this.props.apInfo.SWS_Cnt : "N/A"}</span></Table.Cell>
                            </TableRow>
                            <TableRow>
                                <Table.Cell># of Upstream Jurisheds</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.Jurished_Cnt != null ? this.props.apInfo.Jurished_Cnt : "N/A"}</span></Table.Cell>
                            </TableRow>
                            <TableRow>
                                <Table.Cell># of Upstream Precipitation Nodes</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.Gage_Cnt != null && this.props.apInfo.Gage_Cnt.length > 0 ? this.props.apInfo.Gage_Cnt : "N/A"}</span></Table.Cell>
                            </TableRow>
                            <TableRow>
                                <Table.Cell>Upstream Area</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.US_Area != null ? this.props.apInfo.US_Area + " acres" : "N/A"}</span></Table.Cell>
                            </TableRow>
                            <TableRow>
                                <Table.Cell>Cumulative Length of Upstream Model Reaches</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.US_Length != null ? this.props.apInfo.US_Length + " miles" : "N/A"}</span></Table.Cell>
                            </TableRow>
                            <TableRow>
                                <Table.Cell>Watershed Imperviousness</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.Perc_Imp != null ? this.props.apInfo.Perc_Imp + " %" : "N/A"}</span></Table.Cell>
                            </TableRow>
                            <TableRow>
                                <Table.Cell>Watershed Average Slope</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{this.props.apInfo.WS_Slope != null ? this.props.apInfo.WS_Slope + " %" : "N/A"}</span></Table.Cell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }
}