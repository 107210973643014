import axios, { AxiosInstance, AxiosPromise, AxiosResponse } from "axios";
import Config from "../config/Config";

export default class tooltipFactory {

    baseInstance: AxiosInstance;
    tooltipData: any;
    tooltipDataLoaded: boolean = false;
    setTooltipDataLoaded: any;

    constructor (setTooltipDataLoaded) {
        this.baseInstance = this.makeBaseAxiosInstance();
        this.populateTooltipData();
        this.setTooltipDataLoaded = setTooltipDataLoaded;

        
    }

    private makeBaseAxiosInstance = function(): AxiosInstance {

        return axios.create({
            baseURL: Config.client_endpoint,
            headers: {
                "Access-Control-Allow-Origin": '*',
                "Content-Type": 'application/json;charset=UTF-8',
            }
        });

    };

    public getTooltipJSON = (): AxiosPromise => {
        return this.baseInstance({
            method: "GET",
            url: 'json/tooltipData.json',
        })

    }

    public populateTooltipData = () => {
        this.getTooltipJSON().then((r: AxiosResponse) => {
            this.tooltipData = r.data;
            this.tooltipDataLoaded = true;
            this.setTooltipDataLoaded(true)
        })
        .catch((r: AxiosResponse) => {

        })

    }


}