import React, { Component } from "react";
import './downloadLspc.css';
import { Grid, Button, Table, Icon } from "semantic-ui-react";
import { INavHistory } from "../../../interfaces/INavHistory";
import UploadModal from "../../DOMObjects/UploadModal/UploadModal";
import IUploadModal from "../../DOMObjects/UploadModal/IUploadModal";
import { BaseModel } from "../../../interfaces/IBaseModel";
import ModelDownloads from "../../../models/ModelDownload";
import { AxiosResponse } from "axios";
import { TooltipModal } from "../../toolTipModal/toolTipModal";
import DownloadSubComponent from "../DownloadSubComponent/downloadSubComponent";

export class DownloadLspcState {
    // TODO this is a placeholder for real data that will be obtained from the API
    //data: any = [
    //    {Name: "Flood Storms - 10 year", Description: "You see, the refried beans are really just the adhesive necessary to apply the soft tortilla to the hard taco shell."},
    //    {Name: "Long Term - Daily", Description: "Um, Tabasco? No thanks, do you have any Cholula?"},
    //    {Name: "Water Quality Critical Condition - 85%ile Storm", Description: "Yeah, apparently the taco shack was robbed. They left the money but took the tacos."}
    //];

}

export default class DownloadLspc extends Component<INavHistory> {

    state = new DownloadLspcState();

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {
    }



    disclaimerAgree() {
        let classContext: DownloadLspc = this;
        classContext.setState({ displayDisclaimer: false });
    }

    // TODO can be used to dynamically create rows of file names each with its own download button if needed
    // createTextRows() {
    //     let classContext: DownloadLspc = this;        
    //     let rows = classContext.state.data.map(datum => {
    //         return (
    //             <Grid.Row className="lspc-data-row">
    //                 {datum.Name} 🌮
    //                 <Button>Download</Button>
    //             </Grid.Row>
    //         );
    //     });
    //     return rows;
    // }

    // TODO can be used to dynamically create rows of table data if needed
    //createTableRows() { let classContext: DownloadLspc = this;        
    //    let rows = classContext.state.data.map(datum => {
    //        return (
    //            <Table.Row className="lspc-table-row">
    //                <Table.Cell>
    //                    {datum.Name}
    //                </Table.Cell>
    //                <Table.Cell>
    //                    <Button>Download</Button>
    //                </Table.Cell>
    //                <Table.Cell>
    //                    {datum.Description}
    //                </Table.Cell>
    //            </Table.Row>
    //        );
    //    });
    //    return rows;
    //};


    modelFormatter = (file: File): BaseModel => {

        let modelDownload = new ModelDownloads();

        modelDownload.archived = false;
        modelDownload.fileName = file.name;
        modelDownload.mimeType = file.type;
        modelDownload.downloadType = 'timeseries';

        return modelDownload;
    };

    lspcModelFormatter = (file: File): BaseModel => {

        let modelDownload = new ModelDownloads();

        modelDownload.archived = false;
        modelDownload.fileName = file.name;
        modelDownload.mimeType = file.type;
        modelDownload.downloadType = 'lspc';

        return modelDownload;
    };

    onUploadModalClose = () => {
        ModelDownloads.axiosGetAll({"DownloadType": "timeseries"})
            .then((r: AxiosResponse) => {
                this.setState({timeSeriesData: r.data});
            })
            .catch((r: AxiosResponse) => {
                console.log("error fetching data");
            });

    };

    lspcOnUploadModalClose = () => {
        ModelDownloads.axiosGetAll({"DownloadType": "lspc"})
        .then((r: AxiosResponse) => {
            this.setState({lspcData: r.data});
        })
        .catch((r: AxiosResponse) => {
            console.log("error fetching data");
        });
    };

    render() {

        let classContext: DownloadLspc = this;

        const lspcModalProps: IUploadModal = {
            triggerText: "Upload",
            headerText: "Upload LSPC Data and Executables",
            dropZoneHeight: 250,
            modelFormatter: this.lspcModelFormatter,
            validationFields: ['description', 'displayName'],
            onModalClose: this.lspcOnUploadModalClose
        };

        const modalProps: IUploadModal = {
            triggerText: "Upload",
            headerText: "Upload Timeseries Data",
            dropZoneHeight: 250,
            modelFormatter: this.modelFormatter,
            validationFields: ['description', 'displayName'],
            onModalClose: this.onUploadModalClose
        };

        return (
            <div id="lspc-tab" className="cardsSlideUp">
                <div className="lspc-inner-container">
                    <Grid>
                        <Grid.Row>
                            <div className="ui floating message">
                                <h3 className="ui header">
                                    <i className="exclamation triangle icon"></i>
                                    Disclaimer
                                </h3>
                                <p>
                                    Entries in the LSPC panel of the Repository contain a mix of model files to support the use of the LSPC (Loading Simulation C++ Program) model developed for the Los Angeles region. Downloads include model database files, critical condition boundary conditions (e.g., 85th percentile storm), and continuous simulation boundary conditions (e.g., 10-year calibration timeseries). For a full description on how these datasets were created and how they should be used, reference the appropriate user documentation.
                                </p>
                            </div>
                        </Grid.Row>
    
                        <DownloadSubComponent componentTitle="LSPC Downloads" downloadType="lspc" tooltipFactory={classContext.props.tooltipFactory} tooltipsReady={classContext.props.tooltipsReady} sectionName='lspc' />
                        <DownloadSubComponent componentTitle="Continuous Simulation Timeseries Downloads" downloadType="timeseries_cont" tooltipFactory={classContext.props.tooltipFactory} tooltipsReady={classContext.props.tooltipsReady} sectionName='lspc' />
                        <DownloadSubComponent componentTitle="Critical Conditions Timeseries Downloads" downloadType="timeseries_design" tooltipFactory={classContext.props.tooltipFactory} tooltipsReady={classContext.props.tooltipsReady} sectionName='lspc' />
                        <DownloadSubComponent componentTitle="Future Climate Timeseries Downloads" downloadType="timeseries_future" tooltipFactory={classContext.props.tooltipFactory} tooltipsReady={classContext.props.tooltipsReady} sectionName='lspc' />

                    </Grid>
                </div>
            </div>
        );
    }
}