import { BaseModel } from "../interfaces/IBaseModel";

export class bmpopportunity extends BaseModel {

    "filename": string;
    "filetype": string;
    "fileblob": Blob;
    //"email": string

    constructor() {
        super('bmpopportunity');
    }
}  