import { BaseModel } from "../interfaces/IBaseModel";

export class tier2 extends BaseModel {

    "zipfilename": string;
    "zipfiletype": string;
    "zipfileblob": Blob;
    "scenariofilename": string;
    "scenariofiletype": string;
    "scenariofileblob": Blob;
    "wmmsfilename": string;
    "wmmsfiletype": string;
    "wmmsfileblob": Blob;
    "mjrbmp": boolean;
    //"email": string;
    //"reduction": number;
    "mrcFilename": string
    "mrcFiletype": string
    "mrcFileblob": string

    constructor() {
        super('tier2');
    }
}  