// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import React from "react";
import { ResponsiveBullet } from "@nivo/bullet";
import {animated, to} from '@react-spring/web'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const MyResponsiveBullet = ({ data, min, max, avgs, markerClick /* see data tab */ }) => (
  <ResponsiveBullet
    data={data}
    animate={true}
    motionConfig={'wobbly'}
    minValue={min}
    maxValue={max}
    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
    layout="vertical"
    spacing={120}
    titleAlign="middle"
    titleOffsetY={-25}
    rangeBorderColor="#000000"
    rangeBorderWidth={1}
    measureSize={0.0}
    markerSize={1}
    // rangeColors={["#ffffffff", "#F59841", "#fbd8b6", "#ffffffff", "#006099"]}
    rangeColors={["#ffffffff", "#1ACE7D", "#1ace7d50", "#ffffffff", "#13A0C2"]}
    // myColors={["#13A0C2", "#1ACE7D"]}
    markerColors="#000000"
    measureColors="#000000"
    rangeComponent={({data,index,borderColor,borderWidth,animatedProps: { x, y, width, height, color },onMouseEnter,onMouseMove,onMouseLeave}) => (
      <>
      <defs>
      <filter id="dropshadow1" x="0" y="0"  width="200%" height="200%">
        <feOffset result="offOut" in="SourceAlpha" dx="20" dy="20" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
      </defs>
      <animated.rect
        x={x}
        y={y}
        rx={0}
        ry={0}
        width={to(width, value => value - 4)}
        height={height}
        fill={color}
        //filter={data.v0 == min || data.v1 == max ? "" : "url(#dropshadow1)"}
        stroke={data.v0 == min || data.v1 == max ? "#FFFFFF" : borderColor}
        strokeWidth={data.v0 == min || data.v1 == max ? 0 : borderWidth}
        onMouseEnter={(a) => {
          let ev: any = new MouseEvent("enter", {
            clientX: a.clientX,
            clientY: a.clientY
          });

          if ((data.v0 != min && data.v1 != max) ||(data.v0 == min && index > 0)) {
            onMouseEnter(data, ev);
          }
        }}
        onMouseMove={(a) => {
          let ev: any = new MouseEvent("move", {
            clientX: a.clientX,
            clientY: a.clientY
          });
          if ((data.v0 != min && data.v1 != max) ||(data.v0 == min && index > 0)) {
            onMouseMove(data, ev);
          }
        }}
        onMouseLeave={(a) => {
          let ev: any = new MouseEvent("leave", {
            clientX: a.clientX,
            clientY: a.clientY
          });
          onMouseLeave(data, ev);
        }}
        
      ></animated.rect>
      </>
    )}
    measureComponent={({index,data,animatedProps: { x, y, width, height, color },onMouseEnter,onMouseMove,onMouseLeave}) => (
      <animated.g
        transform={to([x, y], (x, y) => `translate(${x},${y})`)}
        onMouseEnter={(a) => {
            let ev: any = new MouseEvent("enter", {
                clientX: a.clientX,
                clientY: a.clientY
            });
            if(avgs.indexOf(data.v1) < 0){
                // data.color = '#006099';
                data.color = '#13A0C2';
            } 
          onMouseEnter(data, ev);
        }}

        onMouseLeave={(a) => {
          let ev: any = new MouseEvent("leave", {
            clientX: a.clientX,
            clientY: a.clientY
          });
          onMouseLeave(data, ev);
        }}
      >

        {/*  */}
        {avgs.indexOf(data.v1) > -1 && <animated.line x1="-15" x2="15" y1="-15" y2="15"
            stroke={color}
            strokeWidth={1.5}
            />}
        {avgs.indexOf(data.v1) > -1 && <animated.line x1="-15" x2="15" y1="15" y2="-15"
            stroke={color}
            strokeWidth={1.5}
            />}
        {avgs.indexOf(data.v1) < 0 && <animated.line x1={-75} x2={75} y1={0} y2={0}
            // stroke={'#006099'}
            stroke={'#13A0C2'}
            strokeWidth={3}
            strokeDasharray="6,2" />}

      </animated.g>
    )}
    markerComponent={({size, data, animatedProps: { x, y1, color },onMouseEnter,onMouseMove,onMouseLeave}) => (

      <animated.g
        transform={to([x, y1], (x, y) => `translate(${x},${y + size / 2})`)}
        onMouseEnter={(a) => {
          //console.log("mouse enter marker", data);
          let ev: any = new MouseEvent("enter", {
            clientX: a.clientX,
            clientY: a.clientY
          });
          onMouseEnter(data, ev);
        }}
        onClick={(ev) => {
          markerClick(data, ev);
        }}
        onMouseLeave={(a) => {
          let ev: any = new MouseEvent("leave", {
            clientX: a.clientX,
            clientY: a.clientY
          });
          onMouseLeave(data, ev);
        }}
      >
        <animated.circle cx="0" cy="0" r="2.5" fill={color} />
      </animated.g>
    )}
  />
);
