import './weatherutil.css';
import React, { Component } from "react";
import { INavHistory } from "../../../interfaces/INavHistory";
import { Grid, Icon, Message, Button, Label } from "semantic-ui-react";
import Config from "../../../config/Config";
import { IUtilityCard } from "../../../interfaces/IUtilityCard";
import { weather } from "../../../models/weather";
import { AxiosResponse } from "axios";
import { processviewmodel } from "../../../models/processviewmodel";
import { weatherdata } from "../../../models/weatherdata";
import { setInterval } from "timers";
import DownloadLink from '../../../core/downloadLink/downloadLink';

export class WeatherUtilState {
    //pageObj: weather = new weather();
    gagefilename: string = "No selection";
    gagefile: File = null;
    prismfilename: string = "No selection";
    prismfile: File = null;
    nldasfilename: string = "No selection";
    nldasfile: File = null;
    evapfilename: string = "No selection";
    evapfile: File = null;
    email: string = "";
    
    processing: boolean = false;
    processStatus: string = "";
    processMessage: string = "";
}

export default class WeatherUtil extends Component<IUtilityCard> {

    state = new WeatherUtilState;
    interval: any = null;

    uploadFile() {
        if (this.state.gagefile == null || this.state.nldasfile == null || this.state.prismfile == null || this.state.evapfile == null) {
            let list = new Array<string>();
            list.push("Please select all needed files");
            this.handleErrors(list);
        } else {
            let classContext: WeatherUtil = this;
            classContext.setState({ processing: true });
            this.handleErrors(new Array<string>());
            let form = new FormData();

            form.append("data", this.state.gagefile);
            form.append("data", this.state.nldasfile);
            form.append("data", this.state.prismfile);
            form.append("data", this.state.evapfile);
            if (this.state.email.length > 0) {
                if (classContext.validateEmail(classContext.state.email)) {
                    form.append("email", classContext.state.email);
                } else {
                    let list = new Array<string>();
                    list.push("The email address provided is invalid");
                    classContext.handleErrors(list);
                    classContext.setState({ processing: false });
                    return;
                }
            }
            weather.axiosPostUpload(form).then((r: AxiosResponse) => {
                classContext.setStatusMessage(r.data.status, r.data.message);
                if (r.data.status.toLowerCase() == "submitted") {
                    let data: processviewmodel = r.data;
                    if (data.queueId > 0) {
                        let id = data.queueId;
                        let intvl = setInterval(() => {
                            processviewmodel.axiosGet(id).then((r: AxiosResponse) => {
                                let data: processviewmodel = r.data;
                                classContext.setStatusMessage(data.status, data.message);
                                if (data.status != undefined && data.status.toLowerCase() == "completed") {
                                    classContext.props.stopInterval();
                                    if (data.message.length > 1) {
                                        let list = new Array<string>();
                                        list.push(data.message);
                                        classContext.handleErrors(list);
                                    }
                                    classContext.getResult(data.resultId);
                                } else if (data.status != undefined && data.status.toLowerCase() == "error") {
                                    classContext.props.stopInterval();
                                    classContext.setState({ processing: false });
                                    let list = new Array<string>();
                                    list.push("Error during processing");
                                    list.push(data.message);
                                    classContext.handleErrors(list);
                                }
                                else {
                                    //console.log('keep checking');

                                }
                            });
                        }, 2000);
                        classContext.props.beginInterval(intvl);
                    }
                } else if (r.data.status.toLowerCase() == "error") {
                    classContext.setState({ processing: false });
                    let list = new Array<string>();
                    list.push("Error during processing");
                    list.push(r.data.message);
                    classContext.handleErrors(list);
                } else {
                    classContext.setState({ processing: false });
                    let list = new Array<string>();
                    list.push("Unknown error");
                    classContext.handleErrors(list);
                }

            }).catch(function (error) {
                classContext.setState({ processing: false });
                let list = new Array<string>();
                list.push(error.message);
                classContext.handleErrors(list);
            })
        }
    }


    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {

    }

    getResult(id: number) {
        let classContext: WeatherUtil = this;
        classContext.setState({ processing: false });
        if (id > 0) {
            window.location.href = Config.api_endpoint + 'Weather/' + id;
        }
    }

    validateEmail(email: string): boolean {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) { return true; }
        return false;
    }

    setStatusMessage(status: string, message: string) {
        let classContext: WeatherUtil = this;
        if (status != undefined && (status.toLowerCase() == "submitted" || status.toLowerCase() == "queued" || status.toLowerCase() == "processing")) {
            classContext.setState({ processStatus: status, processMessage: message })
        } else {
            classContext.setState({ processStatus: "", processMessage: "" })
        }
    }

    handleErrors(list) {
        let err = document.getElementById('errorPlace');
        err.classList.remove('hidden');

        let msgs = document.getElementById('errorMessages');
        while (msgs.firstChild) {
            msgs.removeChild(msgs.firstChild);
        }
        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].indexOf(';') > 0) {
                    var smallArr = list[i].split(';');
                    for (var j = 0; j < smallArr.length; j++) {
                        if (smallArr[j].length > 1) {
                            let dv = document.createElement('li');
                            dv.append(smallArr[j])
                            msgs.appendChild(dv);
                        }
                    }
                }
                else {
                    let dv = document.createElement('li');
                    dv.append(list[i])
                    msgs.appendChild(dv);
                }
            }
        } else {
            err.classList.add('hidden');
        }
    }


    handleItemUpdate(evt, typestr) {
        //alert(evt)
        let lbl = document.getElementById(typestr + 'uploadlabel');
        if (evt.target.files.length > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
            this.setState({ [typestr + 'filename']: evt.target.files[0].name, [typestr + 'file']: evt.target.files[0] });
            this.handleErrors(new Array<string>());
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
            this.setState({ [typestr + 'filename']: 'None Selected', file: null });
        }
    }

    handleTextUpdate(evt) {
        this.setState({ email: evt.target.value });
    }


    render() {

        let classContext: WeatherUtil = this;
        

        return (
            <div className="weather-container scroll-fix">
                <div className="weather-inner-container">
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <h2><Icon className={'page-icon wmms-' + this.props.wmmsColor} name={this.props.cardIcon} circular inverted />{this.props.cardTitle}</h2>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    {this.props.cardDescription} The data sources used to develop the timeseries utilize a network of ground-based and remotely sensed data from:
                                    <ul>
                                        <li>the Los Angeles County Dept. of Public Works Automatic Local Evaluation in Real Time (ALERT) system,</li>
                                        <li>the North American Land Data Assimilation System (NLDAS), and</li>
                                        <li>the Parameter-elevation Regressions on Independent Slopes Model (PRISM).</li>
                                    </ul>
                                </div>
                        </Grid.Column>
                            <Grid.Column>
                                <div>
                                    <p>Each of the modeled subwatersheds within the WMMS project area receive a precipitation and evapotranspiration timeseries from this utility based on their proximity to the nearest ALERT gage, PRISM and NLDAS node. The <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={42} linkText="reference table" filename="weather_crosswalk.xlsx" mimetype="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" bold={true} wmmsColor={classContext.props.wmmsColor} /> indicates the linkage between subwatersheds and their corresponding gage and nodes. Provided <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={44} linkText="geospatial data" filename="BoundaryConditions_GIS.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> (e.g., shapefiles) can be used to reference the WMMS subwatersheds, ALERT gages, and PRISM and NLDAS node locations as needed.</p>                                    
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    <div id="errorPlace" className="hidden">
                                        <Message color='red' onDismiss={() => classContext.handleErrors(new Array<string>())}>
                                            <Message.Header>Processing Messages:</Message.Header>
                                            <Message.List id='errorMessages'>
                                            </Message.List>
                                        </Message>
                                    </div>
                                    <div className='top-padding'>
                                        <div className=''>File Name:<b> {classContext.state.gagefilename}</b></div>
                                        <div className='subtext'>Gage files should all be in the <i>.xlsx</i> file extension and uploaded in a zipped folder. Example file name: <i>1070_2008-10-17~2018-09-06_Logger_hourly.xlsx</i>.</div>
                                        <label  className="upload-basic-btn" htmlFor='gageupload'><Label className="upload-basic-btn" color='red' basic id='gageuploadlabel'>Click here to select a collection of gage .xlsx files in .zip.</Label></label>
                                        <input id='gageupload' type="file" accept=".zip" onChange={(evt) => classContext.handleItemUpdate(evt, 'gage')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className=''>File Name:<b> {classContext.state.nldasfilename}</b></div>
                                        <div className='subtext'>NLDAS precipitation files should all be in the <i>.prcp</i> file extension and uploaded in a zipped folder. Example file name: <i>x53y71.prcp</i>.</div>
                                        <label className="upload-basic-btn" htmlFor='nldasupload'><Label className="upload-basic-btn" color='red' basic id='nldasuploadlabel'>Click here to select a collection of NLDAS Precipitation .prcp files in .zip.</Label></label>
                                        <input id='nldasupload' type="file" accept=".zip" onChange={(evt) => classContext.handleItemUpdate(evt, 'nldas')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className=''>File Name:<b> {classContext.state.prismfilename}</b></div>
                                        <div className='subtext'>PRISM files should all be in the <i>.prcp</i> file extension and uploaded in a zipped folder. Example file name: <i>541084.prcp</i>.</div>
                                        <label className="upload-basic-btn" htmlFor='prismupload'><Label className="upload-basic-btn" color='red' basic id='prismuploadlabel'>Click here to select a collection of PRISM .prcp files in .zip.</Label></label>
                                        <input id='prismupload' type="file" accept=".zip" onChange={(evt) => classContext.handleItemUpdate(evt, 'prism')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className=''>File Name:<b> {classContext.state.evapfilename}</b></div>
                                        <div className='subtext'>NLDAS evaporation files should all be in the <i>.evap</i> file extension and uploaded in a zipped folder. Example file name: <i>x53y71.evap</i>.</div>
                                        <label className="upload-basic-btn" htmlFor='evapupload'><Label className="upload-basic-btn" color='red' basic id='evapuploadlabel'>Click here to select a collection of NLDAS Evaporation .evap files in .zip.</Label></label>
                                        <input id='evapupload' type="file" accept=".zip" onChange={(evt) => classContext.handleItemUpdate(evt, 'evap')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>Optional Email:<b> Provide email address to receive completion email</b></div>
                                        <div className="ui input emailtextpad"><input className="emailinput" id='weatheremail' disabled={this.state.processing} type="text" placeholder='email@email.com' value={classContext.state.email} onChange={(evt) => classContext.handleTextUpdate(evt)} /></div>
                                    </div>
                                </div>
                                <br />
                                <div className="semantic-orange-disabled-btn">
                                    <Button size='medium' loading={this.state.processing} disabled={this.state.processing} className={"wmms-" + this.props.wmmsColor + "-btn"} onClick={() => this.uploadFile()} >Process</Button>
                                </div>
                                    <b>{this.state.processStatus}</b> {this.state.processMessage}
                                <div className='WeatherStatus'>
                                </div>
                            </Grid.Column>
                            <Grid.Column className="">
                                <div>
                                    <p>The process description below outlines the necessary Utility input files to successfully create the meteorological timeseries described above. For further details on the process described below, access the <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={40} linkText="user document" filename="weather_readme.pdf" mimetype="application/pdf" bold={true} wmmsColor={classContext.props.wmmsColor} />. Formatted <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={43} linkText="sample data" filename="Weather_Template.zip" mimetype="application/x-zip-compressed" bold={true} wmmsColor={classContext.props.wmmsColor} /> is also provided as an example for each input dataset.</p>
                                    <p>1. Upload formatted hourly precipitation data (in ‘.xlsx’ files) from the LACDPW ALERT system in a zipped folder in the provided space. A <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={41} linkText="macro" filename="weather_macro.xlsm" mimetype="application/vnd.ms-excel.sheet.macroEnabled.12" bold={true} wmmsColor={classContext.props.wmmsColor} /> is provided to facilitate the formatting of ALERT data; see the user’s guide for further detail on its use.</p>
                                    <p>2. Upload formatted hourly precipitation data (‘.prcp’ text file) from NLDAS and monthly precipitation data from PRISM in zipped folders in the provided spaces. Reference the above sample data for formatted timeseries. </p>
                                    <p>3. Upload formatted hourly evapotranspiration data from NLDAS (‘.evap’ text file). Reference the above formatted data for sample timeseries.</p>
                                    <p>4. Once ALERT, NLDAS, and PRISM datasets are uploaded the utility will create a set of timeseries that link all of the uploaded input files to create ‘.pre’ and ‘.air’ files.</p>
                                </div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        );

    }
}