// {
//     layer: "projects",
//     displayName: "GI Projects",
//     color: "rgba(66, 134, 244, .9)",
// },
// {
//     layer: "basemapswap",
//     displayName: "Streets / Satellite",
//     color: "rgb(43, 107, 54, .9)"    
// }
export class MapLegendElementTemplate {
    layer: string;
    displayName: string;
    color: string;
}