import React, { Component } from "react";
import './downloads.css';
import { INavHistory } from "../../interfaces/INavHistory";
import { Button, Accordion } from "semantic-ui-react";
import DownloadLspc from "./DownloadLspc/downloadLspc";
import DownloadSustain from "./DownloadSustain/downloadSustain";
import DownloadSpatial from "./DownloadSpatial/downloadSpatial";
import repoGraphic from '../../images/wmms_repo_diagram.png';
import DownloadDocument from "./DownloadDocument/downloadDocument";

export class DownloadsState {
    displayDisclaimer: boolean = true;
    activeTab: string = "DOCUMENTS";
}

export default class Downloads extends Component<INavHistory> {

    state = new DownloadsState();

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {

    }

    disclaimerAgree(){
        let classContext: Downloads = this;
        var myDiv = document.getElementsByClassName("downloads-inner-container")[0];
        myDiv.scrollTop = 0;
        classContext.setState({ displayDisclaimer: false }, () => {

        });
    }

    clickTab(name: string) {
        let classContext: Downloads = this;
        classContext.setState({ activeTab: name }, () => {
            // document.getElementById(`downloadTop`).scrollIntoView();
        });
       
    }


    render() {

        let classContext: Downloads = this;

        return (
            <div className="downloads-container" style={{ height: window.innerHeight - 25 + "px" }}>
                <div className="downloads-inner-container">
                    <div className="downloads-top" id="downloadTop">
                        <div className="wmms-blue-header-div wmms-blue-header">
                            <div>
                                <i aria-hidden="true" className="cloud download icon"></i> Documents, Data & Downloads
                            </div>
                        </div>
                    </div>
                {classContext.state.displayDisclaimer == true && 
                    <div className="cardsSlideUp pb-2">
                        <div className="side-panel-subtext">
                            <p >
                                TERMS AND CONDITIONS
                            </p>
                            <p>This is the Los Angeles County Watershed Management Modeling System (WMMS) License Agreement.</p>
                            <p>1. INTRODUCTION </p>
                            <p>The Los Angeles County Watershed Management Modeling System (referred to in this License Agreement as the "WMMS" and specifically including the MapWindow software, Loading Simulation Program in C++ plug-in(referred to as LSPC), Regional Optimization plug-in, all associated GIS files, spreadsheets, and databases used in association with WMMS and User Manual for WMMS) is licensed to you subject to the terms of this License Agreement. This License Agreement forms a legally binding contract between you and the Los Angeles County Flood Control District ("LACFCD") in relation to your use of the WMMS.</p>
                            <p>2. ACCEPTING THIS LICENSE AGREEMENT </p>
                            <p> In order to use the WMMS, you must first agree to this License Agreement. You may not use the WMMS if you do not accept this License Agreement. You can accept this License Agreement by (A) clicking to accept or agree to this License Agreement, where this option is made available to you; or (B) by actually downloading and/or using the WMMS, in which case you agree that use of the WMMS constitutes acceptance of the Licensing Agreement from that point onwards. If you are agreeing to be bound by this License Agreement on behalf of your employer or other entity, you represent and warrant that you have full legal authority to bind your employer or such entity to this License Agreement. If you do not have the requisite authority, you may not accept the Licensing Agreement or use the WMMS on behalf of your employer or other entity. </p>
                            <p>3. WMMS LICENSE FROM LACFCD</p>
                            <p> Subject to the terms of this License Agreement, LACFCD grants you a limited, royalty-free, non-assignable and non-exclusive license to use the WMMS for stormwater management. You agree that LACFCD owns all legal right, title and interest in and to the WMMS, including any Intellectual Property Rights, if any, that subsist in the WMMS. "Intellectual Property Rights" means any and all rights under patent law, copyright law, trade secret law, trademark law, and any and all other proprietary rights. No license, express or implied, by estoppel or otherwise, to any Intellectual Property Rights is granted by this document. LACFCD reserves all rights not expressly granted to you. LACFCD may make changes to WMMS and the associated User Manual at any time, without notice.

                            You may not copy (except for backup purposes), modify, adapt, redistribute, decompile, reverse engineer, disassemble, or create derivative works of the WMMS or any part of the WMMS without LACFCD's written consent.

                            You agree that the form and nature of the WMMS and the associated User Manual that LACFCD makes available to the public may change without prior notice to you and that future versions of the WMMS may be incompatible with your use or on your computer system. You agree that LACFCD may stop (permanently or temporarily) providing the WMMS (or any features within the WMMS) to you or to users generally at LACFCD's sole discretion, without prior notice to you.

                            Nothing in this License Agreement gives you a right to use any of LACFCD's trade names, trademarks, service marks, logos, domain names, or other distinctive features, including for the names Los Angeles County Watershed Management Modeling System, WMMS, Los Angeles County Flood Control District, and LACFCD.

                            You agree that you will not remove, obscure, or alter any proprietary rights notices (including copyright and trademark notices) that may be affixed to or contained within the WMMS or the accompanying User Manual. </p>
                            <p>4. USE OF THE WMMS BY YOU </p>
                            <p> You agree that you will not engage in any activity with the WMMS, including the development or distribution of an application, that interferes with, disrupts, damages, or accesses in an unauthorized manner the servers, networks, or other properties or services of any third party including, but not limited to, the LACFCD.

                            You agree that you are solely responsible for (and that LACFCD has no responsibility to you or to any third party for) any data, content, or resources that you create, transmit or display through WMMS, any breach of your obligations under this License Agreement, any applicable third party contract or Terms of Service, or any applicable law or regulation, and for the consequences (including any loss or damage which LACFCD or any third party may suffer) of any such actions or breach.</p>
                            <p>5. TERMINATING THIS LICENSE AGREEMENT </p>
                            <p> This License Agreement will continue to apply until terminated by either you or LACFCD. If you want to terminate this License Agreement, you may do so by ceasing your use of the WMMS. LACFCD may at any time, terminate this License Agreement with you if: (A) you have breached any provision of this License Agreement; or (B) LACFCD is required to do so by law; or (C) LACFCD decides to no longer provide, in it's sole discretion, the WMMS or certain parts of the WMMS to users. When this License Agreement comes to an end, all of the legal rights, obligations and liabilities that you and LACFCD have benefited from, been subject to (or which have accrued over time whilst this License Agreement has been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and the provisions of Sections 6, 7, 8 and 10 shall continue to apply to such rights, obligations and liabilities indefinitely.</p>
                            <p>6. DISCLAIMER OF WARRANTIES </p>
                            <p> YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE WMMS IS AT YOUR SOLE RISK AND THAT THE WMMS IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND FROM LACFCD.

                            YOUR USE OF THE WMMS AND ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WMMS IS AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH USE.

                            LACFCD FURTHER EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
                            <p>7. LIMITATION OF LIABILITY </p>
                            <p> YOU EXPRESSLY UNDERSTAND AND AGREE THAT LACFCD, THE COUNTY OF LOS ANGELES, AND THEIR RESPECTIVE BOARDS, OFFICERS, EMPLOYEES, AGENTS, SUBSIDIARIES, SUCCESSORS, ASSIGNS AND SUB-CONTRACTORS, SHALL NOT BE LIABLE TO YOU UNDER ANY THEORY OF LIABILITY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT MAY BE INCURRED BY YOU, INCLUDING FOR ANY LOSS OF DATA, DAMAGE TO YOUR COMPUTER OR COMPUTER SYSTEM, FAILURE TO MEET ANY STATE OR FEDERAL REQUIREMENTS UNDER ANY FEDERAL OR STATE LAW, INCLUDING, BUT NOT LIMITED TO THE CLEAN WATER ACT AND THE PORTER-COLOGNE WATER QUALITY CONTROL ACT, OR FOR ANY DAMAGE RESULTING FROM ANY ASSISTANCE LACFCD AND/OR COUNTY OF LOS ANGELES EMPLOYEES MAY PROVIDE YOU ON WMMS.</p>
                            <p>8. INDEMNIFICATION</p>
                            <p> To the maximum extent permitted by law, you agree to defend, indemnify and hold harmless LACFCD, Los Angeles County, and their respective boards, officers, employees, agents, subsidiaries, successors, assigns and sub-contractors, from and against any and all claims, actions, suits or proceedings, as well as any and all losses, liabilities, damages, costs and expenses (including reasonable attorneys fees) arising out of or accruing from (a) your use of the WMMS, (b) the User Manual for WMMS, and (c) any non-compliance by you with this License Agreement.</p>
                            <p>9. CHANGES TO THE LICENSE AGREEMENT </p>
                            <p> LACFCD may make changes to the License Agreement as it distributes new versions of the WMMS. When these changes are made, LACFCD will make a new version of the License Agreement available on the website where the WMMS and the User Manual is made available.</p>
                            <p>10. GENERAL LEGAL TERMS </p>
                            <p> This License Agreement constitutes the whole legal agreement between you and LACFCD and govern your use of the WMMS, the User Manual for WMMS, any assistance you may receive from LACFCD and/or Los Angeles County employees in using WMMS, and completely replaces any prior agreements, if any, between you and LACFCD in relation to the WMMS.

                            You agree that if LACFCD does not exercise or enforce any legal right or remedy which is contained in this License Agreement (or which LACFCD has the benefit of under any applicable law), this will not be taken to be a formal waiver of LACFCD's rights and that those rights or remedies will still be available to LACFCD.

                            If any court of law, having the jurisdiction to decide on this matter, rules that any provision of this License Agreement is invalid, then that provision will be removed from this License Agreement without affecting the rest of this License Agreement. The remaining provisions of this License Agreement will continue to be valid and enforceable.

                            The rights granted in this License Agreement may not be assigned or transferred by either you or LACFCD without the prior written approval of the other party. Neither you nor LACFCD shall be permitted to delegate their responsibilities or obligations under this License Agreement without the prior written approval of the other party.

                            This License Agreement, and your relationship with LACFCD under this License Agreement, shall be governed by the laws of the State of California without regard to its conflict of laws provisions. You and LACFCD agree to submit to the exclusive jurisdiction of the courts located within the county of Los Angeles, California to resolve any legal matter arising from this License Agreement. Notwithstanding this, you agree that LACFCD shall still be allowed to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction. </p>
                            
                        </div>
                        <div className="downloads-accept-button">
                            <div className="dark-blue-disabled-btn ">
                                <button className="ui button scenario-btn ui wmms-dark-blue-btn button" onClick={() => { classContext.disclaimerAgree() }}>I Agree</button>
                            </div>
                        </div>
                        <div className="downloads-bottom-padding"></div>
                    </div>
                }

                {classContext.state.displayDisclaimer == false &&
                    <div className="cardsSlideUp">
                        <div className="side-panel-subtext">
                            <p>WELCOME to the WMMS 2.0 Model and Timeseries Repository. Contained below in the four available panels (Documents, Spatial Data, LSPC, SUSTAIN) are a number of files necessary to execute the WMMS 2.0 Model Framework. </p>
                            <p>Throughout the WMMS 2.0 web interface, user documents, land use datasets, precipitation boundary conditions, and other files are intentionally made available for download to support key functionality. This Model and Timeseries Repository was developed to contain all of this broadly provided data in a convenient singular download location. Click any of the colored panel headers below to navigate the Repository. </p>
                        </div>
                        <div className="downloads-graphic">
                            <div>
                                <img className="scaleImg" id="repo graphic" src={repoGraphic} alt="wmms model repo" />
                            </div>
                        </div>
                        <div>
                            <div className="ui pointing secondary menu">
                                <a className={'download-subheader item doc ' + (classContext.state.activeTab == "DOCUMENTS" ? 'active' : '')} onClick={() => classContext.clickTab("DOCUMENTS")}>Documents</a>
                                <a className={'download-subheader item spatial ' + (classContext.state.activeTab == "SPATIAL" ? 'active' : '')} onClick={() => classContext.clickTab("SPATIAL")}>Spatial Data</a>
                                <a className={'download-subheader item lspc ' + (classContext.state.activeTab == "LSPC" ? 'active' : '')} onClick={() => classContext.clickTab("LSPC")}>LSPC</a>
                                <a className={'download-subheader item sustain ' + (classContext.state.activeTab == "SUSTAIN" ? 'active' : '')} onClick={() => classContext.clickTab("SUSTAIN")}>SUSTAIN</a>
                            </div>
                            <div className={'ui segment tab ' + (classContext.state.activeTab == "DOCUMENTS" ? 'active' : '')}>
                                <DownloadDocument tooltipFactory={this.props.tooltipFactory} tooltipsReady={this.props.tooltipsReady} />
                            </div>
                            <div className={'ui segment tab ' + (classContext.state.activeTab == "SPATIAL" ? 'active' : '')}>
                                <DownloadSpatial tooltipFactory={this.props.tooltipFactory} tooltipsReady={this.props.tooltipsReady} />
                            </div>
                            <div className={'ui segment tab ' + (classContext.state.activeTab == "LSPC" ? 'active' : '')}>
                                <DownloadLspc tooltipFactory={this.props.tooltipFactory} tooltipsReady={this.props.tooltipsReady} />
                            </div>
                            <div className={'ui segment tab ' + (classContext.state.activeTab == "SUSTAIN" ? 'active' : '')}>
                                <DownloadSustain tooltipFactory={this.props.tooltipFactory} tooltipsReady={this.props.tooltipsReady} />
                            </div>
                        </div>
                    </div>
                }
                </div>
            </div>
        );

    }
}