import { BaseModel } from "../interfaces/IBaseModel";

export class optimizationupload extends BaseModel {

    "zipfilename": string;
    "zipfiletype": string;
    "zipfileblob": Blob;

    constructor() {
        super('optimizationviewer');
    }
}  