import React, { Component } from "react";
import "./optimization.css";
import { INavHistory } from "../../interfaces/INavHistory";
import ScenarioViewer from "./ScenarioViewer/scenarioViewer";
import { Button, Label, Icon } from "semantic-ui-react";
import { AxiosResponse } from "axios";
import { optimizationupload } from "../../models/optimizationupload";
import { optimizationsummary } from "../../models/optimizationsummary";
import { eventNames } from "cluster";
import ComparisonTable from "./ComparisonTable/comparisonTable";
import { TooltipModal } from "../toolTipModal/toolTipModal";
import AlertComponent from "../AlertComponent/alertComponent";
import { alertPropsModel } from "../../models/alertPropsModel";
import logo from '../../images/wmms-opt.png';
import DownloadLink from "../../core/downloadLink/downloadLink";
import Config from "../../config/Config";
import HrefLink from "../../core/hrefLink/hrefLink";
import FileDragAndDrop from "../../core/fileDragAndDrop/FileDragAndDrop";

export class OptimizationState {
    scenarios: Array<optimizationsummary> = new Array<optimizationsummary>();
    scenarioCount: number = 0;
    zipfilename: string = "No selection";
    zipfile: File = null;
    isLoadingScenario: boolean = false;
    compareTableData: Array<any> = null;
    isComparing: boolean = false;
    batchData: any = [];
    planData: any = [];
    alertIsOpen: boolean = false; 
    alertConfguration: alertPropsModel = new alertPropsModel();
}

export default class Optimization extends Component<INavHistory> {
    state = new OptimizationState();

    componentDidMount() {
        this.addSample();
    }

    handleItemUpdate(evt, typestr) {
        let lbl = document.getElementById(typestr + 'uploadlabel');
        if (evt.target.files.length > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
            this.setState({ [typestr + 'filename']: evt.target.files[0].name, [typestr + 'file']: evt.target.files[0] });
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
            this.setState({ [typestr + 'filename']: 'None Selected', file: null });
        }
    }

    addSample() {
        let classContext: Optimization = this;
        if (classContext.state.scenarioCount == 0 && !classContext.state.isLoadingScenario) {

            let arr: Array<any> = classContext.state.scenarios;

            classContext.setState({
                isLoadingScenario: true
            });

            let form = new FormData();
            form.append("sample", "true");

            optimizationupload.axiosPostUpload(form)
            .then((r: AxiosResponse) => {
                let data: optimizationsummary = r.data;
                if (data.errors == null || data.errors.length == 0) {
                    data.visible = true;
                    data.childState = {
                        tableTitle: null,
                        tableFactor: null,
                        tableData: null
                    };
                    data.key = this.state.scenarios.length;
                    arr.push(data);
                    let cnt: number = classContext.state.scenarioCount + 1;
                    classContext.setState({
                        scenarios: arr,
                        isLoadingScenario: false,
                        scenarioCount: cnt
                    }, () => {
                        // this scrolls to the newly added scenario
                            //document.getElementById(`scenario-viewer-${this.state.scenarioCount - 1}`).scrollIntoView({ behavior: "smooth" });
                    });
                } else {

                    let alertProps: alertPropsModel = {
                        header: data.errors.join("; "),
                        body: '',
                        color: 'warning',
                        size: 'small-alert',
                        className: '',
                        context: this,
                        closeBtn: true,
                        setTimeOut: true,
                        timeOutTime: 4000
                    };
                    this.setState({ alertConfguration: alertProps, isLoadingScenario: false }, () => {
                        this.setState({ alertIsOpen: true });
                    });

                    // alert(data.errors.join("; "));
                }
            })
            .catch((r: AxiosResponse) => {
                //console.log('There was an issue adding that senario', r);
                this.setState({ isLoadingScenario: false });
                let alertProps: alertPropsModel = {
                    header: 'There was an issue adding that senario',
                    body: '',
                    color: 'warning',
                    size: 'small-alert',
                    className: '',
                    context: this,
                    closeBtn: true,
                    setTimeOut: true,
                    timeOutTime: 4000
                };
                this.setState({ alertConfguration: alertProps }, () => {
                    this.setState({ alertIsOpen: true });
                });
            })
        }
    }

    fileDragDropUpload = (files: Array<any>) => {
        let classContext: Optimization = this;

        if (files != undefined && files.length == 1) {
            classContext.setState({ ['zipfilename']: files[0].name, ['zipfile']: files[0] }, () => {
                classContext.addScenarioClick();
            });
        }
    }

    addScenarioClick() {
        if (this.state.zipfile == null) {
            let alertProps: alertPropsModel = {
                header: 'Please select a file',
                body: '',
                color: 'warning',
                size: 'small-alert',
                className: '',
                context: this,
                closeBtn: true,
                setTimeOut: true,
                timeOutTime: 2500
            };
            this.setState({ alertConfguration: alertProps }, () => {
                this.setState({ alertIsOpen: true });
            });
   
        } else {
            let classContext: Optimization = this;
            if (classContext.state.scenarioCount >= 3) {
                // alert("You've reached the maximum of 3 scenarios!");

                let alertProps: alertPropsModel = {
                    header: "You've reached the maximum of 3 scenarios!",
                    body: '',
                    color: 'warning',
                    size: 'small-alert',
                    className: '',
                    context: this,
                    closeBtn: true,
                    setTimeOut: true,
                    timeOutTime: 2500
                };
                this.setState({ alertConfguration: alertProps }, () => {
                    this.setState({ alertIsOpen: true });
                });

                
            } else  if (!this.state.isLoadingScenario) {
                let arr: Array<any> = classContext.state.scenarios;

                classContext.setState({ 
                    isLoadingScenario: true
                });

                let form = new FormData();
                form.append("data", this.state.zipfile);

                optimizationupload.axiosPostUpload(form)
                    .then((r: AxiosResponse) => {
                        let data: optimizationsummary = r.data;
                        if (data.errors == null || data.errors.length == 0) {
                            data.visible = true;
                            data.childState = {
                                tableTitle: null,
                                tableFactor: null,
                                tableData: null
                            };
                            data.key = this.state.scenarios.length;
                            arr.push(data);
                            let cnt: number = classContext.state.scenarioCount + 1;
                            classContext.setState({ 
                                scenarios: arr,
                                isLoadingScenario: false,
                                scenarioCount: cnt
                             }, () => {
                                 // this scrolls to the newly added scenario
                                    document.getElementById(`scenario-viewer-${this.state.scenarioCount - 1}`).scrollIntoView({ behavior: "smooth" });
                             });
                        } else {

                            let alertProps: alertPropsModel = {
                                header: data.errors.join("; "),
                                body: '',
                                color: 'warning',
                                size: 'small-alert',
                                className: '',
                                context: this,
                                closeBtn: true,
                                setTimeOut: true,
                                timeOutTime: 4000
                            };
                            this.setState({ alertConfguration: alertProps, isLoadingScenario: false }, () => {
                                this.setState({ alertIsOpen: true });
                            });

                            // alert(data.errors.join("; "));
                        }
                    })
                    .catch((r: AxiosResponse) => {
                        //console.log('There was an issue adding that senario', r);
                        this.setState({ isLoadingScenario: false });
                        let alertProps: alertPropsModel = {
                            header: 'There was an issue adding that senario',
                            body: '',
                            color: 'warning',
                            size: 'small-alert',
                            className: '',
                            context: this,
                            closeBtn: true,
                            setTimeOut: true,
                            timeOutTime: 4000
                        };
                        this.setState({ alertConfguration: alertProps }, () => {
                            this.setState({ alertIsOpen: true });
                        });
                    })
            }
        }
    }

    removeScenario(evt, data) {
        let classContext: Optimization = this;

        let scenToRemove = data.id.split("-")[2];

        // toggle visibility of matching scenario
        classContext.state.scenarios.forEach((scen, i) => {
            if (scen.key == scenToRemove) {
                let arr: Array<any> = classContext.state.scenarios;
                arr[scenToRemove].visible = false;
                classContext.setState({ scenarios: arr });
            }
        });

        // add hidden class to matching scenario
        document.getElementById(`scenario-viewer-${scenToRemove}`).classList.toggle("hidden");
        let cnt: number = classContext.state.scenarioCount - 1;
        classContext.setState({ scenarioCount: cnt });
    }

    compareScenarios() {
        let compareScens = [];
        this.state.scenarios.forEach((scen, index) => {
            if (scen.visible) {
                scen.childState.batchData = this.state.batchData[index]
                scen.childState.planData = this.state.planData[index]
                compareScens.push(scen.childState)
            }
        });
        document.getElementById("scenario-container").classList.toggle("hidden");
        this.setState({ 
            compareTableData: compareScens,
            isComparing: true
        });
    }

    handleCompareBtnClick = (evt) => {
        let splitId = evt.target.id.split("-");
        let clickedBtn = splitId[0];
        let btnId = splitId[2];
        let activeScens = this.state.scenarios.filter(x => x.visible);
        let matchingScen = activeScens[btnId].key;
    }

    closeCompare() {
        document.getElementById("scenario-container").classList.toggle("hidden");
        this.setState({ isComparing: false });
    }
    
    updateScenarioStates = (currentState) => {
        if (this.state != null) {

            let didUpdate = -1;

            this.state.scenarios.forEach(scen => {
                if (scen.key == currentState.key) {
                    if (scen.childState.tableTitle !== currentState.tableTitle || scen.childState.tableFactor !== currentState.tableFactor || scen.childState.tableData !== currentState.tableData) {
                        didUpdate = scen.key;
                    }
                }
            });

            if (didUpdate > -1) {
                let arr: Array<any> = this.state.scenarios;
                arr[didUpdate].childState.tableTitle = currentState.tableTitle;
                arr[didUpdate].childState.tableFactor = currentState.tableFactor;
                arr[didUpdate].childState.tableData = currentState.tableData;
                this.setState({ scenarios: arr });
            };
        }
    }
    
    getScenarioJSX = (): any => {
        return this.state.scenarios.map(m => {return {scenariodata: m}}).map((props: any, i: number) => {
            return <div key={props.scenariodata.key} id={`scenario-viewer-${props.scenariodata.key}`} className="scenario-viewer">
                <div className="remove-btn-div">
                    <Button id={`remove-btn-${props.scenariodata.key}`} className="remove-btn" onClick={(evt, data) => this.removeScenario(evt, data)} title="Remove Scenario"><Icon name='x' /></Button>
                </div>
                <ScenarioViewer  indexValue={i} setBatchData={this.setBatchData} setPlanData={this.setPlanData} onStateUpdate={this.updateScenarioStates} {...props} tooltipFactory={this.props.tooltipFactory}
                    tooltipsReady={this.props.tooltipsReady}></ScenarioViewer>
                <hr className="optimizationHr"/>
            </div>
        });
    }

    setBatchData = (data, index) => {
        let classContext: Optimization = this;
        let array = classContext.state.batchData.slice(0);
        array[index] = data;

        classContext.setState({batchData: array});

    };

    setPlanData = (data, index) => {
        let classContext: Optimization = this;
        let array = classContext.state.planData.slice(0);
        array[index] = data;

        classContext.setState({planData: array});
    };

    render() {
        let classContext: Optimization = this;

        return (
            <div className="optimization-container">
                <FileDragAndDrop onUpload={classContext.fileDragDropUpload} count={1} formats={[".crz"]} >
                {
                    this.state.alertIsOpen &&
                    <AlertComponent 
                            // ref={this.childAlert} 
                            header={this.state.alertConfguration.header}
                            body={this.state.alertConfguration.body}
                            color={this.state.alertConfguration.color}
                            size={this.state.alertConfguration.size}
                            className={this.state.alertConfguration.className}
                            context={this.state.alertConfguration.context}
                            closeBtn={this.state.alertConfguration.context}
                            setTimeOut={this.state.alertConfguration.setTimeOut}
                            timeOutTime={this.state.alertConfguration.timeOutTime}
                     />
                }
                <div className="optimization-inner-container">
                    <div className="optimization-top">
                        <div className="">
                            <div className="opt-header-div wmms-blue-header">
                                <img className="mainLogo" src={logo} alt="wmms2Logo" />
                            </div>
                            <p className="side-panel-subtext">
                                WELCOME to the Optimization Viewer. This viewer renders watershed-scale optimization outputs from the Two-tiered Optimization Utility. These optimization curves are the culmination of the WMMS 2.0 Optimization Framework; they display the cost-optimized strategies to achieve pollutant or flow reductions based on the provided BMP menu and reduction targets. For additional context on the optimization utilities used to develop these curves, reference the <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={38} linkText="Optimization Utilities User Document" filename="OptimizationUtilitiesUserDocument.pdf" mimetype="application/pdf" bold={false} wmmsColor={'sustain'} />.
                                <br />
                                <br />
                                To illustrate the functionality of the Optimization Viewer, sample data has been pre-loaded below. For context, this data was developed from BMPs characterized in the <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={56} linkText="BMP Opportunities Utility Tutorial" filename="User_Package.zip" mimetype="application/x-zip-compressed" bold={false} wmmsColor={'sustain'} />. A schematic has also been generated to describe functions. <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pl-point5'} className={""} modalSize={'large'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer9.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer9.body : 'Tooltip data is still loading please try again in a moment'} />
                            </p>
                        </div>
                    </div>
                    <div className="sticky">

                        <div className="blue-hr-top-sticky">
                            {/* here for styles */}
                        </div>
                        <div className="grey-header-container-sticky effect7-sticky">
                            <div className="optimization-main-menu">    
                                <div className="inner-optimization-main-menu">
                                    <div className="upload-scenarios">
                                        <div className='upload-scenarios-label'>
                                            <p>Select a scenario to upload:</p>
                                        </div>
                                        <div className=''>
                                            <div className='padding-bottom-point5'>File Name:<b> {classContext.state.zipfilename}</b></div>
                                            <label className="upload-basic-btn" htmlFor='zipupload'><Label className="upload-basic-btn" color='red' basic id='zipuploadlabel'>Click here to select a .crz file.</Label></label>
                                            <input id='zipupload' type="file" accept=".crz" onChange={(evt) => classContext.handleItemUpdate(evt, 'zip')} className="hidden" />
                                        </div>
                                    <div className='btn-w-tooltip-div dark-blue-disabled-btn'>
                                            <Button animated='fade'
                                                loading={this.state.isLoadingScenario} 
                                                disabled={this.state.isComparing}
                                                className='scenario-btn ui wmms-dark-blue-btn button' 
                                                onClick={() => this.addScenarioClick()}>
                                                <Button.Content visible>Add to Viewer</Button.Content>
                                                <Button.Content hidden style={{display: "inline"}} ><Icon name='eye' /></Button.Content>
                                            </Button>
                                            <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer1.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer1.body : 'Tooltip data is still loading please try again in a moment'} />
                                        </div>
                                    <div className='btn-w-tooltip-div dark-blue-disabled-btn'>
                                            <Button 
                                                disabled={this.state.scenarioCount <= 1 || this.state.isLoadingScenario || this.state.isComparing}
                                                className="scenario-btn wmms-dark-blue-btn"
                                                onClick={() => this.compareScenarios()}>Compare Curve Slices
                                            </Button>    
                                            <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer2.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer2.body : 'Tooltip data is still loading please try again in a moment'} />               
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="blue-hr-bottom-sticky">
                            {/* here for styles */}
                        </div>
                    </div>
                    <div id="scenario-container">
                        {this.getScenarioJSX()}
                    </div>
                    {
                        this.state.isComparing &&
                            <div id="table-container">
                                <div className="close-compare-btn-div">
                                    <div>
                                        {/* here for style purposes */}
                                    </div>
                                    <div>
                                        <Button className="close-compare-btn button compare-back-btn" onClick={() => this.closeCompare()}><Icon name='x' title="Close Comparison" /></Button>
                                    </div>
                                </div> 
                            <ComparisonTable data={this.state.compareTableData} handleClicks={(evt) => this.handleCompareBtnClick(evt)} tooltipFactory={this.props.tooltipFactory}
                                tooltipsReady={this.props.tooltipsReady} updateColorFunction={null}></ComparisonTable>
                            </div>
                    }
                </div>
                </FileDragAndDrop>
            </div>
        );
    }
}