import React, { useState } from "react";
import { useEffect } from "react";
import { Table } from "semantic-ui-react";

// need a few properties:
// data array
// optional field display name prop; otherwise use data prop name directly
// list of properties displayed (in order of display)
// sort (bool or array of fields to sortable)
// click even listener (bool or array of fields)

export interface DataTableProps{
  tableData: any;
  columnArr?: string[];
  headerNames?: string[];
  valuePostpend?: string[];
  numberRoundLength?: number[]; // works with sort type
  headerBackgroundColor: string;
  headerTextColor: string;
  sortable?: boolean;
  celled?: boolean;
  fixed?: boolean;
  striped?: boolean;
  columnSortType?: Array<'text'|'number'>;
}


export const DataTable: React.FC<DataTableProps> = ({tableData, columnArr, headerNames, headerBackgroundColor, headerTextColor, sortable, celled, fixed, striped, columnSortType, valuePostpend, numberRoundLength}) => {

    const [sortTable, dispatch] = React.useReducer(exampleReducer, {
        column: null,
        data: tableData,
        direction: null,
      })
    const { column, data, direction } = sortTable

    const [headerArr, setHeaderArr] = useState<string[]>(new Array<string>())
    const [dataArr, setDataArr] = useState<string[]>(new Array<string>())

    useEffect(() =>{
      let columnNames = [];
      if(columnArr){
        columnNames = columnArr;
      } else {
        if(tableData){
          for (var prop in tableData[0]) {
            if (Object.prototype.hasOwnProperty.call(tableData[0], prop)) {
              columnNames.push(prop);
            }
          }
        }
      }

      //console.log('set dataarr eff');
      setDataArr(columnNames);

      let hdrNames = [];
      if(headerNames){
        hdrNames = headerNames;
      } else {
        if(columnNames){
          hdrNames = columnNames;
        }
      }

      setHeaderArr(hdrNames);

      dispatch({ type: 'RESET', column: '' })

    }, [tableData, columnArr, headerNames])

    function exampleReducer(state, action) {
        switch (action.type) {
          case 'CHANGE_SORT':
            if (state.column === action.column) {
              return {
                ...state,
                data: state.data.slice().reverse(),
                direction:
                  state.direction === 'ascending' ? 'descending' : 'ascending',
              }
            }

            let newData = [];
            //console.log('change sort', state, action.column);
            if(columnSortType && columnArr){
              // get array index
              let idx = -1;
              columnArr.map((d, i) => {
                if(d == action.column){idx = i;}
              })
              //console.log('idx', idx, tableData)
              // then look into columnSortType for sorting type text vs number
              let sortType = columnSortType[idx];
              if(sortType === 'text'){
                newData = tableData.sort(function(a: any, b: any){
                  if (a[action.column] !== null && b[action.column] === null){return -1;}
                  if (a[action.column] === null && b[action.column] !== null){return 1;}
                  if (a[action.column].toLowerCase() < b[action.column].toLowerCase()){return -1;}
                  if (a[action.column].toLowerCase() > b[action.column].toLowerCase()){return 1;}
                  return 0;
                });
              } else {
                newData = tableData.sort(function(a: any, b: any){return a[action.column] - b[action.column];});
              }
            } else {
              newData = tableData.sort(function(a: any, b: any){return a[action.column] - b[action.column];});
            }
      
            return {
              column: action.column,
              data: newData,
              direction: 'ascending',
            }
          case 'RESET':
            return {
              column: null,
              data: tableData,
              direction: null,
            }
          default:
            throw new Error()
        }
      }

    const formatData = (val: any, idx: number): string =>{
      let display = '';
      //data[dataName] + ' ' +( valuePostpend && valuePostpend[idx].length >0? valuePostpend[idx]: '')
      if(val === null){return display;}
      if(columnSortType && columnSortType[idx] === 'number' && numberRoundLength && numberRoundLength[idx] > 0 && val){
        display = display + val.toFixed(numberRoundLength[idx]);
      } else {
        display = display + val;
      }

      if(valuePostpend && valuePostpend[idx].length > 0){ display = display + ' ' + valuePostpend[idx]; }

      return display;
    }

    return (
        <div className=''>
            <Table sortable={sortable === true} celled={celled===true} fixed={fixed=== true} striped={striped === true}>
            <Table.Header>
                <Table.Row >
                    {dataArr.length > 0 && dataArr.map((dataName, i) =>(
                        <Table.HeaderCell
                            key={dataName}
                            sorted={column === dataName ? direction : null}
                            onClick={() => {
                              if(sortable === true) {dispatch({ type: 'CHANGE_SORT', column: dataName })}}}
                            style={{backgroundColor: headerBackgroundColor, color: headerTextColor }}
                        >
                            {headerArr[i]}
                        </Table.HeaderCell>
                    ))}
                    {dataArr.length == 0 && <Table.HeaderCell>No data</Table.HeaderCell>}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.length > 0 && data.map((data, index) => (
                <Table.Row key={data.id}>
                    {dataArr.length > 0 && dataArr.map((dataName, idx) =>(
                        <Table.Cell>{formatData(data[dataName], idx)}</Table.Cell>
                    ))}
                    {dataArr.length == 0 && <Table.Cell>No data</Table.Cell>}
                </Table.Row>
                ))}
            </Table.Body>
            </Table>
          </div>
    )
}