import React, { Component } from "react";
import IAreasProps from "../interfaces/IAreasProps";
import Area from "./Area";

export class AreasState {

}

export default class Areas extends Component<IAreasProps> {

  state = new AreasState();

  // NOTE: order matters! areas will stack with first created in the back to last created in the front. if not sorted properly, they will obscure one another

  render() {
      const { crcData, yScaleLeft, yScaleRight, xScale, height, bmpGroupInfo, colors } = this.props;

      const allProps = [];
      
      if (crcData != undefined){

        bmpGroupInfo.forEach((x, i) => {

          const areaProps = {
            data: crcData[x.groupName],
            yScale: yScaleLeft,
            xScale,
            height,
            fill: colors[x.groupName],
            color: colors[x.groupName],
            key: x.groupName,
            maxY: null //crcData[x.groupName][crcData[x.groupName].length - 1].y // assumes last option will have highest ac-ft value, which should be the case. can do additional sorting logic if needed.
          }

          allProps.push(areaProps);
        })
      }
      
      const areas = allProps.map(props => {
        return <Area key={props.key} {...props} />;
      })

      return <g className="areas">{areas}</g>;
    } 
}