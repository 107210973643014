import { BaseModel } from "../interfaces/IBaseModel";

export class networkviewer extends BaseModel {

    "assessmentPointId": string;
    "gageId": string;
    "data": Array<networkApData>;
    //"email": string

    constructor() {
        super('networkviewer');
    }
}  

export class networkApData {
    "simulation": string;
    "event": string;
    "outFile": string;
    "preFile": string;
}