import React, { Component } from "react";
import './wrampsutil.css';
import { INavHistory } from "../../../interfaces/INavHistory";
import { Grid, Icon, Message, Button, Label, Dropdown, Select } from "semantic-ui-react";
import Config from "../../../config/Config";
import { IUtilityCard } from "../../../interfaces/IUtilityCard";
import { wramps } from "../../../models/wramps";
import { AxiosResponse } from "axios";
import { processviewmodel } from "../../../models/processviewmodel";
import { setInterval } from "timers";
import { WrampsWatershedGroup } from "../../../models/wrampsWatershedGroup";
import DownloadLink from "../../../core/downloadLink/downloadLink";
import HrefLink from "../../../core/hrefLink/hrefLink";

export class WrampsUtilState {
    pageObj: wramps = new wramps();
    filename: string = "No selection";
    file: File = null;
    wmg: number = 0;
    watershedGroups: WrampsWatershedGroup[] = new Array<WrampsWatershedGroup>();
    wsgOptions = [];

    processing: boolean = false;
    processStatus: string = "";
    processMessage: string = "";
}

export default class WrampsUtil extends Component<IUtilityCard> {

    state = new WrampsUtilState;
    interval: NodeJS.Timeout;

    uploadFile() {
        // error list
        let list= new Array<string>();
        if (this.state.file == null) {
            list.push("No file selected");
            this.handleErrors(list);
        } else {
            let classContext: WrampsUtil = this;
            classContext.setState({ processing: true });
            this.handleErrors(new Array<string>());
            let form = new FormData();

            let wrampsutil = new wramps();
            wrampsutil.fileblob = this.state.file;
            wrampsutil.filename = this.state.filename;
            wrampsutil.filetype = "xlsx";
            //form.append("attachmentData", JSON.stringify(wrampsutil));

            form.append("data", this.state.file);

            //if (classContext.state.pageObj.watershedGroupName != null && classContext.state.pageObj.watershedGroupName.length > 0) {
            //    form.append("watershedGroupd", classContext.state.pageObj.watershedGroupName);
            //} else {
            //    let list = new Array<string>();
            //    list.push("Please select a watershed group");
            //    classContext.handleErrors(list);
            //    classContext.setState({ processing: false });
            //    return;
            //}

            wramps.axiosPostUpload(form).then((r: AxiosResponse) => {
                classContext.setStatusMessage(r.data.status, r.data.message); // if using status messages
                if (r.data.status.toLowerCase() == "submitted") {
                    let data: processviewmodel = r.data;
                    if (data.queueId > 0) {
                        let id = data.queueId;
                        let intvl = setInterval(() => {
                            processviewmodel.axiosGet(id).then((r: AxiosResponse) => {
                                let data: processviewmodel = r.data;
                                classContext.setStatusMessage(data.status, data.message); // if using status messages
                                if (data.status != undefined && data.status.toLowerCase() == "completed") {
                                    classContext.props.stopInterval();
                                    if (data.message != null) {
                                        list.push(data.message);
                                        classContext.handleErrors(list);
                                    }
                                    classContext.getResult(data.resultId);
                                } else if (data.status != undefined && data.status.toLowerCase() == "error") {
                                    classContext.props.stopInterval();
                                    classContext.setState({ processing: false });
                                    list.push("Error during processing");
                                    list.push(data.message);
                                    classContext.handleErrors(list);
                                }
                                else {
                                }
                            });
                        }, 2000);
                        classContext.props.beginInterval(intvl);
                    }
                } else if (r.data.status.toLowerCase() == "error") {
                    classContext.setState({ processing: false });
                    list.push("Error during processing");
                    list.push(r.data.message);
                    classContext.handleErrors(list);
                } else {
                    classContext.setState({ processing: false });
                    list.push("Unknown error");
                    classContext.handleErrors(list);
                }
            }).catch(function (error) {
                classContext.setState({ processing: false });
                list.push(error.message);
                classContext.handleErrors(list);
            })
        }
    }

    componentDidMount() {
        //WrampsWatershedGroup.axiosGetAll().then((r: AxiosResponse) => {
        //    if (r.data != null) {
        //        this.setState({ watershedGroups: r.data }, () => {
        //            let wsgOptions = []
        //            this.state.watershedGroups.forEach((group: WrampsWatershedGroup) => {
        //                if (group.active == 1) {
        //                    wsgOptions.push({ key: group.id, text: group.groupName, value: group.internalName });
        //                }
        //            });
        //            this.setState({ wsgOptions: wsgOptions });
        //        });

        //    } else {
        //        //console.log("empty data returned");
        //    }
        //}).catch((e: AxiosResponse) => {
        //    //console.log("Fetch error: " + e);
        //});
    }

    getResult(id: number) {
        let classContext: WrampsUtil = this;
        classContext.setState({ processing: false });
        if (id > 0) {
            window.location.href = Config.api_endpoint + 'Wramps/' + id;
        }
    }

    handleErrors(list) {
        let err = document.getElementById('errorPlace');
        err.classList.remove('hidden');

        let msgs = document.getElementById('errorMessages');
        while (msgs.firstChild) {
            msgs.removeChild(msgs.firstChild);
        }
        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].indexOf(';') > 0) {
                    var smallArr = list[i].split(';');
                    for (var j = 0; j < smallArr.length; j++) {
                        if (smallArr[j].length > 1) {
                            let dv = document.createElement('li');
                            dv.append(smallArr[j])
                            msgs.appendChild(dv);
                        }
                    }
                }
                else {
                    let dv = document.createElement('li');
                    dv.append(list[i])
                    msgs.appendChild(dv);
                }
            }
        } else {
            err.classList.add('hidden');
        }
    }

    handleSelect(data: any) {
        let classContext: WrampsUtil = this;
        let pageObj = Object.assign({}, classContext.state.pageObj);

        // check if input type field exists
        pageObj.watershedGroupName = data.value;

        classContext.setState({ pageObj: pageObj });
    }

    handleItemUpdate(evt) {
        let lbl = document.getElementById('uploadlabel');
        if (evt.target.files.length > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
            this.setState({ ['filename']: evt.target.files[0].name, ['file']: evt.target.files[0] });
            this.handleErrors(new Array<string>());
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
            this.setState({ filename: 'None Selected', file: null });
        }
    }

    setStatusMessage(status: string, message: string) {
        let classContext: WrampsUtil = this;
        if (status != undefined && (status.toLowerCase() == "submitted" || status.toLowerCase() == "queued" || status.toLowerCase() == "processing")) {
            classContext.setState({ processStatus: status, processMessage: message })
        } else {
            classContext.setState({ processStatus: "", processMessage: "" })
        }
    }




    render() {
        let classContext: WrampsUtil = this;
        return (
            <div className="wramps-container scroll-fix">
                <div>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <h2><Icon className={'page-icon wmms-' + this.props.wmmsColor}  name={this.props.cardIcon} circular inverted />{this.props.cardTitle}</h2>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <div>
                                    <b>ATTENTION:</b> This Utility has been specifically developed for use by municipalities with access to the Watershed Reporting, Adaptive Management and Planning System (WRAMPS) database.  Valid login credentials are required for obtaining information on built or planned best management practices (BMPs) stored within WRAMPS that serve as input to this Utility. Individuals without access to WRAMPS can reach out to the lead representative of the appropriate watershed management group to obtain the desired BMP data.
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid divided='vertically'>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    In 2016, WRAMPS launched as a web-based platform for MS4 reporting and is used by a majority of municipalities in Los Angeles County. Since that time, WRAMPS has become the key repository of data regarding existing and planned structural BMPs, storing information for over 10,000 BMPs across the County (as of October 2019). BMP information stored within WRAMPS is organized by municipal jurisdiction and watershed management group and can be used to track progress towards meeting goals defined by the individual watershed management programs.
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                    The WRAMPS Utility translates the contents of a bulk export from the WRAMPS database into a summary spreadsheet which can be uploaded to the BMP Opportunities Utility (the next utility in the Optimization Framework). This process enables existing and planned BMPs to be integrated into the WMMS 2.0 Optimization Framework, such that their impact on downstream hydrology and water quality can be characterized. 
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    <div id="errorPlace" className="hidden">
                                        <Message color='red' onDismiss={() => classContext.handleErrors(new Array<string>())}>
                                            <Message.Header>Processing Messages:</Message.Header>
                                            <Message.List id='errorMessages'>
                                            </Message.List>
                                        </Message>
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.filename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='wrampsupload'><Label className="upload-basic-btn" color='red' basic id='uploadlabel'>Click here to select a .xlsx file.</Label></label>
                                        <input id='wrampsupload' type="file" accept=".xlsx" onChange={(evt) => classContext.handleItemUpdate(evt)} className="hidden" />
                                    </div>
                                </div>

                                {/*<br />

                                <div className="wramps-util-dropdown-h2o">
                                    <div className="wramps-util-dropdown-title">
                                        Watershed Management Group:
                                    </div>
                                    <Dropdown
                                        className="dropdown-element wramps-util-dropdown" 
                                        placeholder='Select a Group'
                                        search
                                        selection
                                        options={this.state.wsgOptions}
                                        onChange={(evt, data) => classContext.handleSelect(data)}
                                    />
                                </div>
                                */}
                                <br />
                                <div className="semantic-teal-disabled-btn">
                                    <Button size='medium' loading={this.state.processing} disabled={this.state.processing} className={"wmms-" + this.props.wmmsColor + "-btn"} onClick={() => this.uploadFile()} >Process</Button>
                                </div>
                                <b>{this.state.processStatus}</b> {this.state.processMessage}
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                    <p>The process description below outlines the necessary steps to successfully execute this utility. For additional details, access the <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={45} linkText="user document" filename="WRAMPSUtilityUserDocument.pdf" mimetype="application/pdf" bold={true}wmmsColor={classContext.props.wmmsColor} />.</p>
                                    <p>1. With the appropriate credentials (username and password), log in to <HrefLink wmmsColor={this.props.wmmsColor} endpointUrl="https://wramps.org/" linkText='WRAMPS ' newTab={true} bold={false} icon='external' /> and access the BMP database to obtain the desired list of existing and/or planned BMPs. Output from the WRAMPS database is in the form of a Microsoft Excel spreadsheet.</p>
                                    <p>2. Upload the WRAMPS database output directly to this utility and select "Process".</p>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>

            </div>
        );
    }
}