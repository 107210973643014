import React, { Component } from "react";
import * as d3 from 'd3';
import ICalloutProps from "../interfaces/ICalloutProps";

export default class Callout extends Component<ICalloutProps> {
    ref: React.RefObject<SVGGElement>;

    constructor(props: ICalloutProps) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidUpdate() {
        const { xvalue, yvalue, xCoord, yCoord, label } = this.props;

        let callout = d3.select(this.ref.current);
        let svg = d3.select("svg");
        if (!yvalue) return callout.style("display", "none");

        callout
            .attr("transform", `translate(${xCoord},${yCoord})`)
            .style("display", null)
            .style("pointer-events", "none")
            .style("font", "12px sans-serif");

        const path = callout.selectAll("path")
            .data([null])
            .join("path")
            .attr("fill", "white")
            .attr("stroke", "black");

        const text = callout.selectAll("text")
            .data([null])
            .join("text")
            .call(text => text
                .selectAll("tspan")
                .data((label + "").split(/\n/))
                .join("tspan")
                .attr("x", 0)
                .attr("y", (d, i) => `${i * 1.1}em`)
                .style("font-weight", (_, i) => i ? null : "bold")
                .text(d => d));

        const { x, y, width: w, height: h } = text.node().getBBox();

        text.attr("transform", `translate(${-w / 2},${8 - y})`);
        path.attr("d", `M${-w / 2 - 5},3H-3l3,-3l3,3H${w / 2 + 5}v${h + 10}h-${w + 10}z`);
    }

    render() {
        return <g data-testid="callout"  ref={this.ref} />;
    }
}