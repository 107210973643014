import { BaseModel } from "../interfaces/IBaseModel";

export class ftable extends BaseModel {

    "filename": string;
    "filetype": string;
    "fileblob": Blob;


    constructor() {
        super('ftable');
    }
}  