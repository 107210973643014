import React, { Component } from "react";
import IAreaProps from "../interfaces/IAreaProps";
import * as d3 from 'd3';

export default class Area extends Component<IAreaProps> {
    ref: React.RefObject<SVGPathElement>;

  constructor(props: IAreaProps) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { data, yScale, xScale, height } = this.props;

    var line = d3.area(data)
    .x(function(d) { return xScale(d.x); })
    .y0(height)
    .y1(function(d) { return yScale(d.y); });

    d3.select(this.ref.current)
    .attr("class", "area")
    .attr("d", line(data))
    .transition();
  }

  componentDidUpdate() {
    const { data, yScale, xScale, height } = this.props;

    var line = d3.area(data)
    .x(function(d) { return xScale(d.x); })
    .y0(height)
    .y1(function(d) { return yScale(d.y); });

    d3.select(this.ref.current)
      .attr("class", "area")
      .attr("d", line(data))
      .transition();
  }

  render() {
    const { fill, color } = this.props;

    return <path fill={fill} stroke={color} strokeWidth="1" ref={this.ref} />;
  }
}