// import { convertUTCDateToLocalDate, getDownloadDateText } from "./DateHelpers";

// export const buildFileDownloadName = (processType: number, description: string, created: Date) => {
//     let name: string = "";
//     let processName: string = description.replace(/[^a-zA-Z0-9 ]/g, "");
//     name = processName.replace(/ /g, '_');
//     if (processType == 1) { name = name + "_Workbook_Inp"; }
//     if (processType == 2) { name = name + "_Mapping_Inp"; }
//     if (processType == 3) { name = name + "_Sustain_Inp"; }
//     if (processType == 4) { name = name + "_ScenDef_Inp"; }
//     let processTime: Date = convertUTCDateToLocalDate(new Date(created.toString()));
//     name = name + "_" + getDownloadDateText(processTime);
//     return name;
// }

export const buildLeftUnit = (loadedGraphType: string, conversionUnit: string, costUnit: string, footprintUnit: string): string =>{
    if(loadedGraphType == "CapacityCost" || loadedGraphType == "CapacityConcentration" || loadedGraphType == "CapacityFootprint"){
        return conversionUnit;
    } else if(loadedGraphType == "CostCapacity"){
        return costUnit;
    } else if(loadedGraphType == "FootprintCost"){
        return footprintUnit;
    } else {
        return "";
    } 
}

export const buildLeftFactor = (loadedGraphType: string, conversionFactor: number, costFactor: number, footprintFactor: number): number =>{
    if(loadedGraphType == "CapacityCost" || loadedGraphType == "CapacityConcentration" || loadedGraphType == "CapacityFootprint"){
        return conversionFactor;
    } else if(loadedGraphType == "CostCapacity"){
        return costFactor;
    } else if(loadedGraphType == "FootprintCost"){
        return footprintFactor;
    } else {
        return 1.0;
    } 
}

export const buildLeftLabel = (loadedGraphType: string): string =>{
    if(loadedGraphType == "CapacityCost" || loadedGraphType == "CapacityConcentration" || loadedGraphType == "CapacityFootprint"){
        return "Capacity";
    } else if(loadedGraphType == "CostCapacity"){
        return "Cost";
    } else if(loadedGraphType == "FootprintCost"){
        return "Footprint";
    } else {
        return "Unknown";
    } 
}

//(classContext.state.loadedGraphType == "CapacityCost"  || classContext.state.loadedGraphType == "CapacityConcentration") ? "" : classContext.state.loadedGraphType == "CostCapacity" ? ".2s" : classContext.state.loadedGraphType == "FootprintCost" ? ".2s" : ""
export const buildLeftLabelFormat = (loadedGraphType: string): string =>{
    if(loadedGraphType == "CostCapacity"){
        return ".2s";
    } else {
        return "";
    } 
}

export const buildRightUnit = (loadedGraphType: string, conversionUnit: string, costUnit: string, footprintUnit: string): string =>{
    if(loadedGraphType == "CapacityCost" || loadedGraphType == "FootprintCost"){
        return costUnit;
    } else if(loadedGraphType == "CostCapacity"){
        return conversionUnit;
    } else if(loadedGraphType == "CapacityFootprint"){
        return footprintUnit;
    }else if(loadedGraphType == "CapacityConcentration"){
        return "mg/l";
    } else {
        return "";
    } 
}

export const buildRightFactor = (loadedGraphType: string, conversionFactor: number, costFactor: number, footprintFactor: number): number =>{
    if(loadedGraphType == "CapacityCost" || loadedGraphType == "FootprintCost"){
        return costFactor;
    } else if(loadedGraphType == "CostCapacity"){
        return conversionFactor;
    } else if(loadedGraphType == "CapacityFootprint"){
        return footprintFactor;
    } else {
        return 1.0; // also for "CapacityConcentration"
    } 
}

export const buildRightLabel = (loadedGraphType: string): string =>{
    if(loadedGraphType == "CapacityCost" || loadedGraphType == "FootprintCost"){
        return "Cost";
    } else if(loadedGraphType == "CostCapacity"){
        return "Capacity";
    } else if(loadedGraphType == "CapacityFootprint"){
        return "Footprint";
    } else if(loadedGraphType == "CapacityConcentration"){
        return "Concentration";
    } else {
        return "Unknown";
    } 
}
//(classContext.state.loadedGraphType == "CapacityCost" || classContext.state.loadedGraphType == "FootprintCost") ? ".2s" : ((classContext.state.loadedGraphType == "CostCapacity" || classContext.state.loadedGraphType == "CapacityConcentration") ? "" : ""),
export const buildRightLabelFormat = (loadedGraphType: string): string =>{
    if(loadedGraphType == "CapacityCost" || loadedGraphType == "FootprintCost"){
        return ".2s";
    } else {
        return "";
    } 
}

export const formatNumber = (data: number, numDecimal: number): string => {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getDateTimeStr = (date: Date): string =>{
    let h = checkTime(date.getHours());
    let m = checkTime(date.getMinutes());
    let s = checkTime(date.getSeconds());
    let d = checkTime(date.getDate());
    let mo = checkTime(date.getMonth() + 1);
    let y = checkTime(date.getFullYear());
    
    return "_" + y + mo + d + "_" + h + m + s;
}

function checkTime(i) {
    return (i < 10) ? "0" + i : i;
}

export const edgleyRound = (num: number): string => {
    if(num >= 100){return num.toFixed(0);}
    else if(num >= 1 && num < 100){return num.toFixed(1);}
    return num.toFixed(2);
}
