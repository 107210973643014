import { BaseModel } from "../../interfaces/IBaseModel";
import { ClimateItem } from "./climateItem";

export class ClimateSummary extends BaseModel {

    "guid": string;
    "errors": string[];
    "items": ClimateItem[];
    "selectedScenarioId": number;
    "isSample": boolean;

    constructor() {
        super('');
    }
}  