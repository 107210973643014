import React, { Component } from "react";
import { INavHistory } from "../../interfaces/INavHistory";
import './network.css';
import { identifier } from "@babel/types";
import NetworkSidePanel from "./NetworkSidePanel/networkSidePanel";
import NetworkBottomPanel from "./NetworkBottomPanel/networkBottomPanel";
import { EsriMap } from "../../factories/mapComponent/EsriMap";
import { MapConfig } from "../../factories/mapComponent/mapTypes/MapConfig";
import { LayerInfo } from "../../factories/mapComponent/mapTypes/LayerInfo";
import { networkApData, networkviewer } from "../../models/networkAP";
import { FeatureInfoResult, FeatureInfoResultItem } from "../../factories/mapComponent/helpers/FeatureInfoResult";
import Config from "../../config/Config";
import { AxiosResponse } from "axios";
import { networktimeseries } from "../../models/networktimeseries";
import { alertPropsModel } from "../../models/alertPropsModel";
import { EsriMapLegend } from "../../factories/mapComponent/EsriMapLegend";

export class NetworkState {
    mainViewToShow: number = 1;
    sidePlanelView: number = 1;
    showBottomPanel: number = 1;
    mapDataLoaded: boolean = false;
    geoJsonDataLoaded: boolean = false;
    apData: networkviewer = new networkviewer();
    firstDropdownArray: Array<any>;
    apAttrib: AssessmentPointAttributes = new AssessmentPointAttributes();
    numFadeOut: boolean = false;
    loading: boolean = false;
    precipMsg: string = "Loading...";
    flagAngle: number = 0;
    alertIsOpen: boolean = false; 
    alertConfguration: alertPropsModel = new alertPropsModel();
    collapseLegend: () => any = null;
}

export class AssessmentPointAttributes {
    SWS_ID: string = "";
    Watershed: string = "";
    SWS_Cnt: string = "";
    Type: string = "";
    Gage_Cnt: string = "";
    Jurished_Cnt: string = "";
    Jurished_ID: string = "";
    PreFile_ID: string = "";
    PreFiles: string = "";
    US_Area: string = "";
    US_Length: string = "";
    WS_Slope: string = "";
    Perc_Imp: string = "";
    Watershed_ID: string = "";
    PrecipNode: string = "";
    AllUS_SWS: string = "";
    AllUS_Pre: string = "";
}

export default class Network extends Component<INavHistory> {

    state = new NetworkState();
    childEsriMapRef = React.createRef<EsriMap>();

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {}


    renderSidePanelView (view, networkContext?: any) {
        if (networkContext) {
            if (this.state !== null && this.state !== undefined) {
                if (this.state.collapseLegend) {
                    this.state.collapseLegend();
                };
            }
            networkContext.setState({ sidePlanelView: view });
        } else {
            if (this.state !== null && this.state !== undefined) {
                if (this.state.collapseLegend) {
                    this.state.collapseLegend();
                };
            }
            this.setState({ sidePlanelView: view });
        };
    };

    renderMainView (view, networkContext?: any) {
        if (view == 1) {
            if (networkContext) {
                networkContext.setState({ mainViewToShow: view});

                setTimeout(() => {  
                    networkContext.setState({ showBottomPanel: view })
                }, 1000);
                
            } else {
                this.setState({ mainViewToShow: view });

                setTimeout(() => {
                    this.setState({ showBottomPanel: view })
                }, 1000);
            };

        } else {

            if (networkContext) {
                networkContext.setState({ mainViewToShow: view, showBottomPanel: view});
            } else {
                this.setState({ mainViewToShow: view, showBottomPanel: view });
            };
        };

    };

    //setAngle() {
    //    if (this.state.flagAngle == 0) {
    //        this.setState({ flagAngle: 87 }, () => {
    //            var layerArr = this.getMapLayers();
    //            let geojson = [];
    //            for (var i = 0; i < layerArr.length; i++) {
    //                if (layerArr[i].layerType == 'geojson') { geojson.push(layerArr[i]); }
    //            }
    //            if (geojson != null && geojson.length > 0) {
    //                this.childEsriMapRef.current.addGEOJSONLayers(geojson);
    //            }
    //        })
    //    }
    //    else {
    //        this.setState({ flagAngle: 0 }, () => {
    //            var layerArr = this.getMapLayers();
    //            let geojson = [];
    //            for (var i = 0; i < layerArr.length; i++) {
    //                if (layerArr[i].layerType == 'geojson') { geojson.push(layerArr[i]);}
    //            }
    //            if (geojson != null && geojson.length > 0) {
    //                this.childEsriMapRef.current.addGEOJSONLayers(geojson);
    //            }
    //        })
    //    }
    //}

    updateAssessmentPointData(data: networkviewer, networkContext?: any) {
        if (networkContext) {

            networkContext.setState({ apData: data });
        } else {
            this.setState({ apData: data });
        };
    };

    populateAPDescription(item: FeatureInfoResultItem): AssessmentPointAttributes{
        let apInfo: AssessmentPointAttributes = new AssessmentPointAttributes();
        console.log('in ap info', item);
        if (item != null) {
            if (item.attributes != null && item.attributes.length > 0) {
                for (var i = 0; i < item.attributes.length; i++) {
                    if (item.attributes[i].name == "SWS_ID") { apInfo.SWS_ID = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "Name") { apInfo.Watershed = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "SWS_Cnt") { apInfo.SWS_Cnt = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "Type") { apInfo.Type = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "Gage_Cnt") { apInfo.Gage_Cnt = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "Jurisd_Cnt") { apInfo.Jurished_Cnt = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "JShed_ID") { apInfo.Jurished_ID = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "PreFile") { apInfo.PreFile_ID = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "PreFiles") { apInfo.PreFiles = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "US_Ac") { apInfo.US_Area = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "RchLen_Mi") { apInfo.US_Length = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "Imperv_Per") { apInfo.Perc_Imp = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "Avg_Slope") { apInfo.WS_Slope = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "Watershed") { apInfo.Watershed_ID = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "PrecipNode") { apInfo.PrecipNode = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "AllUpst_SW") { apInfo.AllUS_SWS = item.attributes[i].value.toString(); }
                    else if (item.attributes[i].name == "AllUpst_Pr") { apInfo.AllUS_Pre = item.attributes[i].value.toString(); }
                }
            } else if (item.attributes != null) {
                console.log("yepp", item.attributes)

                let itemCopy: any = item;

                let featureInfos = [];



                for (let key in item.attributes) {

                    featureInfos.push({
                        name: key,
                        value: item.attributes[key] == null || item.attributes[key] == undefined ? "a" : item.attributes[key]
                    });

                };

                console.log('feature infos built', featureInfos);

                for (var i = 0; i < featureInfos.length; i++) {
                    console.log('issues', featureInfos[i].name);
                    if (featureInfos[i].name == "SWS_ID") { apInfo.SWS_ID = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "Name") { apInfo.Watershed = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "SWS_Cnt") { apInfo.SWS_Cnt = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "Type") { apInfo.Type = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "Gage_Cnt") { apInfo.Gage_Cnt = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "Jurisd_Cnt") { apInfo.Jurished_Cnt = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "JShed_ID") { apInfo.Jurished_ID = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "PreFile") { apInfo.PreFile_ID = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "PreFiles") { apInfo.PreFiles = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "US_Ac") { apInfo.US_Area = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "RchLen_Mi") { apInfo.US_Length = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "Imperv_Per") { apInfo.Perc_Imp = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "Avg_Slope") { apInfo.WS_Slope = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "Watershed") { apInfo.Watershed_ID = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "PrecipNode") { apInfo.PrecipNode = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "AllUpst_SW") { apInfo.AllUS_SWS = featureInfos[i].value.toString(); }
                    else if (featureInfos[i].name == "AllUpst_Pr") { apInfo.AllUS_Pre = featureInfos[i].value.toString(); }
                }

                console.log("party on wayne")


            }
        }
        console.log('ap info res', apInfo);
        return apInfo
    }

    updateLoadingFunction(loading, msg, networkContext?: any) {
        if (networkContext != null) {
            if (loading != null) {
                networkContext.setState({ loading: loading, precipMsg: msg });
            } else {
                networkContext.setState({ precipMsg: msg });
            }
        } else {
            if (loading != null) {
                this.setState({ loading: loading, precipMsg: msg });
            } else {
                this.setState({ precipMsg: msg });
            }
        }
    }

    eventListenerFunction(evt, data, networkClassContext) {
        let resData: Array<FeatureInfoResult> = data;
        console.log('click event', evt, resData);

        let apId: string = "";
        let gageId: string = "";

        if (resData != null && resData.length > 0) {
            console.log('resdata', resData[0]);
            if (resData[0].name == "assessment_points") {
                if (resData[0].features.length == 1) {
                    let apInfo: AssessmentPointAttributes = this.populateAPDescription(resData[0].features[0]);
                    apId = apInfo.SWS_ID;
                    // gageId = apInfo.PreFiles;
                    gageId = apInfo.PreFile_ID;
                    
                    // this handles the bottom panel's table number fade
                    this.setState({ numFadeOut: true }, () => {
                        setTimeout(() => {
                            this.setState({ apAttrib: apInfo, numFadeOut: false  });

                        }, 500);
                    })
                }
                else if (resData[0].features.length == 0) { 
                    // alert("No assessment point selected. Please click on an assessment point."); 

                    let alertProps: alertPropsModel = {
                        header: "No assessment point selected. Please click on an assessment point.",
                        body: '',
                        color: 'warning',
                        size: 'small-alert',
                        className: '',
                        context: this,
                        closeBtn: true,
                        setTimeOut: true,
                        timeOutTime: 4000
                    };
                    this.setState({ alertConfguration: alertProps }, () => {
                        this.setState({ alertIsOpen: true });
                    });
                }
                else if (resData[0].features.length > 1) { 
                    // alert("Multiple assessment points selected. Please select a single assessment point.");

                    let alertProps: alertPropsModel = {
                        header: "Multiple assessment points selected. Please select a single assessment point.",
                        body: '',
                        color: 'warning',
                        size: 'small-alert',
                        className: '',
                        context: this,
                        closeBtn: true,
                        setTimeOut: true,
                        timeOutTime: 4000
                    };
                    this.setState({ alertConfguration: alertProps }, () => {
                        this.setState({ alertIsOpen: true });
                    });
                }
            }
        } else if (data.screenPoint) {
            console.log('screen point', data);
            let features = [];
            data.results.forEach((r) => {
                if (r.graphic.layer.id == "assessmentPoints") {

                    let apInfo:AssessmentPointAttributes = this.populateAPDescription(r.graphic);
                    console.log('after apinfo');
                    let featureInfo = {
                        apId: apInfo.SWS_ID,
                        gageId: apInfo.PreFile_ID
                    };


                    features.push(featureInfo);

                    // this handles the bottom panel's table number fade
                    this.setState({ numFadeOut: true }, () => {
                        setTimeout(() => {
                            this.setState({ apAttrib: apInfo, numFadeOut: false });

                        }, 500);
                    })
                };
            });

            if (features.length > 0) {
                apId =  features[0].apId;
                gageId = features[0].gageId;
            };

        };

        console.log('somewhere over');

        let needUpdate: boolean = true;
        if (this.state.apData != null && apId != null && apId.length > 0 && gageId != null && gageId.length > 0 && this.state.apData.assessmentPointId != null && this.state.apData.assessmentPointId == apId) {

            // same point, stop
            console.log('please stop');
            this.updateLoadingFunction(false, "No Precipitation Data Found", networkClassContext);
            needUpdate = false;
            
        }

        if (needUpdate == true && apId != null && apId.length > 0 && gageId != null && gageId.length > 0) {
            console.log('map update context', networkClassContext);

            this.updateLoadingFunction(true, "Loading...", networkClassContext)
            this.childEsriMapRef.current.ZoomTo(evt);

            networkviewer.axiosGetAll({ apId: apId, gageId: gageId }).then((r: AxiosResponse) => {
                //console.log('yay response');
                if (r.data != null) {
                    let info: networkviewer = r.data;
                    //this.props.updateAssessmentPoint(info, this.props.networkContext)
                    //console.log(info, "INFO");
                    if (info != null && info.data != null && info.data.length > 0) {
                        this.setState({ apData: info });
                    }
                }
            }).catch((r: AxiosResponse) => {
                console.log('Error getting network data', r);
            });

            networkClassContext.renderSidePanelView(3);
            networkClassContext.renderMainView(2);
        } else {
            console.log("no update");
        }
    }

    getMapLayers = (): LayerInfo[] => {

        // see LayerInfo class for description of different titles
        const layerInfos: LayerInfo[] = [
            { name: 'assessment_points', legendDisplayName:"Assessment Points", shortName: 'assessmentPoints', visible: true, legendOrder: 10, imgSrc: "", layerType: 'geojson', queryFeatures: true },
            { name: 'Major_Channel_Simpler', legendDisplayName: "Stream Network", shortName: 'majorChannels', visible: true, legendOrder: 50, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'Jurisheds', legendDisplayName: "Jurisheds", shortName: 'jurished', visible: true, legendOrder: 80, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'Groundwater_Basins', legendDisplayName: "Groundwater Basins", shortName: 'groundwaterBasins', visible: true, legendOrder: 70, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: '6_ALERT_PRISM', legendDisplayName: "Precipitation Nodes", shortName: 'alertGages5', visible: true, legendOrder: 30, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'MAJOR_DAMS', legendDisplayName: "Major Dams", shortName: 'majorDams', visible: true, legendOrder: 35, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'LDR_merc', legendDisplayName: "Stream Diversions", shortName: 'streamDiversions', visible: true, legendOrder: 35, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'Effluent-Plant', legendDisplayName: "Water Reclamation Plants", shortName: 'waterReclamationPlants', visible: true, legendOrder: 35, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'DEBRIS_BASINS', legendDisplayName: "Debris Basins", shortName: 'debrisBasins', visible: true, legendOrder: 35, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'SpreadingBasins', legendDisplayName: "Spreading Grounds", shortName: 'spreadingBasins', visible: true, legendOrder: 35, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'Merge_Union_Clip', legendDisplayName: "Jurisdictions", shortName: 'mergeUnion', visible: false, legendOrder: 100, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'Updated_Gages_V2_hydro', legendDisplayName: "Hydrology Calibration Stations", shortName: 'mergeRunoff', visible: false, legendOrder: 40, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'WQ_CalibrationStations', legendDisplayName: "Water Quality Calibration Stations", shortName: 'calibrationStations', visible: false, legendOrder: 35, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: '7_Alert_Gages_wData', legendDisplayName: "Precipitation Voronoi Polygons", shortName: 'alertGages7', visible: false, legendOrder: 20, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'scw_ws_areas', legendDisplayName: "SCW Watershed Areas", shortName: 'scwAreas', visible: false, legendOrder: 90, imgSrc: "", layerType: 'wms', queryFeatures: false },
            { name: 'SDN_Gravity_Main', legendDisplayName: "Storm Drains", shortName: 'gravityMain', visible: false, legendOrder: 110, imgSrc: "", layerType: 'wms', queryFeatures: false },
        ];

        return layerInfos;
    }

    getLegendCollapseFunction = (legendContext: EsriMapLegend) => {
        this.setState({collapseLegend: legendContext.collapseLegend});
    };


    render() {
      
        let classContext: Network = this;

        let layerInfos = this.getMapLayers();

        const esriMapConfig = new MapConfig('topo-vector', [-118.2, 34.2], 10, Config.map_connection_url, layerInfos, classContext, this.state.flagAngle, true, this.getLegendCollapseFunction);        

        return (
            <div className="network-container">
                <div className="network-inner-container">
                    <div className={this.state.mainViewToShow == 2 ? "view-two-container" : "view-one-container"}>
                        <div className={this.state.mainViewToShow == 2 ? 'view-two-top-div' : "view-two-top-div"}>
                            {/* <button onClick={() => { this.setAngle() }}>click</button> */}
                            <div className={this.state.mainViewToShow == 2 ? 'side-panel-div-big contract-right-animation' : "side-panel-div expand-right-animation"}>
                                <NetworkSidePanel view={this.state.sidePlanelView} renderMainView={this.renderMainView} renderSidePanelView={this.renderSidePanelView} networkContext={this}
                                    updateAssessmentPoint={this.updateAssessmentPointData} apData={this.state.apData} loading={this.state.loading}
                                    precipMsg={this.state.precipMsg} updateLoadingFunction={this.updateLoadingFunction} />
                            </div>

                            <div className="network-right-container">
                                {/* <div className="right-side-map-div" id="nw-map-div"> */}
                                <EsriMap ref={this.childEsriMapRef} {...esriMapConfig}/>
                                {/* </div> */}
                                {
                                    this.state.showBottomPanel == 2 &&
                                    <div className={this.state.mainViewToShow == 2 ? 'view-two-bottom-div contract-up-animation' : "view-two-bottom-div expand-up-animation"}>
                                        <NetworkBottomPanel apInfo={this.state.apAttrib} numFadeOut={this.state.numFadeOut}  />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



// original layout 
{/* <div className="network-container">
<div className="network-inner-container">
    <div className={this.state.mainViewToShow == 2 ? "view-two-container" : "view-one-container"}>
        <div className={this.state.mainViewToShow == 2 ? 'contract-up-animation' : "view-two-top-div expand-up-animation"}>
            <div className={this.state.mainViewToShow == 2 ? ' contract-right-animation side-panel-div-big' : "side-panel-div expand-right-animation"}>
                <NetworkSidePanel view={this.state.sidePlanelView} renderMainView={this.renderMainView} renderSidePanelView={this.renderSidePanelView} networkContext={this} updateAssessmentPoint={this.updateAssessmentPointData} />
            </div>


            <div className="right-side-map-div" id="nw-map-div">
                <EsriMap {...esriMapConfig}/>
            </div>

        </div>
        {
            this.state.showBottomPanel == 2 &&
            <div className="view-two-bottom-div">
                <NetworkBottomPanel view={this.state.sidePlanelView} renderMainView={this.renderMainView} renderSidePanelView={this.renderSidePanelView} networkContext={this} updateAssessmentPoint={this.updateAssessmentPointData} />
            </div>
        }
    </div>
</div>
</div> */}