import './tier2util.css';
import React, { Component } from "react";
import { INavHistory } from "../../../interfaces/INavHistory";
import { Grid, Icon, Message, Button, Label, Radio, Checkbox } from "semantic-ui-react";
import Config from "../../../config/Config";
import { IUtilityCard } from "../../../interfaces/IUtilityCard";
import { tier2 } from "../../../models/tier2";
import { AxiosResponse } from "axios";
import { processviewmodel } from "../../../models/processviewmodel";
import { setInterval } from "timers";
import DownloadLink from '../../../core/downloadLink/downloadLink';
import HrefLink from '../../../core/hrefLink/hrefLink';

export class Tier2UtilState {
    zipfilename: string = "No selection";
    zipfile: File = null;
    scenariofilename: string = "No selection";
    scenariofile: File = null;
    wmmsfilename: string = "No selection";
    wmmsfile: File = null;
    mrcfilename: string = "No selection";
    mrcfile: File = null;
    mjrbmp: boolean = false;
    //email: string = "";
    //reduction: number = null;
    
    processing: boolean = false;
    processStatus: string = "";
    processMessage: string = "";
}

export default class Tier2Util extends Component<IUtilityCard> {

    state = new Tier2UtilState;
    interval: NodeJS.Timeout;

    componentDidMount() {
    }

    getResult(id: number) {
        let classContext: Tier2Util = this;
        classContext.setState({ processing: false });
        if (id > 0) {
            window.location.href = Config.api_endpoint + 'Tier2/' + id;
        }
    }

    uploadFile() {
        // error list
        let list = new Array<string>();
        if (this.state.zipfile == null || this.state.scenariofile == null || this.state.wmmsfile == null) {
            list.push("Please select all needed files");
            this.handleErrors(list);
        } else {
            let classContext: Tier2Util = this;
            classContext.setState({ processing: true });
            this.handleErrors(new Array<string>());
            let form = new FormData();

            form.append("data", this.state.zipfile);
            form.append("data", this.state.scenariofile);
            form.append("data", this.state.wmmsfile);
            form.append("data", this.state.mrcfile);
            let mjrbmpstring = this.state.mjrbmp.toString();
            form.append("mjrbmp", mjrbmpstring);

            //if (this.state.email.length > 0) {
            //    if (classContext.validateEmail(classContext.state.email)) {
            //        form.append("email", classContext.state.email);
            //    } else {
            //        list.push("The email address provided is invalid");
            //        classContext.handleErrors(list);
            //        classContext.setState({ processing: false });
            //        return;
            //    }
            //}

            tier2.axiosPostUpload(form).then((r: AxiosResponse) => {
                classContext.setStatusMessage(r.data.status, r.data.message);
                if (r.data.status.toLowerCase() == "submitted") {
                    let data: processviewmodel = r.data;
                    if (data.queueId > 0) {
                        let id = data.queueId;
                        let intvl = setInterval(() => {
                            processviewmodel.axiosGet(id).then((r: AxiosResponse) => {
                                let data: processviewmodel = r.data;
                                classContext.setStatusMessage(data.status, data.message);
                                if (data.status != undefined && data.status.toLowerCase() == "completed") {
                                    classContext.props.stopInterval();
                                    if (data.message != null) {
                                        list.push(data.message);
                                        classContext.handleErrors(list);
                                    }
                                    classContext.getResult(data.resultId);
                                } else if (data.status != undefined && data.status.toLowerCase() == "error") {
                                    classContext.props.stopInterval();
                                    classContext.setState({ processing: false });
                                    list.push("Error during processing");
                                    list.push(data.message);
                                    classContext.handleErrors(list);
                                }
                                else {
                                }
                            });
                        }, 2000);
                        classContext.props.beginInterval(intvl);
                    }
                } else if (r.data.status.toLowerCase() == "error") {
                    classContext.setState({ processing: false });
                    list.push("Error during processing");
                    list.push(r.data.message);
                    classContext.handleErrors(list);
                } else {
                    classContext.setState({ processing: false });
                    list.push("Unknown error");
                    classContext.handleErrors(list);
                }
            }).catch(function (error) {
                classContext.setState({ processing: false });
                list.push(error.message);
                classContext.handleErrors(list);
            })
        }
    }

    //validateEmail(email: string): boolean {
    //    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) { return true; }
    //    return false;
    //}
    
    //handleEmailUpdate(evt) {
    //    this.setState({ email: evt.target.value });
    //}

    handleToggleUpdate = () => {
        //console.log(!this.state.mjrbmp);
        this.setState({ mjrbmp: !this.state.mjrbmp });
    }

    setStatusMessage(status: string, message: string) {
        let classContext: Tier2Util = this;
        if (status != undefined && (status.toLowerCase() == "submitted" || status.toLowerCase() == "queued" || status.toLowerCase() == "processing")) {
            classContext.setState({ processStatus: status, processMessage: message })
        } else {
            classContext.setState({ processStatus: "", processMessage: "" })
        }
    }

    handleErrors = (list) => {
        let err = document.getElementById('errorPlace');
        err.classList.remove('hidden');

        let msgs = document.getElementById('errorMessages');
        while (msgs.firstChild) {
            msgs.removeChild(msgs.firstChild);
        }
        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].indexOf(';') > 0) {
                    var smallArr = list[i].split(';');
                    for (var j = 0; j < smallArr.length; j++) {
                        if (smallArr[j].length > 1) {
                            let dv = document.createElement('li');
                            dv.append(smallArr[j])
                            msgs.appendChild(dv);
                        }
                    }
                }
                else {
                    let dv = document.createElement('li');
                    dv.append(list[i])
                    msgs.appendChild(dv);
                }
            }
        } else {
            err.classList.add('hidden');
        }
    }

    handleItemUpdate(evt, typestr) {
        let lbl = document.getElementById(typestr + 'uploadlabel');
        if (evt.target.files.length > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
            this.setState({ [typestr + 'filename']: evt.target.files[0].name, [typestr + 'file']: evt.target.files[0] });
            this.handleErrors(new Array<string>());
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
            this.setState({ [typestr + 'filename']: 'None Selected', file: null });
        }
    }
    
    render() {
        let classContext: Tier2Util = this;
        return (
            <div className='scroll-fix'>
                <div>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <h2><Icon className={'page-icon wmms-' + this.props.wmmsColor} name={this.props.cardIcon} circular inverted />{this.props.cardTitle}</h2>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    Within a jurished, the set of “Best Solutions” represent the most cost-effective BMP implementation options. However, when compared with results from another jurished, it is sometimes more cost-effective on a regional scale to manage more aggressively in another jurished to meet downstream objectives. This Two-tiered Optimization Utility is where this problem formulation for watershed-scale optimization across all considered jurisheds occurs. In this two-tier model structure, the varying cost and benefits of all considered BMP options are balanced across a management area to most cost-effectively meet downstream objectives. Reference the <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={38} linkText="Optimization Utilities User Document" filename="OptimizationUtilitiesUserDocument.pdf" mimetype="application/pdf" bold={true} wmmsColor={classContext.props.wmmsColor} /> for a full description of the processes and mechanics for executing this utility.  
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                    This Utility accepts output from the second tier of optimization (if MJR BMPs are not present) or the MJR BMP simulation (if MJR BMPs are present). The output of the Two-tiered utility is a CRZ file used in the <HrefLink endpointUrl={Config.appUrl + 'optimization'} wmmsColor={classContext.props.wmmsColor} linkText='optimization viewer' /> for result visualization and further processing.
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    <div id="errorPlace" className="hidden">
                                        <Message color='red' onDismiss={() => classContext.handleErrors(new Array<string>())}>
                                            <Message.Header>Processing Messages:</Message.Header>
                                            <Message.List id='errorMessages'>
                                            </Message.List>
                                        </Message>
                                    </div>

                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.zipfilename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='zipupload'><Label className="upload-basic-btn" color='red' basic id='zipuploadlabel'>Click here to select a .zip file.</Label></label>
                                        <input id='zipupload' type="file" accept=".zip" onChange={(evt) => classContext.handleItemUpdate(evt, 'zip')} className="hidden" />
                                    </div>

                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.scenariofilename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='scenarioupload'><Label className="upload-basic-btn" color='red' basic id='scenariouploadlabel'>Click here to select a scenario definition .xlsx file.</Label></label>
                                        <input id='scenarioupload' type="file" accept=".xlsx" onChange={(evt) => classContext.handleItemUpdate(evt, 'scenario')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.wmmsfilename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='wmmsupload'><Label className="upload-basic-btn" color='red' basic id='wmmsuploadlabel'>Click here to select a .wmms file.</Label></label>
                                        <input id='wmmsupload' type="file" accept=".wmms" onChange={(evt) => classContext.handleItemUpdate(evt, 'wmms')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>Process with MJR BMPs?</div>
                                        <div className="tier2flex">
                                            <div className="ui fitted toggle checkbox ">
                                                <Checkbox onClick={classContext.handleToggleUpdate} checked={this.state.mjrbmp} className="tier2toggle" toggle />
                                            </div>
                                            <div className="toggleText">
                                                {this.state.mjrbmp == true && <span>Yes</span>}
                                                {this.state.mjrbmp == false && <span>No</span>}
                                            </div>
                                        </div>
                                    </div>
                                    {/*this.state.mjrbmp && <div className='top-padding'>
                                        <div className='padding-bottom-point5'>Optional File Name:<b> {classContext.state.mrcfilename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='mrcupload'><Label className="upload-basic-btn" color='red' basic id='mrcuploadlabel'>Click here to select optional .mrc file.</Label></label>
                                        <input id='mrcupload' type="file" accept=".mrc" onChange={(evt) => classContext.handleItemUpdate(evt, 'mrc')} className="hidden" />
                                    </div>*/}
                                    {/*<div className='top-padding'>
                                        <div className='padding-bottom-point5'>Optional Email:<b> Provide email address to receive completion email</b></div>
                                        <div className="ui input emailtextpad"><input className="textinput" id='useremail' disabled={this.state.processing} type="text" placeholder='email@email.com' value={classContext.state.email} onChange={(evt) => classContext.handleEmailUpdate(evt)} /></div>
                                    </div >*/}
                                </div>
                                <br />
                                <div className="semantic-yellow-disabled-btn">
                                    <Button size='medium' loading={this.state.processing} disabled={this.state.processing} className={"wmms-" + this.props.wmmsColor + "-btn"} onClick={() => this.uploadFile()} >Process</Button>
                                </div>
                                <b>{this.state.processStatus}</b> {this.state.processMessage}
                                <div className='WeatherStatus'>
                                </div>
                            </Grid.Column>
                            <Grid.Column className="">
                                <div>
                                    <p><b>Process Description:</b></p>
                                    <p>1. If MJR BMPs are not present, upload the zipped folder containing output in the <i>2production</i> folder. If MJR BMPs are present, upload the zipped folder containing output from the <i>2production</i> folder and the <i>3mjrBmpProduction</i> folder.</p>
                                    <p>2. Upload a configured "scenario definition file" that defines the management objective, assessment point, and decision variables. A <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={39} linkText="template" filename="ScenarioDefintion_Template.xlsx" mimetype="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" bold={true} wmmsColor={classContext.props.wmmsColor} /> is provided as an example; see the user guide for further detail on its use.</p>
                                    <p>3. Upload the ".wmms" file within the <i>4viewer</i> folder. This file contains BMP and model specifications as defined in the BMP Opportunities template spreadsheet template.</p>
                                    <p>4. If MJR BMPs are <i>not</i> present, toggle the MJRBMPs slider to "No". If MJR BMPs <i>are</i> present, toggle the MJRBMPs slider to "Yes".</p>
                                </div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>

            </div>
        );
    }
}