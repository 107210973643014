import { BaseModel } from "../../interfaces/IBaseModel";

export class climateUpload extends BaseModel {

    "zipfilename": string;
    "zipfiletype": string;
    "zipfileblob": Blob;

    constructor() {
        super('climatechangeviewer');
    }
}  