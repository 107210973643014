import React, { Component } from "react";
import IAxisBottomProps from '../interfaces/IAxisBottomProps';
import * as d3 from 'd3';

export default class AxisBottom extends Component<IAxisBottomProps> {
    ref: React.RefObject<SVGGElement>;

    constructor(props: IAxisBottomProps) {
      super(props);
      this.ref = React.createRef();
    }
  
    componentDidMount() {
      if (this.ref.current) {
        d3.select(this.ref.current)
          .call(d3.axisBottom(this.props.scale));
      }
    }
  
    componentDidUpdate() {
      if (this.ref.current) {
        d3.select(this.ref.current)
          .transition()
          .call(d3.axisBottom(this.props.scale));
      }
    }
  
    render() {
      return <g color={"black"} fontSize={12} transform={`translate(0, ${this.props.height})`} ref={this.ref} />;
    }
}