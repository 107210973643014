import React, { Component } from "react";
import IAxisLeftProps from '../interfaces/IAxisLeftProps';
import * as d3 from 'd3';

export default class AxisLeft extends Component<IAxisLeftProps> {
    ref: React.RefObject<SVGGElement>;

    constructor(props: IAxisLeftProps) {
      super(props);
      this.ref = React.createRef();
    }
  
    componentDidMount() {
      if (this.ref.current) {
        d3.select(this.ref.current)
          .call(d3.axisLeft(this.props.scale));
      }
    }
  
    componentDidUpdate() {
      if (this.ref.current) {
        d3.select(this.ref.current)
          .transition()
          .call(d3.axisLeft(this.props.scale));
      }
    }
  
    render() {
      // className={this.props.class != null ? this.props.class : null}
      return <g fontSize={12} color={"black"} ref={this.ref} />;
      
    }
}