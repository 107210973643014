import axios, { AxiosResponse, AxiosPromise, AxiosRequestConfig, AxiosInstance } from 'axios';
import Config from '../../../config/Config'; //'../config/Config';

export class GetBaseModel {

    //  thisType: new () => any; //this is populated by the constructor and is the constructor of the child class.  This is used to create new instances from the base

    /**
     * This
     * @param strEndPoint
     * @param type - just pass the actual class in, more example if the endpoint is BMPType and the model is BMPType, 
     * pass in BMPType as in the class, not in a string.  This is used to create new instances of the object
     */
    endpoint: string;

    constructor(strEndPoint: string) {//, type: new () => any ) {
        this.endpoint = strEndPoint;
        // this.thisType = type;
        this.axiosBaseInstance = this.makeBaseAxiosInstance();
    }

    axiosBaseInstance: AxiosInstance;

    // ----- Axios config Functions ----- //

    private makeBaseAxiosInstance = function (): AxiosInstance {
        let context: GetBaseModel = this;
        let token: string = '';


        return axios.create({
            baseURL: Config.api_endpoint,
            headers: {
                "Access-Control-Allow-Origin": '*',
                "Content-Type": 'application/json;charset=UTF-8'
            }
        });

    };

    private makeBaseAxiosFileInstance = function (): AxiosInstance {
        let context: GetBaseModel = this;
        let token: string = '';

        return axios.create({
            baseURL: Config.api_endpoint,
            headers: {
                "Access-Control-Allow-Origin": '*',
                "Content-Type": 'multipart/form-data'
            }
        });

    };

    static axiosGet = function (data?): AxiosPromise {
        //console.log("axios data: " + data)
        let t: GetBaseModel = new this();
        return t.axiosBaseInstance({
            method: "GET",
            url: t.endpoint,
            params: data
        })
    };

}

export class GetFeatureInfo extends GetBaseModel {

    "data": string;
    constructor() {
        super('getfeatureinfo');
    }
}  

