import React, { Component } from "react";
import ICircleProps from "../interfaces/ICircleProps";
import * as d3 from 'd3';

export default class Circle extends Component<ICircleProps> {
    ref: React.RefObject<SVGCircleElement>;

  constructor(props: ICircleProps) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    let { radius, cx, cy, defaultcx, defaultcy} = this.props;

    // console.log("circle props", cx, cy);
    // console.log("default", defaultcx, defaultcy);

    return (
      <circle id={"circle"} r={radius} cx={this.props.cx == null ? defaultcx : cx} cy={this.props.cx == null ? defaultcy : cy} style={{fill: "rgb(128, 0, 0)"}} ref={this.ref}/>
    );
  }
}