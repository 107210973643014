import React, { Component } from "react";
import './Dropdown.css';
import { Label, Dropdown, Grid } from "semantic-ui-react";
import { IDropdownH2O, DropdownOption } from "./IDropdownProps";


export default class DropdownH2O extends Component<IDropdownH2O> {

    state = {
        value: -1,
        loading: true
    }
    
    componentDidMount = () => {
        
        if (this.props.dropdownItems.length > 0) {
            
            this.setState({
                loading: false,
                value: this.props.dropdownItems[0].optionValue
            }, () => {
                // if (this.props.onUpdate) {
                //     this.props.onUpdate(this);
                // };
            });
        };
    };
    
    componentDidUpdate = () => {
        if (this.props.onUpdate) {
            this.props.onUpdate(this);
        };
    };

    manageChangeOnSingle = (event: any, data) => {
        // console.log("single", event,  data)

        this.setState({
            value: data.value,
        }, () => {
            this.props.onSelect(event, data);
        });
    }

    manageChangeOnMultiple = (event: any, data: any) => {
        console.log("multiple", event,  data)

    }

    createOptions = () => {
        if (this.props.dropdownItems !== null && this.props.dropdownItems !== undefined) {
            return this.props.dropdownItems.map((o: DropdownOption, index: number) => {
               return { key: index, text: o.optionText, value: o.optionValue} 
            });
        } else {
            return [];
        }
    }

    render() {
        return  (
            <div className="dropdown-h2o">
                {
                    this.props.isMultiselect && 
                    <div>
                            <div className="dropdown-title">
                                {this.props.dropdownTitle}:
                            </div>
                            <Dropdown className="dropdown-element" 
                                //   placeholder={this.props.dropdownItems[0].optionText} 
                                //   text={this.state.text}  
                                //   value={this.state.value} 
                                options={this.createOptions()}
                                loading={this.state.loading}
                                onChange={(event, data) => { this.manageChangeOnMultiple(event, data) }}
                                disabled={this.props.dropdownItems.length < 2}
                                multiple 
                                selection>
                                      
                            </Dropdown>
                    </div>
                }
                {
                    !this.props.isMultiselect &&
                    <div>
                        <div className="dropdown-title">
                            {this.props.dropdownTitle}:
                        </div>
                        <Dropdown className="dropdown-element" 
                            //   placeholder={this.props.dropdownItems[0].optionText} 
                            //   text={this.state.text}  
                            //   value={this.state.value} 
                            options={this.createOptions()}
                            loading={this.state.loading}
                            onChange={(event, data) => { this.manageChangeOnSingle(event, data) }}
                            disabled={this.props.dropdownItems.length < 2}
                            value={this.state.value}
                            selection>
                                  
                        </Dropdown>
                    </div>
                }
            </div>
        )
    }
}