import React, { Component } from "react";
import './hrefLink.css';
import { IHrefLinkProps } from "../../interfaces/IHrefLinkProps";
import { AxiosResponse } from "axios";
import ModelDownloads from "../../models/ModelDownload";
import { Icon } from "semantic-ui-react";


export class HrefLinkState {

}

export default class HrefLink extends Component<IHrefLinkProps> {

    state = new HrefLinkState();

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {

    }


    hrefClick = () => {
        let classContext: HrefLink = this;
        if (classContext.props.newTab == true) {
            window.open(classContext.props.endpointUrl);
        }
        else {
            window.location.href = classContext.props.endpointUrl;
        }
    };



    render() {

        let classContext: HrefLink = this;

        return (
            <span className={'href-link wmms-' + classContext.props.wmmsColor} onClick={() => classContext.hrefClick()}>
                {classContext.props.bold != true && 
                    classContext.props.linkText
                }
                {classContext.props.bold == true &&
                    <b>{classContext.props.linkText}</b>
                }
                {classContext.props.icon != null &&
                    <Icon name={classContext.props.icon} size='small' />}
            </span>
        );
    }
}