import { BaseModel } from "../interfaces/IBaseModel";

export class weather extends BaseModel {

    "gagefilename": string;
    "gagefiletype": string;
    "gagefileblob": Blob;
    "nldasfilename": string;
    "nldasfiletype": string;
    "nldasfileblob": Blob;
    "prismfilename": string;
    "prismfiletype": string;
    "prismfileblob": Blob;
    "evapfilename": string;
    "evapfiletype": string;
    "evapfileblob": Blob;
    "email": string

    constructor() {
        super('weather');
    }
}  