import { BaseModel } from "../interfaces/IBaseModel";

export class alertPropsModel {
    header: string;
    body: string;
    color: string;
    size: string; // opt(small-alert, med-alert, large-alert)
    className: string;
    context: any;    
    closeBtn: true;
    setTimeOut: boolean;
    timeOutTime?: number;

}  