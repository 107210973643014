import './ftableutil.css'
import React, { Component } from "react";
import { INavHistory } from "../../../interfaces/INavHistory";
import { Button, Input, Label, Form, Icon, Message, Grid, GridColumn, LabelDetail } from "semantic-ui-react";
import { ftable } from "../../../models/ftable";
import { AxiosResponse } from "axios";
import { IUtilityCard } from "../../../interfaces/IUtilityCard";
import Config from "../../../config/Config";
import { processviewmodel } from "../../../models/processviewmodel";
import { setInterval } from "timers";
import DownloadLink from '../../../core/downloadLink/downloadLink';


export class FTableUtilState {
    pageObj: ftable = new ftable();
    filename: string = "No selection";
    file: File = null;
    processing: boolean = false;
}


export default class FTableUtil extends Component<IUtilityCard> {
    state = new FTableUtilState;
    interval: NodeJS.Timeout;
    uploadFile() {
        // error list
        let list = new Array<string>();
        if (this.state.file == null) {
            list.push("No file selected");
            this.handleErrors(list);
        } else {
            let classContext: FTableUtil = this;
            classContext.setState({ processing: true });
            this.handleErrors(new Array<string>());
            let form = new FormData();
            let table = new ftable();
            table.fileblob = this.state.file;
            table.filename = this.state.filename;
            table.filetype = "text/csv";
            form.append("attachmentData", JSON.stringify(table));
            form.append("data", this.state.file);

            ftable.axiosPostUpload(form).then((r: AxiosResponse) => {
                if (r.data.status.toLowerCase() == "submitted") {
                    let data: processviewmodel = r.data;
                    if (data.queueId > 0) {
                        let id = data.queueId;
                        let intvl = setInterval(() => {
                            processviewmodel.axiosGet(id).then((r: AxiosResponse) => {
                                let data: processviewmodel = r.data;
                                if (data.status.toLowerCase() == "completed") {
                                    classContext.props.stopInterval();
                                    classContext.getResult(data.resultId);
                                    // if completed but with errors
                                    if (data.message != null) {
                                        list.push(data.message);
                                        classContext.handleErrors(list);
                                    }
                                } else if (data.status.toLowerCase() == "error") {
                                    classContext.props.stopInterval();
                                    classContext.setState({ processing: false });
                                    list.push("Error during processing");
                                    list.push(data.message);
                                    classContext.handleErrors(list);
                                }
                                else {
                                    //console.log('keep checking');
                                }
                            });
                        }, 1000);
                        classContext.props.beginInterval(intvl);
                    }
                } else if (r.data.status.toLowerCase() == "error") {
                    classContext.setState({ processing: false });
                    list.push("Error during processing");
                    list.push(r.data.message);
                    classContext.handleErrors(list);
                } else {
                    classContext.setState({ processing: false });
                    list.push("Unknown error");
                    classContext.handleErrors(list);
                }
            })
        }
    }
    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {
    }
    getResult(id: number) {
        let classContext: FTableUtil = this;
        classContext.setState({ processing: false });
        if (id > 0) {
            window.location.href = Config.api_endpoint + 'FTable/' + id;
        }
    }
    handleErrors(list) {
        let err = document.getElementById('errorPlace');
        err.classList.remove('hidden');
        let msgs = document.getElementById('errorMessages');
        while (msgs.firstChild) {
            msgs.removeChild(msgs.firstChild);
        }
        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].indexOf(';') > 0) {
                    var smallArr = list[i].split(';');
                    for (var j = 0; j < smallArr.length; j++) {
                        if (smallArr[j].length > 1) {
                            let dv = document.createElement('li');
                            dv.append(smallArr[j])
                            msgs.appendChild(dv);
                        }
                    }
                }
                else {
                    let dv = document.createElement('li');
                    dv.append(list[i])
                    msgs.appendChild(dv);
                }
            }
        } else {
            err.classList.add('hidden');
        }
    }
    handleItemUpdate(evt) {
        //alert(evt)
        let lbl = document.getElementById('uploadlabel');
        if (evt.target.files.length > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
            this.setState({ filename: evt.target.files[0].name, file: evt.target.files[0] });
            this.handleErrors(new Array<string>());
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
            this.setState({ filename: 'None Selected', file: null });
        }
    }
    render() {
        let classContext: FTableUtil = this;
        return (
            <div  className='scroll-fix'>
                <div>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <h2><Icon className={'page-icon wmms-' + this.props.wmmsColor}  name={this.props.cardIcon} circular inverted />{this.props.cardTitle}</h2>
                        </Grid.Row>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <div>
                                    {this.props.cardDescription}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    <div id="errorPlace" className="hidden">
                                        <Message color='red' onDismiss={() => classContext.handleErrors(new Array<string>())}>
                                            <Message.Header>Errors parsing the file:</Message.Header>
                                            <Message.List id='errorMessages'>
                                            </Message.List>
                                        </Message>
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.filename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='ftableupload'><Label className="upload-basic-btn" color='red' basic id='uploadlabel'>Click here to select a stage/storage CSV file.</Label></label>
                                        <input id='ftableupload' type="file" accept=".csv" onChange={(evt) => classContext.handleItemUpdate(evt)} className="hidden" />
                                    </div>
                                </div>
                                <br />
                                <div className="semantic-blue-disabled-btn">
                                    <Button size='medium' loading={this.state.processing} disabled={this.state.processing} className={"wmms-" + this.props.wmmsColor + "-btn"} onClick={() => this.uploadFile()} >Process</Button>
                                </div>
                            </Grid.Column>
                            <Grid.Column className="">
                                <div>
                                    <p><b>Process Description:</b></p>
                                    <p>1. Save <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={36} linkText="template input file" filename="fTable_Template.csv" mimetype="text/csv" bold={true} wmmsColor={classContext.props.wmmsColor} /> (.csv) to desired location on local hard drive.</p>
                                    <p>2. Input parameter values for each of the desired hydraulic features. Reference the parameter <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={35} linkText="readme file" filename="fTable_readme.pdf" mimetype="application/pdf" bold={true} wmmsColor={classContext.props.wmmsColor} /> for descriptions and context.</p>
                                    <p>3. Save .csv on local drive.</p>
                                    <p>4. Upload to the Utility website and execute the tool.</p>
                                    <p>5. Retrieve output .csv in local download folder.</p>
                                    <p>6. Upload .csv to LSPC Map Interface through "Load stage/storage" pathway option (See LSPC Map User Document for further details).</p>
                                </div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>

            </div>
        );
    }
}