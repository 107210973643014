import React, { Component } from "react";
import './downloadLink.css';
import { Grid, Button, Table, Icon } from "semantic-ui-react";
import { IDownloadLinkProps } from "../../interfaces/IDownloadLinkProps";
import { AxiosResponse } from "axios";
import ModelDownloads from "../../models/ModelDownload";


export class DownloadLinkState {
    // TODO this is a placeholder for real data that will be obtained from the API
    //data: any = [
    //    {Name: "Flood Storms - 10 year", Description: "You see, the refried beans are really just the adhesive necessary to apply the soft tortilla to the hard taco shell."},
    //    {Name: "Long Term - Daily", Description: "Um, Tabasco? No thanks, do you have any Cholula?"},
    //    {Name: "Water Quality Critical Condition - 85%ile Storm", Description: "Yeah, apparently the taco shack was robbed. They left the money but took the tacos."}
    //];


    downloading: boolean = false;
}

export default class DownloadLink extends Component<IDownloadLinkProps> {

    state = new DownloadLinkState();

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {

    }


    downloadModelFile = (modelDownloadId: number, mimeType: string, filename: string) => {
        let classContext: DownloadLink = this;
        if (classContext.state.downloading == false) { // only download if not already downloading...

            classContext.setState({ downloading: true }, () => {
                ModelDownloads.axiosGetBlobById(modelDownloadId)
                    .then((r: AxiosResponse) => {
                        const bloboBaggins = new Blob([r.data], { type: mimeType });
                        classContext.setState({ downloading: false });
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(bloboBaggins, filename);
                        } else {
                            const bloboBaggins = new Blob([r.data], { type: mimeType });
                            var link = document.createElement('a');
                            var href = window.URL.createObjectURL(bloboBaggins);
                            link.setAttribute('href', href);
                            link.setAttribute('download', filename);
                            document.body.appendChild(link);
                            link.click();
                            setTimeout(() => {
                                window.URL.revokeObjectURL(href);
                                document.body.removeChild(link);
                            }, 0)
                        };
                    })
                    .catch((r: AxiosResponse) => {
                        classContext.setState({ downloading: false });
                        console.log("error downloading file")
                    });
            });
        }
    };



    render() {

        let classContext: DownloadLink = this;

        return (
            <span className={'download-link wmms-' + classContext.props.wmmsColor + (classContext.state.downloading == true ? " download-link-animate" : '')} onClick={() => classContext.downloadModelFile(classContext.props.recordId, classContext.props.mimetype, classContext.props.filename)}>
                {classContext.props.bold != true && 
                    classContext.props.linkText
                }
                {classContext.props.bold == true &&
                    <b>{classContext.props.linkText}</b>
                }
            </span>
        );
    }
}