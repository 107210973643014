import React, { Component } from "react";
import "./optimizationTable.css";
import { INavHistory } from "../../../interfaces/INavHistory";
import { Dropdown, Button, FormGroup, Label, Input, Grid, Table, Icon } from "semantic-ui-react";
import ITableProps from "../../../interfaces/ITableProps";
import { create } from "domain";
import { TooltipModal } from "../../toolTipModal/toolTipModal";
import { alertPropsModel } from "../../../models/alertPropsModel";
import { ColorPickerModal } from "../../../core/colorPickerModal/colorPickerModal";

export class OptimizationTableState {
    backupColors: any = ["#157bdb", "#9eb426", "#896b6d", "#0e1144", "#921caa", "#a7a795", "#013c42", "#29770a", "#d8a135", "#f3927f"];
    editTitle: boolean = false;
    tableTitle: string = null;
    editPercent: boolean = false;
    tablePercent: number = null;
    alertIsOpen: boolean = false; 
    alertConfguration: alertPropsModel = new alertPropsModel();
}

export default class OptimizationTable extends Component<ITableProps> {
    state = new OptimizationTableState();

    constructor(props: ITableProps){
        super(props);
    } 

    // getRandomColor() {
    //     let classContext: OptimizationTable = this;
    //     return classContext.state.backupColors[Math.floor(Math.random() * classContext.state.backupColors.length)];

    //     // a 3-option ternary can be used to fill in a random color if the searched for key name isn't found
    //     // this line would be applied to the table cell that needs to be colored:
    //     // style={{backgroundColor: classContext.props.colors == undefined ? "none" : (classContext.props.colors["LID, Bioretention"] == undefined ? classContext.getRandomColor() : classContext.props.colors["LID, Bioretention"])}}
    //     // not implemented here, because it wouldn't match the chart area unless random color was picked earlier and passed to both chart and table
    // }
    
    createBmpRows() {
        let classContext: OptimizationTable = this;        
        let rows = classContext.props.bmpGroups.map(group => {
            return (
                <Table.Row id={group} className="bmp-row">
                    <Table.Cell className="color-cell" 
                        style={{ backgroundColor: classContext.props.colors[group] }}><ColorPickerModal className={""} modalSize={'mini'} startColor={classContext.props.colors[group]} isOpen={false} bmpName={group} callback={this.props.updateColorFunction} />
                    </Table.Cell>
                    <Table.Cell>{group}</Table.Cell>
                    <Table.Cell 
                        className="capacity-cell"><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{classContext.props.data.bmPs.find(x => x.name == group).value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ac-ft</span>
                    </Table.Cell>
                </Table.Row>
            );
        });
        return rows;
    }

    editTitle() {
        let classContext: OptimizationTable = this;
        classContext.setState({ 
            tableTitle: this.props.title,
            editTitle: true
         });
    }

    handleUpdate(evt, stateProp, label) {
        let lbl = document.getElementById(label);
        if (evt.target.value.length > 2 && evt.target.value.indexOf(" ") == -1) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
        }
        let compState = Object.assign({}, this.state);
        compState[stateProp] = evt.target.value;
        this.setState(compState);
    }

    updateTitle(e) {
        let classContext: OptimizationTable = this;
        classContext.setState({ tableTitle: e.target.value });
    }
    
    saveTitleChanges() {
        let classContext: OptimizationTable = this;
        classContext.setState({ editTitle: false });
        classContext.props.onTitleUpdate(this.state.tableTitle);
    }

    editPercent() {
        let classContext: OptimizationTable = this;
        classContext.setState({ 
            tablePercent: classContext.props.percent,
            editPercent: true
         });
    }

    updatePercent(e) {
        let classContext: OptimizationTable = this;
        classContext.setState({ tablePercent: e.target.value });
    }
    
    savePercentChanges() {
        let classContext: OptimizationTable = this;
        if (this.state.tablePercent > classContext.props.maxPct) {
            // alert("Entered value must not exceed max % from data");

            let alertProps: alertPropsModel = {
                header: "Entered value must not exceed max % from data",
                body: '',
                color: 'warning',
                size: 'small-alert',
                className: '',
                context: this,
                closeBtn: true,
                setTimeOut: true,
                timeOutTime: 3000
            };
            this.setState({ alertConfguration: alertProps }, () => {
                this.setState({ alertIsOpen: true });
            });
        } else {
            classContext.setState({ editPercent: false });
            classContext.props.onPercentUpdate(this.state.tablePercent);
        }
    }

    render() {
        let classContext: OptimizationTable = this;
        return (
            <div>
                <div className="opt-table-div">
                    <Table id="opt-table" celled striped size="small" className="table-shadow opt-table" style={{ tableLayout: 'fixed' }}>
                        {classContext.props.isSample == true &&
                            <div className="watermark-wrap"><div className="watermark-tbl">SAMPLE DATA</div></div>}
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="headerCell blue-table-header opt-table-header">
                                    <TooltipModal icon="info circle" iconClassName={'white-info-icon info-icon-small pl-point5'} className={""} modalSize={'small'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer8.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.OptimizationViwer8.body : 'Tooltip data is still loading please try again in a moment'} />
                          
                                </Table.HeaderCell>
                                <Table.HeaderCell className="headerCell blue-table-header opt-table-header opt-table-title-cell">
                                    {
                                        classContext.state.editTitle
                                            ?
                                            <Input type="text" id="opt-table-input"
                                                defaultValue={(classContext.state.tableTitle == null ? classContext.props.title : classContext.state.tableTitle)} 
                                                onChange={e => classContext.updateTitle(e)} 
                                                onKeyPress={e => {if (e.key === 'Enter') { classContext.saveTitleChanges() }}}
                                            ></Input>
                                            :
                                            (classContext.state.tableTitle == null ? classContext.props.title : classContext.state.tableTitle) 
                                    }
                                    {
                                        classContext.state.editTitle 
                                            ?
                                            <Button className="opt-table-btn" onClick={e => classContext.saveTitleChanges()}><Icon name="save"/></Button>
                                            :
                                            <Button className="opt-table-btn" onClick={e => classContext.editTitle()}><Icon name="edit"/></Button>
                                    }
                                    </Table.HeaderCell>
                                <Table.HeaderCell className="headerCell blue-table-header opt-table-header">
                                   
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body id="tableBody">
                            <Table.Row>
                                <Table.Cell>
                                    <Icon name='circle' className="opt-table-icon-dot" />
                                  
                                </Table.Cell>
                                <Table.Cell>{classContext.props.factor} Reduction</Table.Cell>
                                <Table.Cell>
                                    {
                                        classContext.state.editPercent
                                        ?
                                        <Input type="text" id="opt-table-input"
                                            defaultValue={classContext.props.percent} 
                                            onChange={e => classContext.updatePercent(e)} 
                                            onKeyPress={e => {if (e.key === 'Enter') { classContext.savePercentChanges() }}}
                                        ></Input>
                                        : 
                                        <span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{classContext.props.percent}</span>
                                    } %
                                    {
                                        classContext.state.editPercent 
                                        ?
                                        <Button className="opt-table-btn-blue" onClick={e => classContext.savePercentChanges()}><Icon name="save"/></Button>
                                        :
                                        <Button className="opt-table-btn-blue" onClick={e => classContext.editPercent()}><Icon name="edit"/></Button>
                                    }
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Icon name='minus' className="opt-table-icon-dash"/>
                                </Table.Cell>
                                <Table.Cell>Total Cost</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>$ {classContext.props.data == undefined ? "--" : (classContext.props.data.cost / 1000000).toFixed(0)} M</span></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                </Table.Cell>
                                <Table.Cell>Total Capacity</Table.Cell>
                                <Table.Cell><span className={this.props.numFadeOut ? 'fadeOut' : 'fadeIn'}>{classContext.props.data == undefined ? "--" : (classContext.props.data.bmPs.reduce((a, b) => ({ value: a.value + b.value })).value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ac-ft</span></Table.Cell>
                            </Table.Row>
                            {classContext.props.data != undefined ? this.createBmpRows() : null}
                        </Table.Body>
                    </Table>

                    <div className="upload-scenarios-table-container">
                        <div className="upload-scenarios-table">
                            <div className="btn-w-tooltip-div light-blue-disabled-btn">
                                <Button disabled={this.props.isSample == true || this.props.isGeneratingPlan == true} loading={this.props.isGeneratingPlan} className="wmms-light-blue-btn wmms-lbl-position" title="Generate Implementation Plan" onClick={() => { this.props.generatePlan() }} icon='archive' content='Generate Plan' labelPosition='left' />

                                <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-small pr-2 pl-point5'} className={""} modalSize={'tiny'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.GeneratePlan.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.GeneratePlan.body : 'Tooltip data is still loading please try again in a moment'} />
                            </div>
                            <div className="btn-w-tooltip-div light-blue-disabled-btn">
                                <Button disabled={this.props.isSample == true || this.props.isGeneratingAPData == true} loading={this.props.isGeneratingAPData} className="wmms-light-blue-btn wmms-lbl-position" title="Generate Assessment Point Data file" onClick={() => { this.props.generateAPData() }} icon='share alternate square' content='AP Data' labelPosition='left' />
                                <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-small pr-2 pl-point5'} className={""} modalSize={'small'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.APData.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.APData.body : 'Tooltip data is still loading please try again in a moment'} />
                            </div>
                        </div>
                    </div>

                    <div className="upload-scenarios-table-container">
                        <div className="upload-scenarios-table">
                            <div className="btn-w-tooltip-div light-blue-disabled-btn">
                                <Button disabled={this.props.isSample == true || this.props.isGeneratingBatch == true}  loading={this.props.isGeneratingBatch} className="wmms-light-blue-btn wmms-lbl-position" title="Generate Sustain to LSPC batch file" onClick={() => { this.props.generateBatch() }} icon='share square' content='To LSPC' labelPosition='left' />
                                <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-small pr-2 pl-point5'} className={""} modalSize={'small'} header={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.LSPC.header : 'One moment'} bodyText={this.props.tooltipsReady == true ? this.props.tooltipFactory.tooltipData.LSPC.body : 'Tooltip data is still loading please try again in a moment'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}