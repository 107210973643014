import React, { Component } from "react";
// import Measure, { ContentRect } from 'react-measure';
import IPrecipTimeseriesProps from "../../../interfaces/IPrecipTimeseriesProps";
import { statement } from "@babel/template";
import TimeseriesSVG from "./chart/TimeseriesSVG";
import PrecipTimeseriesSVG from "./chart/PrecipTimeseriesSVG";



export class PrecipTimeseriesChartState {

}

export default class PrecipTimeseriesChart extends Component<IPrecipTimeseriesProps> {

    state = new PrecipTimeseriesChartState();

    render() {
        return (
            <PrecipTimeseriesSVG
                svgWidth={this.props.width}
                svgHeight={this.props.height}
                data={this.props.data}
                fill={this.props.fill}
                color={this.props.color}
                clickHandler={this.props.clickHandler}
                zoomHandler={this.props.zoomHandler}
                zoomStart={this.props.zoomStart}
                zoomEnd={this.props.zoomEnd}
                noData={this.props.noData}
                precipMsg={this.props.precipMsg}
                loading={this.props.loading}
            />
        )
    };

}