import React, { Component } from "react";
import * as d3 from 'd3';
import IPathsInverseProps from "../interfaces/IPathsInverseProps";
import IData from "./../interfaces/IData";

export default class PathsInverse extends Component<IPathsInverseProps> {

    ref: React.RefObject<SVGPathElement>;

    constructor(props: IPathsInverseProps) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {

    }
    componentDidUpdate() {
        const { data, height, xScale, yScale, color, strokeWidth } = this.props;
        var line = d3.line()
            .x(function (d) { return xScale(d[0]); })
            .y(function (d) { return yScale(d[1]); });

        let segData = this.computeSegments(data, height);

        d3.select(this.ref.current).select("#inversePathId").selectAll('path').remove();

        d3.select(this.ref.current).select("#inversePathId").selectAll('g').data(segData.seg)
            .enter()
            .append('path')
            .attr('d', line)
            .transition();
    }

    computeSegments = (lineData: Array<IData>, height: number) => {
        var segments: Array<Array<any>> = new Array<Array<any>>();
        lineData.forEach(function (item: IData) {
            let segment: Array<any> = new Array<any>();
            segment.push([item.id,height]);
            segment.push([item.id, item.value]);
            //console.log(segments, segment, item);
            //return segment;
            segments.push(segment);
        })
        var data = { seg: segments };
        //console.log(segments);
        return data;
    }


    render() {
        const { color, strokeWidth } = this.props;

        return <g id="" data-testid="path" ref={this.ref}><g fill="none" stroke={color} strokeWidth={strokeWidth} strokeLinejoin="round" strokeLinecap="round" id="inversePathId"/></g>;
    }
}