// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import React, { Component, useEffect, useState } from "react";
import { ResponsiveBar } from '@nivo/bar'
import {animated, to} from '@react-spring/web'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const DivergingBar = ({ data, indexBy, groupMode, legendNames, capturePropName /* see data tab */ }) => {
    const [graphData, setGraphData] = useState([]);
    const [ymin, setYMin] = useState(-100);
    const [ymax, setYMax] = useState(100);
    const [maxBottomPad, setMaxBottomPad] = useState(20);
    const [myColors, setMyColors] = useState(['','']);
    const [myKeys, setMyKeys] = useState(['','']);
    const [showsDeficit, setShowsDeficit] = useState(false);
    //const [legendStyle, setLegendStyle] = useState('both');
    const [markers, setMarkers] = useState([]);


    useEffect(() => {
        //console.log('div useeffect', data);
        if(data && data.length > 0){
            let historic = data[0];
            let excess: boolean = false;
            let deficit: boolean = false;
            let newDivergeBarData = [];
            let newDivKeys = [];
            let newDivColors = [];
            let newMarkers = [];
            //let divLegend = "both";
            let divMin = 0;
            let divMax = 0;
            //console.log('historic', historic);
            for(var i = 1; i < data.length; i++){
                let diff = data[i].Runoff - historic.Runoff;
                //console.log('diff', diff, data[i]);
                if(historic.Runoff <= data[i].Runoff){ // runoff excess
                    excess = true;
                    let newDivItem = {
                        'id': data[i].id,
                        'Excess Compared to Historical': Math.round(diff) ,
                        'Mitigation by BMP Solution': Math.round(data[i].Capture),
                    }
                    newDivergeBarData.push(newDivItem);
                    if(newDivItem['Excess Compared to Historical'] > divMax){divMax = newDivItem['Excess Compared to Historical'];}
                    if(newDivItem['Mitigation by BMP Solution'] > divMax){divMax = newDivItem['Mitigation by BMP Solution'];}
                } else { // Deficit Compared to Historical
                    deficit = true;
                    let newDivItem = {
                        'id': data[i].id,
                        'Deficit Compared to Historical': Math.round(diff),
                        'Mitigation by BMP Solution': Math.round(data[i].Capture),
                    }
                    newDivergeBarData.push(newDivItem);
                    if(newDivItem['Deficit Compared to Historical'] < divMin){divMin = newDivItem['Deficit Compared to Historical'];}
                    if(newDivItem['Mitigation by BMP Solution'] > divMax){divMax = newDivItem['Mitigation by BMP Solution'];}

                }
            }

            if(excess === true && deficit === false ) // excess only
            {
                newMarkers.push({
                    axis: 'y',
                    value: 0,
                    lineStyle: { stroke: '#888888', strokeWidth: 1 },
                    // textStyle: { fill: '#006099' },
                    textStyle: { fill: '#13A0C2' },
                    legend: legendNames[0],
                    legendPosition: 'top-left',
                    legendOrientation: 'vertical'
                });
            }
            else if(excess === false && deficit === true )// deficit only
            {
                newMarkers.push({
                    axis: 'y',
                    value: 0,
                    lineStyle: { stroke: '#888888', strokeWidth: 1 },
                    textStyle: { fill: '#e25c3b' },
                    legend: legendNames[1],
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical'
                });
            }
            else// both
            {
                newMarkers.push({
                    axis: 'y',
                    value: 0,
                    lineStyle: { strokeOpacity: 0 },
                    // textStyle: { fill: '#006099' },
                    textStyle: { fill: '#13A0C2' },
                    legend: (legendNames[0]),
                    legendPosition: 'top-left',
                    legendOrientation: 'vertical'
                });
                newMarkers.push({
                    axis: 'y',
                    value: 0,
                    lineStyle: { stroke: '#888888', strokeWidth: 1 },
                    textStyle: { fill: '#e25c3b' },
                    legend: (legendNames[1]),
                    legendPosition: 'bottom-left',
                    legendOrientation: 'vertical'
                });
            }

            if(excess === true){
                newDivKeys.push('Excess Compared to Historical');
                // newDivColors.push('#7eb0ce');
                // newDivColors.push('#006099');
                newDivColors.push('#AEDEEA');
                
            }
            newDivKeys.push('Mitigation by BMP Solution');
            newDivColors.push('#13A0C2');

            if(deficit === true){

                newDivKeys.push('Deficit Compared to Historical');
                // newDivKeys.push('Deficit Mitigation by BMP Solution');
                //"#F59841", "#fbd8b6"
                newDivColors.push('#f2b5a6');
                // newDivColors.push('#e25c3b');
                //newDivColors.push('#fbd8b6');
                //newDivColors.push('#F59841');

            }
            // calculate bottom padding
            let ratio = Math.abs(divMax / divMin);
            let newPad = (10.5 * ratio) + 5.5;
            //console.log('min and max', divMin, divMax, ratio, newPad);
            if(newPad < 10) {newPad = 10;}
            if(newPad > 110) {newPad = 110;}
            // add a bit?
            divMax = divMax * 1.1;
            divMin = divMin * 1.1;

            setMaxBottomPad(newPad);
            setYMin(divMin);
            setYMax(divMax);
            setMarkers(newMarkers);
            //setLegendStyle(divLegend);
            setMyColors(newDivColors);
            setMyKeys(newDivKeys);
            setGraphData(newDivergeBarData);
            setShowsDeficit(deficit)
            //console.log('set done', divMin, divMax, divLegend, newDivColors, newDivergeBarData);
        }
    }, [data])

    const CustomLeftTick = (val: any, animatedProps: any) => {
        return (
            <animated.g opacity={animatedProps.opacity} transform={animatedProps.transform}>
                <animated.line x1="0" x2="5" y1="0" y2="0" stroke="rgb(119, 119, 119)" stroke-width="1px"></animated.line>                
                <animated.text dx={60}
                    textAnchor="end"
                    dominantBaseline="middle"
                    style={{
                        fill: '#333',
                        fontSize: 12,
                        fontWeight: 700
                    }}
                >
                    {val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ac-ft
                </animated.text>
            </animated.g>
        )
    }

    const CustomBottomTick = (val: any, animatedProps: any) => {
        return (
            <animated.g opacity={animatedProps.opacity} transform={animatedProps.transform}>
                <animated.text dy={-20}
                style={{
                    fill: '#000',
                    fontWeight: 700,
                    fontSize: '16px',
                    textAnchor:  'middle'
                }}>
                    {val}
                </animated.text>
            </animated.g>
        )
    }

    return(
    <ResponsiveBar
        data={graphData}
        animate={true}
        motionConfig={'wobbly'}
        //keys={[ 'gained > 100$', 'gained <= 100$', 'lost <= 100$', 'lost > 100$']}
        keys={myKeys}
        indexBy={indexBy}
        margin={{ top: 35, right: 345, bottom: showsDeficit === true? maxBottomPad: 10, left: 10 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        //indexScale={{ type: 'band', round: true }}
        colors={myColors}
        //colors={['#97e3d5', '#61cdbb', '#f47560', '#e25c3b']}
        groupMode={groupMode}
        enableLabel={true}
        minValue={ymin}
        maxValue={ymax}
        // enableGridX={true}
        // enableGridY={false}
        valueFormat={v => `${v} ac-ft`}
        labelTextColor='inherit:darker(1.2)'
        axisTop={ {
            tickSize: 0,
            tickPadding: 12,
            renderTick: ({ value, animatedProps }) => CustomBottomTick(value, animatedProps)
        }}
        axisLeft={null}
        axisRight={{
            format: (v: number) => `${Math.abs(v)} ac-ft`,
            renderTick: ({ value, animatedProps }) => CustomLeftTick(value, animatedProps)
        }}
        axisBottom={null}
        markers={ markers}
        // legendLabel={datum => `${datum.id} (${datum.value})`}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 320,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 250,
                itemHeight: 30,
                itemDirection: 'left-to-right',
                itemOpacity: 1.0,
                symbolSize: 30
            }
        ]}
        theme={{
            fontSize: 14
        }}
    />
    )
}