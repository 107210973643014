import React, { useState } from "react";
import { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { edgleyRound } from "../../../core/HelperFx";
import { TooltipModal } from "../../toolTipModal/toolTipModal";
import './performanceSummary.css'

class PerformanceSummaryItem {
    basemodelName: string;
    modelName: string;
    capture: number;
    goal: number;
    pct: number;
    visible: boolean;
    incdec: string;
    defexc: string;
}

export const PerformanceSummary = ({barData, hideui, tooltipFactory, tooltipsReady}) => {

    const [performanceItems, setPerformanceItems] = useState<PerformanceSummaryItem[]>([]);
    // weight 1 are longer series
    // weight 2 are future
    // if now future, then compare weight 1...

    useEffect(() =>{
        // assume first bar data is always historical; then compare the others accordingly
        if(barData !== undefined && barData.length > 0){
            let historic = barData[0];
            let newPerformanceItems = [];
            for(var i = 1; i < barData.length; i++){
                var newItem = new PerformanceSummaryItem();
                newItem.basemodelName = historic.id;
                newItem.modelName = barData[i].id;
                newItem.capture = barData[i]["Capture"];
                newItem.goal = barData[i]["Runoff"] - historic["Runoff"];
                newItem.pct = Math.round((newItem.capture / newItem.goal) * 10000) / 100;
                newItem.visible = true;
                if(newItem.goal < 0){
                    newItem.incdec = "decrease";
                    newItem.defexc = "deficit";
                } else {
                    newItem.incdec = "increase";
                    newItem.defexc = "excess";
                }
                newPerformanceItems.push(newItem);

                // if(barData[i]["CaptureAvg"] > 0){
                //     var newItem = new PerformanceSummaryItem();
                //     newItem.basemodelName = historic.id;
                //     newItem.modelName = barData[i].idAvg;
                //     newItem.capture = barData[i]["CaptureAvg"];
                //     newItem.goal = barData[i]["Runoff"] - historic["Runoff"];
                //     newItem.pct = Math.round((newItem.capture / newItem.goal) * 10000) / 100;
                //     newItem.visible = true;
                //     newPerformanceItems.push(newItem);
                // }
            }

            setPerformanceItems(newPerformanceItems);
        }
    }, [barData])

    const hideItem = (item: PerformanceSummaryItem) =>{
        if(item){
            const newArray = performanceItems.map(a => Object.assign({}, a));
            for(var i = 0; i < newArray.length; i++){
                if(newArray[i].basemodelName == item.basemodelName && newArray[i].modelName == item.modelName){
                    newArray[i].visible = false;
                }
            }

            setPerformanceItems(newArray);
        }
    }

    const makeAllVisible = () =>{
        const newArray = performanceItems.map(a => Object.assign({}, a));
        for(var i = 0; i < newArray.length; i++){
            newArray[i].visible = true;
        }

        setPerformanceItems(newArray);
    }


    // className='performance-cursor' onClick={() => hideItem(item)}
    return (
        <div className='performance-summary'>
            <div className='line-view-all'>
                {performanceItems.filter((item) => !item.visible).length > 0 && hideui == false && <div className='view-all-cursor' onClick={() => makeAllVisible()} title='View all summaries'><Icon name='eye' color='red' size='small' /></div>}
            </div>
            {/* OG */}
            {/* 
            <b>Summary:</b>
            {performanceItems.filter((item) => item.visible).length === 0 && <div>No performance comparison available</div>}
            {performanceItems.filter((item) => item.visible).length > 0 && performanceItems.filter((item) => item.visible).map((item, i) =>{
                return(<p  key={i + item.basemodelName + item.modelName}>
                    From <b>{item.basemodelName}</b> to <b>{item.modelName}</b> there is a {item.incdec} in runoff of {Math.abs(Math.round(item.goal * 100)/100)} ac-ft. The {item.defexc} is mitigated by {Math.abs(item.pct)}% ({Math.round(item.capture * 100)/100} ac-ft is captured by BMPs in the <b>{item.modelName}</b> scenario, offsetting the {Math.abs(Math.round(item.goal * 100)/100)} ac-ft {item.defexc} by {Math.round(Math.abs(item.capture/item.goal) * 10)/10} times.)
                 </p>)

            })} */}
            <div className="summary-div">
                <div className="summary-header">Summary <TooltipModal icon="info circle" iconClassName={'blue-info-icon info-icon-med pt-1 pl-point5'} className={""} modalSize={'tiny'} header={tooltipsReady == true ? tooltipFactory.tooltipData.SummaryText.header : 'One moment'} bodyText={tooltipsReady == true ? tooltipFactory.tooltipData.SummaryText.body : 'Tooltip data is still loading please try again in a moment'} /></div>
                <div className="custom-summary-blue-hr-bottom-sticky"></div>
                {
                    performanceItems.filter((item) => item.visible).length === 0 && 
                    <div>No performance comparison available</div>
                }
                <div className="summary-flex-div">
                    
                    {
                        performanceItems.filter((item) => item.visible).length > 0 && 
                        performanceItems.filter((item) => item.visible).map((item, i) => {
                            return (
                                <div className='pb-point5 summary-item' key={i + item.basemodelName + item.modelName}>
                                    From <b>{item.basemodelName}</b> to <b>{item.modelName}</b> there is a {item.incdec} in runoff of {edgleyRound(Math.abs(item.goal))} ac-ft. The {item.defexc} is mitigated by {edgleyRound(Math.abs(item.pct))}% ({edgleyRound(item.capture)} ac-ft is captured by BMPs in the <b>{item.modelName}</b> scenario, offsetting the {edgleyRound(Math.abs(item.goal))} ac-ft {item.defexc} by {edgleyRound(Math.abs(item.capture/item.goal))} times.)
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}