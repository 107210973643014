import './inclusivebestsolutionsutil.css';
import React, { Component } from "react";
import { INavHistory } from "../../../interfaces/INavHistory";
import { Grid, Icon, Message, Button, Label } from "semantic-ui-react";
import Config from "../../../config/Config";
import { IUtilityCard } from "../../../interfaces/IUtilityCard";
import { inclusivebestsolutions } from "../../../models/inclusivebestsolutions";
import { AxiosResponse } from "axios";
import { processviewmodel } from "../../../models/processviewmodel";
import { setInterval } from "timers";
import DownloadLink from '../../../core/downloadLink/downloadLink';

export class InclusiveBestSolutionsUtilState {
    //pageObj: inclusivebestsolutions = new inclusivebestsolutions();
    zipfilename: string = "No selection";
    zipfile: File = null;
    wmmsfilename: string = "No selection";
    wmmsfile: File = null;
    email: string = "";
    reduction: number = 100;
    
    processing: boolean = false;
    processStatus: string = "";
    processMessage: string = "";
}

export default class InclusiveBestSolutionsUtil extends Component<IUtilityCard> {

    state = new InclusiveBestSolutionsUtilState;
    interval: NodeJS.Timeout;

    uploadFile() {
        // error list
        let list = new Array<string>();
        if (this.state.zipfile == null || this.state.wmmsfile == null) {
            list.push("Please select all needed files");
            this.handleErrors(list);
        } else {
            let classContext: InclusiveBestSolutionsUtil = this;
            classContext.setState({ processing: true });
            this.handleErrors(new Array<string>());
            let form = new FormData();

            form.append("data", this.state.zipfile);
            form.append("data", this.state.wmmsfile);

            if (classContext.state.reduction != null && classContext.state.reduction > 0 && classContext.state.reduction <= 100) {
                form.append("reduction", classContext.state.reduction.toString());
            } else {
                let list = new Array<string>();
                list.push("Please specify a target reduction between 0-100");
                classContext.handleErrors(list);
                classContext.setState({ processing: false });
                return;
            }

            inclusivebestsolutions.axiosPostUpload(form).then((r: AxiosResponse) => {
                classContext.setStatusMessage(r.data.status, r.data.message);
                if (r.data.status.toLowerCase() == "submitted") {
                    let data: processviewmodel = r.data;
                    if (data.queueId > 0) {
                        let id = data.queueId;
                        let intvl = setInterval(() => {
                            processviewmodel.axiosGet(id).then((r: AxiosResponse) => {
                                let data: processviewmodel = r.data;
                                classContext.setStatusMessage(data.status, data.message);
                                if (data.status != undefined && data.status.toLowerCase() == "completed") {
                                    classContext.props.stopInterval();
                                    if (data.message != null) {
                                        list.push(data.message);
                                        classContext.handleErrors(list);
                                    }
                                    classContext.getResult(data.resultId);
                                } else if (data.status != undefined && data.status.toLowerCase() == "error") {
                                    classContext.props.stopInterval();
                                    classContext.setState({ processing: false });
                                    list.push("Error during processing");
                                    list.push(data.message);
                                    classContext.handleErrors(list);
                                }
                                else {
                                }
                            });
                        }, 2000); 
                        classContext.props.beginInterval(intvl);
                    }
                } else if (r.data.status.toLowerCase() == "error") {
                    classContext.setState({ processing: false });
                    list.push("Error during processing");
                    list.push(r.data.message);
                    classContext.handleErrors(list);
                } else {
                    classContext.setState({ processing: false });
                    list.push("Unknown error");
                    classContext.handleErrors(list);
                }
            }).catch(function (error) {
                classContext.setState({ processing: false });
                list.push(error.message);
                classContext.handleErrors(list);
            })
        }
    }

    componentDidMount() {
    }

    getResult(id: number) {
        let classContext: InclusiveBestSolutionsUtil = this;
        classContext.setState({ processing: false });
        if (id > 0) {
            window.location.href = Config.api_endpoint + 'InclusiveBestSolutions/' + id;
        }
    }
    
    handleReductionUpdate(evt) {
        this.setState({ reduction: evt.target.value });
    }

    setStatusMessage(status: string, message: string) {
        let classContext: InclusiveBestSolutionsUtil = this;
        if (status != undefined && (status.toLowerCase() == "submitted" || status.toLowerCase() == "queued" || status.toLowerCase() == "processing")) {
            classContext.setState({ processStatus: status, processMessage: message })
        } else {
            classContext.setState({ processStatus: "", processMessage: "" })
        }
    }

    handleErrors = (list) => {
        let err = document.getElementById('errorPlace');
        err.classList.remove('hidden');

        let msgs = document.getElementById('errorMessages');
        while (msgs.firstChild) {
            msgs.removeChild(msgs.firstChild);
        }
        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].indexOf(';') > 0) {
                    var smallArr = list[i].split(';');
                    for (var j = 0; j < smallArr.length; j++) {
                        if (smallArr[j].length > 1) {
                            let dv = document.createElement('li');
                            dv.append(smallArr[j])
                            msgs.appendChild(dv);
                        }
                    }
                }
                else {
                    let dv = document.createElement('li');
                    dv.append(list[i])
                    msgs.appendChild(dv);
                }
            }
        } else {
            err.classList.add('hidden');
        }
    }

    handleItemUpdate(evt, typestr) {
        let lbl = document.getElementById(typestr + 'uploadlabel');
        if (evt.target.files.length > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
            this.setState({ [typestr + 'filename']: evt.target.files[0].name, [typestr + 'file']: evt.target.files[0] });
            this.handleErrors(new Array<string>());
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
            this.setState({ [typestr + 'filename']: 'None Selected', file: null });
        }
    }
    
    render() {
        let classContext: InclusiveBestSolutionsUtil = this;
        return (
            <div className='scroll-fix'>
                <div>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <h2><Icon className={'page-icon wmms-' + this.props.wmmsColor}  name={this.props.cardIcon} circular inverted />{this.props.cardTitle}</h2>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    Three utilities have been developed to manage the workflow for SUSTAIN’s two-tier optimization process: (1) the Inclusive Best Solutions Utility, (2) the MJR BMP Utility, and (3) the Two-tiered Optimization Utility. These utilities accept and process output from SUSTAIN model runs such that their results can be uploaded to the Optimization Viewer to discern cost-optimal BMP performance and develop implementation strategies. 
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                    Once the first tier of the SUSTAIN optimization is completed, results can be uploaded to this Inclusive Best Solutions Utility to be organized as an inclusive set of best solutions. Output from this optimization is a cloud of thousands (sometimes tens-of-thousands) of unique BMP combinations for each modeled subwatershed/jurisdiction (“jurished”). Each BMP combination is a distinct blend of BMP type and storage capacity from the BMP spreadsheet template developed for the BMP Opportunities Utility. For additional details on this process and for the other two utilities in the Optimization Framework (MJR BMP and Two-tiered Optimization) a  <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={38} linkText="user document" filename="OptimizationUtilitiesUserDocument.pdf" mimetype="application/pdf" bold={true} wmmsColor={classContext.props.wmmsColor} /> has been developed. 
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    <div id="errorPlace" className="hidden">
                                        <Message color='red' onDismiss={() => classContext.handleErrors(new Array<string>())}>
                                            <Message.Header>Processing Messages:</Message.Header>
                                            <Message.List id='errorMessages'>
                                            </Message.List>
                                        </Message>
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.zipfilename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='zipupload'><Label className="upload-basic-btn" color='red' basic id='zipuploadlabel'>Click here to select a .zip file.</Label></label>
                                        <input id='zipupload' type="file" accept=".zip" onChange={(evt) => classContext.handleItemUpdate(evt, 'zip')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.wmmsfilename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='wmmsupload'><Label className="upload-basic-btn" color='red' basic id='wmmsuploadlabel'>Click here to select a .wmms file.</Label></label>
                                        <input id='wmmsupload' type="file" accept=".wmms" onChange={(evt) => classContext.handleItemUpdate(evt, 'wmms')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>Target Reduction:</div>
                                        <div className="ui right labeled input reductiontext"><input className="textinput" id='reduction' disabled={this.state.processing} type="text" placeholder='0-100' value={classContext.state.reduction} onChange={(evt) => classContext.handleReductionUpdate(evt)} /><div className="ui basic label">%</div></div>
                                    </div >
                                </div>
                                <br />
                                <div className="semantic-purple-disabled-btn">
                                    <Button size='medium' loading={this.state.processing} disabled={this.state.processing} className={"wmms-" + this.props.wmmsColor + "-btn"} onClick={() => this.uploadFile()} >Process</Button>
                                </div>
                                <b>{this.state.processStatus}</b> {this.state.processMessage}
                                <div className='WeatherStatus'>
                                </div>
                            </Grid.Column>
                            <Grid.Column className="">
                                <div>
                                    <div>
                                        <p><b>Process Description:</b></p>
                                        <p>1. Upload the zipped folder containing output from the first tier of the SUSTAIN optimization in the <i>1optimized</i> folder. This folder is located within the broader package of folders output from the BMP Opportunities Utility.</p>
                                        <p>2. Upload the ".wmms" file within the <i>4viewer</i> folder. This file contains BMP and model specifications as defined in the BMP Opportunities spreadsheet template.</p>
                                        <p>3. Enter a maximum reduction target for the selected constituent (e.g., total zinc). The recommended value is 100% (i.e., maximum possible reduction in each jurished)</p>
                                    </div>
                                </div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>

            </div>
        );
    }
}