import './App.css';
import React, { Component } from "react";
import { INavHistory } from "./interfaces/INavHistory";
import { Menu, MenuItemProps, MenuItem, Icon, Sticky } from "semantic-ui-react";
import logo from './images/WMMS_logo.png';
import Downloads from "./components/Downloads/downloads";
import Utilities from "./components/Utilities/Utilities";
import Network from "./components/Network/network";
import Optimization from "./components/Optimization/optimization";
import Links from "./components/Links/links";
import UtilityMenu from "./UtilitiesMenu";
import TooltipFactory from './core/tootipFactory';
import {Route} from 'react-router-dom';
import Config from './config/Config';
import About from './components/About/about';
import UtilitiesPW from './components/Utilities-PW/UtilitiesPW';
import ComingSoon from './components/ComingSoon/comingSoon';
import Mobile from './components/Mobile/mobile';
import ClimateViewer from './components/ClimateChangeViewer/ClimateViewer';
 

export default class App extends Component<INavHistory> {
    state = {
        activeItem: "home",
        tooltipFactory: null,
        tooltipsReady: false,
        isMobile: false
    }

    // Will eventually need to implement an async function here to look for cookie info in the browser
    // And also put in componentDidUpdate so app will constantly be checking auth status
    componentDidMount() {
        
        this.handleFirstRender();    
        const tooltipFactory = new TooltipFactory(this.tooltipLoaded);
        this.setState({ tooltipFactory: tooltipFactory })   
    }

    tooltipLoaded = (dataLoaded: boolean) => {
        this.setState({ tooltipsReady: dataLoaded })
    }

    handleComponentChange = (name) => {
        let width = window.innerWidth;

        if (width < 800 && this.state) {
            this.setState({ activeItem: "mobile", isMobile: true }, () => {
                this.props.history.push(Config.routeUrl + "mobile");
            })
        }
        else {
            this.setState({ activeItem: name, isMobile: false }, () => {
                this.props.history.push(Config.routeUrl + name);
            })
        }
    }

    checkScreen = () => {
        let width = window.innerWidth;

        if (width < 800 && this.state) {
            //this.setState({ activeItem: "mobile", isMobile: true }, () => {
            //    this.props.history.push(Config.routeUrl + "mobile");
            //})
            this.handleComponentChange("mobile");
        }
    }

    handleFirstRender = () => {
        this.checkScreen();


        let desiredUrl: string = window.location.href;

        if (Config.appUrl == desiredUrl) {
            this.handleComponentChange("home");
        } else {

            let desiredRoute = desiredUrl.split(Config.appUrl)[1];
            if (desiredRoute.split("/").length > 1) {
                let parentRoute = desiredRoute.split("/")[0];
                this.setState({ activeItem: parentRoute.toLowerCase() })
                this.props.history.push(Config.routeUrl + parentRoute);
            } else {
                this.setState({ activeItem: desiredRoute.toLowerCase() })
                this.props.history.push(Config.routeUrl + desiredRoute);
            }
        }
        
    }

    getActiveComponent = (): JSX.Element => {
        //return (<ComingSoon />) // for coming soon lnly
        switch (this.state.activeItem) {
            case "downloads": {
                return (<Downloads />)
            };
            case "utilities": {
                return (<Utilities utilities={UtilityMenu.getMenu()} />)
            };
            case "pw-utilities": {
                return (<UtilitiesPW utilities={UtilityMenu.getMenu()} />)
            };
            case "home": {
                return (<Network />)
            };
            case "optimization": {
                return (<Optimization tooltipFactory={this.state.tooltipFactory}
                    tooltipsReady={this.state.tooltipsReady}/>)
            };
            case "cliamte": {
                return (<ClimateViewer />)
            };            
            case "links": {
                return (<Links />)
            };
            case "about": {
                return (<About />)
            };
            case "mobile": {
                return (<Mobile />)
            };
            default: {
                return (<Network />)
            }
        };
    };

    render() {

        let classContext: App = this;

        return (

            <div className='wmms-util-wrapper'>
                <Sticky>
                    {classContext.state.isMobile == false &&
                        <Menu stackable pointing  className="nav-bar">
                            <Menu.Item onClick={ () => {this.handleComponentChange('home')}}>
                                <img id="menuLogo" src={logo} alt="wmms2Logo"/>
                            </Menu.Item>


                                <Menu.Item name='about' active={classContext.state.activeItem === 'about'} onClick={() => this.handleComponentChange('about')}>About</Menu.Item>

                                <Menu.Item name='downloads' active={classContext.state.activeItem === 'downloads'} onClick={() => this.handleComponentChange('downloads')}><Icon name='cloud download' />Documents, Data &amp; Downloads</Menu.Item>

                                <Menu.Item name='utilities' active={classContext.state.activeItem === 'utilities'} onClick={() => this.handleComponentChange('utilities')}><Icon name='cogs' />Utilities</Menu.Item>

                                <Menu.Item name='optimization'  active={classContext.state.activeItem === 'optimization'} onClick={() => this.handleComponentChange('optimization')}><Icon name='chart area' />Optimization Viewer</Menu.Item>

                                <Menu.Item name='climate' disabled={classContext.state.isMobile} active={classContext.state.activeItem === 'climate'} onClick={() => this.handleComponentChange('climate')}><Icon name='leaf' />Climate Resiliency Viewer</Menu.Item>

                                <Menu.Item name='links' disabled={classContext.state.isMobile} active={classContext.state.activeItem === 'links'} onClick={() => this.handleComponentChange('links')}>Links</Menu.Item>
                        </Menu>
                    }
                    {classContext.state.isMobile == true &&
                        <Menu stackable pointing className="nav-bar">
                            <Menu.Item>
                                <img id="menuLogo" src={logo} alt="wmms2Logo" />
                            </Menu.Item>
                        </Menu>
                    }
                        
                </Sticky>

                <div className="container">
                    <React.Fragment>
                        {/*<Route exact path={Config.routeUrl + 'home'} render={(props) => { return <ComingSoon {...props} /> }} key={0} />*/ }
                        
                        <Route exact path={Config.routeUrl + 'about'} render={(props) => { return <About tooltipFactory={this.state.tooltipFactory} tooltipsReady={this.state.tooltipsReady} /> }} key={0} />
                        <Route exact path={Config.routeUrl + 'links'} render={(props) => { return <Links tooltipFactory={this.state.tooltipFactory} tooltipsReady={this.state.tooltipsReady} /> }} key={1} />
                        <Route exact path={Config.routeUrl + 'utilities'} render={(props) => { return <Utilities utilities={UtilityMenu.getMenu()} {...props} />}} key={2} />
                        <Route exact path={Config.routeUrl + 'pw-utilities'} render={(props) => { return <UtilitiesPW utilities={UtilityMenu.getMenu()} {...props} />}} key={3} />
                        <Route exact path={Config.routeUrl + 'home'} render={(props) => { return <Network {...props} />}} key={4} />
                        <Route exact path={Config.routeUrl + 'optimization'} render={(props) => { return <Optimization tooltipFactory={this.state.tooltipFactory} tooltipsReady={this.state.tooltipsReady} {...props}/>}} key={5} />
                        <Route exact path={Config.routeUrl + 'climate'} render={(props) => { return <ClimateViewer tooltipFactory={this.state.tooltipFactory} tooltipsReady={this.state.tooltipsReady} {...props}/>}} key={8} />
                        <Route exact path={Config.routeUrl + 'downloads'} render={(props) => { return <Downloads tooltipFactory={this.state.tooltipFactory} tooltipsReady={this.state.tooltipsReady} /> }} key={6} />
                        <Route exact path={Config.routeUrl + 'mobile'} render={(props) => { return <Mobile tooltipFactory={this.state.tooltipFactory} tooltipsReady={this.state.tooltipsReady} /> }} key={7} />
                    </React.Fragment>
                </div>
            </div>
        );
    };
};