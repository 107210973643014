import { BaseModel } from "../interfaces/IBaseModel";

export default class ModelDownloads extends BaseModel {

    id: number;
    filePath: string;
    fileName: string;
    displayName?: string;
    description?: string;
    downloadType: string;
    mimeType: string;
    archived: boolean;
    tooltipName?: string;
    displayWeight: number;


    constructor () {
        super("ModelDownloads")
    }
    
}