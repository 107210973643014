import axios, { AxiosResponse, AxiosPromise, AxiosRequestConfig, AxiosInstance } from 'axios';
import Config from '../config/Config';

export class BaseModel {

    //  thisType: new () => any; //this is populated by the constructor and is the constructor of the child class.  This is used to create new instances from the base

    /**
     * This
     * @param strEndPoint
     * @param type - just pass the actual class in, more example if the endpoint is BMPType and the model is BMPType, 
     * pass in BMPType as in the class, not in a string.  This is used to create new instances of the object
     */
    endpoint: string;

    constructor(strEndPoint: string) {//, type: new () => any ) {
        this.endpoint = strEndPoint;
        // this.thisType = type;
        this.axiosBaseInstance = this.makeBaseAxiosInstance();
        this.axiosBaseFileInstance = this.makeBaseAxiosFileInstance();
    }

    axiosBaseInstance: AxiosInstance;
    axiosBaseFileInstance: AxiosInstance;
    axiosBaseZipInstance: AxiosInstance;

    // ----- Axios config Functions ----- //

    private makeBaseAxiosInstance = function (): AxiosInstance {
        let context: BaseModel = this;
        let token: string = '';


        return axios.create({
            baseURL: Config.api_endpoint,
            headers: {
                "Access-Control-Allow-Origin": '*',
                "Content-Type": 'application/json;charset=UTF-8'
            }
        });

    };

    private makeBaseAxiosFileInstance = function (): AxiosInstance {
        let context: BaseModel = this;
        let token: string = '';

        return axios.create({
            baseURL: Config.api_endpoint,
            headers: {
                "Access-Control-Allow-Origin": '*',
                "Content-Type": 'multipart/form-data'
            }
        });

    };

    static axiosGet = function (id: number): AxiosPromise {
        let t: BaseModel = new this();  //new (<any>this)();
        let d = new Date();
        let queryString = d.valueOf().toString();
        let endpoint = String(t.endpoint + "/" + id + "?" + queryString);

        return t.axiosBaseFileInstance({
            method: 'GET',
            url: endpoint
        });
    };

    static axiosPostUpload = function (formData: FormData): AxiosPromise {
        let t: BaseModel = new this();  //new (<any>this)();
        let endpoint = String(t.endpoint + "/upload");

        return t.axiosBaseFileInstance({
                method: 'POST',
            url: endpoint,
            data: formData
        });
    };

    static axiosGetAll = function (data?): AxiosPromise {
        //console.log("axios data: " + data)
        let t: BaseModel = new this();
         return t.axiosBaseInstance({
            method: "GET",
            url: t.endpoint,
            params: data
        })
    };

    static axiosGetBlob = function (data?) {
        //let t: BaseModel = new (<any>this)();
        let t: BaseModel = new this();
        let endpoint = String(t.endpoint);

        return t.axiosBaseFileInstance({
                method: 'GET',
            url: endpoint,
            params: data,
            responseType: 'blob'
        });
    };

    static axiosGetBlobById = function (id: number) {
        //let t: BaseModel = new (<any>this)();
        let t: BaseModel = new this();
        let endpoint = String(t.endpoint + "/download/" + String(id));

        return t.axiosBaseFileInstance({
                method: 'GET',
            url: endpoint,
            responseType: 'blob'
        });
    };

    static axiosPostImage = function (data?) {
        //let t: BaseModel = new (<any>this)();
        let t: BaseModel = new this();
        let endpoint = String(t.endpoint);

        return t.axiosBaseFileInstance({
            method: 'POST',
            url: endpoint,
            data: data,
            responseType: 'blob'
        });
    };

    static MultiRequest = function (requests: AxiosPromise[]) {
        return axios.all(requests);
    }; 
}