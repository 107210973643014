import './mjrbmpsolutions.css';
import React, { Component } from "react";
import { IUtilityCard } from '../../../interfaces/IUtilityCard';
import { Grid, Icon, Message, Label, Checkbox, Button } from 'semantic-ui-react';
import { Config } from '../../../config/Config';
import { mjrsolutions } from '../../../models/mjrsolutions';
import { AxiosResponse } from 'axios';
import { processviewmodel } from '../../../models/processviewmodel';
import { setInterval } from "timers";
import DownloadLink from '../../../core/downloadLink/downloadLink';


export class MjrBMPSolutionsState {
    zipfilename: string = "No selection";
    zipfile: File = null;
    wmmsfilename: string = "No selection";
    wmmsfile: File = null;
    //email: string = "";
    //reduction: number = null;

    processing: boolean = false;
    processStatus: string = "";
    processMessage: string = "";
}

export default class MjrBMPSolutions extends Component<IUtilityCard> {

    state = new MjrBMPSolutionsState;
    interval: NodeJS.Timeout;

    componentDidMount() {
    }

    uploadFile() {
        // error list
        let list = new Array<string>();
        if (this.state.zipfile == null || this.state.wmmsfile == null) {
            list.push("Please select all needed files");
            this.handleErrors(list);
        } else {
            let classContext: MjrBMPSolutions = this;
            classContext.setState({ processing: true });
            this.handleErrors(new Array<string>());
            let form = new FormData();

            form.append("data", this.state.zipfile);
            form.append("data", this.state.wmmsfile);


            //if (this.state.email.length > 0) {
            //    if (classContext.validateEmail(classContext.state.email)) {
            //        form.append("email", classContext.state.email);
            //    } else {
            //        list.push("The email address provided is invalid");
            //        classContext.handleErrors(list);
            //        classContext.setState({ processing: false });
            //        return;
            //    }
            //}

            mjrsolutions.axiosPostUpload(form).then((r: AxiosResponse) => {
                classContext.setStatusMessage(r.data.status, r.data.message);
                if (r.data.status.toLowerCase() == "submitted") {
                    let data: processviewmodel = r.data;
                    if (data.queueId > 0) {
                        let id = data.queueId;
                        let intvl = setInterval(() => {
                            processviewmodel.axiosGet(id).then((r: AxiosResponse) => {
                                let data: processviewmodel = r.data;
                                classContext.setStatusMessage(data.status, data.message);
                                if (data.status != undefined && data.status.toLowerCase() == "completed") {
                                    classContext.props.stopInterval();
                                    if (data.message != null) {
                                        list.push(data.message);
                                        classContext.handleErrors(list);
                                    }
                                    classContext.getResult(data.resultId);
                                } else if (data.status != undefined && data.status.toLowerCase() == "error") {
                                    classContext.props.stopInterval();
                                    classContext.setState({ processing: false });
                                    list.push("Error during processing");
                                    list.push(data.message);
                                    classContext.handleErrors(list);
                                }
                                else {
                                }
                            });
                        }, 2000);
                        classContext.props.beginInterval(intvl);
                    }
                } else if (r.data.status.toLowerCase() == "error") {
                    classContext.setState({ processing: false });
                    list.push("Error during processing");
                    list.push(r.data.message);
                    classContext.handleErrors(list);
                } else {
                    classContext.setState({ processing: false });
                    list.push("Unknown error");
                    classContext.handleErrors(list);
                }
            }).catch(function (error) {
                classContext.setState({ processing: false });
                list.push(error.message);
                classContext.handleErrors(list);
            })
        }
    }

    getResult(id: number) {
        let classContext: MjrBMPSolutions = this;
        classContext.setState({ processing: false });
        if (id > 0) {
            window.location.href = Config.api_endpoint + 'MjrBMPSolutions/' + id;
        }
    }


    setStatusMessage(status: string, message: string) {
        let classContext: MjrBMPSolutions = this;
        if (status != undefined && (status.toLowerCase() == "submitted" || status.toLowerCase() == "queued" || status.toLowerCase() == "processing")) {
            classContext.setState({ processStatus: status, processMessage: message })
        } else {
            classContext.setState({ processStatus: "", processMessage: "" })
        }
    }

    handleErrors = (list) => {
        let err = document.getElementById('errorPlace');
        err.classList.remove('hidden');

        let msgs = document.getElementById('errorMessages');
        while (msgs.firstChild) {
            msgs.removeChild(msgs.firstChild);
        }
        if (list.length > 0) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].indexOf(';') > 0) {
                    var smallArr = list[i].split(';');
                    for (var j = 0; j < smallArr.length; j++) {
                        if (smallArr[j].length > 1) {
                            let dv = document.createElement('li');
                            dv.append(smallArr[j])
                            msgs.appendChild(dv);
                        }
                    }
                }
                else {
                    let dv = document.createElement('li');
                    dv.append(list[i])
                    msgs.appendChild(dv);
                }
            }
        } else {
            err.classList.add('hidden');
        }
    }

    handleItemUpdate(evt, typestr) {
        let lbl = document.getElementById(typestr + 'uploadlabel');
        if (evt.target.files.length > 0) {
            lbl.classList.remove('red');
            lbl.classList.add('green');
            this.setState({ [typestr + 'filename']: evt.target.files[0].name, [typestr + 'file']: evt.target.files[0] });
            this.handleErrors(new Array<string>());
        } else {
            lbl.classList.add('red');
            lbl.classList.remove('green');
            this.setState({ [typestr + 'filename']: 'None Selected', file: null });
        }
    }


    render() {
        let classContext: MjrBMPSolutions = this;
        return (
            <div className='scroll-fix'>
                <div>
                    <Grid divided='vertically'>
                        <Grid.Row>
                            <h2><Icon className={'page-icon wmms-' + this.props.wmmsColor}  name={this.props.cardIcon} circular inverted />{this.props.cardTitle}</h2>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    The MJR BMP Utility is used when Multi-jurished Regional (MJR) BMPs are present within the modeled domain.  MJR BMPs are projects whose drainage areas are contained within more than one jurished. If MJR BMPs are not present, users should proceed directly to the Two-Tiered Optimization Utility. 
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                    This utility processes output files from the second tier of optimization (<i>2production</i> folder) such that the performance of MJR BMPs can be evaluated in conjunction with other upstream regional and distributed projects. Reference the  <DownloadLink endpointUrl={Config.api_endpoint + '/ModelDownloads'} recordId={38} linkText="Optimization Utilities User Document" filename="OptimizationUtilitiesUserDocument.pdf" mimetype="application/pdf" bold={true} wmmsColor={classContext.props.wmmsColor} /> for a full description of the processes and mechanics for executing this utility. 
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <div>
                                    <div id="errorPlace" className="hidden">
                                        <Message color='red' onDismiss={() => classContext.handleErrors(new Array<string>())}>
                                            <Message.Header>Processing Messages:</Message.Header>
                                            <Message.List id='errorMessages'>
                                            </Message.List>
                                        </Message>
                                    </div>

                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.zipfilename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='zipupload'><Label className="upload-basic-btn" color='red' basic id='zipuploadlabel'>Click here to select a .zip file.</Label></label>
                                        <input id='zipupload' type="file" accept=".zip" onChange={(evt) => classContext.handleItemUpdate(evt, 'zip')} className="hidden" />
                                    </div>
                                    <div className='top-padding'>
                                        <div className='padding-bottom-point5'>File Name:<b> {classContext.state.wmmsfilename}</b></div>
                                        <label className="upload-basic-btn" htmlFor='wmmsupload'><Label className="upload-basic-btn" color='red' basic id='wmmsuploadlabel'>Click here to select a .wmms file.</Label></label>
                                        <input id='wmmsupload' type="file" accept=".wmms" onChange={(evt) => classContext.handleItemUpdate(evt, 'wmms')} className="hidden" />
                                    </div>
                                    {/*<div className='top-padding'>
                                        <div className='padding-bottom-point5'>Optional Email:<b> Provide email address to receive completion email</b></div>
                                        <div className="ui input emailtextpad"><input className="textinput" id='useremail' disabled={this.state.processing} type="text" placeholder='email@email.com' value={classContext.state.email} onChange={(evt) => classContext.handleEmailUpdate(evt)} /></div>
                                    </div >*/}
                                </div>
                                <br />
                                <div className="semantic-brown-disabled-btn">
                                    <Button size='medium' loading={this.state.processing} disabled={this.state.processing} className={"wmms-" + this.props.wmmsColor + "-btn"} onClick={() => this.uploadFile()} >Process</Button>
                                </div>
                                <b>{this.state.processStatus}</b> {this.state.processMessage}
                                <div className='WeatherStatus'>
                                </div>
                            </Grid.Column>
                            <Grid.Column className="">
                                <div>
                                    <p><b>Process Description:</b></p>
                                    <p>1. Upload the zipped folder containing output in the <i>2production</i> folder. This folder is located within the broader package of folders output from the BMP Opportunities Utility.</p>
                                    <p>2. Upload the ".wmms" file within the <i>4viewer</i> folder. This file contains BMP and model specifications as defined in the BMP Opportunities template spreadsheet template. </p>
                                </div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>

            </div>
        );
    }
}