import React, { Component } from "react";
import IOptimizationSVGProps from "./interfaces/IOptimizationSVGProps";
import * as d3 from 'd3';
import AxisLeft from "./chartPieces/AxisLeft";
import AxisRightMillionFormat from "./chartPieces/AxisRightMillionFormat";
import AxisBottom from "./chartPieces/AxisBottom";
import Path from "./chartPieces/Path";
import Areas from "./chartPieces/Areas";
import Circle from "./chartPieces/Circle";
import Callout from "./chartPieces/Callout";
import LabelLeft from "./chartPieces/LabelLeft";
import LabelBottom from "./chartPieces/LabelBottom";
import LabelRight from "./chartPieces/LabelRight";
import Line from "./chartPieces/Line";

export class OptimizationSVGState {
  cx: number = null;
  cy: number = null;
  mouseXvalue: number = null;
  mouseYvalue: number = null;
  calloutX: number = null;
  calloutY: number = null;
  label: string = "";
  selectedPct: number = null;
  selectedCost: number = null;
}

export default class OptimizationSVG extends Component<IOptimizationSVGProps> {

    state = new OptimizationSVGState();
    xScale: any;
    yScaleLeft: any;
    yScaleRight: any;
    myBisect: any;
    uniqueID: string;


    forceResetCircle = () => {
      this.setState({
        cx: null,
        cy: null
      });
    }

    // have to reset cx & cy when props change due to update button, otherwise circle won't position correctly
    componentDidUpdate(prevProps) {

      const classContext: OptimizationSVG = this;

      if (this.props.defaultCirclePct != prevProps.defaultCirclePct) {
        this.setState({
          cx: null,
          cy: null
        });
      }

      d3.select(("#" + this.uniqueID)).select(".opt-rect")
      .on("mousemove", function (x, y, z) {
        let mouseCoords = d3.mouse(z[0]);
        let res = classContext.bisect(mouseCoords[0]); 
        let xcoord = classContext.xScale(res.x);
        let ycoord = classContext.yScaleRight(res.y);
        let label = res.x.toLocaleString() + "%";
        classContext.setState({ 
          mouseXvalue: res.x, 
          mouseYvalue: res.y, 
          calloutX: xcoord, 
          calloutY: ycoord, 
          label: label 
        });

      })
      .on("mouseout", function (x ,y ,z) {
        classContext.setState({ mouseXvalue: null, mouseYvalue: null, calloutX: 0, calloutY: 0 });
      })
      .on("click", function () {
        classContext.setPointOnClick(classContext.state.calloutX, classContext.state.calloutY, classContext.state.mouseXvalue, classContext.state.mouseYvalue);
      }) 


    }

    componentWillMount = () => {

      this.uniqueID = this.getUniqueId();

    }

    componentDidMount = () => {
      const classContext: OptimizationSVG = this;

      d3.select(("#" + this.uniqueID)).append('rect')
        .attr("class", "opt-rect")
        .attr('width', this.props.svgWidth)
        .attr('height', this.props.svgHeight)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
      .on("mousemove", function (x, y, z) {
        let mouseCoords = d3.mouse(z[0]);
        let res = classContext.bisect(mouseCoords[0]); 
        let xcoord = classContext.xScale(res.x);
        let ycoord = classContext.yScaleRight(res.y);
        let label = res.x.toLocaleString() + "%";
        classContext.setState({ 
          mouseXvalue: res.x, 
          mouseYvalue: res.y, 
          calloutX: xcoord, 
          calloutY: ycoord, 
          label: label 
        });

      })
      .on("mouseout", function (x ,y ,z) {
        classContext.setState({ mouseXvalue: null, mouseYvalue: null, calloutX: 0, calloutY: 0 });
      })
      .on("click", function () {
        classContext.setPointOnClick(classContext.state.calloutX, classContext.state.calloutY, classContext.state.mouseXvalue, classContext.state.mouseYvalue);
      }) 

    }


    getUniqueId = () => {

      return "optSVG" + String(Math.floor(Math.random() * 6) + 1) + String(Math.floor(Math.random() * 6) + 1) + String(Math.floor(Math.random() * 6) + 1) + String(Math.floor(Math.random() * 6) + 1) + String(Math.floor(Math.random() * 6) + 1); 

    }

    bisect = (mx: any) => {
      let reduction = this.xScale.invert(mx);
      let index = this.myBisect(this.props.reductionData, reduction, 1);
      let a = this.props.reductionData[index - 1];
      let b = this.props.reductionData[index];
      if (a == null && b != null) { return b; }
      if (a != null && b == null) { return a; }
      if (a == null && b == null) { return { x: null, y: null };}
      return reduction - a.reduction > b.reduction - reduction ? b : a;
    }

    mouseEvent = (event: any) => {
      let res = this.bisect(event.clientX); // hard-coded margin left for now - offset of sorts?
      let xcoord = this.xScale(res.x);
      let ycoord = this.yScaleRight(res.y);
      let label = res.x.toLocaleString() + "%";
      this.setState({ 
        mouseXvalue: res.x, 
        mouseYvalue: res.y, 
        calloutX: xcoord, 
        calloutY: ycoord, 
        label: label 
      });
    }

    mouseLeave = () => {
      this.setState({ mouseXvalue: null, mouseYvalue: null, calloutX: 0, calloutY: 0 });
    }

    setPointOnClick = (xCoord, yCoord, xValue, yValue) => {
      let classContext: OptimizationSVG = this;
      this.setState({ 
        cx: xCoord, 
        cy: yCoord 
      });
      // send selected point back up so it can be used for data table
      classContext.props.clickHandler(xValue, yValue);
    }

    render() {      
      let classContext: OptimizationSVG = this;
      const { svgHeight, svgWidth, crcData, reductionData, maxCapacity, bmpGroupInfo, colors, defaultCirclePct } = this.props;
  
      // right margin is larger to accomodate large numbers in tick labels
      const margin = { top: 15, right: 70, bottom: 55, left: 55 };
      const width = svgWidth - margin.left - margin.right;
      const height = svgHeight - margin.top - margin.bottom;
  
      // 'range' defines the space on the svg that the element can use (in pixels)
      // 'domain' defines the values that will show on the axes
      // with range and domain defined, d3 will scale appropriately and create ticks with values on the axes
      // if needed, can additionally define how we want ticks formatted (eg $10M instead of 10,000,000)

      classContext.yScaleLeft = d3.scaleLinear().range([height, 0]);
      classContext.xScale = d3.scaleLinear().range([0, width]);
      classContext.yScaleRight = d3.scaleLinear().range([height, 0]);
      classContext.myBisect = d3.bisector(d => d.x).right;
      
      let defaultcx = 0;
      let defaultcy = 430;

      // reductionData & crcData are undefined on first render...
      if (reductionData != undefined && maxCapacity != undefined) {
        const maxReduction = reductionData.reduce((max, b) => Math.max(max, b.x), reductionData[0].x);
        classContext.xScale.domain([0, maxReduction]);
        classContext.yScaleRight.domain([0, d3.max(reductionData, d => d.y) || 0]);
        classContext.yScaleLeft.domain([0, maxCapacity || 0]);

        // set default location of circle
        let circleCoords = reductionData.find(x => x.x == defaultCirclePct);
        if (circleCoords != undefined) {
          defaultcx = classContext.xScale(circleCoords.x);
          defaultcy = classContext.yScaleRight(circleCoords.y);
        }
      } 
      
      // find y-coord of uppermost area at x-coord of circle (to use for drawing vertical line)
      let lineY = 0;
      if (crcData != undefined) {
        let matchingYs = [];
        if (classContext.state.cx == null) {
            Object.keys(crcData).forEach(key => {
                let item = crcData[key].find(x => classContext.xScale(x.x) == defaultcx);
                //console.log('item', item);
                if (item != null) {
                    let match = classContext.yScaleLeft(item.y);
                    matchingYs.push(match);
                }
          });
        } else {
            Object.keys(crcData).forEach(key => {
                let item = crcData[key].find(x => classContext.xScale(x.x) == classContext.state.cx);
                if (item != null) {
                    let match = classContext.yScaleLeft(item.y);
                    matchingYs.push(match);
                }
          });
        }
        lineY = Math.min(...matchingYs);
      }
      
      // create props for all chart pieces
      const axisBottomProps = {
        height,
        scale: classContext.xScale,
        class: "opt-viewer-axis"
      };

      const axisLeftProps = { 
        scale: classContext.yScaleLeft,
        class: "opt-viewer-axis"
      };

      const axisRightProps = { 
        scale: classContext.yScaleRight,
        position: width,
        color: "rgb(128, 0, 0)",
        class: "opt-viewer-axis opt-viewer-axis-right"
      };

      const labelBottomProps = {
        text: "Reduction (%)",
        width: width,
        height: height,
        margin: margin,
        fontSize: "15"
      };

      const labelLeftProps = {
          text: "Capacity (ac-ft)",
          width: width,
          height: height,
          margin: margin,
          fontSize: "15"
      };

      const labelRightProps = {
        text: "Implementation Cost ($)",
        width: width - 5,
        height: height,
        margin: margin,
        fontSize: "15"
        // color: "rgb(128, 0, 0)"
     };

      const areaProps = {
        crcData,
        xScale: classContext.xScale,
        yScaleLeft: classContext.yScaleLeft,
        yScaleRight: classContext.yScaleRight,
        height,
        bmpGroupInfo,
        colors
      };

      const pathProps = {
        data: reductionData,
        yScale: classContext.yScaleRight,
        xScale: classContext.xScale,
        height,
        fill: "none",
        color: "rgb(128, 0, 0)",
        strokeWidth: "3"
      };

      const circleProps = {
        radius: 7,
        color: "rgb(128, 0, 0)",
        cx: classContext.state.cx,
        cy: classContext.state.cy,
        defaultcx,
        defaultcy
      };

      const lineProps = {
        defaultx: defaultcx,
        x: classContext.state.cx,
        y: lineY,
        // circleCoords: {cx: classContext.state.cx, cy: lineY},
        // defaultCircleCoords: {cx: defaultcx, cy: lineY},
        height,
        color: "rgb(128, 0, 0)",
        strokeWidth: "2"
      }

      const calloutProps = {
        xvalue: classContext.state.mouseXvalue,
        yvalue: classContext.state.mouseYvalue,
        xCoord: classContext.state.calloutX,
        yCoord: classContext.state.calloutY,
        label: classContext.state.label
     };
    
      // NOTE: order of chart pieces is important. first rendered will be in back, with each additional piece overlayed
        return (
            <div>
                {classContext.props.isSample == true && <div className="watermark">SAMPLE DATA</div>}
        <svg className="optSVG" height={svgHeight} width={svgWidth}>
          <g id={this.uniqueID}  transform={`translate(${margin.left},${margin.top})`}>
            <Areas {...areaProps} />
            <Path {...pathProps} />
            <Circle {...circleProps} />
            <Line {...lineProps} />
            <AxisLeft {...axisLeftProps} />
            <LabelLeft {...labelLeftProps} />
            <AxisBottom {...axisBottomProps} />
            <LabelBottom {...labelBottomProps} />
            <AxisRightMillionFormat {...axisRightProps} />
            <LabelRight {...labelRightProps} />
            <Callout {...calloutProps} />
          </g>
                </svg>
            </div>
      );
    }
}