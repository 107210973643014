// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import React, { Component } from "react";
import { ResponsiveLine } from '@nivo/line'
import { PatternLines } from "@nivo/core";
import {animated, to} from '@react-spring/web'


export const MyResponsiveLine = ({ data, xAxisName, yAxisName, selScenXVal, lineLegendClick, seriesClick, xAxisUnit, yAxisUnit }) => {
   //        transform={to([x, y], (x, y) => `translate(${x},${y})`)}
    const CustomLeftTick = (val: any, animatedProps: any) => {
        return (
            <animated.g opacity={animatedProps.opacity} transform={animatedProps.transform}>
                <line x1="0" x2="5" y1="0" y2="0" stroke="rgb(119, 119, 119)" stroke-width="1px"></line>    
                <text dx={-10}
                    textAnchor="end"
                    dominantBaseline="middle"
                    style={{
                        fill: '#333',
                        fontSize: 12,
                        fontWeight: 700
                    }}
                >
                    {val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + yAxisUnit}
                </text>
            </animated.g>
        )
    }

    const CustomRightTick = (val: any, animatedProps: any) => {
        return (
            <animated.g opacity={animatedProps.opacity} transform={animatedProps.transform}>
                <line x1="0" x2="5" y1="0" y2="0" stroke="rgb(119, 119, 119)" stroke-width="1px"></line>    
                <text dx={8}
                    textAnchor="start"
                    dominantBaseline="middle"
                    style={{
                        fill: '#333',
                        fontSize: 12,
                        fontWeight: 700
                    }}
                >
                    {val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + yAxisUnit}
                </text>
            </animated.g>
        )
    }

    const CustomBottomTick = (val: any, animatedProps: any) => {
        return (
            <animated.g opacity={animatedProps.opacity} transform={animatedProps.transform} >
                <line x1="0" x2="" y1="0" y2="5" stroke="rgb(119, 119, 119)" stroke-width="1px"></line>   
                <text dy={20}
                style={{
                    fill: '#000',
                    fontWeight: 700,
                    fontSize: '12px',
                    textAnchor:  'middle'
                }}>
                    {val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + xAxisUnit}
                </text>
            </animated.g>
        )
    }
    
    return(

    <ResponsiveLine
        data={data}
        animate={true}
        motionConfig={'wobbly'}
        margin={{ top: 20, right: 270, bottom: 80, left: 95 }}
        xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false }}
        axisTop={null}
        axisRight={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yAxisName,
            legendOffset: 75,
            legendPosition: 'middle',
            renderTick: ({ value, animatedProps }) => CustomRightTick(value, animatedProps)
        }}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: xAxisName,
            legendOffset: 36,
            legendPosition: 'middle',
            renderTick: ({ value, animatedProps }) => CustomBottomTick(value, animatedProps)
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yAxisName,
            legendOffset: -90,
            legendPosition: 'middle',
            renderTick: ({ value, animatedProps }) => CustomLeftTick(value, animatedProps)
        }}
        colors={["#F59841", "#62AEE4", "#009933","#C288F8", "#4DD6D3", "#F9E43E", "#D5D5D5", "#FF7777", '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']}
        pointSize={6}
        markers={[
            {
                axis: 'x',
                value: selScenXVal,
                lineStyle: { stroke: '#00b8e655', strokeWidth: 5},
                legend: 'selected solution',
                // textStyle: {padding: '50px'},
                legendPosition: 'top',
            }
        ]}
        //layers={ ['grid','axes', 'markers', 'lines', 'mesh', 'points', 'legends']}
        pointLabelYOffset={-12}
        useMesh={true}
        onClick={function(data, evt) {
            //console.log('click graph', data, evt);
            seriesClick(data.data.y, data.serieId);
        }}
        //legendLabel={datum => `${datum.id}`}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: true,
                translateX: 270,
                // translateX: 230,
                translateY: 30,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                // itemWidth: 140,
                itemWidth: 175,
                itemHeight: 18,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ],
                // padding: 10,
                onClick:function(data, event) {lineLegendClick(data)}
            }
        ]}
    />)
}