import { LayerInfo } from '../mapTypes/LayerInfo';
import { TileWMS } from 'ol/source';

// --- qgis server queries helper class ----
// QGIS server docs on formatting requirements below:
// https://docs.qgis.org/testing/en/docs/user_manual/working_with_ogc/server/services.html#getfeatureinfo

export class FeatureInfoFinder {

    // baseURL is the url that comes from the featureInfoUrl property of an ESRI WMS layer. 
    constructor(baseURL: string, webMercatorUtils: any, Point: any, mapView: any) {
        this.baseURL = baseURL;
        this.webMercatorUtils = webMercatorUtils;
        this.Point = Point;
        this.mapView = mapView
    };

    mapView: any;
    baseURL: string;
    webMercatorUtils: any;
    Point: any;
    olTileSource: any = null;

    buildQueryString = (layerInfos: LayerInfo[], latitude: number, longitude: number): string => {

        let layerString = "";

        layerInfos.forEach((l: LayerInfo, index: number) => {
            if (l.queryFeatures && l.layerType == 'wms') {
                layerString += l.name;
                if (index !== (layerInfos.length - 1)) {
                    layerString += ",";
                };
            };
        });

        if (layerString == "") {
            return null;
        }

        let service = "SERVICE=WMS";
        let version = "VERSION=1.1.0";
        let request = "REQUEST=GetFeatureInfo";
        let format = "FORMAT=image.png";
        let transparent = "TRANSPARENT=true";
        let queryLayers = "QUERY_LAYERS=" + layerString;
        let layers = "LAYERS=" + layerString;
        let height = "HEIGHT=256";
        let width = "WIDTH=256";
        let crs = "CRS=EPSG:3857";
        let infoFormat = "INFO_FORMAT=text/xml";
        
        let iParam = "I=256";
        let jParam = "J=256";
        
        
        // let topPoint = new this.Point({
        //     latitude: ( latitude + .5 ),
        //     longitude: ( longitude - .5 )
        // });
        
        // let bottomPoint = new this.Point({
        //     latitude: ( latitude - .5 ),
        //     longitude: ( longitude + .5 )
        // });
        
        // let webTop = this.webMercatorUtils.geographicToWebMercator(topPoint);
        // let webBottom = this.webMercatorUtils.geographicToWebMercator(bottomPoint);

        
        //  ------------------- alternative using open layers ot generate info URL --------------------------
        let singlePoint = new this.Point({
            latitude: ( latitude  ),
            longitude: ( longitude  )
        });
        let clickCenter = this.webMercatorUtils.geographicToWebMercator(singlePoint);

        if (this.olTileSource == null) {
            this.olTileSource = new TileWMS(({
                url: this.baseURL,
                params: {
                    'LAYERS': layerString, 'TILED': true, 'VERSION': '1.3.0',
                    'FORMAT': 'image/png', 'WIDTH': 256, 'HEIGHT': 256, 'CRS': 'EPSG:3857'
                },
                serverType: 'geoserver'
            }));
        }
        
        
        let viewResolution = this.mapView.resolution; //this.map.getView().getResolution();
        let url = this.olTileSource.getFeatureInfoUrl(
            [clickCenter.x, clickCenter.y], viewResolution, 'EPSG:3857',
            { 'INFO_FORMAT': 'text/xml' });
            
            
        // -------------------------------------------------------------------------- 


        // let bottomPoint = new this.Point({
            //     latitude: ( latitude  ),
            //     longitude: ( longitude  )
            // });
                
                

        // // -13209541.4801,3960355.3095,-13079598.5320,4066144.1567
        
        //         let x1 = -13209541.4801;
        //         let y1 = 3960355.3095;
        //         let x2 = -13079598.5320;
        //         let y2 = 4066144.1567;

            
        // let bbox = "BBOX=" + String(webBottom.y) + "," + String(webBottom.x) + "," + String(webTop.y) + "," + String(webTop.x);
        // let bbox = "BBOX=" + String(webBottom.x) + "," + String(webTop.x);
        // let bbox = "BBOX=" + String(webBottom.x) + "," + String(webBottom.y) + "," + String(webTop.x) + "," + String(webTop.y);

        // let fullQuery = this.baseURL + "&" + service + "&" + version + "&" + request + "&" + format +  "&"+ transparent + "&" + queryLayers + "&" + layers + "&" + height + "&" + width + "&" + crs + "&" + infoFormat + "&" + "TILED=true"  + "&" + bbox + "&" + iParam +  "&" + jParam

        // let encodedURI = encodeURI(fullQuery);

        //console.log(encodedURI, "query URI");


        return url;
    };


};